import React from "react";
import { AwIcon } from "../../../../../components";


interface Props {
    open: any;
}

export const AddPhoneBtn: React.FC<Props> = ({open}) => {
    return (
        <div title="Setup report messaging." className={"grid-dd-phone-btn"}>
            <AwIcon {...{onIconClick: open, iconName: "question", size: '1rem', color: 'red' }}   />
        </div>
    )
  }