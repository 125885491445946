import React, { useRef, useEffect } from 'react';

import "./OrderPageTabs.css";

interface Props {
    tabs: {label: string;}[];
    changeContent: (label: string) => void;
    children?: any;
}

export const OrderPageTabs: React.FC<Props> = ({tabs, changeContent, ...props}) => {
    const elRef = useRef<any>();
    useEffect(() => {
        if(elRef && elRef.current){
            const label = elRef.current.querySelector("label[for='0']");
            label.click();
        }
        return () => {}
    }, [])

    return (
        <div className="v-tab-container">
            <div className="v-tabs" ref={elRef}>
                {tabs.map((tab,idx) => {
                    return (
                        <div key={idx} className="v-tab-wrap">
                            <input type="radio" name="tab" id={`${idx}`} value={tab.label} />
                            <label className="v-tab" htmlFor={`${idx}`} onClick={() => changeContent(tab.label)}>
                                {tab.label.replace(/([a-z])([A-Z])/g, '$1 $2')}
                            </label>
                        </div>
                    )
                })}
            </div>
            <div className="v-content">
                {props.children}
            </div>
        </div>
    );
}