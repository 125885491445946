import React from "react";
import { useDispatch } from "react-redux";
import { signOut } from "../../store";
import {
  getLoggedInUser,
  getRefreshToken,
  removeLoggedInUser
} from "../../utils";
import api from "../../store/api";
import {
  signInFailed_type,
  getSettingsFailed_type,
  getSettingsSuccess_type,
  signInSuccess_type
} from "../../store/actiontypes";
import { ConfigContext } from "../../AwAppContext";

export const useAuthResLogic = () => {
  const dispatch = useDispatch();

  const { awConfig, setAwConfig } = React.useContext(ConfigContext);

  const getStateSettingsAsync = async () => {
    let success = true;
    if (!awConfig.statesettings.length) {
      const settingsRes = await api.settings.getSettings();
      const { type, data } = settingsRes;
      success = !(type === "error");
      if (!success) {
        dispatch({
          type: getSettingsFailed_type,
          payload: data.message
        });
      } else {
        setAwConfig({ ...awConfig, statesettings: data });
        dispatch({ type: getSettingsSuccess_type, payload: data });
      }
    }
    return success;
  };

  const signInAUserAsync = async (action, loginPage = false) => {
    const res = await api.user.signIn(action);
    let success = !(res.type === "error");
    if (!success) {
      const { data } = res;
      let errorMsg = data.message;
      if (!loginPage) shouldLogOut();
      dispatch({ type: signInFailed_type, payload: errorMsg });
    } else {
      setAwConfig(state => ({
        ...state,
        session: { time: res.data.expires_in, reset: res.data.Token }
      }));
      dispatch({ type: signInSuccess_type, payload: res.data });
    }
    return success;
  };

  
  const shouldRefreshAsync = async () => {
    const token = getLoggedInUser();
    await getStateSettingsAsync();

    if (token && Object.keys(token).length) {
      let credentials = { refresh_token: "" };
      credentials.refresh_token = getRefreshToken(token);
      const refreshAction = {
        type: "refresh_login",
        payload: credentials
      };

      await signInAUserAsync(refreshAction);
    } else {
      shouldLogOut();
    }
  };

  const shouldLogOut = async () => {
    await api.requestmethods.get("api/auth/logout"); // it returns null if user logged out successfully
    dispatch(signOut());
    removeLoggedInUser();
  };

  return {
    shouldLogOut,
    shouldRefreshAsync,
    getStateSettingsAsync,
    signInAUserAsync
  };
};

