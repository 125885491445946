import React from "react";
import { ConfigContext } from "../../AwAppContext";
import { IGrowlFuncs } from "../message";

export const useAwToastR = () => {
  const { awConfig } = React.useContext(ConfigContext);
  const msgSvc = (awConfig.msgFuncs.current as IGrowlFuncs);
  return msgSvc;
};

