import { screenTypes } from "./FuncUtil";

export const getNewState = () => {
  const state = {
    skip: 0,
    take: 50,
    pageSize: 50,
    sort: [],
    pageable: {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true
    }
  };
  return state;
};
export const gridBreakpoint = 1000;
export const gridMidBreakpoint = 1400;
function getScreenCols(cols: any[], addedCol: number) {
  switch (addedCol) {
    case 0:
      return cols.filter((value, index, array) => index < 3);
    case 1:
      return cols.filter((value, index, array) => index < 2);
    case 2:
      return cols.filter((value, index, array) => index < 1);
    // case screenTypes.landscapeTablet:
    //   return cols.filter((value, index, array) => index < 4);
    // case screenTypes.smallScreen:
    //   return cols.filter((value, index, array) => index < 5);
    default:
      return cols.filter((value, index, array) => false);
  }
}

function getMidScreenCols(cols: any[], addedCol: number) {
  switch (addedCol) {
    case 0:
      return cols.filter((value, index, array) => index < 5);
    case 1:
      return cols.filter((value, index, array) => index < 4);
    case 2:
      return cols.filter((value, index, array) => index < 3);
    // case screenTypes.landscapeTablet:
    //   return cols.filter((value, index, array) => index < 4);
    // case screenTypes.smallScreen:
    //   return cols.filter((value, index, array) => index < 5);
    default:
      return cols.filter((value, index, array) => false);
  }
}

export const getGridScreenColumns = (
  screen: any,
  cols: any[],
  addedCol: number = 0
): any[] => {
  if (screen.width <= gridBreakpoint) {
    return getScreenCols(cols, addedCol);
  } else if (
    screen.width > gridBreakpoint &&
    screen.width < gridMidBreakpoint
  ) {
    return getMidScreenCols(cols, addedCol);
  } else {
    return cols.filter((value, index, array) => true);
  }
  // switch (screen) {
  //   case screenTypes.phone:
  //     return cols.filter((value, index, array) => index < 1);
  //   case screenTypes.landscapePhone:
  //     return cols.filter((value, index, array) => index < 2);
  //   case screenTypes.portraitTablet:
  //     console.log(cols.filter((value, index, array) => index < 3));
  //     return cols.filter((value, index, array) => index < 3);
  //   case screenTypes.landscapeTablet:
  //     return cols.filter((value, index, array) => index < 4);
  //   case screenTypes.smallScreen:
  //     return cols.filter((value, index, array) => index < 5);
  //   default:
  //     return cols.filter((value, index, array) => index < array.length);
  // }
};
// export const pageChange = (event) => {
//     this.setState(this.createState(event.page.skip, event.page.take));
// }

// export const createState = (skip, take) => {
//     return {
//         // items: products.slice(skip, skip + take),
//         // total: products.length,
//         skip: skip,
//         pageSize: take,
//         pageable: this.state ? this.state.pageable : {
//             buttonCount: 5,
//             info: true,
//             type: 'numeric',
//             pageSizes: true,
//             previousNext: true
//         }
//     };
// }

// export const updatePagerState = (key, value) => {
//     const newPageableState = Object.assign({}, this.state.pageable, { [key]: value });
//     this.setState(Object.assign({}, this.state, { pageable: newPageableState }));
// }
