import React from "react";
import { AwPageContainer } from "../AwPageContainer";
import { AwTitleBar } from "../../../components";

export const PasswordSettingsPagePresentation = ({ ...props }) => {
  return (
    <AwPageContainer>
      <AwTitleBar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10%"
        }}
      >
        <div
          className="card"
          style={{ width: "50%", maxWidth: "700px", minWidth: "300px" }}
        >
          <div className="card-body">{props.children}</div>
        </div>
      </div>
    </AwPageContainer>
  );
};
