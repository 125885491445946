import React from "react";
import { SegmentCard } from "./SegmentCard";
import { report_segments_shortName } from "../../../../defs";

export const EditReportForm = ({
  onInputChange,
  rxNumber,
  segmentDetailSearchProp
}) => {
  const segments = [
    { key: report_segments_shortName.pat, label: "Patient Information" },
    { key: report_segments_shortName.dsp, label: "Dispensing Record" },
    { key: report_segments_shortName.pre, label: "Prescriber Information" },
    {
      key: report_segments_shortName.air,
      label: "Additional Information Reporting"
    }
  ];

  const handleInputChange = (e, initSegValueObj, seg) => {
    e.target["segmentName"] = seg;
    onInputChange(e, initSegValueObj);
  };

  return (
    <form>
      {segments.map((segment, i) => {
        return (
          <SegmentCard
            key={i}
            segment={segment}
            rxNumber={rxNumber}
            segmentDetailSearchProp={segmentDetailSearchProp}
            onInputChange={(e, initSegValueObj) =>
              handleInputChange(e, initSegValueObj, segment.key)
            }
          />
        );
      })}
    </form>
  );
};
