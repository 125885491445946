import React, { useEffect } from 'react';

import { KsCard } from "./keyword";
import { KeywordSearchBox } from "./keyword/KeywordSearchBox";
import { AwCard } from '../../../../components';
import { DoCGridCard } from './doc-card';
import { DoCModal } from './doc-modal';

import "./DoC.css";
import { useDoCApis } from './useDoCApis';

export const DoC = () => {
    const {docs, GetDocsAsync} = useDoCApis();
    
    const onRemoveAllAsync = async (i: number) => {
      // console.log(i);

      // if (await awConfirmDelete()) {
      //   // const res = await api.requestmethods.deleteAll(base_url, idsArr, stateProvinceId);
      //   const res = await docApis.deleteDoCsAsync(idsArr, stateProvinceId);
        
      //   if(awShowToastr(msgSvc, res, 'deleted')){
      //     let data = deepCopy(gridData);
      //   let selectedStateIdx = -1;
  
      //   data.forEach((doc, i) => {
      //     if (doc.StateProvinceId === stateProvinceId) {
      //       doc.Details = doc.Details.filter(
      //         (v) => !idsArr.includes(v.ProductId)
      //       );
      //       if (!doc.Details.length) {
      //         selectedStateIdx = i;
      //       }
      //     }
      //   });
  
      //   if (selectedStateIdx > -1) {
      //     data = spliceArray(data, selectedStateIdx);
      //   }
  
      //   setGridData(data);
      //   }
      // }
    }
    useEffect(() => {
        GetDocsAsync();
    }, []);

    return (
        <article className="doc">
            <div
                className="doc-search-container"
                style={{ marginBottom: "2em", textAlign: "right" }}
            >
                <KeywordSearchBox />
            </div>
            
            {/* <KsCard open={openKSModal} savedKw={handleKwSaved} kwDeleted={handleKwDeleted} kwIds={keywordIds}  /> */}
            <KsCard />

            <section className="row main-content">
              <div className="col-12">
                <AwCard labelKey={"DoC"} label={"DRUG OF CONCERN"}>
                  <div className="row" style={{ flexGrow: 1 }}>
                    {docs.grid.map((data, i) => {
                      return (
                        <DoCGridCard
                          key={i}
                          result={data}
                          // idx={i}
                          // onRemovedAll={() => onRemoveAllAsync(i)}
                          />
                      );
                    })}
                  </div>
                </AwCard>
              </div>
            </section>

            <DoCModal />


        </article>
    )
}


