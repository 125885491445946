import React, { useEffect, useContext } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUsers,
  faIdBadge,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTachometerAlt,
  faAddressBook,
  faFlagUsa,
  faCapsules,
  faUsersCog,
  faBars,
  faSortDown,
  faCogs,
  faCog,
  faSignOutAlt,
  faPaperPlane,
  faArrowAltCircleDown,
  faTrashAlt,
  faPenAlt,
  faSearch,
  faTimes,
  faPencilAlt,
  faSquare,
  faCheck,
  faPlus,
  faUndo,
  faInfoCircle,
  faInfo,
  faEdit,
  faEye,
  faEyeSlash,
  faBell,
  faQuestion,
  faEnvelope,
  faTrashRestoreAlt
} from "@fortawesome/free-solid-svg-icons";

import { FwLayout, useCurrentUser } from "./components";

import { useSelector } from "react-redux";
import { ConfigContext } from "./AwAppContext";

import { AwUserSession, ProgressBar } from "./components/shared";
import { Route, Routes, useLocation } from "react-router-dom";
import { SignIn } from "./containers";
import MainRoutes from "./components/MainRoutes";
import { webStores } from "./infrastructure/webStores";
library.add(
  faTachometerAlt,
  faBars,
  faSortDown,
  faUsers,
  faIdBadge,
  faChevronUp,
  faAddressBook,
  faFlagUsa,
  faCapsules,
  faUsersCog,
  faChevronLeft,
  faChevronRight,
  faCogs,
  faCog,
  faSignOutAlt,
  faPaperPlane,
  faArrowAltCircleDown,
  faTrashAlt,
  faTrashRestoreAlt,
  faPenAlt,
  faSearch,
  faTimes,
  faPencilAlt,
  faSquare,
  faCheck,
  faPlus,
  faUndo,
  faInfoCircle,
  faInfo,
  faCheck,
  faEdit,
  faEye,
  faEyeSlash,
  faBell,
  faQuestion,
  faEnvelope
);

interface IContextProps {
  previousPageName: string;
  nextPageName: string;
  statesettings: any[];
  pharmacies: any[];
  msgFuncs: any;
  session: { time: number; reset: boolean };
}

// const ProgressBar = lazy(() =>
//   import("./components/shared").then(fromComp => ({ default: fromComp.ProgressBar }))
// );


// const AwUserSession = lazy(() =>
//   import("./components/shared").then(fromComp => ({ default: fromComp.AwUserSession }))
// ); 

// const SessionExpiration = lazy(() =>
//   import("./components").then(fromComp => ({
//     default: fromComp.SessionExpiration
//   }))
// );

const App: React.FC = (props: any) => {
  const { setAwConfig } = useContext(ConfigContext);
  const settings = useSelector((state: any) => state.settings.Settings);

  const { user } = useCurrentUser();

  useEffect(() => {
    setAwConfig((state: any) => ({
      ...state,
      session: { time: user.expires_in, reset: user.Token }
    }));
  }, [user.Token]);

  useEffect(() => {
    setAwConfig((state: any) => ({
      ...state,
      statesettings: settings
    }));

  }, []);
  return (
    <>
      {user.IsAuthenticated && 
        (
          <>
            <ProgressBar />
            <AwUserSession isLoggedIn={user.IsAuthenticated} />
          </>
        )}
      {/* <FwLayout location={props.location} /> */}
      <MainRoutes />
    </>
  );
};


export default App;
