import React from 'react'
import { AwSearchBoxField, useAwToastR } from '../../../../../components'
import { keywordsActions } from '../store';
import { useDoCApis } from '../useDoCApis';

export const KeywordSearchBox = () => {
    const {keywords, docDispatch, VerifyKeySearchAsync} = useDoCApis();
    const msgSvc =  useAwToastR();

    const checkForKeywordAsync = async (search: any) => {
        if(!!search) {
            const exist = await VerifyKeySearchAsync(search);
            if (exist) {
                docDispatch({type: keywordsActions.set_search, payload: search});
            } else {
                if(keywords.searchKey) docDispatch({type: keywordsActions.set_search, payload: ""});
                msgSvc.showInfo("Nothing was found.");
            }
        } else {
            msgSvc.showInfo("Please type the drug you want to search!");
        }
    };
    return <AwSearchBoxField submit={checkForKeywordAsync} customStyles="" />
}
