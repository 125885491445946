import React, { useEffect, useState, useRef } from 'react';
import api from '../../../store/api';
import { useIsComponentActive } from '../../../components';
import { isResponseError } from '../../../utils';

interface Props {
}
const cache = {} as any;
const wksOptions = [1,2,3,4,5];
export const WeeklyReportedOrderSummary: React.FC<Props> = () => {
    const baseUrl  = '/api/reported/weekly';
    const [reports, setReports] = useState<any[]>([]);
    const { componentRef } = useIsComponentActive();
    const [wks, setWks] = useState(1);

    const getWeeklyReportSummaryAsync = async (weeks: number) => {
        const url = `${baseUrl}/${weeks}`;
        if(cache[url]) return setReports(cache[url]);
        var res = await api.requestmethods.get(url);
        const isError = isResponseError(res);
        if(!isError) {
            cache[url] = res;
            if(componentRef.active) setReports(res);
        }
    }

    const handleSelectChange = (e: any) => {
        setWks(e.target.value);
    }
    
    useEffect(() => {
        getWeeklyReportSummaryAsync(wks);
        return () => {}
    }, [wks])

    return (
        <div className="weekly-report">
            <div className="search">
                <small>
                    The <span>date range</span> is from Sunday to Saturday!
                </small>
                <select  value={wks} onChange={handleSelectChange}>
                    {wksOptions.map((wk, idx) => {
                        return (
                            <option key={idx} value={wk}>{wk} </option>
                        )
                    })}
                </select>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Date Range</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((report, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{report.ReportDateRange}</td>
                                <td>{report.TotalCount}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
