import React from 'react'
import { UserModal } from './UserModal'
import { EditUserForm } from './forms';
import { useUserAndRoleAddFuncs } from '../useUserAndRoleAddFuncs';


interface Props {
    user: any;
    onHide: any;
    onUserEdited: any;
}

export const EditUser: React.FC<Props> = ({user, onHide, onUserEdited }) => {
    const {
        modalRef,
        error,
        handleChange,
        editUserAsync,
        modalClose,
        handleSetError,
        formValues
      } = useUserAndRoleAddFuncs(
        "api/admin/users",
        ["FirstName", "LastName", "RoleName"],
        onHide,
        onUserEdited
      );
    
      return (
        <div className="edit-user">
          <UserModal
            mBtnLabel="Save"
            mTitle={"Edit"} 
            modalRef={modalRef} 
            submitClicked={() => editUserAsync(user)} 
            closeClicked={modalClose}
            error={error}
            formValues={formValues}
            handleError={handleSetError}
            Rform={EditUserForm}
            formParams={{user}}
            handleChange={handleChange}
           />
        </div>
      );
}
