import React, { useState, useRef } from "react";
import {
  AwButton,
  useAwToastR,
  useModalFunctions
} from "../../../../components";
import {
  awIcons,
  awConfirmWithUser,
  isResponseError,
  navigateAway
} from "../../../../utils";
import { EditReportForm } from "./EditReportForm";
import { CdiForm } from "./edit-report-cdi/CdiForm";
import { ReportEditModal } from "./edit-report-modal/ReportEditModal";
import api from "../../../../store/api";
import { report_segments_shortName } from "../../../../defs";

import { useEditReportFunc } from "./function-hooks";
import { AlertErrors } from "../../../../components/message";

import "./EditReportGlobalStyles.css";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../../../infrastructure/api-urls";
interface Props {
  rxdetails: any;
  pharmacies: any[];

}
export default function EditReport({ rxdetails, pharmacies }: Props) {
  const navigate = useNavigate();
  const base_url = API_URLS.statereportedit;
  const [edited] = useState(false);
  const [error, setError] = useState({ isError: false, msg: [] });

  let originalSubmittedSegmentValuesRef = useRef({});

  // custom hooks
  const msgSvc = useAwToastR();
  const { modalRef, showModal, hideModal, resetLoading } = useModalFunctions();
  const {
    isLoading,
    segmentDetailSearchProp,
    cdiIds,
    segmentInit,
    inputFieldRef,
    handleEditClickedAsync
  } = useEditReportFunc(rxdetails, pharmacies, showModal, base_url);

  const handleModalClose = async (e: any) => {
    const strArray = checkForSegmentThatNeedsUpdate(
      Object.keys(inputFieldRef.current),
       (inputFieldRef.current as any)
    );

    if (strArray.length > 0) {
      if (await awConfirmWithUser("Any changes made will be lost!")) {
        await deleteCreatedReportAndCloseModal(
          hideModal,
          base_url,
          segmentDetailSearchProp
        );
         (inputFieldRef.current as any) = segmentInit;
      }
    } else {
      await deleteCreatedReportAndCloseModal(
        hideModal,
        base_url,
        segmentDetailSearchProp
      );
       (inputFieldRef.current as any) = segmentInit;
    }
  };

  const handleFormSubmit = async (e:any) => {
    const strArray = checkForSegmentThatNeedsUpdate(
      Object.keys(inputFieldRef.current),
       (inputFieldRef.current as any)
    );

    if (strArray.length > 0) {
      strArray.forEach(str => {
        if ((inputFieldRef.current as any)[str] === report_segments_shortName.cdi) {
          const cdisArr =  (inputFieldRef.current as any)[str];
           (inputFieldRef.current as any)[str] = cdisArr.filter((obj: any) => {
            const keys = Object.keys(obj);
            return keys.length > 0;
          });
        }
      });
      await submitSegmentChanges(strArray,  (inputFieldRef.current as any));
    } else {
      await changesSavedAsync();
    }
  };

  const submitSegmentChanges = async (keysArr: any, allSegmentObj: any) => {
    const keyLength = keysArr.length;
    for (let i = 0; i < keyLength; i++) {
      if (error.isError) resetError();
      const isLastItem = keyLength - 1 === i;
      const url = getSegmentUpdateUrl(base_url, keysArr[i]);
      let dataToSubmit = null;
      if (keysArr[i] === report_segments_shortName.cdi) {
        let newObjArr: any[] = [];
        const arrayWithChanges = allSegmentObj[keysArr[i]]; // array of obj with changes;
        arrayWithChanges.forEach((obj: any, index: any) => {
          if (
            (originalSubmittedSegmentValuesRef.current as any)[index] &&
            Object.keys(obj).length
          ) {
            newObjArr.push(
              Object.assign(
                {},
                (originalSubmittedSegmentValuesRef.current as any)[index],
                obj
              )
            );
          }
        });
        dataToSubmit = newObjArr;
      } else {
        dataToSubmit = Object.assign(
          (originalSubmittedSegmentValuesRef.current as any)[keysArr[i]],
          allSegmentObj[keysArr[i]]
        );
      }

      // make api call with the url
      const res = await api.requestmethods.put(url, dataToSubmit);

      if (isResponseError(res)) {
        // setError(state => ({ ...state, isError: true, msg: [res.data] }));
        if (!error.isError) {
          setError((state: any) => ({
            ...state,
            isError: true,
            msg: [
              "There is a possible field value mismatch or a system issue. Please make sure you provided the correct values for each field or see your administrator for assistance!"
            ]
          }));
        }
        resetLoading();
        // msgSvc.showError(
        //   `The ${keysArr[i]} wasn't updated. Please try again later.`,
        //   true
        // );
        // if (isLastItem) {
        //   // this checks to make sure we are on the last update.
        //   hideModal();
        // }
      }

      if (!isResponseError(res) && isLastItem) {
        // it means values has been updated successfully
        changesSavedAsync();
      }
    }
  };

  const resetError = () =>
    setError(state => ({ ...state, isError: false, msg: [] }));

  const changesSavedAsync = async () => {
    if (error.isError) resetError();
    resetLoading();
    await msgSvc.showSuccess("updated");
    const location = {
      pathname: "/dashboard",
      state: { reset: true }
    };
    navigateAway(navigate, location);
  };

  // init paramter, db segment obj, is passed in to compare with any changes that is made by the user
  // if there is no changes made that warrants db call then nothing happens
  const handleInputChange = (e: any, init: any) => {
    const { segmentName, name, value } = e.target;
    if (segmentName !== report_segments_shortName.cdi) {
      if (init[name] !== value) {
        (inputFieldRef.current as any)[segmentName][name] = value;
      } else {
        // if value is empty then delete the object property
        delete (inputFieldRef.current as any)[segmentName][name];
      }
      setInitialSubmittedValues(segmentName, init);
    } else {
      setCdiValues(e.target.index, segmentName, name, value, init);
    }
  };

  const setInitialSubmittedValues = (key: any, init: any) => {
    (originalSubmittedSegmentValuesRef.current as any)[key] = init;
  };

  const setCdiValues = (index: number, segmentName: any, name: any, value: any, init: any) => {
    if ((inputFieldRef.current as any)[segmentName].length > 0) {
      if (init[name] !== value) {
         (inputFieldRef.current as any)[segmentName][index][name] = value;
      } else {
        delete  (inputFieldRef.current as any)[segmentName][index][name];
      }
    } else {
       (inputFieldRef.current as any)[segmentName][index] = { [name]: value };
    }

    // original obj has to be saved by index
    // its because cdi's are in an array format
    setInitialSubmittedValues(index.toString(), init);
  };

  // const removeCdiObjWithNoKeys = (segmentName, index) => {
  //    (inputFieldRef.current as any)[segmentName].splice(index, 1);
  // };

  let btn;
  if (edited) {
    btn = (
      <AwButton icon={awIcons.checkmark} btnClicked={handleEditClickedAsync} />
    );
  } else {
    btn = (
      <AwButton
        loading={isLoading}
        label={"Edit"}
        btnClicked={handleEditClickedAsync}
      />
    );
  }
  return (
    <>
      <div style={{ maxWidth: "65px" }}  className="edit-report-page">{btn}</div>
      <ReportEditModal
        ref={modalRef}
        title="Edit Report"
        size={"fullscreen"}
        onModalClose={handleModalClose}
        onSubmit={handleFormSubmit}
      >
        {error.isError && <AlertErrors errors={error.msg} />}
        <EditReportForm
          onInputChange={handleInputChange}
          rxNumber={rxdetails.RxNumber}
          segmentDetailSearchProp={segmentDetailSearchProp}
        />

        <CdiForm
          cdiIds={cdiIds}
          onInputChange={handleInputChange}
          reportIds={segmentDetailSearchProp}
        />
      </ReportEditModal>
    </>
  );
};



async function deleteCreatedReportAndCloseModal(
  hideModal: any,
  base_url: string,
  segmentDetailSearchProp: any
) {
  hideModal();
  await (api.requestmethods as any).delete(
    `${base_url}/${segmentDetailSearchProp.createdReportId}`
  );
}

function checkForSegmentThatNeedsUpdate(segmentArray: any[], segObj: any) {
  const segToUpdate: any[] = [];
  let cdiAddedForUpdate = 0; // makes sure that cdi is not added twice to the segToUpdate array
  const updatedSegObj = JSON.parse(JSON.stringify(segObj));
  segmentArray.forEach(str => {
    if (str === report_segments_shortName.cdi) {
      // cdi is an array - [{}]
      const objectToDeleteIdx: any[] = [];
      if (updatedSegObj[str].length > 0 && cdiAddedForUpdate === 0) {
        updatedSegObj[str].forEach((obj: any, index: number) => {
          if (Object.keys(obj).length > 0 && cdiAddedForUpdate === 0) {
            cdiAddedForUpdate++;
            segToUpdate.push(str);
          } else if (Object.keys(obj).length === 0) {
            // updatedSegObj is an obj ref so deleting the object will make sure the original obj is deleted as well.
            objectToDeleteIdx.push(index);
          }
        });

        // delete cdi objects without keys
        objectToDeleteIdx.forEach(idx => {
          updatedSegObj[str].splice(idx, 1);
        });
      }
    } else {
      if (Object.keys(updatedSegObj[str]).length > 0) {
        segToUpdate.push(str);
      }
    }
  });
  return segToUpdate;
}

function getSegmentUpdateUrl(base_url: string, segmentName: any) {
  let url;
  switch (segmentName) {
    case report_segments_shortName.pat:
      url = `${base_url}/UpdatePatFields`;
      break;
    case report_segments_shortName.dsp:
      url = `${base_url}/UpdateDspFields`;
      break;
    case report_segments_shortName.pre:
      url = `${base_url}/UpdatePreFields`;
      break;
    case report_segments_shortName.cdi:
      url = `${base_url}/UpdateCdiFields`;
      break;
    case report_segments_shortName.air:
    default:
      url = `${base_url}/UpdateAirFields`;
      break;
  }
  return url;
}
