import React from "react";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import { AwFileInput } from "../form-atoms";

export const AwFileInputField = React.memo(
  ({ name, label, onChange, refresh }) => {
    return (
      <AwFormFieldWrapper name={name} label={label} required={true}>
        <AwFileInput name={name} onChange={onChange} refresh={refresh} />
      </AwFormFieldWrapper>
    );
  }
);
