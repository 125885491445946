import { useState } from "react";
import { useSessionTimeInterval, tCountDownHandler } from "./useSessionTimeInterval";

export const useSessionTimeout = (signOutAsync: () => Promise<void>) => {
    const [{ minutes, seconds }, setTime] = useState({ minutes: 2, seconds: 0 });
    
    const startTimer: tCountDownHandler = (min: number, sec: number) => {
        setTime(state => ({ ...state, minutes: min, seconds: sec }));
        
        if(!min && !sec) {
            signOutAsync();
        };
    }

    
    const { startInterval, stopInterval } = useSessionTimeInterval(startTimer, 1000);

    const handleStopInterval = () => {
        stopInterval();
        setTime(state => ({ minutes: 2, seconds: 0 }));
    }
    return {
        minutes, 
        seconds, 
        startInterval,
        stopInterval: handleStopInterval
    };
}
