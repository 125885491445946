import { createSelector } from "reselect";
import { IAppState } from "../AppState";
import { initReport } from "../../utils";
import { getDashboardStateSettingsSelector } from "./settingsSelectors";

export const getDashboardReportsSelector = createSelector(
  (state: IAppState)  => state.dashboard.DashbaoardReports,
  (state: IAppState)  => getDashboardStateSettingsSelector(state),
  (reports, settings) => {
      if(reports.Items && reports.Items.length > 0) {
        reports.Items.forEach(report => {
          if (Object.keys(settings).length > 0) {
            report.StateSetting = settings[report.StateProvinceId];
          }
          return initReport(report);
        });
        return reports;
      }
      return reports;
  }
);

export const getDashboardReportErrorSelector = createSelector(
  (state: IAppState) => state.dashboard.Error,
  (error: string) => error 
);

export const getAllDashboardReportSelector = createSelector(
  (state: IAppState) => state.dashboard.AllReports,
  (allReports: boolean) => allReports
);