import { useState } from "react";
export const useForm = init => {
  let [state, setState] = useState(init || {});
  const handleUpdate = e => {
    setState(() => {
      if (e.isNewState) {
        // for (var member in state) delete state[member];
        state = e.value;
        return state;
      } else {
        state[e.target.name] = e.target.value;
        return state;
      }
    });
  };
  return [state, handleUpdate];
};
