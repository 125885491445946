import { createSelector } from "reselect";
import { IAppState } from "../AppState";

export const isStateSettingsSelector = createSelector(
    (state: IAppState)  => state.settings.Settings,
    (setting) => setting.length > 0
);

export const getStateSettingsSelector = createSelector(
    (state: IAppState)  => state.settings.Settings,
    (setting) => setting
);

export const getDashboardStateSettingsSelector = createSelector(
    (state: IAppState)  => state.settings.DashboardStateSettings,
    (setting) => setting
);

export const getStateSettingsErrorSelector = createSelector(
    (state: IAppState) => state.settings.Error,
    (error: string) => error
);