import React from "react";
import { createSelector } from "reselect";

import { Fieldset } from "primereact/fieldset";
import { useSelector } from "react-redux";
import { SystemForm } from "./SystemForm";

export const SystemSettings = React.memo(({ stateProvinceId }) => {
  const settingsObj = useSelector(state => {
    return getRequiredSettingsObj(state, stateProvinceId);
  });

  return (
    <div className="state-systemsettings">
      <Fieldset legend="Basic Settings Information">
        <SystemForm selectedStateSetting={settingsObj} />
      </Fieldset>
    </div>
  );
});

const getStateSettingSelector = createSelector(
  state => state.settings.DashboardStateSettings,
  setting => setting
);

function getRequiredSettingsObj(state, stateProvinceId) {
  const dashSettings = getStateSettingSelector(state);
  const setting = dashSettings[stateProvinceId];
  return setting;
}
