import React, { useState, useEffect, useRef } from "react";
import {
  AwCard,
  DisplayGrid,
  useGridRefreshFunc
} from "../../../../components";
import {
  getUserGridCol,
  UsersGridBeginingCols,
  UserGridEndingCols
} from "../AdminUtil";
import { RegisterUser } from "./RegisterUser";
import { EditUser } from "./EditUser";
import { AddUserPhone } from "./user-phone";
import { getObjectKeyLength } from "../../../../utils";
import Api from "../../../../store/api";




export const UserGrid = ({ extraCol, refresh, onResendEmail }) => {
  const [show, setShow] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false);
  const userRef = useRef({});
  
  const updateUser = data => userRef.current = data;

  const openNewRegisterForm = () => {
    updateUser({});
    setShow(true);
  }

  const openPhoneModal = (data) => {
    updateUser(data);
    setPhoneModal(true);
  }

  const openUserEditModal = (user) => {
    updateUser(user);
    setShow(true);
  }


  
  const isActionCol = extraCol && extraCol.props;
  const beginCol = UsersGridBeginingCols(openPhoneModal);
  const endCol = UserGridEndingCols(openUserEditModal, onResendEmail, isActionCol);
  const usersCol = getUserGridCol();

  // custom hook
  const { gridRefresh, handleGridRefresh } = useGridRefreshFunc();
  const [prevRefreshValue, setRefreshValue] = useState(refresh);

  useEffect(() => {
    if (refresh !== prevRefreshValue) {
      handleGridRefresh();
      setRefreshValue(refresh);
    }
  }, [refresh]);


  return (
    <div className="user-grid">
      <AwCard
        labelKey={"Users"}
        label={"Current"}
        showPlus={!!extraCol ? true : false}
        addRow={openNewRegisterForm}
      >
        <DisplayGrid
          tableName="users"
          url="api/admin/users"
          urlExt={null}
          columns={usersCol}
          refresh={gridRefresh}
          showExport={true}
        >
          <>{beginCol.props.children}</>
          <>
            {endCol.props.children}
            {isActionCol && extraCol.props.children}
          </>
        </DisplayGrid>
      </AwCard>

      {show && <>{!getObjectKeyLength(userRef.current) ? (
        <RegisterUser
          onUserAdded={handleGridRefresh}
          onHide={() => setShow(false)}
        />
      ) : (<EditUser
              onHide={() => setShow(false)}
              onUserEdited={handleGridRefresh}
              user={userRef.current}
       />)}</>}
      {phoneModal && (
        <AddUserPhone
          userRef={userRef}
          onPhoneAdded={handleGridRefresh}
          onHide={() => setPhoneModal(false)}
        />
      )}

      {/* <button className="btn btn-danger" onClick={callFaKe}>Get FaKe</button> */}
    </div>
  );
};


          {/* onUserAdded={handleGridRefresh} */}
export default UserGrid;
