import React from "react";
import { AwPageContainer } from "../../AwPageContainer";
import { AwTab } from "../../../../components";

import { Pharmacist } from "./pharmacist/Pharmacist";
import { Pharmacy } from "./pharmacy/Pharmacy";

import "./licenseStyles.css";
// import { getAxios } from "../../../../utils";
export const Licenses = React.memo(({ stateProvinceId }) => {
  // Just for testing license notification
  // const handleNotification = async () => {
  //   const axios = getAxios();
  //   const res  = await axios.get('/api/pharmacists/sendnotification');
  //   console.log(res);

  // }
  return (
    <AwPageContainer>
      {/* <button onClick={handleNotification}>Send Notification</button> */}
      <AwTab isLicense={true}>
        <Pharmacist header="Pharmacist" stateProvinceId={stateProvinceId} />
        <Pharmacy header="Pharmacy" stateProvinceId={stateProvinceId} />
      </AwTab>
    </AwPageContainer>
  );
});
