import React, { useState, useEffect, useRef } from 'react';
import {KeywordStateModal} from "./KeywordStateModal";
import {KsListItem} from "./KsListItem";
import {KsAddPatIdListItem} from "./KsAddPatIdListItem";
import { KeywordForm } from './KeywordForm';
import { IMProps, tPatListItemBtn } from '../IDocInterfaces';
import { isResponseError } from '../../../../../utils';

interface Props {
    ksProps: IMProps;
    close: () => void;
    // keywordStates: any;
    onDelete: (item: any) => void;
    onPatListItemClicked: tPatListItemBtn;
    stateAddedToKwS: (item: any) => void;
}

export const KeywordState: React.FC<Props> = ({ksProps, close, onDelete, onPatListItemClicked, stateAddedToKwS}) => {
    const formLabel = useRef({show: 'Show', hide: "Hide"}).current;
    const [{modalRef, showModal, hideModal, resetLoading}, setModalFxns] = useState<any>({});
    const [fdState, setFDState] = useState({show: false, label: formLabel.show });
    const handlehideModal = () => {
        hideModal();
        close();
        hideForm(true);
    }

    const handleSubmitClicked = () => {}


    const handleStateAddedToKwS = (data: any) => {
        if(!isResponseError(data)) hideForm(true);
        stateAddedToKwS({res: data, keyword: ksProps.keyword});
    }

    const handleFDState = () => {
        let label = formLabel.show;
        if(!fdState.show) label = formLabel.hide;
        setFDState(state => ({...state, show: !fdState.show, label: label}));
    }

    const hideForm = (bol: boolean) => {
        setFDState(state => ({...state, show: !bol, label: formLabel.show}));
    }
    


    useEffect(() => {
        if(showModal && ksProps.show) showModal();
    }, [ksProps])

    
    return (
            <KeywordStateModal 
                handlehideModal={handlehideModal} 
                handleSubmitClicked={handleSubmitClicked} 
                getModalFxns={setModalFxns}
                keyword={ksProps.keyword}>
                    {!ksProps.addID && <div className="ks-add-cv">
                        <button type="button" className="btn btn-link" onClick={handleFDState}>
                        {fdState.label} Add Form
                        </button>
                    </div>
                    }
                    {fdState.show && <KeywordForm 
                        kwId={ksProps.keyword.SRKeywordID} 
                        stateAddedToKwS={handleStateAddedToKwS} 
                        hideForm={hideForm} />}

                    <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                        {ksProps.ksData.length > 0 ? (
                            <div>
                                {ksProps.addID ? (ksProps.ksData.map((d, idx) => {
                                    return <KsAddPatIdListItem key={idx} item={d} onPatBtnClicked={onPatListItemClicked} />
                                })) : (ksProps.ksData.map((d, idx) => {
                            return <KsListItem key={idx} item={d} onDelete={onDelete} />
                        })) }
                            </div>
                        ) : <div>Not related to any state!</div>}
                    </div>
            </KeywordStateModal>
    )
}

