import React from "react";

import { FwTopBar } from "./fwTopBar";
import { FwMenus } from "./fwMenus";
export default function FwMenuArea({ show }) {
  return (
    <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>
      <FwTopBar />
      {show && <FwMenus />}
    </div>
  );
}
