import React, { useState } from "react";
import {
  DisplayGrid,
  AwDModal,
  DeleteAndEdit,
  AwGridTopBtnLogic,
  AwButton,
  useStateBasicFunctions
} from "../../../../../components";
import { AwPageContainer } from "../../../AwPageContainer";
import LicenseAlertDialog from "../LicenceAlertDialog";
import { formGenericErrorMsg, TABLE_NAME } from "../../../../../defs";

import {
  awConfirmDelete,
  awIcons,
  isAllRequiredFieldsAnswered
} from "../../../../../utils";

import { GridColumn as Column } from "@progress/kendo-react-grid";
import api from "../../../../../store/api";
import { Alert } from "../../../../../components/message";
import LicenseAlertCol from "../LicenseAlertCol";
import GridHeader from "../../../../../components/grid/GridHeader";

import { PharmacyCreateForm } from "./PharmacyCreateForm";
import { useLicenseBasicFunctions } from "../useLicenseBasicFunctions";

export const PharmacyGrid = ({ stateProvinceId }) => {
  const [columns, setColumns] = useState(pharmacyColumns);
  const tableName = "pharmacyLicense";
  const base_url = "api/pharmacylicense";

  const initLicenseFormValues = {
    StateProvinceId: stateProvinceId,
    EffectiveDate: new Date(),
    ExpDate: new Date()
  };
  const {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    formData,
    setFormData,
    formError,
    setFormError,
    formChanges,
    setFormChanges,
    modalRef,
    createBtnRef,
    handleModalClose,
    handleHttpResponse
  } = useStateBasicFunctions({}, initLicenseFormValues);

  const {
    dialogRef,
    edit,
    handleLicenseNewModalOpen,
    handleLicenseUpdateClick,
    notifications,
    setNotifications,
    selectedLicense,
    setSelectedLicense,
    getNotificationsAsync
  } = useLicenseBasicFunctions(addBtn, setFormData, modalRef, setFormChanges);

  const handleSubmit = async e => {
    const data = Object.assign({}, formData, formChanges);
    const x = ["LicenseNumber", "ExpDate"];
    const isSubmitable = isAllRequiredFieldsAnswered(x, data);
    if (isSubmitable) {
      setFormError({
        isError: false,
        errorMsg: ""
      });
      if (edit.isEdit) {
        const data = Object.assign({}, formData, formChanges);
        const res = await api.requestmethods.put(base_url, data);
        handleHttpResponse(res);
      } else {
        const res = await api.requestmethods.post(base_url, formChanges);
        handleHttpResponse(res);
      }
    } else {
      setFormError({
        isError: true,
        errorMsg: formGenericErrorMsg
      });
    }
  };

  const onDeleteRecord = async e => {
    if (await awConfirmDelete()) {
      const queryString = `pharmacyId=${e.PharmacyId}&licenseId=${e.LicenseId}`;
      await api.requestmethods.delete(base_url, queryString);
      msgSvc.showSuccess("deleted");
      handleGridRefresh();
    }
  };

  const showAlertDialog = async data => {
    setSelectedLicense(data);
    // const items = await getNotificationsAsync(data.PharmacyId, false);
    // Using LicenseId because that is more unique than the pharmacyId
    const items = await getNotificationsAsync(data.LicenseId, false);
    setNotifications(items);
    dialogRef.current.show();
  };

  return (
    <AwPageContainer>
      <AwGridTopBtnLogic table={TABLE_NAME.licensePharmacy}>
        <AwButton
          icon="plus"
          label={"License"}
          btnClicked={handleLicenseNewModalOpen}
          ref={createBtnRef}
        />
      </AwGridTopBtnLogic>

      <DisplayGrid
        tableName={tableName}
        url={base_url}
        urlExt={stateProvinceId}
        columns={columns}
        showExport={true}
        refresh={gridRefresh}
      >
        <></>
        <>
          <Column
            className="added-col-td"
            title="Download"
            width="60px"
            filterable={false}
            headerCell={props => (
              <GridHeader {...props} iconName={awIcons.alert} />
            )}
            cell={props => {
              return <LicenseAlertCol show={showAlertDialog} {...props} />;
            }}
          ></Column>

          <Column
            width="140px"
            className="added-col-td delete-col"
            title="Action"
            filterable={false}
            cell={props => {
              return (
                <DeleteAndEdit
                  onDelete={onDeleteRecord}
                  onEdit={handleLicenseUpdateClick}
                  {...props}
                />
              );
            }}
          />
        </>
      </DisplayGrid>
      <LicenseAlertDialog
        ref={dialogRef}
        alerts={notifications}
        license={selectedLicense}
        isPharmacy={true}
      />

      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={edit.btn}
        title={`${TABLE_NAME.licensePharmacy} Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <PharmacyCreateForm dObj={formData} fieldChange={setFormChanges} />
      </AwDModal>
    </AwPageContainer>
  );
};


const pharmacyColumns = [
  { field: "LicenseNumber", header: "License" },
  { field: "Type", header: "Type" },
  { field: "Status", header: "Status", isLicenseStatus: true },
  { field: "ExpDate", header: "Expiration", isDate: true },
  { field: "Schedule", header: "Schedule" },
  { field: "NABP", header: "NABP" },
  { field: "CreatedBy", header: "Created By" }
];
