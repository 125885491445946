import React from "react";
import {
  DisplayGrid,
  AwGridTopBtnLogic,
  AwDModal,
  AwButton,
  DeleteAndEdit,
  useStateBasicFunctions
} from "../../../../components";
import { formGenericErrorMsg, TABLE_NAME } from "../../../../defs";
import {
  awConfirmDelete,
  isAllRequiredFieldsAnswered,
  validURL,
  isValidEmail,
  isValidPhoneLength
} from "../../../../utils";
import api from "../../../../store/api";
import { Alert } from "../../../../components/message";
import { GridColumn as Column } from "@progress/kendo-react-grid";

import { ContactForm } from "./ContactForm";
export const ContactGrid = ({ stateProvinceId }) => {
  const tableName = "Contacts";
  const base_url = "api/Contacts";
  const webKey = "Url";

  const onDeleteRecord = async e => {
    if (await awConfirmDelete()) {
      await api.requestmethods.deleteWithoutParams(base_url, e.ContactId);
      msgSvc.showSuccess("deleted");
      handleGridRefresh();
    }
  };

  const handleSubmit = e => {
    let dataToSubmit = Object.assign({}, formData, formChanges);
    const isSubmitable = isAllRequiredFieldsAnswered(
      requiredFields,
      dataToSubmit
    );

    if (isSubmitable) {
      const isValidUrl = validURL(dataToSubmit[webKey]);
      if (isValidUrl) {
        if (isValidEmail(dataToSubmit["Email"])) {
          handleFormError("", false);
          dataToSubmit["StateProvinceId"] = stateProvinceId;
          if (
            isValidPhoneLength(dataToSubmit, ["PhoneNumber", "MobileNumber"])
          ) {
            if (modalSubmitBtn === addBtn) {
              addContact(dataToSubmit);
            } else {
              saveContact(dataToSubmit);
            }
          } else {
            handleFormError(
              "Please check the length of your 'phone' field(s).",
              true
            );
          }
        } else {
          handleFormError("Please provide a valid 'email'.", true);
        }
      } else {
        handleFormError("Please provide a valid 'Web Address'.", true);
      }
    } else {
      handleFormError(formGenericErrorMsg, true);
    }
  };

  const addContact = async values => {
    const res = await api.requestmethods.post(base_url, values);
    handleHttpResponse(res);
  };

  const saveContact = async values => {
    const res = await api.requestmethods.put(base_url, values);
    handleHttpResponse(res);
  };

  const {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    modalSubmitBtn,
    formData,
    formError,
    formChanges,
    setFormChanges,
    modalRef,
    createBtnRef,
    handleModalClose,
    handleNewModalOpen,
    handleUpdateClick,
    handleFormError,
    handleHttpResponse
  } = useStateBasicFunctions({ StateProvinceId: stateProvinceId });

  return (
    <div className="contacts-grid">
      <AwGridTopBtnLogic table={TABLE_NAME.contacts}>
        <AwButton
          icon="plus"
          label={TABLE_NAME.contacts}
          btnClicked={() =>
            handleNewModalOpen({ StateProvinceId: stateProvinceId })
          }
          ref={createBtnRef}
        />
      </AwGridTopBtnLogic>

      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={modalSubmitBtn}
        title={`${TABLE_NAME.contacts} Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <ContactForm onInputChange={setFormChanges} dObj={formData} />
      </AwDModal>
      <DisplayGrid
        tableName={tableName}
        url={base_url}
        urlExt={stateProvinceId}
        columns={contactColumns}
        showExport={true}
        refresh={gridRefresh}
      >
        <></>
        <>
          <Column
            width="140px"
            className="added-col-td delete-col"
            title="Action"
            filterable={false}
            cell={props => {
              return (
                <DeleteAndEdit
                  onDelete={onDeleteRecord}
                  onEdit={handleUpdateClick}
                  {...props}
                />
              );
            }}
          />
        </>
      </DisplayGrid>
    </div>
  );
};

const requiredFields = [
  "FirstName",
  "LastName",
  "Title",
  "Department",
  "PhoneNumber",
  "MobileNumber",
  "Email",
  "Url",
  "Address1",
  "City",
  "StateProvinceId",
  "PostalCode"
];

const contactColumns = [
  { field: "FirstName", header: "First Name", width: "128px" },
  { field: "LastName", header: "Last Name" },
  { field: "PhoneNumber", header: "Phone No.", isPhone: true },
  { field: "MobileNumber", header: "Mobile", isPhone: true },
  // {field: 'FaxNumber', header: 'Fax No.'},
  { field: "Email", header: "Email" },
  { field: "Url", header: "Web Address" },
  { field: "Address1", header: "Address 1" },
  { field: "City", header: "City" },
  { field: "PostalCode", header: "Zip Code" },
  { field: "CreatedBy", header: "Created By" }
];
