import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { awIcons } from "../../../../utils";
export const LicenseAlertCol = ({ show, ...props }) => {
  return (
    <td style={{ textAlign: "center" }}>
      <span
        onClick={() => show(props.dataItem)}
        className="aw-icons bell"
        tabIndex="0"
      >
        <FontAwesomeIcon icon={awIcons.alert} />
      </span>
    </td>
  );
};

export default LicenseAlertCol;
