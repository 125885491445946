import {
  signInSuccess_type,
  signInFailed_type,
  signOut_type,
  profileImageUploadSuccess_type
} from "../actiontypes";
import { IAction } from "../actiontypes";
import { StateReportUser } from "../../models";

export interface IAuthState {
  IsAuthenticated: boolean;
  CurrentUser: StateReportUser;
  Error: string;
}

const initState: IAuthState = {
  IsAuthenticated: false,
  CurrentUser: new StateReportUser(),
  Error: ""
};

export const authReducer = (state = initState, action: IAction): IAuthState => {
  switch (action.type) {
    case signInSuccess_type:
      return {
        ...state,
        CurrentUser: action.payload,
        IsAuthenticated: (action.payload as StateReportUser).IsAuthenticated,
        Error: ""
      };
    case profileImageUploadSuccess_type:
      return {
        ...state,
        CurrentUser: { ...state.CurrentUser, Profile: action.payload },
        Error: ""
      };
    case signInFailed_type:
      return {
        ...state,
        IsAuthenticated: false,
        Error: action.payload
      };
    case signOut_type:
      return {
        ...state,
        CurrentUser: new StateReportUser(),
        IsAuthenticated: false,
        Error: ""
      };
    default:
      return state;
  }
};
