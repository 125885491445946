import React, { useState } from "react";

export const AwSearchBoxField = ({ submit, customStyles }) => {
  const [value, setValue] = useState("");

  const handleSubmit = e => {
    if (e) e.preventDefault();
    submit(value);
    setValue("");
  };

  let styles = {};
  if (customStyles) {
    styles = customStyles;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="ui action input search-form"
      style={{ ...styles }}
    >
      <input
        type="text"
        placeholder="Search..."
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <button type="submit" className="ui icon button">
        <i aria-hidden="true" className="search icon"></i>
      </button>
    </form>
  );
};
