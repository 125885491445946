import React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { awIcons } from "../../../utils";
import GridHeader from "../../../components/grid/GridHeader";
import DeleteGridRow from "../../../components/grid/delete/DeleteGridRow";

import {AddPhoneBtn} from "./users/user-phone"
import { AwIcon, useCurrentUser } from "../../../components";
import { getCanEditUser } from "./users/forms/userFormHelper";

export const getUserGridCol = () => {
  return [
    { field: "Email", header: "Email" },
    { field: "RoleName", header: "Role" }
  ];
};

export const getRoleGridCol = () => {
  return [{ field: "Name", header: "Name" }];
};


{/* <button onClick={props.open}> */}
    {/* </button> */}
export const UsersGridBeginingCols = (openModal) => (
  <>
    <Column
      field={"FirstName"}
      title={"Name"}
      cell={({dataItem}) => (
        <td>
          <div style={{display: 'flex',
    justifyContent: 'space-between', alignItems: 'center'}}>
          <span>{dataItem["FirstName"]} {dataItem["LastName"]}</span> 
          {!!openModal && <AddPhoneBtn open={() => openModal(dataItem)} />}
          </div>
        </td>
      )}
    ></Column>
  </>
);


export const UserGridEndingCols = (onUserEditClicked, onResendEmailClicked, showAction = false) => (
  <>
    <Column
      className="added-col-td"
      title="Status"
      width="100px"
      filterable={false}
      cell={props => {
        const isActive = props.dataItem.EmailConfirmed;
        return (
          <td>
            {isActive ? (
              <span style={{ color: "green" }}>Active</span>
            ) : (
              <span style={{ color: "red" }}>Unactivated</span>
            )}
          </td>
        );
      }}
    ></Column>
    
    {showAction && <Column 
      className="su-actions"
      title="SU Actions"
      width="100px"
      filterable={false}
      cell={props => {
        return <SUActions data={props.dataItem} editUser={onUserEditClicked} resendEmail={onResendEmailClicked} />
      }}
      >
    </Column>}

  </>
);

export const GridActionCol = ({
  handleDelete,
  user = null,
  pageName = null
}) => (
  <>
    <Column
      className="added-col-td delete-col"
      title="Delete"
      width="60px"
      filterable={false}
      headerCell={() => <GridHeader iconName={awIcons.delete} />}
      cell={props => (
        <DeleteGridRow
          isDataNeeded={true}
          user={user}
          pageName={pageName}
          onDelete={handleDelete}
          {...props}
        />
      )}
    ></Column>
  </>
);


const SUActions = ({data, editUser, resendEmail}) => {
  const {user} = useCurrentUser();
  const canEdit = getCanEditUser(data, user);
  let btns = [
    {title: "Edit User", icon: "edit", fxn: editUser}
  ]
  if(!data.EmailConfirmed){ btns = [...btns, 
    {title: "Resend confirmation email", icon: "envelope", fxn: resendEmail}];
  }
  return(
    <td>
      {canEdit ? (
        <div className="su-actions" >
          {btns.map((btn, i) => {
            return (<SUBtn key={i} btn={btn} data={data} />)
          })}
        </div>) : null}
    </td>
  )
}

const SUBtn = ({btn, data})=> {
  return <span title={btn.title} className="su-btn" onClick={() => btn.fxn(data)}>
    <AwIcon iconName={btn.icon} />
  </span>;
}
