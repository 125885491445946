import React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { gridMidBreakpoint, awIcons } from "../../../utils";
import { GridRowDetails } from "./GridRowDetails";
import GridHeader from "../GridHeader";

export const GridInfoCol = (width, tablename) => {
  return (
    <>
      {width < gridMidBreakpoint && (
        <Column
          width="70px"
          headerCell={() => <GridHeader iconName={awIcons.info} />}
          filterable={false}
          cell={props => <GridRowDetails tablename={tablename} {...props} />}
        />
      )}
    </>
  );
};

export default GridInfoCol;

// title="Info"