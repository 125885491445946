import axios, { AxiosResponse } from "axios";
import {
  setTokenAndGetUserData,
  getRequestHeaders,
  isResponseError,
  getAxios,
  endProgress,
} from "../../utils";
import { StateReportUser } from "../../models";
import { IAction } from "../actiontypes";
import { IDashboardReport } from "../../interfaces";
import { getStateName } from "../../defs";
import {IsDevelopment} from "../../utils/AppEnvironment";

const fakeServer = false;
const baseUrl = fakeServer
  ? "http://localhost:4000/api/auth/1/login"
  : "/api/auth/login";

// axios.defaults.baseURL = 'https://api.example.com';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// /api/auth/1/login
// let config = {
//   onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
//     // let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
//     // do whatever you like with the percentage complete
//     // maybe dispatch an action that will update a progress bar or something
//   }
// };

export const cancelTokensObject = {
  cancelLogin: () => {},
  cancelDashboardApiRequest: () => {},
  cancelPharmaciesApiRequest: () => {},
  cancelSettingsApiRequest: () => {},
};

function cancelUserToken(c: () => any) {
  cancelTokensObject.cancelLogin = c;
}
function cancelDashboardToken(c: () => any) {
  cancelTokensObject.cancelDashboardApiRequest = c;
}
function cancelPharmaciesToken(c: () => any) {
  cancelTokensObject.cancelPharmaciesApiRequest = c;
}
function cancelSettingsToken(c: () => any) {
  cancelTokensObject.cancelSettingsApiRequest = c;
}

// const urls = {
//   reports: { get: "api/StateReports", delete: "api/StateReports" },
// };
const cancellationTokens = {} as any;
export default {
  user: {
    signIn: async (request: IAction): Promise<StateReportUser> => {
      let url = baseUrl;
      if (request.type === "google") {
        url = "api/auth/externalproviderlogin";
      }
      const axiosInst: any = getAxios();
      const axiosRequest = axiosInst.post(`${url}`, request.payload, {
        cancelToken: new axios.CancelToken(cancelUserToken),
      });
      const data = await makeApiRequest(axiosRequest);
      // console.log('data', data);
      return determineDataTypeToSend(dataType.login, data);
    },
  },
  settings: {
    getSettings: async () => {
      const headers = getRequestHeaders();
      const axiosInst = getAxios();
      const request = axiosInst.get(`/api/statesettings`, {
        headers,
        cancelToken: new axios.CancelToken(cancelSettingsToken),
      });
      const settings = await makeApiRequest(request);
      return determineDataTypeToSend(dataType.statesettings, settings);
    },
  },
  pharmacies: {
    getPharmacies: async (): Promise<any> => {
      const headers = getRequestHeaders();

      const axiosInst: any = getAxios();
      const request = axiosInst.get(`api/pharmacies`, {
        headers,
        cancelToken: new axios.CancelToken(cancelPharmaciesToken),
      });
      return await makeApiRequest(request);
    },
  },
  dashboard: {
    getReports: async (request: IAction): Promise<IDashboardReport> => {
      const { skip, take } = request.payload;
      const headers = getRequestHeaders();

      const axiosInst: any = getAxios();
      const axiosRequest = axiosInst.get(
        `api/StateReports?pNumber=${skip}&&pSize=${take}&&allReports=${true}`,
        { headers, cancelToken: new axios.CancelToken(cancelDashboardToken) }
      );
      return await makeApiRequest(axiosRequest);
    },
    deleteReport: (id: number): Promise<any> => {
      const headers = getRequestHeaders();

      const axiosInst: any = getAxios();
      const request = axiosInst.delete(`api/StateReports/${id}`, { headers });
      return makeApiRequest(request);
    },
  },
  grid: {
    getGridData: async (base_url: string, queryString: string): Promise<any> => {
      const headers = getRequestHeaders();
      const axiosInst: any = getAxios();
      const request: Promise<AxiosResponse<any>> = axiosInst.get(
        `${base_url}?${queryString}`,
        {
          headers,
          cancelToken: new axios.CancelToken(function executor(c) {
            cancellationTokens[base_url] = c;
          }),
        }
      );
      return await makeApiRequest(request);
    },
    getCancelationTokens: () => cancellationTokens,
  },
  states: {
    asap: {
      get: async (url: string) => {
        const headers = getRequestHeaders();
        const axiosInst: any = getAxios();
        const request = axiosInst.get(url, { headers });
        const res = await makeApiRequest(request);
        return res.Items;
      },
    },
  },
  
  requestmethods: {
    get: async (base_url: string, queryString?: string): Promise<any> => {
      const headers = getRequestHeaders();
      const url = !!queryString ? `${base_url}?${queryString}` : base_url;

      const axiosInst: any = getAxios();

      const request: Promise<AxiosResponse<any>> = axiosInst.get(`${url}`, {
        headers,
        cancelToken: new axios.CancelToken(function executor(c) {
          cancellationTokens[base_url] = c;
        }),
      });
      return await makeApiRequest(request);
    },
    
    post: async (
      base_url: string,
      data: any,
      isFile: boolean = false
    ): Promise<any> => {
      const headers = getRequestHeaders(isFile);
      const axiosInst: any = getAxios();
      const request: Promise<AxiosResponse<any>> = axiosInst.post(
        `${base_url}`,
        data,
        {
          headers,
          cancelToken: new axios.CancelToken(function executor(c) {
            cancellationTokens[base_url] = c;
          }),
        }
      );

      return await makeApiRequest(request);
    },
    put: async (base_url: string, data: any): Promise<any> => {
      const headers = getRequestHeaders();

      const axiosInst: any = getAxios();
      const request: Promise<AxiosResponse<any>> = axiosInst.put(
        `${base_url}`,
        data,
        {
          headers,
          cancelToken: new axios.CancelToken(function executor(c) {
            cancellationTokens[base_url] = c;
          }),
        }
      );
      return await makeApiRequest(request);
    },
    delete: async (base_url: string, queryString: string): Promise<any> => {
      return await deleteRecord(base_url, queryString);
    },
    deleteWithoutParams: async (
      base_url: string,
      queryString: string
    ): Promise<any> => {
      return await deleteWithoutParams(base_url, queryString);
    },
    // submit: (base_url: string, queryString: string): Promise<any> => {
    //   return submitRecord(base_url, queryString);
    // },
    deleteAll: async (
      base_url: string,
      array: any[],
      stateProvinceId: string = ""
    ): Promise<any> => {
      let queryString = getIdsQueryStr(array);
      if (stateProvinceId) {
        queryString += `&stateProvinceId=${stateProvinceId}`;
      }
      return await deleteRecord(base_url, queryString);
    },
    deleteByBody: deleteByBody,
    // deleteAllByRequestBody: async (
    //   base_url: string,
    //   array: any[],
    //   stateProvinceId: string = ""
    // ): Promise<any> => {
    //   const body = {Ids: array, StateProvinceId: stateProvinceId};
    //   return await deleteByBody(base_url, body);
    // },


    submitAll: async (base_url: string, array: any[]): Promise<any> => {
      const queryString = getIdsQueryStr(array);
      return await submitRecord(base_url, queryString);
    },
    getCancelationTokens: () => cancellationTokens,
  },
  getCancelationToken: (baseUrl: string) => cancellationTokens[baseUrl],
};


async function deleteByBody(base_url: string, body: {}) {
  const headers = getRequestHeaders();
  const axiosInst: any = getAxios();
  const request: Promise<AxiosResponse<any>> = axiosInst.delete(
    `${base_url}`,
    {
      data: body,
      headers,
      cancelToken: new axios.CancelToken(function executor(c) {
        cancellationTokens[base_url] = c;
      })
    }
  );
  return await callApiAndReturnResOrError(request);
}

async function deleteWithoutParams(base_url: string, queryString: string) {
  const headers = getRequestHeaders();
  const axiosInst: any = getAxios();
  const request: Promise<AxiosResponse<any>> = axiosInst.delete(
    `${base_url}/${queryString}`,
    {
      headers,
      cancelToken: new axios.CancelToken(function executor(c) {
        cancellationTokens[base_url] = c;
      }),
    }
  );
  return await callApiAndReturnResOrError(request);
}

async function deleteRecord(base_url: string, queryString: string) {
  const headers = getRequestHeaders();
  const axiosInst: any = getAxios();
  const request: Promise<AxiosResponse<any>> = axiosInst.delete(
    queryString ? `/${base_url}?${queryString}` : `/${base_url}`,
    {
      headers,
      cancelToken: new axios.CancelToken(function executor(c) {
        cancellationTokens[base_url] = c;
      }),
    }
  );
  return await callApiAndReturnResOrError(request);
}

async function submitRecord(base_url: string, queryString: string) {
  const headers = getRequestHeaders();
  const axiosInst: any = getAxios();
  const request: Promise<AxiosResponse<any>> = axiosInst.post(
    `${base_url}?${queryString}`,
    {},
    {
      headers,
      cancelToken: new axios.CancelToken(function executor(c) {
        cancellationTokens[base_url] = c;
      }),
    }
  );
  return await callApiAndReturnResOrError(request);
}

function getIdsQueryStr(array: any[]): string {
  let queryString = "";
  array.forEach((id) => {
    if (queryString) {
      queryString += `&ids=${id}`;
    } else {
      queryString += `ids=${id}`;
    }
  });
  return queryString;
}



const determineDataTypeToSend = (type: string, data: any): any => {
  if (!isResponseError(data)) {
    switch (type) {
      case dataType.login:
        const user = setTokenAndGetUserData(data);
        return { type: dataType.login, data: user };
      case dataType.statesettings:
        data.map(
          (setting: any) =>
            (setting["Name"] = getStateName(setting.StateProvinceId))
        );
        return { type: dataType.statesettings, data: data };
    }
  }
  
  const isProd = process.env.NODE_ENV.toLowerCase() !== "development";
  
  if(isProd && (data.message.split(' ').length > 50)) {
    // console.log(data);
    // console.log('error changed here')
    data["message"] = "Server request failed. Please try again later!"
  }

  return { type: dataType.error, data };
};

const dataType = {
  login: "login",
  statesettings: "statesettings",
  error: "error",
};



function responseObj(
  type: string,
  res: any,
  bool: boolean
): { type: string; response: any; isError: boolean } {
  return { type, response: res, isError: bool };
}

const callApiAndReturnResOrError = async (
  request: Promise<AxiosResponse<any>>
) => {
  try {
    const res = await request;
    endProgress();
    return responseObj("data", res, false);
  } catch (err) {
    const error = (err as any)
    endProgress();
    const data = error.response ? error.response.data : "";
    return responseObj("error", Object.assign({}, error.response, {
      message: data,
    }), true);
  }
};

const makeApiRequest = async (request: Promise<AxiosResponse<any>>) => {
  try {
    const res = await request;
    endProgress();
    // console.log('res.data', res.data);
    return res.data;
  } catch (err) {
    const error = (err as any);
    // error.response.data is the actual response message from the web api.
    // defaultError.message is a generic message from the browser server - Request failed with status code 400
    const defaultError = JSON.parse(JSON.stringify(error));
    const data = error.response ? error.response.data : "";
    const custError = Object.assign({}, error.response, {
      statusText: defaultError.message,
      message: data,
    });

    // return JSON.parse(JSON.stringify(error));
    endProgress();
    return custError;
  }
};

// getStateSettings(dropdown?) {
//   if (!!this.defaultMap && !!dropdown) {
//       return of(this.defaultMap);
//   }
//   return this._http.get(`api/StateSettings`).pipe(
//       map((res: any[]) => {
//           res.forEach(item => item.Name = this.defs.getStateName(item.StateProvinceId));
//           this.defaultMap = this.makeDeepCopy(res);
//           if (!!dropdown) {
//               res = this.defaultMap;
//               return res;
//           }
//           res = this.arrayToObject(this.makeDeepCopy(res), 'StateProvinceId');
//           this.dashboardStateSettings = res;
//           return res;
//       }));
// }
