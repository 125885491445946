import React, { useEffect } from 'react';
import { PasswordSettingsPagePresentation } from '../password-pages/PasswordSettingsPresentation';
import { useAuthSettingsLogic } from '../../../components';
import { ConfirmHeader } from './ConfirmHeader';
import { useLocation } from 'react-router-dom';


export const ConfirmPhoneNumber = () => {
    const location = useLocation();
    const { settingsMsg, loadingSettings, confirmPhoneAsync } = useAuthSettingsLogic();

    
    const verifyPhoneAsync = async () => {
        if(!location.search) return alert("location not implemented in Confirm phone number component");
        await confirmPhoneAsync(location.search);
    }

    useEffect(() => {
        verifyPhoneAsync()
    }, [])

    return (
        <PasswordSettingsPagePresentation >
            <div style={{ textAlign: "center" }}>
            {loadingSettings ? (
                    <ConfirmHeader sub="phone" />
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <ConfirmHeader sub="phone" status={settingsMsg}  />
                        {settingsMsg.success && <p className="text-success"></p>}
                    </div>
                )
            }
            </div>
        </PasswordSettingsPagePresentation>
    )
}
