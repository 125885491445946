import React from "react";

export const AwTransFormStrArrToObjArr = ({ options, ...props }) => {
  const [mOptions, setOptions] = React.useState(getOptions(options));

  React.useEffect(() => {
    setOptions(getOptions(options));
  }, [options]);

  return <>{props.children(mOptions, "key")}</>;
};

const getOptions = options => {
  return options.map(option => {
    return { key: option };
  });
};
