import React, {useReducer} from "react";
import DocContext from './docContext';
import {docReducer} from "./docReducer";
import { IDocPageState, IKeyword, IDocCache } from "../IDocInterfaces";


export const DocProvider = ({...props}) => {
    const initState: IDocPageState = {
        docs: {
            data: [], 
            grid: [], 
            loading: false,
            cache: {} as IDocCache
        }, 
        keywords: {
            searchKey: '', 
            currentKeyword: {} as IKeyword, 
            data: [], 
            grid: [], 
            loading: false,
            cache: {} as IDocCache
        }
    };
    
    const [state, dispatch] = useReducer(docReducer, initState);
    
    return (
        <DocContext.Provider value={{docs: state.docs, keywords: state.keywords, docDispatch: dispatch}}>
            {props.children}
        </DocContext.Provider>
    )
}