import React, { useContext, useRef, useState } from 'react';
import { ConfigContext } from '../../../../../AwAppContext';
import { AwModalCheckList } from '../../../../../components';
import { getSelectionArray } from '../../../../../utils';
import { IStatesToKwS } from '../IDocInterfaces';
import { useDoCApis } from '../useDoCApis';


interface Props {
    kwId: number;
    stateAddedToKwS: (res: any) => void;
    hideForm: (bol: boolean) => void;
}

export const KeywordForm: React.FC<Props> = ({kwId, stateAddedToKwS, hideForm}) => {
    const [loading, setLoading] = useState(false);
    // const base_url = "api/doc";
    const {AddStateToKwSAsync} = useDoCApis();
    const { awConfig } = useContext(ConfigContext);
    let stateArr = useRef<string[]>([]).current;

    
    const onStateSelected = (original: any[], current: any[], label: string) => {
        const arr = getSelectionArray(current, original, "StateProvinceId");
        stateArr = arr;
    };
    

    const handleCancel = () => {
        stateArr = [];
        setLoading(false);
        hideForm(true);
    }

    const handleAddStateToKs = () => {
        if(stateArr.length) {
            const x:IStatesToKwS = {States: stateArr, SRKeywordID: kwId};
            addStateToKwSAsync(x);
        }
    }

    const addStateToKwSAsync = async (data: IStatesToKwS) => {
        setLoading(true);
        const res = await AddStateToKwSAsync(data);
        setLoading(false);
        stateAddedToKwS(res);
    }

    const checklistProps = {
        label:"States", 
        lists: awConfig.statesettings, 
        onChecked: onStateSelected
    };


    return (
        <form>
            {awConfig.statesettings && (
                <AwModalCheckList {...checklistProps} />
            )}
            <div className="ks-form-btns">
                <button type="button" className="btn btn-secondary cancel" onClick={handleCancel} disabled={loading}>cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleAddStateToKs} disabled={loading}>add</button>
            </div>
        </form>
    )
}
