import React, { useState, useEffect } from "react";

export const AwFileInput = ({ name, onChange, refresh }) => {
  const [value, setValue] = useState("");
  const handleChange = e => {
    setValue(e.target.value);
    onChange(e);
  };
  useEffect(() => {
    setValue("");
  }, [refresh]);
  return (
    <div className="ui input cust-semantic input" style={{ width: "100%" }}>
      <input
        className="form-control"
        name={name}
        type="file"
        value={value}
        accept=".png, .jpg, .jpeg, .pdf"
        onChange={handleChange}
      />
    </div>
  );
};
