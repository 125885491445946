import {put, takeEvery, call, all} from "redux-saga/effects";

import {
  IAction,
  get_dashboard_report_type,
  get_dashboard_report_Success_type,
  get_dashboard_report_Failed_type,
  getSettingsSuccess_type,
  getSettingsFailed_type,
} from "../actiontypes";

import api from "../api";
import { getStateSettingsSelector } from "../selectors";
import { store } from "../configureStore";
import { IDashboardReport } from "../../interfaces";

function* getDashboardReportsAsync(action: IAction): Generator<any, void, any> {
    let errorObj: any = {};
    try {
      const statesettings: any[] =  getStateSettingsSelector(store.getState());
      if(statesettings.length > 0) {
        // Generator<IDashboardReport, void, unknown>
        const reports = yield call(
          api.dashboard.getReports,
          action
        );
        let reportErrorMsg = reports.message;
          if(!!reportErrorMsg) {
            reportErrorMsg += '. Please try again later.';
            errorObj = reports;
            errorObj['message'] = reportErrorMsg;
          }
        yield put({ type: get_dashboard_report_Success_type, payload: reports });
      } else {
        const stateAndReport = yield all([call(api.settings.getSettings), call(api.dashboard.getReports, action)]);

        let settingsErrorMsg = stateAndReport[0].message;
        if(!!settingsErrorMsg) {
          settingsErrorMsg += '. Please try again later.';
          errorObj = stateAndReport[0];
          errorObj['message'] = settingsErrorMsg;
          yield put({ type: getSettingsFailed_type, payload: settingsErrorMsg });
        } else {
          let errorOccurred = false;
          let reportErrorMsg = stateAndReport[1].message;
          if(!!reportErrorMsg) {
            reportErrorMsg += '. Please try again later.';
            errorObj = stateAndReport[1];
            errorObj['message'] = reportErrorMsg;
            errorOccurred = true;
          }
          
          
          yield put({ type: getSettingsSuccess_type, payload: stateAndReport[0]})
          if (!errorOccurred) {
            yield put({ type: get_dashboard_report_Success_type, payload: stateAndReport[1] });
          }

        }
      }

    } catch (error) {
      if (errorObj.config.url.indexOf("StateReport") !==  -1) {
        yield put({
          type: get_dashboard_report_Failed_type,
          payload: errorObj.message
        });
      } else {
        yield put({
          type: getSettingsFailed_type,
          payload: errorObj.message
        });
      }
    }
  }

  export function* watchGetDashboardReportAsync() {
    yield takeEvery(get_dashboard_report_type, getDashboardReportsAsync);
    // yield takeLatest(signIn_type, signInAsync);
  }
