import React, { useState } from "react";
//import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import AwButton from "../button/AwButton";
import { AwModalTitle } from "./AwModalTitle";

export const AwModal = React.forwardRef((props, ref) => {
  const { onModalClose, onSubmit, submitBtnLabel, title } = props;
  const [show, setShow] = useState(false);

  const modal = {
    open: () => setShow(true),
    close: () => setShow(false)
  };

  ref.current = modal;
  return (
    <>
      <Modal show={show} backdrop="static" onHide={onModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <AwModalTitle>{title}</AwModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <AwButton
            width="6em"
            label="Close"
            type="secondary"
            btnClicked={onModalClose}
          />
          <AwButton
            width="6em"
            label={submitBtnLabel}
            type="default"
            btnClicked={onSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
});

AwModal.defaultProps = {
  submitBtnLabel: "Save"
};
