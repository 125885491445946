import React, { useEffect } from "react";
import {
  AwInputFormField,
  AwFileInputField,
  useSetFileValue
} from "../../../../components";
import { Alert } from "../../../../components/message";

export function DocumentForm({ onInputChange, dObj }) {
  const { isBigFile, msg, fileEvt, handleChange } = useSetFileValue();

  useEffect(() => {
    if (fileEvt) {
      onInputChange(fileEvt);
    }
    return () => {};
  }, [fileEvt]);

  return (
    <div className="state-document-modal-form">
      {isBigFile && <Alert message={msg} align="center" />}
      <form autoComplete="off">
        {documentFields.map((field, i) => {
          return field.isFile ? (
            <span key={i}>
              {!dObj["DocumentName"] && (
                <AwFileInputField
                  name={field.name}
                  label={field.label}
                  onChange={handleChange}
                />
              )}
            </span>
          ) : (
            <AwInputFormField
              key={i}
              required={true}
              init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
              name={field.name}
              label={field.label}
              onChange={onInputChange}
            />
          );
        })}
      </form>
    </div>
  );
}

// <AwFormFieldWrapper
//                   name={field.name}
//                   label={field.label}
//                   required={true}
//                 >
//                   <AwInput
//                     type={inputTypes.file}
//                     name={field.name}
//                     onChange={handleChange}
//                   />
//                 </AwFormFieldWrapper>

const documentFields = [
  { name: "DocumentName", label: "Name of Document" },
  // { name: "CreatedDate", label: "Date Uploaded", isDate: true },
  // { name: "FileName", label: "File Name" },
  // { name: "FileType", label: "File Type" },
  // { name: "FileSize", label: "File Size (bytes)" },
  { name: "File", label: "File", isFile: true },
  { name: "Description", label: "Description" }
];
