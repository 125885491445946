import  { Fragment, useEffect, useState } from "react";

import { BrowserRouter } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import App from "./App";
import { useAuthResLogic } from "./components";

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href') || "";


export const PreApp = () => {
  const [loaded, setLoaded] = useState(false);
  const { shouldRefreshAsync } = useAuthResLogic();

  useEffect(() => {
    async function checkIfUserIsStillAuthenticated() {
      await shouldRefreshAsync();
      setLoaded(true);
    }
    checkIfUserIsStillAuthenticated();
    return () => {};
  }, []);

  return (
    <div className={"pre-app"}>
      <AppLoadingSpinner loaded={loaded} hideChn={true}>
          <BrowserRouter basename={baseUrl}>
            <App />
          </BrowserRouter>
        </AppLoadingSpinner>
    </div>
  );
};


interface ILoader {
  loaded: boolean;
  hideChn?: boolean;
  bgClr?: string;
  children: any;
}
export const AppLoadingSpinner:React.FC<ILoader> = ({loaded, hideChn = false, bgClr = "#fff", children}) => {
  return (
    <>
        {!hideChn || loaded ? children : <Fragment />} 
        {!loaded && <div  
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute', 
            left: '0', 
            right: '0', 
            top: '0', 
            bottom: '0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: bgClr
            }}>
          <div>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            visible={true}  
            width={'100'} />
          </div>
        </div>}
    </>
  )
}

// const TestCom = () => {
//   return (
//     <div>Great</div>
//   )
// }

// const options = {
//   lines: 10,
//   length: 20,
//   width: 10,
//   radius: 20,
//   scale: 1.0,
//   corners: 1,
//   color: "#000",
//   opacity: 0.25,
//   rotate: 0,
//   direction: 1,
//   speed: 1,
//   // trail: 60,
//   // fps: 20,
//   zIndex: 2e9,
//   top: "50%",
//   left: "50%",
//   shadow: false,
//   hwaccel: false,
//   position: "absolute"
// };
