import React from "react";

import { GridColumn as Column } from "@progress/kendo-react-grid";
import {
  DisplayGrid,
  DeleteAndEdit,
  GridCellWithLink,
  AwGridTopBtnLogic,
  AwButton,
  AwDModal,
  useAxios,
  useStateBasicFunctions
} from "../../../../components";
import {
  awConfirmDelete,
  isAllRequiredFieldsAnswered,
  validURL
} from "../../../../utils";
import api from "../../../../store/api";
import { formGenericErrorMsg, TABLE_NAME } from "../../../../defs";
import { Alert } from "../../../../components/message";

import { AccountForm } from "./AccountForm";

export const AccountGrid = ({ stateProvinceId }) => {
  const base_url = "api/accounts";
  const webKey = "Url";
  const { data } = useAxios(base_url, []);

  const {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    modalSubmitBtn,
    formData,
    formError,
    formChanges,
    setFormChanges,
    modalRef,
    createBtnRef,
    handleModalClose,
    handleNewModalOpen,
    handleUpdateClick,
    handleFormError,
    handleHttpResponse
  } = useStateBasicFunctions();

  const onDeleteRecord = async e => {
    if (await awConfirmDelete("Are you sure you want to delete this record?")) {
      const res = await api.requestmethods.deleteWithoutParams(
        base_url,
        e.AccountId
      );
      msgSvc.showSuccess("deleted");
      handleGridRefresh();
    }
  };

  const handleSubmit = e => {
    const dataToSubmit = Object.assign({}, formData, formChanges);
    const isSubmitable = isAllRequiredFieldsAnswered(
      ["Type", "Password", "UserName", webKey],
      dataToSubmit
    );
    if (isSubmitable) {
      const isValidUrl = validURL(dataToSubmit[webKey]);
      if (isValidUrl) {
        handleFormError("", false);
        if (modalSubmitBtn === addBtn) {
          dataToSubmit["StateProvinceId"] = stateProvinceId;
          addAccount(dataToSubmit);
        } else {
          saveAccount(dataToSubmit);
        }
      } else {
        handleFormError("Please provide a valid 'Web Address'.", true);
      }
    } else {
      handleFormError(formGenericErrorMsg, true);
    }
  };

  const addAccount = async values => {
    const res = await api.requestmethods.post(base_url, values);
    handleHttpResponse(res);
  };

  const saveAccount = async values => {
    const res = await api.requestmethods.put(base_url, values);
    handleHttpResponse(res);
  };

  return (
    <div className="account-grid">
      <AwGridTopBtnLogic table={TABLE_NAME.accounts}>
        <AwButton
          icon="plus"
          label={TABLE_NAME.accounts}
          btnClicked={handleNewModalOpen}
          ref={createBtnRef}
        />
      </AwGridTopBtnLogic>

      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={modalSubmitBtn}
        title={`${TABLE_NAME.bookmarks} Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <AccountForm
          onInputChange={setFormChanges}
          options={data}
          dObj={formData}
        />
      </AwDModal>

      <DisplayGrid
        tableName="accounts"
        url={base_url}
        urlExt={stateProvinceId}
        columns={accountColumns}
        showExport={true}
        refresh={gridRefresh}
      >
        <>
          <Column
            field={"Title"}
            title={"Title"}
            cell={props => (
              <GridCellWithLink defaultLink={true} link={"Url"} {...props} />
            )}
          ></Column>
        </>
        <>
          <Column
            width="140px"
            className="added-col-td delete-col"
            title="Action"
            filterable={false}
            cell={props => {
              return (
                <DeleteAndEdit
                  onDelete={onDeleteRecord}
                  onEdit={handleUpdateClick}
                  {...props}
                />
              );
            }}
          />
        </>
      </DisplayGrid>
    </div>
  );
};

const accountColumns = [
  // { field: "Title", header: "Title" },
  { field: "Type", header: "Type" },
  { field: "UserName", header: "Username" },
  {
    field: "Password",
    header: "Password",
    filterable: true,
    isPassword: true
  }, // filterable will set this col to false
  { field: "CreatedBy", header: "Created By" }
];
