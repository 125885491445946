import React, { useState, useEffect } from "react";
import {
  AwCard,
  DisplayGrid,
  useGridRefreshFunc
} from "../../../../components";
import { getRoleGridCol } from "../AdminUtil";
import { AddRole } from "./AddRole";

export const RoleGrid = ({ extraCol, refresh = false }) => {
  const [show, setShow] = useState(false);
  const rolesCol = getRoleGridCol();

  const { gridRefresh, handleGridRefresh } = useGridRefreshFunc();
  const [prevRefreshValue, setRefreshValue] = useState(refresh);
  useEffect(() => {
    // The below code helps avoid resetting the cache value to an empty { } on initial load
    if (refresh !== prevRefreshValue) {
      handleGridRefresh();
      setRefreshValue(refresh);
    }
  }, [refresh]);

  return (
    <div className="role-grid">
      <AwCard
        labelKey={"Roles"}
        label={"User Roles"}
        showPlus={!!extraCol ? true : false}
        addRow={() => setShow(true)}
      >
        <DisplayGrid
          tableName="roles"
          url="api/Admin/Roles"
          urlExt={null}
          columns={rolesCol}
          showExport={true}
          refresh={gridRefresh}
        >
          <></>
          <>{extraCol && extraCol.props && extraCol.props.children}</>
        </DisplayGrid>
      </AwCard>
      {show && (
        <AddRole
          onRoleAdded={handleGridRefresh}
          onHide={() => setShow(false)}
        />
      )}
    </div>
  );
};

export default RoleGrid;
