import React from "react";
import {
  AwInputFormField,
  AwPrimeReactCalendar,
  AwFormFieldWrapper
} from "../../../../../components";
import { convertStringToDate } from "../../../../../utils";

export const PharmacyCreateForm = ({ dObj, fieldChange }) => {
  return (
    <form noValidate>
      {formFields.map((field, i) => {
        return field.isDate ? (
          getDateField(dObj, field, fieldChange, i)
        ) : (
          <AwInputFormField
            key={i}
            required={field.isRequired}
            init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
            name={field.name}
            label={field.label}
            onChange={fieldChange}
          />
        );
      })}
    </form>
  );
};

export default PharmacyCreateForm;

const getDateField = (dObj, field, change, i) => {
  let date = new Date();
  if (dObj[field.name]) date = convertStringToDate(dObj[field.name]);
  return (
    <AwFormFieldWrapper
      required={field.isRequired}
      name={field.name}
      label={field.label}
      key={i}
    >
      <div style={{ marginRight: "30px" }}>
        <AwPrimeReactCalendar
          type={field.name}
          dateSelected={change}
          init={date}
        />
      </div>
    </AwFormFieldWrapper>
  );
};
const formFields = [
  { name: "LicenseNumber", label: "License Number", isRequired: true },
  { name: "Type", label: "License Type" },
  { name: "EffectiveDate", label: "Effective Date", isDate: true },
  { name: "ExpDate", label: "Expiration Date", isDate: true, isRequired: true },
  { name: "Schedule", label: "Schedule" },
  { name: "NABP", label: "NABP" }
];
