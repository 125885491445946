import React from "react";

import { ListItem, ListItemIcon, ListItemText, FontAwesomeIcon } from "../../../../../../third-party-lib";

import "./FwMenu.css";
import { NavLink } from "react-router-dom";
export default function FwMenu(props) {
  const menu = props.menu;
  const styles = props.horizontal ? { display: "inline-block" } : {};

  return (
    // <div style={styles}>
    //   <Icon color="primary">{menu.icon}</Icon>
    //   {menu.text}
    // </div>
    <div
      style={styles}
      className={
        "fw-menu " +
        (!props.horizontal ? "vertical-menu" : "horizontal-menu") +
        (props.isActive ? " active" : "")
      }
    >
      {/* <NavLink
        to={menu.route}
        isActive={() => props.isActive}
        activeStyle={{ background: "red" }}
      > */}
      <NavLink
        to={menu.route}
        className={props.isActive ? "active" : ""}
        style={{ background: props.isActive ? "red" : "" }}
      >
        <ListItem
          button
          style={{
            padding: "0",
            paddingLeft: "15px",
            paddingRight: "8px",
            marginRight: "10px"
          }}
        >
          <ListItemIcon style={{ minWidth: "30px" }} className="list-item-icon">
            <FontAwesomeIcon icon={menu.icon} />
          </ListItemIcon>
          <ListItemText primary={menu.text} />
        </ListItem>
      </NavLink>
    </div>
  );
}
