import { useState, useRef } from "react";
import {
  reportDateUtc,
  endDateExclusive,
  strToIntArr,
  isResponseError,
  awSwalAlert
} from "../../../../../utils";
import api from "../../../../../store/api";

export const useEditReportFunc = (
  rxdetails,
  pharmacies,
  showModal,
  base_url
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [segmentDetailSearchProp, setSegmentDetailSearchProp] = useState({
    rxNumber: rxdetails.RxNumber,
    submittedReportId: rxdetails.StateReportId
  });
  const [cdiIds, setCdiIds] = useState([]);
  const segmentInit = { PAT: {}, DSP: {}, PRE: {}, AIR: {}, CDI: [] };
  const inputFieldRef = useRef(segmentInit);
  const createDataRef = useRef({
    Prescriptions: "",
    StartDateUtc: "",
    PatientId: "",
    StateReportId: ""
  });

  let createdReportRef = useRef({});

  const handleEditClickedAsync = async e => {
    setIsLoading(!isLoading);
    var pres = strToIntArr(rxdetails.RxNumber);
    createDataRef.current = {
      PharmacyIds: [pharmacies[0].PharmacyId],
      Prescriptions: pres.data,
      StateProvinceIds: [],
      StartDateUtc: reportDateUtc(rxdetails.DateFilled),
      EndDateUtc: reportDateUtc(endDateExclusive(rxdetails.DateFilled)),
      PatientId: rxdetails.PatientId,
      StateReportId: rxdetails.StateReportId
    };

    // res is the actual created report
    const res = await api.requestmethods.post(
      `${base_url}/${rxdetails.RxNumber}/createandedit`,
      createDataRef.current
    );
    
    if (!isResponseError(res)) {
      if (res.CdiIds.length > 0) {
        res.CdiIds.forEach(id => {
          // initializing cdi array with these empty objects
          // helps make sure any of the cdi can modified in any order.
          // if this is not set and there are two cdi objs but one is modified
          // then the results becomes - [empty, {…}] - which makes it difficult to modify the first item since it is empty.
          inputFieldRef.current.CDI.push({});
        });

        setCdiIds(res.CdiIds);
      }
      createdReportRef.current = res;
      setSegmentDetailSearchProp(state => ({
        ...state,
        createdReportId: res.StateReportId
      }));
      showModal();
    } else {
      const { data } = res; // when there is an error, res has data array which is the actual error.
      // console.log('this is the error data', data);
      const isMore = data.length > 1;
      const html = isMore
        ? `
      <ol>${data.map(msg => `<li>${msg}</li>`)}</ol>`
        : data[0];

      awSwalAlert(html, "error", isMore);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    segmentDetailSearchProp,
    cdiIds,
    segmentInit,
    inputFieldRef,
    handleEditClickedAsync
  };
};
