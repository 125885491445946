import React, { useState } from "react";
import { resetFormDataToEmptyObj } from "../../../../utils";
import api from "../../../../store/api";
export const useLicenseBasicFunctions = (
  addBtn,
  setFormData,
  modalRef,
  setFormChanges
) => {
  const dialogRef = React.useRef();

  const [notifications, setNotifications] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState({});
  const [edit, setEditProp] = useState({
    isEdit: false,
    btn: addBtn
  });

  const handleLicenseNewModalOpen = () => {
    setEditProp({ isEdit: false, btn: addBtn });
    setFormData(resetFormDataToEmptyObj());
    modalRef.current.open();
  };

  const handleLicenseUpdateClick = e => {
    setEditProp({ isEdit: true, btn: "Save" });
    setFormChanges(resetFormDataToEmptyObj(e));
    setFormData(resetFormDataToEmptyObj(e));
    modalRef.current.open();
  };

  const getNotificationsAsync = async (id, isPharmacist = true) => {
    const res = await api.requestmethods.get(
      `api/Notifications/${id}`,
      `isPharmacist=${isPharmacist}`
    );
    return res.Items;
  };

  return {
    dialogRef,
    edit,
    handleLicenseNewModalOpen,
    handleLicenseUpdateClick,
    notifications,
    setNotifications,
    selectedLicense,
    setSelectedLicense,
    getNotificationsAsync
  };
};
