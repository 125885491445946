import React from "react";
import { AwPageContainer } from "../../AwPageContainer";
import { ContactGrid } from "./ContactGrid";
export const Contacts = React.memo(({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <ContactGrid stateProvinceId={stateProvinceId}></ContactGrid>
    </AwPageContainer>
  );
});
