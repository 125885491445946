import React from "react";

export const ActiveCol = props => {
  let style = { color: "green" };
  if (props.dataItem[props.field] !== "Active") {
    style = { color: "red" };
  }
  
  return (
    <td>
      <span style={{ ...style, fontWeight: "510" }}>
        {props.dataItem[props.field]}
      </span>
    </td>
  );
};

export default ActiveCol;
