import { useEffect, useState } from "react";
export const useAwTimeInterval = (handler: any, time: any, condition: any = null) => {
  const [intervalId, setIntervalId] = useState();
  useEffect(() => {
    // if extra condition = false it mean it was defined
    // if not define at all then do someting else.
    let id:any = null;
    if (condition === false || condition === true) {
      if (condition === true) {
        id = setInterval(handler, time);
      } else {
        clearInterval(intervalId);
      }
    } else {
      id = setInterval(handler, time);
    }
    setIntervalId(id);
    return () => {
      clearInterval(id);
    };
  }, [condition]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return () => clearInterval(intervalId);
};
