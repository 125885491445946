import React from "react";
import { ChangePassword } from "./change-password";
import { UploadProfile } from "./upload-profile";
import { AwPageContainer } from "../../AwPageContainer";

export const UserSettings = () => {
  return (
    <AwPageContainer>
      <div className="row aw-usersettings">
        <div className="col-md-6 offset-md-3 mb-3">
          <UploadProfile />
        </div>
        <hr />
        <div className="col-md-6 offset-md-3 mt-5">
          <ChangePassword />
        </div>
      </div>
    </AwPageContainer>
  );
};
