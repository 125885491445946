import React, { useState, useEffect } from "react";

import { Grid,
  GridColumn as Column,
  GridToolbar} from "../../../third-party-lib";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import "./DisplayGrid.css";
import { useDisplayGridAxios, useWindowResize } from "../../custom-hooks";
import { getGridScreenColumns } from "../../../utils";
import GridPasswordCol from "../password/GridPasswordCol";
import DateCol from "../date/DateCol";
import { GridInfoCol } from "../action-column";
import PhoneCol from "../phone/PhoneCol";
import ActiveCol from "../active/ActiveCol";
import { useGridStateChangeFunc } from "./useGridStateChangeFunc";


export const DisplayGrid = ({
  tableName,
  url,
  urlExt,
  columns,
  showExport,
  children,
  ...props
}) => {
  const [screen] = useWindowResize();
  const [newCols, setColumns] = useState(columns);
  useEffect(() => {
    setColumns(getGridScreenColumns(screen, columns, 2));
  }, [screen]);

  const {
    gridRef,
    gridEvent,
    handleDataStateChange
  } = useGridStateChangeFunc();

  const { newState, loading } = useDisplayGridAxios(
    tableName,
    url,
    urlExt,
    gridEvent,
    props
  );

  let _export;
  const exportFns = () => {
    _export.save();
  };

  // const bullets = Array(props.children.length).fill("o");
  // bullets[0] = "●";
  // console.log(bullets);

  const infoCol = GridInfoCol(screen.width, tableName);
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="aw-grid">
      {newState.total === 0 && loading === true && loadingPanel}
      <ExcelExport 
        data={newState.data} 
        ref={exporter => (_export = exporter)}>
        <Grid
          ref={gridRef}
          loading={true}
          filterable={true}
          sortable={true}
          pageable={{ pageSizes: true }}
          {...props}
          total={newState.total}
          data={newState.data}
          skip={gridEvent.skip}
          pageSize={gridEvent.take}
          filter={gridEvent.filter}
          sort={gridEvent.sort}
          onDataStateChange={handleDataStateChange}
        >
          {showExport && (
            <GridToolbar style={{ maxWidth: "100%" }} width="1000px">
              {/* themeColor={"primary"} */}
              <button
                title="Export PDF"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={exportFns}
              >
                Export to Excel
              </button>
            </GridToolbar>
          )}
          {childrenArray.length > 0 && childrenArray[0].props.children}
          {newCols.map((col, i) => {
            if (col.isPassword) {
              return (
                <Column
                  filterable={col.filterable ? false : true} // if filterable is set then it means the col shouldnt be filtered cuz cols are filterable by default
                  key={i}
                  field={col.field}
                  title={col.header}
                  cell={props => <GridPasswordCol {...props} />}
                />
              );
            } else if (col.isDate) {
              return (
                <Column
                  filterable={col.filterable ? false : true}
                  key={i}
                  field={col.field}
                  title={col.header}
                  cell={props => <DateCol fieldName={col.field} {...props} />}
                />
              );
            } else if (col.isLicenseStatus) {
              return (
                <Column
                  filterable={col.filterable ? false : true}
                  key={i}
                  field={col.field}
                  title={col.header}
                  cell={props => <ActiveCol {...props} />}
                />
              );
            } else if (col.isPhone) {
              return (
                <Column
                  filterable={col.filterable ? false : true}
                  key={i}
                  field={col.field}
                  title={col.header}
                  cell={props => <PhoneCol {...props} />}
                />
              );
            } else {
              return (
                <Column
                  filterable={col.filterable ? false : true}
                  key={i}
                  field={col.field}
                  title={col.header}
                />
              );
            }
          })}
          {infoCol && infoCol.props.children}
          {childrenArray.length > 1 && childrenArray[1].props.children}
        </Grid>
      </ExcelExport>
    </div>
  );
};


export default DisplayGrid;

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
