import React, { useState } from "react";

import { GridColumn as Column } from "@progress/kendo-react-grid";
import {
  DisplayGrid,
  AwDModal,
  AwButton,
  AwGridTopBtnLogic,
  DeleteAndEdit,
  useStateBasicFunctions
} from "../../../../../components";
import {
  awConfirmDelete,
  awIcons,
  isAllRequiredFieldsAnswered
} from "../../../../../utils";
import { AwPageContainer } from "../../../AwPageContainer";
import { Alert } from "../../../../../components/message";
import { TABLE_NAME, formGenericErrorMsg } from "../../../../../defs";
import { PharmacistCreateForm } from "./PharmacistCreateForm";
import api from "../../../../../store/api";
import GridHeader from "../../../../../components/grid/GridHeader";

import { LicenseAlertCol } from "../LicenseAlertCol";
import { LicenseAlertDialog } from "../LicenceAlertDialog";
import { useLicenseBasicFunctions } from "../useLicenseBasicFunctions";

export const PharmacistGrid = ({ stateProvinceId }) => {
  const tableName = "pharmacistLicense";
  const base_url = "api/pharmacists";
  const [columns, setColumns] = useState(pharmacistColumns);
  const initLicenseFormValues = {
    StateProvinceId: stateProvinceId,
    EffectiveDate: new Date(),
    ExpDate: new Date()
  };

  const {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    formData,
    setFormData,
    formError,
    setFormError,
    formChanges,
    setFormChanges,
    modalRef,
    createBtnRef,
    handleModalClose,
    handleHttpResponse
  } = useStateBasicFunctions({}, initLicenseFormValues);

  const {
    dialogRef,
    edit,
    handleLicenseNewModalOpen,
    handleLicenseUpdateClick,
    notifications,
    setNotifications,
    selectedLicense,
    setSelectedLicense,
    getNotificationsAsync
  } = useLicenseBasicFunctions(addBtn, setFormData, modalRef, setFormChanges);

  const handleSubmit = async e => {
    const data = Object.assign({}, formData, formChanges);
    const x = [
      "LicenseNumber",
      "FirstName",
      "LastName",
      "EffectiveDate",
      "ExpDate",
      "PIC"
    ];
    const isSubmitable = isAllRequiredFieldsAnswered(x, data);

    if (isSubmitable) {
      setFormError({
        isError: false,
        errorMsg: ""
      });
      if (edit.isEdit) {
        const res = await api.requestmethods.put(base_url, data);
        handleHttpResponse(res);
      } else {
        const res = await api.requestmethods.post(base_url, data);
        handleHttpResponse(res);
      }
    } else {
      setFormError({
        isError: true,
        errorMsg: formGenericErrorMsg
      });
    }
  };

  const onDeleteRecord = async e => {
    if (await awConfirmDelete()) {
      const queryString = `id=${
        e.PharmacistId
      }&stateProvinceId=${stateProvinceId}&isPharmacist=${true}`;
      await api.requestmethods.delete(base_url, queryString);
      msgSvc.showSuccess("deleted");
      handleGridRefresh();
    }
  };

  const showAlertDialog = async data => {
    setSelectedLicense(data);
    const items = await getNotificationsAsync(data.PharmacistId);
    setNotifications(items);
    dialogRef.current.show();
  };

  return (
    <AwPageContainer>
      <AwGridTopBtnLogic table={TABLE_NAME.licensePharmacist}>
        <AwButton
          icon="plus"
          label={"License"}
          btnClicked={handleLicenseNewModalOpen}
          ref={createBtnRef}
        />
      </AwGridTopBtnLogic>

      <DisplayGrid
        tableName={tableName}
        url={base_url}
        urlExt={stateProvinceId}
        columns={columns}
        showExport={true}
        refresh={gridRefresh}
      >
      
        <></>
        <>
          <Column
            className="added-col-td"
            title="Download"
            width="60px"
            filterable={false}
            headerCell={props => (
              <GridHeader {...props} iconName={awIcons.alert} />
            )}
            cell={props => {
              return <LicenseAlertCol show={showAlertDialog} {...props} />;
            }}
          ></Column>

          <Column
            width="140px"
            className="added-col-td delete-col"
            title="Action"
            filterable={false}
            cell={props => {
              return (
                <DeleteAndEdit
                  onDelete={onDeleteRecord}
                  onEdit={handleLicenseUpdateClick}
                  {...props}
                />
              );
            }}
          />
        </>
      </DisplayGrid>

      <LicenseAlertDialog
        ref={dialogRef}
        alerts={notifications}
        license={selectedLicense}
      />

      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={edit.btn}
        title={`${TABLE_NAME.licensePharmacist} Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <PharmacistCreateForm dObj={formData} fieldChange={setFormChanges} />
      </AwDModal>
    </AwPageContainer>
  );
};

const pharmacistColumns = [
  { field: "FirstName", header: "First Name" },
  { field: "LastName", header: "Last Name" },
  { field: "LicenseNumber", header: "License" },
  { field: "Status", header: "Status", isLicenseStatus: true },
  { field: "EffectiveDate", header: "Effective Date", isDate: true },
  { field: "ExpDate", header: "Expiration Date", isDate: true },
  { field: "PIC", header: "PIC" },
  { field: "CreatedBy", header: "Created By" }
];
