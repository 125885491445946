import React, { useRef } from 'react';
import { AwDModal, IModalProps } from '../../../../components';
import { Alert } from '../../../../components/message';


interface Props {
    modalRef: React.MutableRefObject<IModalProps>;
    mBtnLabel?: string;
    mTitle: string;
    submitClicked: any;
    closeClicked: any;
    error: string;
    formValues: any;
    handleError: any;
    Rform: any;
    formParams: any;
    handleChange: any;
}

export const UserModal: React.FC<Props> = ({
    mBtnLabel = "Add", 
    mTitle,
    modalRef, 
    submitClicked, 
    closeClicked, 
    error, 
    formValues, 
    handleError,
    Rform, 
    formParams, 
    handleChange
    }) => {

    const phone = useRef({isPhone: false}).current;


    const handlePhoneChecked = (e: any) => {
        handleChange(e);
        phone["isPhone"] = e.target.value;
    }
        

    const onMSubmitClicked = () => {
        
        if(phone.isPhone && !formValues.PhoneNumber) return handleError(true);
        
        const inValidPhone = (formValues.PhoneNumber && formValues.PhoneNumber.length !== 10);
        if((phone.isPhone 
            && inValidPhone) || inValidPhone) return handleError(true, "Please enter a valid phone number!");
            
        submitClicked('user');
    }
    
    return (
        <div className="user-modal">
            <AwDModal
                {...{ref: modalRef,
                onModalClose: closeClicked,
                onSubmit: onMSubmitClicked,
                submitBtnLabel: mBtnLabel,
                title: `${mTitle} User`}}
                >
                    <div>
                        {!!error && <Alert message={error} align={"center"} />}
                        <Rform {...formParams} onInputChange={handleChange} onPhoneChecked={handlePhoneChecked} onCheck={handleChange} />
                    </div>
            </AwDModal>
        </div>
    )
}
