export const formGenericErrorMsg = "Please Answer All Required Fields!";
export const httpErrorMsg = "Couldn't save record. Please try again later.";

export const reportingTimeZone = "America/Denver";

export const inputTypes = {
  text: "text",
  date: "date",
  file: "file",
  phone: "phone",
  textarea: "textarea",
  select: "select",
  checkbox: "checkbox",
  webEndPoint: "webendpoint",
  onlyNumbersAndComma: "onlyNumbersAndComma"
};

export const TABLE_NAME = {
  accounts: "Accounts",
  asap: "Asap",
  bookmarks: "Bookmarks",
  contacts: "Contacts",
  documents: "Documents",
  history: "History",
  licensePharmacy: "Pharmacy License",
  licensePharmacist: "Pharmacist License"
};

export const httpMethods = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete"
};

export const UserSystemRoles = {
  admin: "Admin",
  manager: "Manager",
  superUser: "SuperUser"
};

export const states = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};

export const initialGridState = {
  skip: 0,
  take: 50,
  sort: [],
  filter: { logic: "and", filters: [] },
  group: [],
  data: [],
  total: 0,
  allReports: true,
  isErrorResponse: false
};
