import React from "react";
import { AwInputFormField } from "../../../../components";
import { BookmarkCategoryFormField } from "./BookmarkCategoryFormField";

export function BookmarkForm({ onInputChange, options, dObj }) {
  return (
    <div className="state-bookmark-modal-form">
      <form>
        <BookmarkCategoryFormField
          options={options}
          dValue={Object.keys(dObj).length > 0 ? dObj["Category"] : null}
          onCategoryValueChange={onInputChange}
        />
        {bookmarkFields.map((field, i) => {
          return (
            <AwInputFormField
              key={i}
              required={true}
              init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
              name={field.name}
              label={field.label}
              onChange={onInputChange}
            />
          );
        })}
      </form>
    </div>
  );
}

const bookmarkFields = [
  { name: "Title", label: "Title" },
  { name: "WebUrl", label: "Web Address" }
];
