import { useRef } from "react";



let minutes = 1;
let seconds = 60;

export type tCountDownHandler = (min: number, sec: number) => void;

export const useSessionTimeInterval = (handler: tCountDownHandler, time: number) => {
    const variableRef = useRef<any>({intervalId: 0 as any, minutes: minutes, seconds: seconds });
    
    const handleCounter = (min: number, sec: number) => {
      if (sec) {
        sec--;
      } else if (min) {
        min--;
        sec = 59;
      }
      return { min, sec };
    };
  
    const startCounter = () => {
      const { min, sec } = handleCounter(variableRef.current.minutes, variableRef.current.seconds);
      variableRef.current.minutes = min;
      variableRef.current.seconds = sec;
      handler(variableRef.current.minutes, variableRef.current.seconds);
    };
    
    const interval = {
      startInterval: () => {
        variableRef.current.intervalId = setInterval(startCounter, time);
      },

      stopInterval: () => {
        variableRef.current.minutes = minutes;
        variableRef.current.seconds = seconds;
        clearInterval(variableRef.current.intervalId);
      }
    }
    
    return {startInterval: interval.startInterval, stopInterval: interval.stopInterval};
  };


  