import { useContext } from 'react';

import api from "../../../../store/api";

import {IKeyword, IStatesToKwS, IDoC, IDocCache, IDoCApi, IKwSResponseModel} from "./IDocInterfaces";
import DocContext from './store/docContext';
import { docsActions, keywordsActions } from './store';
import { isResponseError, getPagedQS, deepCopy } from '../../../../utils';

const base_url = "api/doc";
const kw_base_url = `api/dockeywords`;
const kws_base_url = `api/dockeywordstates`;

// const kw_base_url = `${base_url}/keywords`;
// const kws_base_url = `${base_url}/keywordstates`;

type ICreatedResource = () => Promise<any>;

interface IValueTypes {
    type: string;
    cache: IDocCache;
    rm: string;
}

export const useDoCApis = () : IDoCApi => {
    const {docs, keywords, docDispatch } = useContext(DocContext);
    const typeValues = {
        docs: {rm: docsActions.remove_cache, type: docsActions.set_cache, cache: docs.cache},
        keywords: {rm: keywordsActions.remove_cache, type: keywordsActions.set_cache, cache: keywords.cache}
    }

    const getCacheAsync = async (vt: IValueTypes, key: string, resource: ICreatedResource) => {
        let res;
        const value = (vt.cache as any)[key];
        if(!!value) res = value;
        if(!value) {
            res = await resource();
            if(!isResponseError(res)) docDispatch({type: vt.type, payload: {[key]: res}});
        }
        return res;
    }

    const resourceQueryAsync = async (func: any, dataType: string, cacheType?: string, key?: string) => {
        const res = await func();
        if(!isResponseError(res)) {
            docDispatch({type: dataType, payload: res});
            if(cacheType && key) {
                docDispatch({type: cacheType, payload: {[key]: res}});
            }
        }
        return res;
    }

    const removeCacheAsync = async (vt: IValueTypes, func: any) => {
        let res = await func();
        
        if(!isResponseError(res)) {
            docDispatch({type: vt.rm, payload: {} as IDocCache});
        }
        return res;
    }

    const setStoreDataAsync = async (actionType: string, apiResponse: any) => {
        const res = await apiResponse;
        if(!isResponseError(res)) docDispatch({type: actionType, payload: res});
        return res;
    }

    const createApiResource = (func: any, url: string, body?: any): ICreatedResource => {
        return !!body ? () => func(url, body) : () => func(url);
    }


    
    const getDocsAsync = () => {
        const url = `${base_url}/get`;
        return setStoreDataAsync(docsActions.set_docs, getCacheAsync(typeValues.docs, url, createApiResource(api.requestmethods.get, url)));
    }

    const getNewDocsAsync = async () => {
        const url = `${base_url}/get`;
        return resourceQueryAsync(createApiResource(api.requestmethods.get, url), docsActions.set_docs, docsActions.set_cache, url);
    }

    const getDocsByStateAsync = async (qs: string) => {
        const url = `${base_url}/getdocbystate`;
        const resource =  createApiResource(api.requestmethods.get, url, qs);
        return await getCacheAsync(typeValues.docs, `${url}?${qs}`, resource);
    };

    
    const getNewKeywordsAsync = () => {
        // setStoreDataAsync(keywordsActions.set_keywords, getCacheAsync(typeValues.keywords, kw_base_url, createApiResource(api.requestmethods.get, `${kw_base_url}`)))
        return resourceQueryAsync(createApiResource(api.requestmethods.get, `${kw_base_url}`), keywordsActions.set_keywords, keywordsActions.set_cache, kw_base_url);
    }

    const runApiResourceAsync = async (method: any, url: string, requestBody: any) => {
        const resource = createApiResource(method, url, requestBody);
        const res = await resource();
            
        await getNewKeywordsAsync();
        await getNewDocsAsync();
        
        return res;
    }

    const getNext2DocsByStateAsync = async (pNumber: number, pSize: number, stateProvinceId: string) => {
        const qs = getPagedQS(pNumber, pSize, stateProvinceId);
        const resource = createApiResource(api.requestmethods.get, `${base_url}/getdocbystate`, qs);
        const res = await resource();
        if(!isResponseError(res)) {
            let payload = docs.data.filter((d: any) => d.StateProvinceId !== stateProvinceId);
            if(res.Items.length) {
                payload = [...res.Items, ...payload];
            } 
            if(!res.Items.length) docDispatch({type: keywordsActions.set_cache, payload: {} as any})
           
            docDispatch({type: docsActions.set_docs, payload});
        }
        return res;
    }
    
    const getNewKwSAsync = async (keyword: IKeyword): Promise<IKwSResponseModel[]> => {
        const qs = `keywordId=${keyword.SRKeywordID}`;
        const resource = createApiResource(api.requestmethods.get, `${kws_base_url}`, qs);
        const res = await resource();
        if (!isResponseError(res)) {
            docDispatch({ type: keywordsActions.set_cache, payload: res });
        }
        return res;
    };

    return {
        // resources
        docs, 
        keywords, 
        docDispatch,
        // docs
        GetNewDocsAsync: getNewDocsAsync,
        GetDocsAsync: getDocsAsync,
        GetDocsByStateAsync: getDocsByStateAsync,
        GetNext2DocsByStateAsync: getNext2DocsByStateAsync,
        AddDoCAsync: async (data: any) => {
            return await runApiResourceAsync(api.requestmethods.post, base_url, data);
        },
        DeleteDoCAsync: (doc: IDoC) => removeCacheAsync(typeValues.docs, createApiResource(api.requestmethods.delete, `${base_url}/${doc.ProductId}`, `stateProvinceId=${doc.StateProvinceId}`)),
        DeleteDoCsAsync: (idsArr: any[], stateProvinceId: string) => removeCacheAsync(typeValues.docs, createApiResource(api.requestmethods.deleteByBody, `${base_url}/deleteallasync`, {Ids: idsArr, StateProvinceId: stateProvinceId})),

        // keywords
        VerifyKeySearchAsync: (search: string) => api.requestmethods.get(`${base_url}/productexist/${search}`),
        GetNewKeywordsAsync: getNewKeywordsAsync,
        GetKeywordsAsync: async () => {
            const res = await setStoreDataAsync(keywordsActions.set_keywords, getCacheAsync(typeValues.keywords, kw_base_url, createApiResource(api.requestmethods.get, `${kw_base_url}`)));
            return res;
        },

        GetKeywordHistoryAsync: (keyword: IKeyword) => {
            const base_qs = `${kw_base_url}/${keyword.SRKeywordID}`;
            return getCacheAsync(typeValues.keywords, base_qs, createApiResource(api.requestmethods.get, base_qs))
        },
        SaveKeywordAsync: async (keyword: IKeyword) => {
            return await runApiResourceAsync(api.requestmethods.put, `${kw_base_url}`, keyword);
        },
        DeleteKeywordAsync: async (keyword: IKeyword) => {
            const res = await runApiResourceAsync(api.requestmethods.delete, `${kw_base_url}/${keyword.SRKeywordID}`, "");
            // const itemArr = keywords.data.filter(x => x.SRKeywordID === keyword.SRKeywordID);
            // if(itemArr.length) docDispatch({type: keywordsActions.set_cache})
            return res;
        },

        // keywordstates
        SetDoCsDLValueAsync: async (id: number, stateId: string, add: boolean) => {
            const qs = `keywordId=${id}&stateprovinceId=${stateId}&add=${add}`;
            const callApiAsync = createApiResource(api.requestmethods.get, `${base_url}/setdocsdlvalue`, qs);
            return await callApiAsync();
        },
        GetNewKwSAsync: getNewKwSAsync,
        GetKwSAsync: (keyword: IKeyword) => {
            const qs = `keywordId=${keyword.SRKeywordID}`;
            return getCacheAsync(typeValues.keywords, qs, createApiResource(api.requestmethods.get, `${kws_base_url}`, qs));
        },
        AddStateToKwSAsync: (data: IStatesToKwS) => removeCacheAsync(typeValues.keywords, createApiResource(api.requestmethods.post, `${kws_base_url}`, data)),
        DeleteStateFromKwSAsync: async (data: IStatesToKwS) => {
            const res = await removeCacheAsync(typeValues.keywords, createApiResource(api.requestmethods.deleteByBody, `${kws_base_url}`, data));
            
            const stateProviceId = data.States[0];
            let x = deepCopy(docs.data);
            x = x.filter((d: any) => d.StateProvinceId !== stateProviceId);
            // get next 2 docs for the state
            const newDocs = await getNext2DocsByStateAsync(1, 2, stateProviceId);
            if(!isResponseError(newDocs)){
                x = [...newDocs.Items, ...x];
            }
            docDispatch({type: docsActions.set_docs, payload: x});

            return res;
        }
    }
}
