import React from "react";

export const SettingsHeader = ({ header }) => {
  const styles = {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    padding: ".5rem 0",
    marginBottom: "35px",
    fontWeight: "700"
  };
  return (
    <div style={styles}>
      <h3>{header}</h3>
    </div>
  );
};
