import React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import {
  DisplayGrid,
  DeleteAndEdit,
  AwGridTopBtnLogic,
  AwButton,
  AwDModal,
  useStateBasicFunctions
} from "../../../../components";
import {
  awConfirmDelete,
  isAllRequiredFieldsAnswered,
  getFormData
} from "../../../../utils";
import api from "../../../../store/api";
import { TABLE_NAME, formGenericErrorMsg } from "../../../../defs";
import { Alert } from "../../../../components/message";

import { DocumentForm } from "./DocumentForm";

export const DocumentGrid = ({ stateProvinceId }) => {
  const base_url = "api/Document";
  const file = "FileName";
  const {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    modalSubmitBtn,
    formData,
    formError,
    formChanges,
    setFormChanges,
    modalRef,
    createBtnRef,
    handleModalClose,
    handleNewModalOpen,
    handleUpdateClick,
    handleFormError,
    handleHttpResponse
  } = useStateBasicFunctions();

  const onDeleteRecord = async e => {
    if (await awConfirmDelete()) {
      await api.requestmethods.deleteWithoutParams(base_url, e.DocumentId);
      msgSvc.showSuccess("deleted");
      handleGridRefresh();
    }
  };

  const handleSubmit = () => {
    const reqDocFields = ["DocumentName", "Description"];
    let dataToSubmit = Object.assign({}, formData, formChanges);
    const isSubmitable = isAllRequiredFieldsAnswered(
      modalSubmitBtn === addBtn ? reqDocFields.concat(["File"]) : reqDocFields,
      dataToSubmit
    );
    if (isSubmitable) {
      handleFormError("", false);
      if (modalSubmitBtn === addBtn) {
        dataToSubmit["StateProvinceId"] = stateProvinceId;
        dataToSubmit = getFormData(dataToSubmit);
        // console.log("dataToSubmit", dataToSubmit);
        addDocument(dataToSubmit);
      } else {
        // console.log("dataToSubmit", dataToSubmit);
        saveDocument(dataToSubmit);
      }
    } else {
      handleFormError(formGenericErrorMsg, true);
    }
  };

  const addDocument = async values => {
    const res = await api.requestmethods.post(base_url, values, true);
    handleHttpResponse(res);
  };

  const saveDocument = async values => {
    const res = await api.requestmethods.put(base_url, values);
    handleHttpResponse(res);
  };

  return (
    <div className="documents-grid">
      <AwGridTopBtnLogic table={TABLE_NAME.documents}>
        <AwButton
          icon="plus"
          label={TABLE_NAME.documents}
          btnClicked={handleNewModalOpen}
          ref={createBtnRef}
        />
      </AwGridTopBtnLogic>

      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={modalSubmitBtn}
        title={`${TABLE_NAME.documents} Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <DocumentForm onInputChange={setFormChanges} dObj={formData} />
      </AwDModal>
      <DisplayGrid
        tableName={TABLE_NAME.documents}
        url={base_url}
        urlExt={stateProvinceId}
        columns={documentColumns}
        showExport={true}
        refresh={gridRefresh}
      >
        <>
          <Column
            field={file}
            title={"File Name"}
            cell={props => (
              <td className="grid-link-td">
                <span>
                  <a
                    className="grid-link"
                    href={`${base_url}/${
                      props.dataItem["DocumentId"]
                    }/download`}
                  >
                    {props.dataItem[file]}
                  </a>
                </span>
              </td>
            )}
          ></Column>
        </>
        <>
          <Column
            width="140px"
            className="added-col-td delete-col"
            title="Action"
            filterable={false}
            cell={props => {
              return (
                <DeleteAndEdit
                  onDelete={onDeleteRecord}
                  onEdit={handleUpdateClick}
                  {...props}
                />
              );
            }}
          />
        </>
      </DisplayGrid>
    </div>
  );
};

const documentColumns = [
  { field: "DocumentName", header: "Name of Document" },
  { field: "CreatedDate", header: "Date Uploaded", isDate: true },
  // { field: "FileName", header: "File Name" },
  { field: "FileType", header: "File Type" },
  { field: "FileSize", header: "File Size (bytes)" },
  { field: "Description", header: "Description" },
  { field: "CreatedBy", header: "Created By" }
];
