import React from "react";

export const Alert = React.forwardRef((props: any, ref: any) => {
  return (
    <div
      style={{
        textAlign: props.align,
        backgroundColor: "#f5c6cb",
        width: "100%",
        color: "red",
        padding: "5px",
        marginBottom: "1.3em"
      }}
      className="aw-alerts"
    >
      <p ref={ref} style={{ border: "1px solid white" }}>
        {props.message}
      </p>
    </div>
  );
});

// marginTop: "1.2em"
// Alert.PropTypes = {
//     message: PropTypes.string.isRequired,
//     align: PropTypes.string.isRequired
// }

export default Alert;
