import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import AwButton from "../button/AwButton";

const AwDataModal = React.forwardRef((props, ref) => {
  const { onSubmit, onModalClose, submitBtnLabel, title, button } = props;
  const [show, setShow] = useState(false);

  const modal = {
    open: () => setShow(true),
    close: () => setShow(false)
  }
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  ref.current = modal;
  return (
    <>
      <span className="btn-span">
        {button && React.cloneElement(button, { btnClicked: modal.open })}
      </span>

      <Modal show={show} backdrop="static" onHide={onModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <AwButton width="4rem" label="Close" type="secondary" btnClicked={onModalClose} />
          <AwButton
            width="4rem"
            label={submitBtnLabel}
            type="default"
            btnClicked={onSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
});
export default AwDataModal;

const getModalOpenBtn = (label, icon) => {
  switch (true) {
    case !!label:
      break;

    default:
      break;
  }
};

AwDataModal.propTypes = {
  title: PropTypes.string.isRequired,
  submitBtnLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  button: PropTypes.element.isRequired,
  onModalClose: PropTypes.func.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string
};

AwDataModal.defaultProps = {
  submitBtnLabel: "Submit"
};
