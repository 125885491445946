import React, { useState } from 'react'
import { IKeyword } from '../IDocInterfaces'
import { AwGenericPModal } from '../../../../../components'
import { useDoCApis } from '../useDoCApis'
import { initShortDate } from '../../../../../utils'

interface Props {
    keyword: IKeyword;
}
interface IKwHistory {
    ModifiedBy: string;
    NewKeyword: string;
    OldKeyword: string;
    SRKeywordAuditID: number;
    SRKeywordID: number;
    Timestamp: any;
}
export const KeywordPModal: React.FC<Props> = ({keyword}) => {
    const [histories, setHistories] = useState<IKwHistory[]>([])
    const {GetKeywordHistoryAsync} = useDoCApis();
    const getKwHistoryAsync = async () => {
        var res: IKwHistory[] = await GetKeywordHistoryAsync(keyword);
        setHistories(res);
        return res;
    }
    
    return (
        <AwGenericPModal queryAsync={getKwHistoryAsync} title={`${keyword.SRKeyword} History`}>
            <>
                <button 
                    style={{margin: '0 0.5rem'}} 
                    type="button" 
                    className={`btn btn-light`}
                    disabled={!!keyword.Loading}>
                    history
                </button>
            </>
            <>
            <table className="table">
                <thead>
                    <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Old</th>
                    <th>New</th>
                    <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {histories.map((item: IKwHistory, idx:number) => {
                    return (
                        <tr key={idx}>
                            <th>{item.OldKeyword !== 'N/A' ? 'ModifiedBy' : 'CreatedBy'}</th>
                            <td>{item.ModifiedBy}</td>
                            <td>{item.OldKeyword}</td>
                            <td>{item.NewKeyword}</td>
                            <td>{initShortDate(item.Timestamp)}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            </>
        </AwGenericPModal>
    )
}
