import React, { useEffect } from "react";
import "./ProgressBar.css";
import { useIsComponentActive, useAwTimeInterval } from "../../../components";
// import { useAwTimeInterval, useIsComponentActive } from "../custom-hooks";

let progressWidth = 0;
export const ProgressBar = () => {
  const [show, setShow] = React.useState(false);
  const [width, setWidth] = React.useState(progressWidth);
  const { componentRef } = useIsComponentActive();

  const move = (finish: number) => {
    const increase = finish ? finish : 10;
    progressWidth += increase;
    if (progressWidth <= 100) {
      setWidth(progressWidth);
    }
    // if (finish || progressWidth === 100) {
    //   stop();
    // }
  };

  (window as any).ProgressBar = {
    start: () => {
      if (componentRef.active) {
        setShow(true);
      }
    },
    end: () => {
      if (componentRef.active) {
        const increment = 100 - progressWidth;
        move(increment || 100);
        stop();
      }
    }
  };

  
  const clearTimeInterval = useAwTimeInterval(move, 1000, show);

  const stop = () => {
    setShow(false);
    clearTimeInterval();
  };

  useEffect(() => {
    if (!show) {
      progressWidth = 0;
    }
  }, [show]);

  return (
    <>
      {show && (
        <progress
          max="100"
          value="0"
          className="http-progress-bar"
          style={{ backgroundColor: "red", width: width + "%" }}
        ></progress>
      )}
    </>
  );
};

export default ProgressBar;
