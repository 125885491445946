import { UserSystemRoles } from "../../../defs";

export const useDetermineCanDelete = (pageName, dataItem, user) => {
  let canDelete = (pageName !== 'role' && pageName !== 'users');

  if(!canDelete) {
    const isSU = user && user.Role === UserSystemRoles.superUser;
    const isRole = pageName === "role" && isSU;
  
    const isAdminAndManager = dataItem.RoleName === UserSystemRoles.manager && (user && user.Role === UserSystemRoles.admin);
  
    const notMe = user && dataItem.UserName !== user.Email;
  
    canDelete = isRole || (notMe && isSU) || (notMe && isAdminAndManager);
    
  }

  return {
    canDelete
  };
};


