import { Suspense, lazy } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";


import { SignIn } from "../containers";
  
import {APP_URLS} from "../infrastructure";

// import StatePages from "../containers/pages/state/StatePages";
import Reports from "../containers/pages/report/Reports";
import { useCurrentUser } from "./custom-hooks";
import { ConfirmPhoneNumber } from "../containers/pages";
import { FwLayout } from "./fw";
import { IUser } from "../interfaces";

function lazyComponent(component: any) {
  return lazy(() =>
    import("../containers").then((fromContainers: any) => ({
      default: fromContainers[component]
    }))
  );
}

const Directory = lazyComponent("Directory"),
  Pharmacy = lazyComponent("Pharmacy"),
  Orders = lazyComponent("Orders"),
  Admin = lazyComponent("Admin"),
  Users = lazyComponent("Users"),
  Roles = lazyComponent("Roles"),
  StateRules = lazyComponent("StateRules"),
  UserSettings = lazyComponent("UserSettings"),
  Dashboard = lazyComponent("Dashboard"),
  StatePages = lazyComponent("StatePages"),
  CreatePassword = lazyComponent("CreatePassword"),
  EmailConfirmation = lazyComponent("EmailConfirmation"),
  PageNotFound = lazyComponent("PageNotFound");


export default function MainRoutes() {
  const location = useLocation();
  let { user } = useCurrentUser();
  // const appUrls = GetAllAppUrls();// [string]
  // const notAValidUrl = !appUrls.includes(location.pathname);

  
  if (!location.state) location.state = null;
//   return (
//     <Routes>
//         <Route path="/" element={<FwLayout />} >
//           <Route index element={<SignIn />} />
//           <Route path="first" element={<div>Going good</div>} />
//         </Route>
//       </Routes>
//   )

  return (
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
            <Route path="/" element={<FwLayout />}>
              <Route element={<UnAuthRoute isLoggedIn={user.IsAuthenticated} />}>
                <Route
                  path={APP_URLS.registration.base}
                  element={<SignIn />}
                />
                
                <Route
                  path={APP_URLS.registration.signin}
                  element={<SignIn />}
                />


                <Route
                    path={APP_URLS.registration.emailverification}
                    element={<EmailConfirmation />}
                  />


                <Route
                  path={APP_URLS.registration.confirmphonenumber}
                  element={<ConfirmPhoneNumber />}
                />

                <Route
                  path={APP_URLS.registration.createdpassword}
                  element={<CreatePassword />}
                />
            </Route>
              







            <Route 
              element={<GuardRoute 
                user={user} />}
            >
                <Route path={APP_URLS.dashboard} element={<Dashboard />} />
            </Route>

          {/* admin routes */}
            <Route element={<AdminRoute 
              user={user} />} >
            <Route
              path={APP_URLS.admin.base}
              element={<Admin  />}
            />
            <Route
              path={APP_URLS.admin.users}
              element={<Users />}
            />
            <Route
              path={APP_URLS.admin.roles}
              element={<Roles />}
            />
            </Route>
            
            


          
          {/* Pages */}
            <Route element={<GuardRoute user={user} />}>
                <Route
                    path={APP_URLS.reports}
                    element={<Reports  />}
                    />
                <Route
                    path={APP_URLS.states}
                    element={<StatePages />}
                    />
                <Route
                path={APP_URLS.rules}
                element={<StateRules />}
                />
                <Route
                path={APP_URLS.pharmacy}
                element={<Pharmacy />}
                />
                <Route
                path={APP_URLS.directory}
                element={<Directory /> }
                />
                <Route
                path={APP_URLS.settings}
                element={<UserSettings />}
                />
                <Route
                path={APP_URLS.orders}
                element={<Orders />}
                />
            
                <Route  
                path={APP_URLS.pageNotfound} 
                element={<PageNotFound />} />

                <Route 
                    path={APP_URLS.notAuthorized}
                    element={<UnAuthorizePage />}
                 />
            </Route>
            
            <Route
              element={<WildRoute 
                user={user} />}
            >

            </Route>

            </Route>
        </Routes>
      </Suspense>
  );
}




const UnAuthRoute = ({
  isLoggedIn
}: any) => {
    return isLoggedIn ? <Navigate to={{ pathname: APP_URLS.dashboard }} replace  /> :  <Outlet />;
}


const GuardRoute = ({ user }: any) => {
    const location = useLocation();
    return user.IsAuthenticated ? <Outlet /> : <Navigate to={{ pathname: APP_URLS.registration.signin }} state={{to: location.pathname }} />;
}


const AdminRoute = ({ user }: {user: IUser}) => {
  
  const location = useLocation();
    return user.IsAuthenticated ? user.canAccessAdminPage ? <Outlet /> : <Navigate to={{ pathname: APP_URLS.notAuthorized }} state={{ redirectUrl: location.pathname}} /> : <Navigate to={{ pathname: APP_URLS.registration.signin }} replace />;
};

const WildRoute = ({user}: any) => {

  if(!user.IsAuthenticated) return (<Navigate to={{ pathname: APP_URLS.registration.signin  }} />);
  return <Navigate to={{ pathname: APP_URLS.pageNotfound }} />;
};


const UnAuthorizePage = () => {
    return (
        <div>
            <h1>You don't have the required permissions.</h1>
        </div>
    )
}

// interface CanViewPageProps {
//   component: React.ComponentType<any>;
//   hasPermission: boolean;
//   // Add any additional props here
// }

// const CanViewPage: React.FC<CanViewPageProps> = ({
//   component: Component,
//   hasPermission,
//   ...props
// }) => {
//   if (!hasPermission) {
//     return <Navigate to={{pathname: APP_URLS.pageNotfound}} />;
//   }

//   return <Component {...props} />;
// };



