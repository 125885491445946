import React from "react";
import { useSearchFunc } from "./useSearchFunc";
import "./Search.css";

export const Search = React.memo(({ isEmpty, onSearchRx }) => {
  let outStyle = isEmpty
    ? { maxWidth: "800px", width: "100%" }
    : { display: "flex", flexDirection: "row-reverse" };
  let conditionalStyles = {};
  if (isEmpty) {
    conditionalStyles = { width: "100%" };
  } else {
    conditionalStyles = {};
  }

  const { rxValues, handleInputChange, handleSubmit } = useSearchFunc(
    onSearchRx
  );

  return (
    <div style={{ ...outStyle }}>
      {/* {!!inputMsg && <p className="alert alert-danger">{inputMsg}</p>} */}
      <form
        onSubmit={handleSubmit}
        className="ui action input search-form"
        style={{ ...conditionalStyles }}
      >
        <input
          type="text"
          placeholder="Search..."
          value={rxValues}
          onChange={handleInputChange}
        />
        <button type="submit" className="ui icon button">
          <i aria-hidden="true" className="search icon"></i>
        </button>
      </form>
    </div>
  );
});
