import React, { useEffect } from "react";
import { translateDataSourceResultGroups } from "@progress/kendo-data-query";
import {
  arrayToObject,
  getRequestHeaders,
  hasErrorMessage,
  getQueryParams,
  initReport,
  initShortDate,
  getSpecificDate,
  getStateName,
  getAxios,
  deepCopy,
  endProgress
} from "../../utils";
import { initialGridState } from "../../defs";

const gridCache = {}; // for caching all the reports

export const useDisplayGridAxios = (
  tableName,
  url,
  urlExt,
  gridEvent,
  props
) => {
  const isCurrent = React.useRef(true);
  const [state, setState] = React.useState({
    newState: deepCopy(initialGridState),
    loading: true
  });

  const updateState = (nState, bool = false) => {
    nState.isErrorResponse = bool;
    setState({ newState: nState, loading: false });
  };

  useEffect(() => {
    return () => {
      // called when component is going to unmount
      isCurrent.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.refresh.reset && gridCache[tableName]) gridCache[tableName] = {};
  }, [props.refresh]);

  useEffect(() => {
    //setState(state => ({ newState: gridState, loading: true }));
    const params = getQueryParams(gridEvent, props.allReports, tableName);
    // urlExt is normally StateProvinceId
    const cacheKey = urlExt ? `${url}/${urlExt}?${params}` : `${url}?${params}`; //for in-file caching

    async function getData() {
      const res = await fetchData(
        url,
        urlExt,
        params,
        state.newState,
        tableName,
        props
      );

      if (isCurrent.current) {
        const newState = Object.assign({}, state.newState, res);
        if (hasErrorMessage(res)) {
          updateState(newState, true);
        } else {
          if (Array.isArray(newState.data)) {
            if (newState.data.length > 0) {
              const str = "StateProvinceId";
              if (
                str in newState.data[0] ||
                str.toLowerCase() in newState.data[0]
              ) {
                newState.data.forEach(item => {
                  item["StateName"] = getStateName(item[str]);
                });
                updateState(newState);
              } else {
                updateState(newState);
              }
            } else {
              updateState(newState);
            }
          } else {
            updateState(newState);
          }
          if (gridCache[tableName]) gridCache[tableName][cacheKey] = newState;
          if (!gridCache[tableName])
            gridCache[tableName] = { [cacheKey]: newState };
        }
      }
    }

    const retCache = gridCache[tableName]
      ? gridCache[tableName][cacheKey]
      : null;

    if (isCurrent.current && retCache) {
      updateState(retCache);
    } else {
      getData();
    }
  }, [gridEvent, props.refresh, props.allReports, urlExt]);

  return state;
};

const fetchData = async (
  baseUrl,
  urlExt,
  params,
  dataState,
  tableName,
  props
) => {
  const hasGroups = dataState.group && dataState.group.length;
  let url = urlExt ? `${baseUrl}/${urlExt}` : `${baseUrl}`;

  let headers;
  const isDocument = isSearchedUrl(baseUrl, "api/Document");
  const isProfileUpload = isSearchedUrl(baseUrl, "api/auth/profile");
  if (isDocument || isProfileUpload) {
    headers = getRequestHeaders(true);
  } else {
    headers = getRequestHeaders();
  }

  let urlWithParams = `${url}?${params}`;
  const isUsers = isSearchedUrl(url, "api/admin/users");
  if (
    isSearchedUrl(url, "api/admin/roles") ||
    isUsers
  ) {
    urlWithParams = `/${url}`;
  }
  const axiosInst = getAxios();

  let response = await axiosInst.get(urlWithParams, {
    headers: headers
  });

  endProgress();
  const res = response.data;
  let output = [];
  let resItems = res["Items"];
  
  if(isUsers) {
    resItems = getUserArrItems(resItems);
  }


  if (hasGroups) {
    const groupedObject = arrayToObject(resItems, dataState.group[0].field);
    const arrayOfKeys = Object.keys(groupedObject);
    arrayOfKeys.forEach(item => {
      output.push(groupedObject[item]);
    });
  }

  let reports = {
    data: hasGroups ? output : resItems,
    total: res["TotalCount"]
  };

  let x = JSON.parse(JSON.stringify(reports));
  // console.log('result', JSON.parse(JSON.stringify(reports)));
  if (x.data && x.data.length) {
    switch (tableName) {
      case "dashboard":
        x.data.forEach(report => {
          report.StateSetting = props.reportsettings[report.StateProvinceId];
          return initReport(report);
        });
        // console.log('ex-result', x);
        break;
      case "history":
        x.data.forEach(historyInitReport);
        x.data.forEach(
          item => (item.CreatedDate = initShortDate(item.CreatedDate))
        );
        break;
      case "documents":
        x.data.forEach(item => {
          if (item.CreatedBy == null) {
            item.CreatedBy = "SYSTEM";
          }
          item.CreatedDate = getSpecificDate(item.CreatedDate);
        });
        break;
      case "pharmacylicense":
        x.data.forEach(item => {
          item.ExpDate = initShortDate(item.ExpDate);
        });
        break;
      case "pharmacistlicense":
        x.data.forEach(item => {
          item.ExpDate = initShortDate(item.ExpDate);
          item.EffectiveDate = initShortDate(item.EffectiveDate);
        });
        break;
    }
  }

  const data = hasGroups ? translateDataSourceResultGroups(x.data) : x.data;
  const total = x.total;
  return { data, total };
};

const historyInitReport = report => {
  report.CreatedDate = new Date(report.CreatedDate);
  report.IsLoaded = report.StatusId !== 4 ? "Loaded" : "Failed"; // 4 is the the status for failed on the server
  report.SubmissionType =
    report.RecordCount === 0 ? "Zero Report" : "Prescriptions";
  if (report.$id) {
    switch (report.ReportingMethod) {
      case "NotReporting":
        report.ReportingMethod = "Not Reporting";
        break;
      case "AtlanticAssociates":
        report.ReportingMethod = "Atlantic Associates";
        break;
      case "StateSftpAutoPost":
        report.ReportingMethod = "State sftp Auto Post";
        break;
    }
  }

  return report;
};

function getUserArrItems(resItems) {
  return ((arr) => arr.map(({
    Email,
    EmailConfirmed,
    PhoneNumber,
    PhoneNumberConfirmed,
    CanReceiveReportText,
    CanReceiveReportEmails,
    FirstName,
    Id,
    IsDeleted,
    LastName,
    RoleName,
    Roles,
    CreatedBy,
    UserName }) => {
      return {
        Email,
        EmailConfirmed,
        PhoneNumber,
        PhoneNumberConfirmed,
        CanReceiveReportText,
        CanReceiveReportEmails,
        FirstName,
        Id,
        IsDeleted,
        LastName,
        RoleName,
        Roles,
        CreatedBy,
        UserName
      };
  }))(resItems);
}

function isSearchedUrl(url, subStr) {
  return url.toLowerCase().endsWith(subStr.toLowerCase());
}
