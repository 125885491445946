import React, { useState, useEffect } from "react";

import { AwPageContainer } from "../AwPageContainer";
import { Search } from "./search/Search";
import { ListOfReportSummaries } from "./ListOfReportSummaries";
import { RxSearchService } from "./RxSearchService";
import { useLocation, useNavigate } from "react-router-dom";

const ReportSearchContext = React.createContext({});

export default function Reports() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    SearchResult:
      location.state && location.state.response
        ? location.state.response
        : [],
    isEmpty:
      location.state && location.state.response ? false : true
  });
  const [rxArray, setRxArray] = useState([]);

  useEffect(() => {
    navigate("", {
      state: { response: null }
    });
    // history.push({
    //   state: { response: null }
    // });
  }, []);

  const handleRxSearchClicked = (array: any) => {
    setRxArray(array);
    setValue(state => ({ ...state, isEmpty: false }));
  };
  return (
    <AwPageContainer>
      <ReportSearchContext.Provider value={{ value, setValue }}>
        <div
          className="report"
          style={{
            display: value.isEmpty ? "flex" : "",
            height: "100%",
            justifyContent: value.isEmpty ? "center" : "",
            alignItems: value.isEmpty ? "center" : ""
          }}
        >
          <Search {...{isEmpty: value.isEmpty, onSearchRx: handleRxSearchClicked}}  />

          {!value.isEmpty && (
            <RxSearchService
              rxArray={rxArray}
              historyState={value.SearchResult ? value.SearchResult : null}
            >
              {(rxes: any[]) => {
                return <ListOfReportSummaries rxes={rxes} />;
              }}
            </RxSearchService>
          )}
        </div>
      </ReportSearchContext.Provider>
    </AwPageContainer>
  );
};

