import {docsActions, keywordsActions} from './actions';
import {IAction, IDocPageState, IKeywordState} from "../IDocInterfaces";
import { grouping } from '../DoCUtils';
import { getStateName, deepCopy } from '../../../../../utils';

export const docReducer = (state: IDocPageState, action: IAction<any>) => {
    switch (action.type) {
        case docsActions.set_doc_fetch:
            // const x = state.docs.data;
            const b = Object.assign({}, state.docs, {loading: true});
            return {...state, docs: b};
        case docsActions.set_docs:
            // const x = state.docs.data;
            const payload = deepCopy(action.payload);
            const data = payload;
            const grid = getGridDisplay(payload);
            return {...state, docs: {...state.docs, data, grid, loading: false}}
        case docsActions.set_cache:
            return {...state, docs: {...state.docs, cache: Object.assign(state.docs.cache, action.payload)}}
        case docsActions.remove_cache:
            return {...state, docs: {...state.docs, cache: {}}}

            // --------------keywords------------------
        case keywordsActions.set_search:
            const search: IKeywordState = {...state.keywords, searchKey: action.payload};
            return {...state, keywords: search};
        case keywordsActions.set_keyword:
            const keyword: IKeywordState = {...state.keywords, currentKeyword: action.payload};
            return {...state, keywords: keyword};
        case keywordsActions.set_keywords:
            const kw = Object.assign({}, state.keywords, {data: action.payload, grid: action.payload, loading: false})
            return {...state, keywords: kw};
        case keywordsActions.set_kw_fetch:
            const kb = Object.assign({}, state.keywords, {loading: true});
            return {...state, keywords: kb};
        case keywordsActions.set_cache:
            const p = {...state, keywords: {...state.keywords, cache: Object.assign(state.keywords.cache, action.payload)}};
            return p;
        case keywordsActions.remove_cache:
            const r = {...state, keywords: {...state.keywords, cache: {}}};
            return r;
        default:
            return state;
    }
}



const getGridDisplay = (res: any[]): any[] => {
    const id = "StateProvinceId";
    let data = grouping(res, id, "ProductStrength");
    data = data.map((item) => {
      item["Name"] = getStateName(item[id]);
      return item;
    });
    return data;
};
