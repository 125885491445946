import React from "react";
import { Card, Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AwDeleteData } from "../form-molecules";

const AwCard = ({ labelKey, label, ...props }) => {
  return (
    <Card fluid color="red">
      <Card.Content>
        <Card.Header>
          <Label as="a" color="red" ribbon className="aw-no-user-select">
            {labelKey}
          </Label>
          <span className="aw-no-user-select">{label}</span>
          {props.isDoc && (
            <span style={{ position: "absolute", right: "4%" }}>
              <span style={{position: 'relative',
    top: '-2px',
    left: '-10px'}}>

    <AwDeleteData onDelete={props.onRemoveAll} />
              </span>
              <span>Selection</span>
            </span>
          )}
        </Card.Header>
        {props.showPlus && (
          <div style={{ position: "absolute", right: "15px", color: "blue" }}>
            <FontAwesomeIcon
              className="aw-icons"
              onClick={props.addRow}
              style={{ fontSize: "1.5rem", color: "#db2828" }}
              icon={"plus"}
            />
          </div>
        )}
      </Card.Content>
      <Card.Content>{props.children}</Card.Content>
    </Card>
  );
};

export default AwCard;
