import React from "react";
import { useAxios } from "../../../components";
import PharmacySettings from "./PharmacySettings";
import PharmacyStateSettings from "./PharmacyStateSettings";
import { PharmacySelect } from "./PharmacySelect";
export const Pharmacy = () => {
  const res = useAxios("api/Pharmacies", []);
  const getPharmacyList = array => {
    return array.filter(obj => obj.PharmacyId === 1);
  };
  return (
    <div className="row">
      {!res.loading && (
        <>
          <h1 className="col-12"> Pharmacy Settings</h1>

          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <PharmacySelect array={getPharmacyList(res.data)} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <PharmacySettings pharmacy={res.data[0]} />
          </div>
          <div className="col-lg-6">
            <PharmacyStateSettings
              name={res.data[0].Name}
              pharmacyId={res.data[0].PharmacyId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Pharmacy;
