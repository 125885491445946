import React, { useEffect, useState } from 'react'

import { ReportErrorCheckBoxField } from './ReportErrorCheckBoxField'
import { PhoneNumberFormField } from "./PhoneNumberFormField";
import { AwInputFormField, AwFormSelectField, useCurrentUser } from '../../../../../components';
import { getRoleData } from './userFormHelper';

interface Props {
    onInputChange: any;
    onCheck: (e:any) => void;
    onPhoneChecked: (e:any) => void;
}


export const RegisterUserForm: React.FC<Props> = ({ onCheck, onPhoneChecked, onInputChange}) => {
    const { user: cUser } = useCurrentUser();

    const [phoneRequired, setPhoneRequired] = useState(false);
    
    const { role, fields, options } = getRoleData({RoleName: "Manager"}, cUser.Role, true);

    useEffect(() => {
        onInputChange({ target: { value: role, name: "RoleName" } });
    }, []);


    const handleChecked = (e: any) => {
        const { name, checked } = e.target;
        onCheck({ target: {value: checked, name: name}});
    }

    const handlePhoneChecked = (e: any) => {
      const { name, checked } = e.target;
      onPhoneChecked({ target: {value: checked, name: name}});
      setPhoneRequired(checked);
    }

    return (
        <form noValidate className="register-user-form">
          {fields.map((field, i) => {
            return (
              <AwInputFormField
                key={i}
                {...{required: true, name: field.name, label: field.label, onChange: onInputChange}}
                />
            );
          })}

          <ReportErrorCheckBoxField name="CanReceiveReportEmails" type="email" init={false} onCheck={handleChecked} />

          <ReportErrorCheckBoxField init={false} onCheck={handlePhoneChecked} />

          <PhoneNumberFormField required={phoneRequired} onChange={onInputChange} />

          <AwFormSelectField
              {...{options: options, 
                  optionsLabel: "Role", 
                  name: "RoleName", 
                  dValue: role,
                  value: "key",
                  dropdownText: "key",
                  onSelectChange: onInputChange,
                  required: true
              }}
            />
      </form>
    )
}

