import React from "react";
import { AwModalCheckList } from "../../../../../components";


export const DoCModalCheckList = ({category, onItemSelected}) => {
    return <AwModalCheckList
        label={category.label}
        lists={category.items}
        onChecked={onItemSelected}
    />
}