import React from "react";
import { report_segments_shortName } from "../../../../../defs";
import { SegmentCard } from "../SegmentCard";

export const CdiForm = ({ onInputChange, cdiIds, reportIds }) => {
  const cdi = {
    key: report_segments_shortName.cdi,
    label: "Compound Drug Ingredient Detail"
  };
  const handleInputChange = (e, initSegValueObj, i, segmentName) => {
    e.target["index"] = i;
    e.target["segmentName"] = segmentName;
    onInputChange(e, initSegValueObj);
  };

  return (
    <form style={{ marginTop: "1em" }} className="cd-form">
      {cdiIds.map((id, i) => {
        return (
          <SegmentCard
            key={i}
            segment={cdi}
            rxNumber={id} // instead of using rxNumber, I did use cdiId because I will use that in querying for the cdi
            segmentDetailSearchProp={reportIds}
            onInputChange={(e, initSegValueObj) =>
              handleInputChange(e, initSegValueObj, i, cdi.key)
            }
          />
        );
      })}
    </form>
  );
};

