import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DashboardSubmit({ styles, submit, dataItem }) {
  return (
    <td style={styles} className="submit-td">
      <span
        className={"aw-icons"}
        style={{ cursor: "pointer", color: "rgb(24, 107, 160)" }}
        onClick={() => submit(dataItem.StateReportId)}
        tabIndex="0"
      >
        <FontAwesomeIcon icon="paper-plane" />
      </span>
    </td>
  );
}

DashboardSubmit.propTypes = {
  submit: PropTypes.func.isRequired
};
