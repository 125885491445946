export const awIcons = {
  submit: "paper-plane",
  download: "arrow-alt-circle-down",
  delete: "trash-alt",
  edit: "edit",
  create: "pencil-alt",
  infoCircle: "info-circle",
  info: "info",
  capsules: "capsules",
  checkmark: "check",
  eye: "eye",
  eyeSlash: "eye-slash",
  alert: "bell"
};
