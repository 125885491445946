import React from "react";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import { AwTextArea } from "../form-atoms";

export const AwTextAreaFormField = React.memo(
  ({ name, label, onChange, required }) => {
    return (
      <AwFormFieldWrapper
        name={name}
        label={label}
        required={required ? required : false}
      >
        <AwTextArea name={name} onChange={onChange} />
      </AwFormFieldWrapper>
    );
  }
);
