import React  from "react";
import { useUserAndRoleAddFuncs } from "../useUserAndRoleAddFuncs";
import { RegisterUserForm } from "./forms";
import { UserModal } from "./UserModal";

export const RegisterUser = ({ onHide, onUserAdded }) => {
  const {
    modalRef,
    error,
    handleChange,
    handleSubmitAsync,
    modalClose,
    handleSetError,
    formValues
  } = useUserAndRoleAddFuncs(
    "api/admin/users",
    ["FirstName", "LastName", "UserName", "RoleName"],
    onHide,
    onUserAdded
  );

  return (
    <div className="register-user">
      <UserModal
        mTitle={"Register"} 
        modalRef={modalRef} 
        submitClicked={handleSubmitAsync} 
        closeClicked={modalClose}
        error={error}
        formValues={formValues}
        handleError={handleSetError}
        Rform={RegisterUserForm}
        formParams={{}}
        handleChange={handleChange}
       />
    </div>
  );
};






