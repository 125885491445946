import React, { useRef } from "react";
import { AwPageContainer } from "../AwPageContainer";

import { UserGrid } from "./users";
import { RoleGrid } from "./roles";
import "./Admin.css";

export const Admin = () => {
  return (
    <AwPageContainer>
      <div className="admin-page">
        <div className="card-wrapper user">
          <UserGrid />
        </div>
        <div className="card-wrapper role">
          <RoleGrid />
        </div>
      </div>
    </AwPageContainer>
  );
};

export default Admin;
