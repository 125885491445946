import React, { useState } from "react";
import { SetStringAsHtml, useAuthSettingsLogic, useFormRef } from "../../../../components";
import { checkIfStrIsPasswordRules, validatePasswordFormValues } from "../../../../utils";
import { Button, Typography } from "../../../../third-party-lib";
import { Alert } from "../../../../components/message";
import { AnimatedTextField } from "../../signin/text-field/AnimatedTextField";

interface Props {
    qString: any;
    onSuccess: any;
}
export const PasswordForm: React.FC<Props> = ({ qString, onSuccess }) => {
    const [values, setValues] = useFormRef({});
    const [error, setError] = useState("");
    const [info, setInfo] = useState("");
  
    const { loadingSettings, setPasswordAsync } = useAuthSettingsLogic(
      `api/auth/setpassword`
    );
  
    const handleCreatePasswordAsync = async (values: any) => {
      const successMsgRes = await setPasswordAsync(values, qString);
      if (!successMsgRes.success) {
        setError(successMsgRes.msg);
      } else {
        if (error) setError("");
        if (info) setInfo("");
        onSuccess();
      }
    };
  
  
  
    const handleSubmit = async (e: any) => {
      if (e) e.preventDefault();
      const formMsg = validatePasswordFormValues(values);
      if (formMsg && checkIfStrIsPasswordRules(formMsg)) {
        if (error) setError("");
        setInfo(formMsg);
      } else if (formMsg) {
        if (info) setInfo("");
        setError(formMsg);
      } else if (!formMsg) {
        await handleCreatePasswordAsync(values);
      }
    };
  
    return (
      <>
        <Typography component="h1" variant="h5">
          Create New Password
        </Typography>
        {!!error && (
          <div style={{ marginTop: "5%" }}>
            <Alert message={error} align={"center"} />
          </div>
        )}
        {!!info && (
          <div className="alert alert-danger">
            <SetStringAsHtml value={info} />
          </div>
        )}
        <form noValidate style={{ marginTop: "5%" }} className="create-password-form">
          <AnimatedTextField
            label={"Password"}
            name={"Password"}
            type="password"
            onChange={setValues as any}
            required={true}
          />
          <AnimatedTextField
            label={"Confirm Password"}
            name={"ConfirmPassword"}
            type="password"
            onChange={setValues as any}
            required={true}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submit-btn"
            onClick={handleSubmit}
            disabled={loadingSettings}
          >
            Submit
          </Button>
        </form>
      </>
    );
  };