import React from "react";
import { SessionModal } from "./SessionModal";

import "./AwUserSession.css";


interface Props {
    isLoggedIn: boolean;
}

export const AwUserSession: React.FC<Props> = ({ isLoggedIn }) => {
    return (
      <>
        {isLoggedIn && <SessionModal />}
      </>
    );
};
