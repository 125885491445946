import React from "react";

import { AdminMenuItems } from "./menuList";
import FwMenu from "./fwMenu/FwMenu";
import { useLocation } from "react-router-dom";

const FwMenusWrapper = ({ ...props }) => {
  const location = useLocation();
  
  const styles = props.isHorizontal
    ? { display: "inline-block", marginRight: "20px" }
    : {};

  return (
    <div style={styles} className="menus-wrapper">
      {AdminMenuItems.map((menu, i) => {
        return (
          <FwMenu
            isActive={menu.route === location.pathname}
            key={i}
            menu={menu}
            horizontal={props.isHorizontal}
          />
        );
      })}
    </div>
  );
};
export default FwMenusWrapper;
