import React from "react";
import { AwInputFormField } from "./AwInputFormField";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import { AwZipCodeInput } from "../form-atoms";

export const AwAddressInputFields = ({ dObj, onInputChange }) => {
  return (
    <div className="col-12">
      <div className="row">
        {addressFields.map((field, i) => {
          return (
            <div key={i} className="col-12">
              <AwInputFormField
                required={field.isRequired ? false : true} // if required is set then it means is not required since required is defaulted to true
                init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
                name={field.name}
                label={field.label}
                onChange={onInputChange}
              />
            </div>
          );
        })}

        <div className="col-5">
          <AwInputFormField
            required={true} // if required is set then it means is not required since required is defaulted to true
            init={Object.keys(dObj).length > 0 ? dObj["City"] : ""}
            name={"City"}
            label={"City"}
            onChange={onInputChange}
          />
        </div>

        <div className="col-3">
          {Object.keys(dObj).length ? (
            <AwFormFieldWrapper name={"StateProvinceId"} label={"State"}>
              {dObj["StateProvinceId"]}
            </AwFormFieldWrapper>
          ) : (
            <AwInputFormField
              required={true} // if required is set then it means is not required since required is defaulted to true
              init={Object.keys(dObj).length > 0 ? dObj["StateProvinceId"] : ""}
              name={"StateProvinceId"}
              label={"State"}
              onChange={onInputChange}
            />
          )}
        </div>

        <div className="col-4">
          <AwFormFieldWrapper
            name={"PostalCode"}
            label={"Zip Code"}
            required={true} // if required is set then it means is not required since required is defaulted to true
          >
            <AwZipCodeInput
              name={"PostalCode"}
              onChange={onInputChange}
              init={Object.keys(dObj).length > 0 ? dObj["PostalCode"] : ""}
            />
          </AwFormFieldWrapper>
        </div>
      </div>
    </div>
  );
};

export default AwAddressInputFields;

const addressFields = [
  { name: "Address1", label: "Address 1" },
  { name: "Address2", label: "Address 2", isRequired: true }
];

const g = [
  { name: "City", label: "City" },
  { name: "StateProvinceId", label: "State", displayOnly: true },
  { name: "PostalCode", label: "Zip Code", isZipCode: true }
];
