import { useState, useEffect } from "react";

export const useUpdateArrayBooleans = (init = []) => {
  const setAllValuesAndReturnNewArray = (list, bol = false) => {
    return list.map((v, i) => {
      return bol;
    });
  };

  const checkIfAllIsSelected = (boolArray) => {
    let count = 0;
    for (let i = 0; i < boolArray.length; i++) {
      if (!boolArray[i]) {
        count++;
      }
      if (count > 1) return false;
    }
    return true;
  };

  const [value, setValues] = useState(setAllValuesAndReturnNewArray(init));
  const [checked, setChecked] = useState(false);

  
  useEffect(() => {
    const neV = setAllValuesAndReturnNewArray(init);
    setValues(neV);
  }, [init])



  const singleValue = (index, boolValue) => {
    if (boolValue) {
      const boolRet = checkIfAllIsSelected(value);
      setChecked(boolRet);
    } else {
      setChecked(boolValue);
    }

    setValues((state) => {
      state = [...state];
      state[index] = boolValue;
      return state;
    });
  };

  const resetBoolArray = (data) => {
    setValues(setAllValuesAndReturnNewArray(data));
  };

  const selectAll = (list, bol = false) => {
    setValues(setAllValuesAndReturnNewArray(list, bol));
    setChecked(bol);
  };

  return { value, checked, singleValue, selectAll, resetBoolArray };
};
