import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { awIcons } from "../../../utils";
import "./DeleteAndEditStyles.css";
export const DeleteAndEdit = ({ onDelete, onEdit, ...props }) => {
  return (
    <td className="delete-and-edit">
      <span className="ui buttons">
        <span
          className="ui negative button"
          onClick={() => onDelete(props.dataItem)}
        >
          <FontAwesomeIcon icon={awIcons.delete} />
        </span>
        <div className="or"></div>
        {/* <AwEditGridRow onEdit={onEdit} {...props} /> */}
        <span className="ui button" onClick={() => onEdit(props.dataItem)}>
          <FontAwesomeIcon icon={awIcons.edit} />
        </span>
      </span>
    </td>
  );
};
