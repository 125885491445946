import { createSelector } from "reselect";
import { IAppState } from "../AppState";
import { IAction } from "../actiontypes";

export interface IGridState {
  dashboard: any;
}

// action types
export const set_Cache_data = "Set_Cache_data";
export const get_Cache_data = "Get_Cache_data";
export const clear_Cache_data = "Clear_Cache_data";
// export const set_Cache_data = 'Set_Cache_data';

// actions
export const cachedashboardReports = (data: { key: string; response: any }) => {
  return {
    type: set_Cache_data,
    payload: { key: data.key, response: data.response }
  };
};

// selectors
export const getGridDataSelector = createSelector(
  (state: IAppState) => state.grid,
  (state: IGridState) => {
    return state.dashboard;
  }
);
const initState: IGridState = { dashboard: {} };

export const gridReducer = (state = initState, action: IAction): IGridState => {
  switch (action.type) {
    case set_Cache_data:
      return {
        ...state,
        dashboard: {
          [action.payload.key]: action.payload.response
        }
      };
    default:
      return state;
  }
};
