import React from "react";
import PropTypes from "prop-types";
import AwCheckBox from "../form-atoms/AwCheckBox";

export default function AwCheckBoxFormField({
  label,
  array,
  value,
  onChecked
}) {
  // const [allChecked, setAllChecked] = React.useState(false)
  const handleCheck = (e, index) => {
    // if(allChecked) {
    //     // setAllChecked(false);
    //     console.log(selectAllRef.current.checked);
    //     selectAllRef.current.checked = false;
    // }
    onChecked(e.target.checked, index);
  };
  // const selectAll = (e) => {
  //     setAllChecked(e.target.checked);
  //     JSON.parse(JSON.stringify(array)).map((v, i) => onChecked(e.target.checked, i));
  // }
  // const selectAllRef = React.useRef();
  return (
    <div>
      {/* required={required ? required : false} */}
      <label>
        {label}
        {/* <input ref={selectAllRef} checked={allChecked} type="checkbox" onChange={selectAll} /> Select All */}
      </label>
      {array.map((currentValue, index) => {
        return (
          <AwCheckBox
            key={index}
            index={index}
            label={currentValue[value]}
            handleCheck={e => handleCheck(e, index)}
          />
        );
      })}
    </div>
  );
}

AwCheckBoxFormField.propTpypes = {
  // name: PropTypes.string.isRequired,
  onChecked: PropTypes.func.isRequired
};

AwCheckBoxFormField.defaultProps = {
  // typeOfField: 'text'
};
