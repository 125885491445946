import React from "react";
import { IUseModalProps, IModalProps } from "../modal";


export const useModalFunctions = (): IUseModalProps => {
  const modalRef = React.useRef<IModalProps>({} as IModalProps);

  const showModal = () => {
    modalRef.current.open();
  };

  const hideModal = () => {
    modalRef.current.close();
  };

  const resetLoading = () => {
    modalRef.current.resetLoading();
  };

  const isShowing = () => {
    return modalRef.current.isShowing();
  }
  
  return {
    modalRef,
    isShowing,
    showModal,
    hideModal,
    resetLoading
  };
};
