import {
  IAction,
  get_dashboard_report_Success_type,
  get_dashboard_report_Failed_type,
  update_allreport_boolean,
  cache_dashboard_query_response_type
} from "../actiontypes";
import { IQueryResponse } from "../../interfaces";

export interface IDashboardState {
  DashbaoardReports: IQueryResponse<any>;
  Error: string;
  AllReports: boolean;
  [x: string]: any;
}
const initState: IDashboardState = {
  DashbaoardReports: {} as IQueryResponse<any>,
  Error: "",
  AllReports: true
};

export const dashboardReducer = (
  state = initState,
  action: IAction
): IDashboardState => {
  switch (action.type) {
    case get_dashboard_report_Success_type:
      return {
        ...state,
        DashbaoardReports: action.payload,
        Error: ""
      };
    case get_dashboard_report_Failed_type:
      return {
        ...state,
        DashbaoardReports: initState.DashbaoardReports,
        Error: action.payload
      };
    case update_allreport_boolean:
      return {
        ...state,
        AllReports: action.payload
      };
    case cache_dashboard_query_response_type:
      return {
        ...state
      };
    default:
      return state;
  }
};
