import React, { useState, useEffect } from "react";
import {
  AwCard,
  AwGenericListItem,
  AwDeleteData,
  AwIconCheckbox,
  useAwToastR,
} from "../../../../../components";
import { getSelectionArray, initShortDate, awConfirmDelete, isResponseError, deepCopy, awShowToastr, awShowErrorToastr, getPagedQS } from "../../../../../utils";
import { modifyDocRes } from "../DoCUtils";
import { DoCCardContent } from "./DoCCardContent";
import { DoCCardNav } from "./DoCCardNav";
import { IDocBase, IDoC, IDocGrid, IDoCResProps } from "../IDocInterfaces";
import { docsActions } from "../store";
import { useDoCApis } from "../useDoCApis";


interface IDocCardGrid extends IDocBase {
  Data: any[];
}


interface Props {
    result: IDocGrid;
}
export const DoCGridCard: React.FC<Props> = ({ result }) => {
  const {GetDocsByStateAsync, GetNext2DocsByStateAsync, DeleteDoCAsync, DeleteDoCsAsync} = useDoCApis();

  const [boolValues, setBoolValues] = useState<boolean[]>([]);
  const [allChecked, setAllChecked] = React.useState(false);
  const [navProps, setNavProps] = React.useState({} as IDoCResProps);
  const [contentReset, setResetContent] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const msgSvc = useAwToastR();
  const [doc, setDoc] = useState<IDocCardGrid>({
    Name: result.Name, 
    StateProvinceId: result.StateProvinceId, 
    Data: result.Details
  });
  
  
  const show = (doc.Data.length > 1);
  

  const handleSelectAllClicked = (e: any) => {
    const bol = e.target.checked;
    handleSetAllBoolValues(bol);
  }

  const showToastr = (res: any, msg: string) => {
    let success = !isResponseError(res);
    if(success) {
      msgSvc.showSuccess(msg);
    } else {
      msgSvc.showError(msg);
    }
    return success;
  }

  const handleDocDataChange = (data: any) => {
    setDoc(state => ({...state, Data: data}));
  }

  // const updateGridDisplay = (ids: any[]=[]) => {
  //   let data = deepCopy(doc.Data);
  //   data = data.filter((v: any) => !ids.includes(v.ProductId));
  //   if(!data.length) {
  //     // sdfdfds
  //     const x = docs.data.filter((d) => d.StateProvinceId !== doc.StateProvinceId);
  //     return docDispatch({type: docsActions.set_docs, payload: x});
  //   };
  //   console.log('still not empty');
  //   handleDocDataChange(data);
  // }
  
  const finalizeDeleteAsync = async (stateProvinceId: string) => {
    await GetNext2DocsByStateAsync(1, 2, stateProvinceId);
    handleSetAllBoolValues(false);
    showNavProps(false);
    setResetContent(!contentReset);
  }


  const getArrayOfPdtIds = () => {
    return getSelectionArray(boolValues, doc.Data, "ProductId");
  }


  const handleRemoveAllAsync = async (stateProvinceId: string) => {
    const pdtArray = getArrayOfPdtIds();
    if (pdtArray.length) {
      if (await awConfirmDelete()) {
        const res = await DeleteDoCsAsync(pdtArray, stateProvinceId);
        if(showToastr(res, 'deleted')) {
          finalizeDeleteAsync(stateProvinceId);
        }
      }
    }
  };

  const handleDeleteAsync = async (doc: IDoC) => {
    if (await awConfirmDelete()) {
      const res = await DeleteDoCAsync(doc);
      if ( showToastr(res, 'deleted')) {
        // find it from the list of docs and remove it

        finalizeDeleteAsync(doc.StateProvinceId);

        // set a new docs grid
        // updateGridDisplay([doc.ProductId]);
      } 
    }
  }

  const onSelected = (e: any, index:number) => {
    const bol = e.target.checked;
    setBoolValues((state: any) => {
      state[index] = bol;
      return state;
    });
    if(bol && !allChecked) hasAllBeingSelected();
    if(!bol && allChecked) setAllChecked(false);
  };

  
  const hasAllBeingSelected = () => {
    let falsyVal = 0;
    for (let idx = 0; idx < boolValues.length; idx++) {
      if(falsyVal > 1) {
        falsyVal = 0;
        return setAllChecked(false);
      }
      if(boolValues[idx] ===  false) {
        falsyVal++;
      }
    }
    if(!falsyVal) setAllChecked(true);
  }
  
  const handleSetAllBoolValues = (bol: boolean) => {
    const nv = doc.Data.map(() => bol);
    setBoolValues(nv);
    setAllChecked(bol);
  }


  useEffect(() => {
    setDoc(d => ({...d, Data: result.Details, StateProvinceId: result.StateProvinceId, Name: result.Name}));
    // if(result.Details.length !== boolValues.length) {
      handleSetAllBoolValues(false);
    //}
  }, [result])

  

  const getMoreDocAsync = async (querystring: string) => {
    const qs = `stateProvinceId=${doc.StateProvinceId}&${querystring}`;
    const res = await GetDocsByStateAsync(qs);
    if(awShowErrorToastr(msgSvc, res, '')) {
      setNavProps(res);
      const data = modifyDocRes(res.Items);
      setDoc(state => ({...state, data}));
      handleDocDataChange(data);
    }
    return res;
  }
 

  const showNavProps = (bol: boolean) => {
    setShowNav(bol);
  }
  
  return (
    <div className="col-lg-6 col-md-12" style={{ marginBottom: "1.5em" }}>
      <AwCard
        labelKey={doc.StateProvinceId}
        label={doc.Name}
        isDoc="true"
        onRemoveAll={() => handleRemoveAllAsync(doc.StateProvinceId)}
      >
      <DoCCardContent reset={contentReset} show={show} getMoreDoc={getMoreDocAsync} showNavProps={showNavProps}>
        <DoCCardNav navProps={navProps} getMoreDocAsync={getMoreDocAsync} showNav={showNav}>
            <table className="table" style={{ wordBreak: "break-all" }}>
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>
                      {doc.Data.length > 1 && <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSelectAllClicked({ target: { checked: !allChecked } })
                        }
                      >
                        <AwIconCheckbox checked={allChecked} />
                      </span>}
                    </th>
                    <th>Drug</th>
                    <th style={{ width: "101px" }}>Created On</th>
                    <th className="action-th" style={{ width: "70px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {doc.Data.map((res: any, j: number) => (
                    <tr key={j}>
                      <td>
                        <span style={{ cursor: "pointer" }}>
                          <AwGenericListItem
                            tag={"span"}
                            listItem={res}
                            preSelect={boolValues[j]}
                            handleCheck={(e) => onSelected(e, j)}
                            addLabel={false}
                          />
                        </span>
                      </td>
                      <td>
                        <span className="productname">
                          {res.ProductName}
                          {res.LabelDetails.map((ld: any, k: number) => (
                            <span key={k}>({ld})</span>
                          ))}
                        </span>
                      </td>
                      <td style={{textAlign: 'center'}}>{initShortDate(res.CreatedDate)}</td>
                      <td
                        className="action-td"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AwDeleteData onDelete={() => handleDeleteAsync(res)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </DoCCardNav>
        </DoCCardContent>
      </AwCard>
    </div>
  );
};





// import React, { useState, useContext } from "react";
// import {
//   useInputSelectionFuncs,
//   AwCard,
//   AwGenericListItem,
//   AwDeleteData,
//   AwIconCheckbox,
//   useAwToastR,
// } from "../../../../../components";
// import { getSelectionArray, initShortDate, awConfirmDelete, isResponseError, deepCopy, awShowToastr, awShowErrorToastr } from "../../../../../utils";
// import { modifyDocRes } from "../DoCUtils";
// import { DoCCardContent } from "./DoCCardContent";
// import { DoCCardNav } from "./DoCCardNav";
// import { IDocBase, IDoC, IDocGrid, IDoCResProps } from "../IDocInterfaces";
// import DocContext from "../store/docContext";
// import { docsActions } from "../store";
// import { useDoCApis } from "../useDoCApis";


// interface IDocCardGrid extends IDocBase {
//   Data: any[];
// }


// interface Props {
//     result: IDocGrid;
//     idx: number;
// }
// export const DoCGridCard: React.FC<Props> = ({ result, idx }) => {
//   const {docs, docDispatch, GetDocsByStateAsync, DeleteDoCAsync, DeleteDoCsAsync} = useDoCApis();

//   const [boolValues, setBoolValues] = useState([]);
//   const [allChecked, setAllChecked] = React.useState(false);
//   const [navProps, setNavProps] = React.useState({} as IDoCResProps);
//   const [showNav, setShowNav] = useState(false);

//   const msgSvc = useAwToastR();
//   const [doc, setDoc] = useState<IDocCardGrid>({
//     Name: result.Name, 
//     StateProvinceId: result.StateProvinceId, 
//     Data: result.Details
//   });
  

//   const idsRef = React.useRef([]);
//   const show = (doc.Data.length > 1);
//   const handleCheck = (list: any[], value: any[], label: string) => {
//     handleStateSelection(list, value);
//   };

//   const handleStateSelection = (original: any, current: any) => {
//     const arr = getSelectionArray(current, original, "ProductId");
//     idsRef.current = arr as any;
//   };

//   const {
//     value,
//     checked,
//     onSelected,
//     handleAllChecked,
//     resetBoolArray
//   } = useInputSelectionFuncs(doc.Data, handleCheck, "");
//   const [selectAllChecked, setSelectAllChecked] = React.useState(false);

//   const handleSelectAllClicked = (e: any) => {
//     handleAllChecked(e);
//     setSelectAllChecked(e.target.checked);
//   }

//   const showToastr = (res: any, msg: string) => {
//     let success = !isResponseError(res);
//     if(success) {
//       msgSvc.showSuccess(msg);
//     } else {
//       msgSvc.showError(msg);
//     }
//     return success;
//   }

//   const handleDocDataChange = (data: any) => {
//     setDoc(state => ({...state, Data: data}));
//   }

//   const updateGridDisplay = (ids: any[]=[]) => {
//     let data = deepCopy(doc.Data);
//     data = data.filter((v: any) => !ids.includes(v.ProductId));
//     if(!data.length) {
//       // sdfdfds
//       const x = docs.data.filter((d) => d.StateProvinceId !== doc.StateProvinceId);
//       return docDispatch({type: docsActions.set_docs, payload: x});
//     };
//     console.log('still not empty');
//     handleDocDataChange(data);
//   }
  

//   const handleRemoveAll = async (stateProvinceId: string) => {
//     if (idsRef.current.length) {
//       // onRemoveAll(idsRef.current, stateProvinceId);
//       if (await awConfirmDelete()) {
//         // const res = await api.requestmethods.deleteAll(base_url, idsArr, stateProvinceId);
//         // const res = await api.requestmethods.deleteAllByRequestBody(`${baseUrl}/deleteallasync`, idsRef.current, stateProvinceId);
//         const res = await DeleteDoCsAsync(idsRef.current, stateProvinceId);

//         if(showToastr(res, 'deleted')){
//           updateGridDisplay(idsRef.current);
//         }
//       }
//     }
//   };


//   React.useEffect(() => {
//     console.log('result changed', result);
//     setDoc(d => ({...d, Data: result.Details, StateProvinceId: result.StateProvinceId, Name: result.Name}));
//     if(result.Details.length !== value.length) {
//       resetBoolArray(doc.Data);
//       setSelectAllChecked(false);
//     }
//   }, [result])

//   const handleDelete = async (doc: IDoC, arrIndex: number, detailIndex: number) => {
//     if (await awConfirmDelete()) {
//       const res = await DeleteDoCAsync(doc);
//       if (showToastr(res, 'deleted')) {
//         // find it from the list of docs and remove it

//         // set a new docs grid
//         updateGridDisplay([doc.ProductId]);
//       } 
//     }
//   }

//   const getMoreDocAsync = async (querystring: string) => {
//     const qs = `stateProvinceId=${doc.StateProvinceId}&${querystring}`;
//     const res = await GetDocsByStateAsync(qs);
    
//     if(awShowErrorToastr(msgSvc, res, '')) {
//       setNavProps(res);
//       const data = modifyDocRes(res.Items);
//       setDoc(state => ({...state, data}));
//       handleDocDataChange(data);
//     }
//     return res;
//   }
 

//   const showNavProps = (bol: boolean) => {
//     setShowNav(bol);
//   }
  
//   return (
//     <div className="col-lg-6 col-md-12" style={{ marginBottom: "1.5em" }}>
//       <AwCard
//         labelKey={doc.StateProvinceId}
//         label={doc.Name}
//         isDoc="true"
//         onRemoveAll={() => handleRemoveAll(doc.StateProvinceId)}
//       >
        
//       <DoCCardContent show={show} getMoreDoc={getMoreDocAsync} showNavProps={showNavProps}>
//         <DoCCardNav navProps={navProps} getMoreDocAsync={getMoreDocAsync} showNav={showNav}>
//             <table className="table" style={{ wordBreak: "break-all" }}>
//                 <thead>
//                   <tr>
//                     <th style={{ width: "50px" }}>
//                       {doc.Data.length > 1 && <span
//                         style={{ cursor: "pointer" }}
//                         onClick={() => handleSelectAllClicked({ target: { checked: !selectAllChecked } })
//                         }
//                       >
//                         <AwIconCheckbox checked={checked} />
//                       </span>}
//                     </th>
//                     <th>Drug</th>
//                     <th style={{ width: "101px" }}>Created On</th>
//                     <th className="action-th" style={{ width: "70px" }}>
//                       Action
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {doc.Data.map((res: any, j: number) => (
//                     <tr key={j}>
//                       <td>
//                         <span style={{ cursor: "pointer" }}>
//                           <AwGenericListItem
//                             tag={"span"}
//                             listItem={res}
//                             //preSelect={value[j]}
//                             preSelect={value[j]}
//                             handleCheck={(e) => onSelected(e, j)}
//                             addLabel={false}
//                           />
//                         </span>
//                       </td>
//                       <td>
//                         <span className="productname">
//                           {res.ProductName}
//                           {res.LabelDetails.map((ld: any, k: number) => (
//                             <span key={k}>({ld})</span>
//                           ))}
//                         </span>
//                       </td>
//                       <td style={{textAlign: 'center'}}>{initShortDate(res.CreatedDate)}</td>
//                       <td
//                         className="action-td"
//                         style={{
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                         }}
//                       >
//                         <AwDeleteData onDelete={() => handleDelete(res, idx, j)} />
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//           </DoCCardNav>
//         </DoCCardContent>
//       </AwCard>
//     </div>
//   );
// };


