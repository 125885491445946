import React from 'react'

interface Props {
    status?: {msg: string;
        success: boolean;}
    sub: string;
}
export const ConfirmHeader: React.FC<Props> = ({ sub, status }) => {
    let classes = '';
    const err = !!status && !status.success ? "text-danger" : "";
    const success = !!status && status.success ? "text-success" : "";
    classes += ` ${err} ${success}`;

    return (
        <h4 className={classes}>
            {!!status ? <span>{status.msg}</span> : <span>Confirming {sub}...</span>}
        </h4>
    )
}
