import React, { useState } from 'react';
import './Orders.css';

import { OrderPageTabs } from './tabs';


import { UnreportedOrderSummary } from './UnreportedOrderSummary';
import { WeeklyReportedOrderSummary } from './WeeklyReportedOrderSummary';

interface Props {

}

export const Orders: React.FC<Props> = () => {
    const rxCont = "UnreportedRx";
    const weeklyCont = "WeeklyReport";
    const [content, setContent] = useState(weeklyCont);

    const tabs = [weeklyCont, rxCont].map(str => ({label: str}));

    const handleContentChangeAsync = async (label: string) => {
        const name = label.split(' ').join('');
        setContent(name);
        // const res = await getAsync({label, url: (tabApi as any)[name]});
    }
    
    
    return (
        <div className="orders" style={{width: '100%'}}>
            <OrderPageTabs  tabs={tabs} changeContent={handleContentChangeAsync}>
                {content === rxCont ? <UnreportedOrderSummary /> : <WeeklyReportedOrderSummary />}
            </OrderPageTabs>
        </div>
    );
}