import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { semanticDropDownArraySetUp } from "../../utils";

const AwSelectBox = React.memo(
  ({ array, value, label, label2, onSelectValueChange, dValue }) => {
    const custLabel = !!label2 ? label2 : null;
    const [defValue, setDefValue] = useState(dValue);
    // const [items, setItems] = useState(
    //   semanticDropDownArraySetUp(array, value, label, custLabel)
    // );

    const items = React.useMemo(
      () => semanticDropDownArraySetUp(array, value, label, custLabel),
      [array, value, label, custLabel] // value, label, custLabel i added this dep to prevent seeing console warnings
    );
    const handleClick = (e, { name, value }) => {
      setDefValue(value);
      onSelectValueChange(value);
    };
    useEffect(() => {
      //   console.log("dValue", dValue);
      //   console.log("-----defValue-----", defValue);
      //   console.log("value", value);
      //   console.log("items", array);
      //   // setItems(semanticDropDownArraySetUp(array, value, label, custLabel));
      //   setDefValue(dValue);
      setDefValue(dValue);
    }, [dValue]);
    return (
      <div>
        <Dropdown
          className="form-control"
          placeholder="Select an option..."
          fluid
          search
          selection
          options={items}
          value={defValue} // defaultValue
          onChange={handleClick}
        />
      </div>
    );
  }
);

export default AwSelectBox;
