import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import { AwModalTitle } from "./AwModalTitle";
import { AwModalPItem } from "./AwModalPItem";
import { getKeysWithoutIds } from "../../utils";

export const AwPresentationalModal = ({ dataItems, title, button, ...props }) => {
  const [data, setData] = useState(dataItems);
  const [show, setShow] = useState(false);
  const keysRef = useRef(getKeysWithoutIds(data));
  const modal = {
    open: () => setShow(true),
    close: () => setShow(false)
  };

  // let windowSrollYValue = useRef({ y: 0 }).current;
  const openModal = async (e) => {
    if (e) e.preventDefault();
    
    if(props.queryAsync){
      const x = await props.queryAsync();
      setData(state => ({...state, ...x}));
    };

    modal.open();
  };

  const closeModal = e => {
    // console.log(windowSrollYValue);
    modal.close();
    // window.scrollTo(0, windowSrollYValue.y);
  };

  const checkValue = str => {
    let output = false;
    const newStr = str.toLowerCase();
    if (newStr.indexOf("date") !== -1) {
      output = true;
    }
    return output;
  };


  return (
    <>
      <span className="btn-span">
        {button &&
          React.cloneElement(button, { onClick: openModal, tabIndex: "0" })}
      </span>

      <Modal show={show} backdrop="static" onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <AwModalTitle>{title}</AwModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {keysRef.current.map((str, i) => {
            return (
              <AwModalPItem
                key={i}
                label={str}
                value={data[str]}
                isDate={checkValue(str)}
              />
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};



AwPresentationalModal.propTypes = {
  title: PropTypes.string.isRequired,
  dataItems: PropTypes.object.isRequired
};
