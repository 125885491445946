import React, { useState } from "react";
import UserGrid from "./UserGrid";
import { GridActionCol } from "../AdminUtil";
import { awConfirmDelete, awShowToastr } from "../../../../utils";
import api from "../../../../store/api";
import { useCurrentUser, useAwToastR } from "../../../../components";
import { UserSystemRoles } from "../../../../defs";

import { DeletedUsersGrid } from "./DeletedUsersGrid";

export const Users = () => {
  const { user } = useCurrentUser();
  const msgSvc = useAwToastR();
  const [refreshGrid, setRefreshGrid] = useState(false);

  const handleDelete = async data => {
    const isTrue = await awConfirmDelete();
    if (isTrue) {
      await api.requestmethods.delete(`api/admin/users/${data.Id}`);
      handleGridRefresh();
    }
  };

  const handleGridRefresh = () => {
    setRefreshGrid(!refreshGrid);
  }

  const resendAccountVerificationEmailAsync = async (user) => {
    const res = await api.requestmethods.post(`/api/admin/users/sendemailconfirmationasync`, user);
    awShowToastr(msgSvc, res, res, true);
  }

  const gridActionCol = (user.Role !== UserSystemRoles.manager) ? GridActionCol({ handleDelete, user, pageName: 'users' }) : null;
  return (
    <div className="users" style={{ marginTop: "1.3em" }}>
      <UserGrid refresh={refreshGrid} extraCol={gridActionCol} onResendEmail={resendAccountVerificationEmailAsync} />

      {user.Role === 'SuperUser' && <DeletedUsersGrid shouldRefresh={refreshGrid} refresh={handleGridRefresh} />}
    </div>
  );
};

export default Users;
