import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAwListItemCheckFuncs } from "../custom-hooks";
export const AwGenericListItem = ({
  tag: Tag,
  listItem,
  preSelect,
  handleCheck,
  addLabel = true,
}) => {
 
  const {
    icon,
    canCheck,
    checked,
    handleListSelected,
  } = useAwListItemCheckFuncs(listItem, preSelect, handleCheck);

  let styles = addLabel ? {
    cursor: canCheck ? "pointer" : "not-allowed",
    backgroundColor: checked ? "#186ba0" : "",
    color: checked ? "white" : "",
  }  : {};

  return (
    <Tag
      className={addLabel ? "list-group-item" : ''}
      onClick={handleListSelected}
      style={styles}
    >
      <span style={{ marginRight: "10px" }}>
        {checked ? <FontAwesomeIcon icon={icon} /> : <input type="checkbox" style={{cursor: 'pointer'}} />}
      </span>
      {addLabel && (
        <label
          className="aw-no-user-select"
          style={{ textTransform: "capitalize" }}
        >
          {listItem.Name}
        </label>
      )}
    </Tag>
  );
};

AwGenericListItem.propTypes = {
  tag: PropTypes.any.isRequired,
  listItem: PropTypes.any,
  handleCheck: PropTypes.func,
};

AwGenericListItem.defaultProps = {
  tag: "div",
};
