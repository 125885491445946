import React, { useState, useEffect } from 'react';
import { AwCard, useAwToastR } from '../../../../../components';
import {KeywordGroup} from "./KeywordGroup";
import {KeywordState} from "./KeywordState";
import { awConfirmDelete, awShowToastr, awShowErrorToastr } from '../../../../../utils';
import { IKeyword, IMProps } from '../IDocInterfaces';
import { keywordsActions, docsActions } from '../store';
import { useDoCApis } from '../useDoCApis';

interface Props {
    // open: () => void;
    // savedKw: (docs: any[]) => void;
    // kwDeleted: (res: any) => void;
    // kwIds: number[];
}


export const KsCard: React.FC<Props> = () => {
    const [ksModalProps, setKSModalProps] = useState<IMProps>({show: false, addID: false, ksData: [], keyword: {} as IKeyword});
    const {docs, keywords, docDispatch, GetKeywordsAsync, GetNext2DocsByStateAsync, GetKwSAsync, GetNewKwSAsync, DeleteStateFromKwSAsync, SetDoCsDLValueAsync} = useDoCApis();
    const msgSvc = useAwToastR();

      const handleKwDeletedAsync = async (keyword:IKeyword, res: {States: string[], DoCs: any[]}) => {
        res.States.forEach(async (stateProvinceId: string) => {
            // dx = dx.filter((d: any) => d.StateProvinceId !== stateProvinceId);
            await GetNext2DocsByStateAsync(1, 2, stateProvinceId);
        });


        const newKw = keywords.data.filter(x => x.SRKeywordID !== keyword.SRKeywordID);
        docDispatch({type: keywordsActions.set_keywords, payload: newKw});

        
        //  docDispatch({type: docsActions.set_docs, payload: dx});
      }


      
    const stateAddedToKwSAsync = async (data: {res: any, keyword: IKeyword}) => {
      if (awShowToastr(msgSvc, data, "updated")) {
        await getAndSetKwSAsync(data.keyword);

        const n = [...data.res, ...docs.data];
        // console.log('this is the res', data.res);
        // console.log('this is the data', docs.data);
        // console.log('this is the n - data and res', n);
        docDispatch({type: docsActions.set_docs, payload: n});
      }
    }

  

  
  

  // const updateKSModalData = async (keyword) => {
  //   const res = await docApis.getKwSAsync(keyword);
  //   setKSModalProps(state => ({...state, ksData: res}));
  // }

  const open = async (keyword: IKeyword, shouldAddId = false) => {
    const res = await GetKwSAsync(keyword);
    if(awShowErrorToastr(msgSvc, res, '')) {
      setKSModalProps(state => (
          {
            ...state, 
            show: true, 
            ksData: res, 
            keyword: keyword,
            addID: shouldAddId
          }
        ));
    }
  }

  const closeKSModal = () => {
    setKSModalProps(state => ({...state, show: false}));
  }


  const handleKwSDeleteAsync = async (item: any) => {
    const x = {SRKeywordID: item.SRKeywordID, States: [item.StateProvinceID]};
    const res = await DeleteStateFromKwSAsync(x);
    if(awShowToastr(msgSvc, res, "deleted")) {
      // remove kwS 
      const ks: any[] = ksModalProps.ksData.filter((k: any) => k.StateProvinceID !== item.StateProvinceID);
      setKSModalProps(state => ({...state, ksData: ks}));
    }
  }

  const handlePatListItemClickedAsync = async (item: any, shouldAddId:boolean) => {
    const res = await SetDoCsDLValueAsync(item.SRKeywordID, item.StateProvinceID, shouldAddId);
    if(res) {
      const keyword: IKeyword = {SRKeyword: item.SRKeyword, SRKeywordID: item.SRKeywordID};
      await getAndSetKwSAsync(keyword);
    }
  }

  const getAndSetKwSAsync = async (keyword: IKeyword) => {
    const ks = await GetNewKwSAsync(keyword);
    setKSModalProps(state => ({...state, ksData: ks}));
  }



  
    useEffect(() => {
      GetKeywordsAsync();
    }, [])

    return (
        <>
        {!!keywords.grid.length && (
            <section className="row" style={{marginBottom: '1rem'}}>
                <div className="col-12">
                    <AwCard labelKey={"KEYWORDS"} label="">
                        <form noValidate style={{maxHeight: '300px', overflowY: 'auto'}}>
                            {keywords.grid.map((keyword, idx) => {
                            return (<KeywordGroupWrapper 
                                      keyword={keyword} 
                                      key={idx}
                                      open={open}
                                      kwDeleted={handleKwDeletedAsync}
                                      // savedKw={savedKw}
                                    />)
                            })}
                        </form>
                    </AwCard>
                </div>
                    
                <KeywordState
                  ksProps={ksModalProps}
                  close={closeKSModal}
                  onDelete={handleKwSDeleteAsync}
                  onPatListItemClicked={handlePatListItemClickedAsync}
                  stateAddedToKwS={stateAddedToKwSAsync}/>
          </section>
        )}
        </>
    )
}



interface IKwWProps {
  keyword: IKeyword;
  open: (keyword: IKeyword) => void;
  kwDeleted: (keyword: IKeyword, res: any) => void;
  // savedKw: (res: any[]) => void;
}


const KeywordGroupWrapper: React.FC<IKwWProps> = ({ keyword, open, kwDeleted }) => {
  const [value, setValue] = useState(keyword);
  const msgSvc = useAwToastR();
  const {SaveKeywordAsync, DeleteKeywordAsync} = useDoCApis();

  const saveKeyword = async (body: IKeyword) => {
    setValue({...body, Loading: true});
    const res = await SaveKeywordAsync(body);
    const success = awShowToastr(msgSvc, res, "saved", true);
    if(success) return setValue(state => ({...state, Loading: false}));
    if(!success) return setValue({...keyword, Loading: false});
}

const deleteKeywordAsync = async (keyword: IKeyword) => {
  if(await awConfirmDelete("This keyword is used for automatic DOC update!")) {
    const res = await DeleteKeywordAsync(keyword);
    if(awShowToastr(msgSvc, res, "deleted")) {
      kwDeleted(keyword, res.response.data);
    }
  }
}

return (
    <KeywordGroup 
        keyword={value} 
        deleteKw={deleteKeywordAsync} 
        openKSModal={open}
        onSave={saveKeyword} />
    )
}

