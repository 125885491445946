import React from "react";
import { PasswordSettingsPagePresentation } from "./PasswordSettingsPresentation";
import { Button, Typography } from "../../../third-party-lib";
import { FancyTextField } from "../signin/text-field";
import "./CreatePassword.scss";

import {
  useFormRef,
  SetStringAsHtml,
  useAuthSettingsLogic
} from "../../../components";


import {
  validatePasswordFormValues,
  navigateAway,
  checkIfStrIsPasswordRules
} from "../../../utils";


import { Alert } from "../../../components/message";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PasswordForm } from "./pss-form";
import { APP_URLS } from "../../../infrastructure";


export const CreatePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleResetSuccess = () => {
    navigateAway(navigate, {
      pathname: APP_URLS.registration.signin,
      search: "?newpasswordset=true"
    });
  };


  if(!location.search || (location.search && location.search.length < 15)){
    return <Navigate to={APP_URLS.registration.signin} replace />
  }
  
  return (
    <div className="create-password">
    {/* <PasswordSettingsPagePresentation> */}
      {/* <CreatePasswordForm
        qString={location.search}
        onSuccess={handleResetSuccess}
      /> */}
        <div className="pss-wrapper">
          <PasswordForm
            qString={location.search}
            onSuccess={handleResetSuccess}
          />
        </div>
    {/* </PasswordSettingsPagePresentation> */}
    </div>
  );
};


const CreatePasswordForm = ({ qString, onSuccess }) => {
  const [values, setValues] = useFormRef({});
  const [error, setError] = React.useState("");
  const [info, setInfo] = React.useState("");

  const { loadingSettings, setPasswordAsync } = useAuthSettingsLogic(
    `api/auth/setpassword`
  );

  const handleCreatePasswordAsync = async values => {
    const successMsgRes = await setPasswordAsync(values, qString);
    if (!successMsgRes.success) {
      setError(successMsgRes.msg);
    } else {
      if (error) setError("");
      if (info) setInfo("");
      onSuccess();
    }
  };



  const handleSubmit = async e => {
    if (e) e.preventDefault();
    const formMsg = validatePasswordFormValues(values);
    if (formMsg && checkIfStrIsPasswordRules(formMsg)) {
      if (error) setError("");
      setInfo(formMsg);
    } else if (formMsg) {
      if (info) setInfo("");
      setError(formMsg);
    } else if (!formMsg) {
      await handleCreatePasswordAsync(values);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Create Password
      </Typography>
      {!!error && (
        <div style={{ marginTop: "5%" }}>
          <Alert message={error} align={"center"} />
        </div>
      )}
      {!!info && (
        <div className="alert alert-danger">
          <SetStringAsHtml value={info} />
        </div>
      )}
      <form noValidate style={{ marginTop: "5%" }} className="create-password-form">
        <FancyTextField
          label={"Password"}
          name={"Password"}
          type="password"
          onChange={setValues}
          isWhiteBg={true}
          required={true}
        />
        <FancyTextField
          label={"Confirm Password"}
          name={"ConfirmPassword"}
          type="password"
          onChange={setValues}
          isWhiteBg={true}
          required={true}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="submit-btn"
          onClick={handleSubmit}
          disabled={loadingSettings}
        >
          Submit
        </Button>
      </form>
    </>
  );
};
