import React, { useState } from 'react';
import { AwInput } from "../../../../../components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeywordPModal } from "./KeywordPModal";

export const KeywordGroup = ({keyword, deleteKw, openKSModal, onSave}) => {
    const [value, setValue] = useState('');

    const handleInputChange = (e) => {
        const v = e.target.value;
        if(v) setValue(v);
    }

    const saveKeyword = async (keyword) => {
        if(value) {
            const body = Object.assign({}, keyword, {SRKeyword: value})
            onSave(body);
            setValue('');
        }
    }
    
    
    return (
        <div className="form-group" style={{display: 'flex',     marginRight: "2rem"}}>
            <AwInput 
                init={keyword.SRKeyword} 
                name={keyword.SRKeyword} 
                onChange={handleInputChange} />
            <div style={{display: 'flex', marginLeft: '1rem'}}>
                <KeywordPModal keyword={keyword} />
                <KwButton color="warning" label="states" handleClick={() => openKSModal(keyword)} loading={keyword.Loading}/>
                <KwButton color="warning" label="ID" handleClick={() => openKSModal(keyword, true)} loading={keyword.Loading}/>
                <KwButton color="primary" label="save" handleClick={() => saveKeyword(keyword)} loading={keyword.Loading}/>
                <KwButton color="danger" label="" handleClick={() => deleteKw(keyword)} loading={keyword.Loading}><FontAwesomeIcon icon="trash-alt"/></KwButton>
            </div>
        </div>
    )
}


const KwButton = ({color, label, handleClick, loading, ...props}) => {
    return (
        <button 
            style={{margin: '0 0.5rem'}} 
            type="button" 
            className={`kw btn btn-${color}`} 
            onClick={handleClick} 
            disabled={!!loading}>
            {label} {props.children}
        </button>
    )
}