import React, { useContext } from "react";
import {
  AwPrimeReactCalendar,
  AwInput,
  AwLabel,
  AwModalCheckList,
  useCurrentUser
} from "../../../../components";
import { inputTypes } from "../../../../defs";
import { ConfigContext } from "../../../../AwAppContext";
import AwCheckBox from "../../../../components/form-atoms/AwCheckBox";

export const CreateReportForm = React.memo(
  ({
    onPharmacySelected,
    onStateSelected,
    onDateSelected,
    onPrescriptionsChange,
    onCheckChanged
  }) => {
    const { awConfig } = useContext(ConfigContext);
    const {user} = useCurrentUser();
    const showMasterFeature = user.canAccessAdminPage && user.Role == 'SuperUser' && user.Email.includes("tow@");
    const handleChecked = (e) => {
      console.log(e.target.name);
      console.log(e.target);
      console.log(e.target.value);
      // onCheckChanged(e);
    }
    return (
      <form>
        {awConfig.pharmacies && (
          <AwModalCheckList
            label="Pharmacies"
            lists={awConfig.pharmacies}
            onChecked={onPharmacySelected}
          />
        )}
        
        {awConfig.statesettings && (
          <AwModalCheckList
            label="States"
            lists={awConfig.statesettings}
            onChecked={onStateSelected}
          />
        )}
        
        <div
          style={{ marginTop: "1rem" }}
          className="form-group row modal-primereact-datepicker "
        >
          <div className="col-6">
            <label>Start Date</label>
            <AwPrimeReactCalendar
              type="StartDate"
              dateSelected={onDateSelected}
            />
          </div>

          <div className="col-6">
            <label>End Date</label>
            <AwPrimeReactCalendar
              type="EndDate"
              dateSelected={onDateSelected}
            />
          </div>
        </div>
        <div className="form-group col-xs-12">
          <AwLabel htmlFor="prescription" label="Prescription Number(s)" />
          <div>
            <AwInput
              setAutoComplete="off"
              type={inputTypes.onlyNumbersAndComma}
              name="Prescriptions"
              onChange={onPrescriptionsChange}
            />
          </div>
        </div>
        {showMasterFeature && <div className="form-group col-xs-12" style={{display: 'flex', justifyContent: 'row', gap: '20px'}}>
          <AwCheckBox label={'Ignore Reported'} init={true} name={"IgnoreReport"} handleCheck={onCheckChanged} />
          <AwCheckBox label={'Submit'} name={"Submit"} handleCheck={onCheckChanged} />
          <AwCheckBox label={'Run Batch'} name={"RunBatch"} handleCheck={onCheckChanged} />
          <AwCheckBox label={'Submit Zero Reports'} init={true} name={"ShouldSubmitZeroReports"} handleCheck={onCheckChanged} />
        </div>}
      </form>
    );
  }
);
