import React, { useEffect } from "react";
import AwModalListItem from "./AwModalListItem";

const AwModalUnOrderedList = React.memo(({ lists, label, onChecked }) => {
  const [selectDeselect, setSelectDeselect] = React.useState(false);
  // const [selectAll, setSelectAll] = React.useState(false);

  useEffect(() => {
    if (selectDeselect) {
      handleSelectAll(selectDeselect);
    }
  }, [selectDeselect]);

  const inputRef = React.useRef();

  const handleCheck = (e, index) => {
    const value = e.target.checked;
    onChecked(value, index);
    if (!value && selectDeselect) {
      inputRef.current.checked = value;
    }
  };

  const handleSelectAll = bool => {
    lists.forEach((list, i) => {
      handleCheck({ target: { checked: bool } }, i);
    });
  };

  // const handleSelectAllClick = (e) => {
  //     const value = e.target.checked;
  //     handleSelectAll(value);
  //     // setSelectAll(value);
  //     setSelectDeselect(value);
  // }

  return (
    <div>
      <label className="aw-no-user-select" style={{ fontWeight: "700" }}>
        {label}
        {/* <input type="checkbox" ref={inputRef} onChange={handleSelectAllClick} /> Select All */}
      </label>
      <div style={{ maxHeight: "200px", overflow: "auto" }}>
        <ul>
          {lists.map((list, i) => {
            return (
              <AwModalListItem
                key={i}
                listItem={list}
                preSelect={selectDeselect}
                handleCheck={e => handleCheck(e, i)}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
});

export default AwModalUnOrderedList;
