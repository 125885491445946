import React, { useEffect, useState, useRef } from 'react';
import api from '../../../store/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { awIcons, endDateExclusive, isResponseError, reportDateUtc } from '../../../utils';
import { AwPresentationalModal, AwGenericPModal, AwDateField, AwButton, useForm, AwMinMaxDateField } from '../../../components';
import { AppLoadingSpinner } from '../../../PreApp';

interface IOrder {
    ShippingDate: any;
    RxNumber: number;
    StateProvinceId: string;
    PkFormulaId: number;
    ProductId: number;
    IsManufactured: boolean;
    ProductNDC: any[];
}



const cache = {};
const getInitDate = (days: number) => new Date(new Date().setDate(new Date().getDate()-days))
export const UnreportedOrderSummary = () => {
    // const baseUrl = '/api/reported';
    const maxDate = getInitDate(1);
    const baseUrl = '/api/reported';
    // getInitDate for enddate has 2 as the parameter
    // because endDateExclusive will add a day to it
    // bringing the enddate to the day before the selected date
    // eg Yesterday if the date is today
    const [dates, setDates] = useForm({StartDate: getInitDate(7), EndDate:  getInitDate(1)})
    const [isLoading, setLoading] = useState(true);
    const [rxes, setRxes] = useState<IOrder[]>([]);
    const comp = useRef({isActive: true}).current;
    
    const handleLoading = () => {
        setLoading(x => !x);
    }
    const getOrderItemsAsync = async () => {
        const stored = (cache as any)[getUrl()];
        if(stored) {
            handleLoading();
            return setRxes(stored);
        }

        searchReportAsync();
    }

    const handleDetailsClickedAsync = async (data: IOrder) => {
        return await api.requestmethods.post(baseUrl, data);
    }

    const searchReportAsync = async () => {
        if(!isLoading) handleLoading();
        var response = await api.requestmethods.get(getUrl());
        const isError = isResponseError(response);
        
        handleLoading();
        if(!isError) {
            (cache as any)[baseUrl] = response;
            if(comp.isActive) setRxes(response);
        }
    }

    const handleDates = (e: any) => {
        setDates(e);
    }


    const getUrl = () => {
        // const d = endDateExclusive(dates.EndDate)
        // const endDate = reportDateUtc(d.toDate())
        return `${baseUrl}?startDate=${reportDateUtc(dates.StartDate)}&endDate=${reportDateUtc(dates.EndDate)}`
    };

    useEffect(() => {
        comp.isActive = true;
        getOrderItemsAsync();
        return () => {comp.isActive = false}
    }, [])


    return (
        <div className="unreported-order-summary" style={{position: 'relative'}}>
            <AppLoadingSpinner loaded={!isLoading} bgClr='rgba(255, 255, 255, 0.2)'>
            <div>
                {/* <small>This is a weeks search result!</small> */}
                <div>
                <small>Search</small>
                </div>
                <div className='dates'>
                    <AwMinMaxDateField onDateSelected={handleDates} label={"Start"} type={"StartDate"} isVertical={false} init={dates.StartDate} maxDate={maxDate} />
                    <AwMinMaxDateField onDateSelected={handleDates} label={"End"} type={"EndDate"} isVertical={false} init={dates.EndDate} maxDate={maxDate} />
                    <div>
                        <AwButton ref={null} {...{label: "Search", btnClicked: searchReportAsync}} />
                    </div>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Rx #</th>
                        <th>State</th>
                        <th>Shipping Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                   {rxes.map((rx, idx) => {
                       return (
                           <tr key={idx}>
                               <td>{rx.RxNumber}</td>
                               <td>{rx.StateProvinceId}</td>
                               <td>{rx.ShippingDate}</td>
                               <td>
                                   <AwPresentationalModal 
                                        dataItems={rx}
                                        title="Rx Details"
                                        queryAsync={() => handleDetailsClickedAsync(rx)}
                                        button={<span className="ui circular mini button"
                                        >
                                        <FontAwesomeIcon icon={awIcons.info as any} />
                                    </span>}
                                   />
                                </td>
                           </tr>
                       )
                   })}
                </tbody>
            </table>
            </AppLoadingSpinner>
        </div>
    )
}
