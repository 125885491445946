import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { awIcons } from "../../../utils";

export const GridPasswordCol = props => {
  const defaultP = {
    showPassword: false,
    icon: awIcons.eyeSlash
  };
  const [show, setShow] = useState(defaultP);
  const handleClick = () => {
    if (show.showPassword) {
      setShow(defaultP);
    } else {
      setShow({
        showPassword: true,
        icon: awIcons.eye
      });
    }
  };
  return (
    <td>
      <div style={{ position: "relative" }}>
        <div
          onClick={handleClick}
          style={{
            position: "absolute",
            right: "0",
            cursor: "pointer"
          }}
        >
          <FontAwesomeIcon icon={show.icon} />
        </div>
        {show.showPassword ? (
          <span>{props.dataItem.Password}</span>
        ) : (
          <span>*******</span>
        )}
      </div>
    </td>
  );
};

export default GridPasswordCol;

