import React from "react";
import logo from "../../../assets/images/mq-logo.png";

export const AwTitleBar = ({ title, ...props }) => {
  const hasChn = props.children && props.children.length > 1;
  return (
    <div className={title ? "title-bar" : "aw-title-bar"}>
      <div className="logo-area">
        <img alt="MedQuest Pharmacy" src={logo} />
        {hasChn && props.children[0]}
      </div>
      {hasChn && props.children[1]}
    </div>
  );
};
