import React from "react";
import { maskPhoneNumberDisplay } from "../../../utils";

export const PhoneCol = props => {
  return (
    <td>
      <div>{maskPhoneNumberDisplay(props.dataItem[props.field])}</div>
    </td>
  );
};

export default PhoneCol;
