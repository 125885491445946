import React from "react";
import PropTypes from "prop-types";
import AwInput from "../form-atoms/AwInput";
import AwTextArea from "../form-atoms/AwTextArea";
import AwCheckBox from "../form-atoms/AwCheckBox";
import { inputTypes } from "../../defs";
import { getFieldLabelFromFieldName } from "../../utils";
import { AwLabel } from "../form-atoms";
import { AwTextAreaFormField } from "./AwTextAreaFormField";
import { AwInputFormField } from "./AwInputFormField";
import AwStringArrayCheckBoxFormField from "./AwStringArrayCheckBoxFormField";

export default function AwFormField({
  name,
  onChange,
  typeOfField,
  label,
  dValue
}) {
  const handleCheck = e => {
    const newEvent = {
      target: { value: e.target.checked, name: e.target.name }
    };
    onChange(newEvent);
  };
  return (
    <div className="form-group">
      {/* {typeOfField !== inputTypes.checkbox && (
        <AwLabel htmlFor={name} label={!!label ? label : name} />
      )} */}
      {typeOfField === inputTypes.text && (
        <AwInputFormField init={dValue} name={name} onChange={onChange} />
      )}
      {typeOfField === inputTypes.textarea && (
        <AwTextAreaFormField init={dValue} name={name} onChange={onChange} />
      )}
      {typeOfField === inputTypes.checkbox && (
        <AwStringArrayCheckBoxFormField
          strArr={[name]}
          name={name}
          label={getFieldLabelFromFieldName(name)}
          handleCheck={handleCheck}
        />
      )}
    </div>
  );
}

AwFormField.propTpypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  typeOfField: PropTypes.string,
  dValue: PropTypes.string
};

AwFormField.defaultProps = {
  typeOfField: inputTypes.text,
  dValue: ""
};

{
  /* <AwInput init={dValue} name={name} onChange={onChange} /> */
}
