import React, { useMemo, useState } from "react";
import {
  AwCard,
  useAxios,
  useAxiosFunc,
  AwSelectBox,
  AwFormFieldWrapper
} from "../../../components";
import { arrayToObject, hasErrorMessage } from "../../../utils";
import { PharmacyStateSettingsForm } from "./PharmacyStateSettingsForm";
import api from "../../../store/api";
import { Alert } from "../../../components/message";
const PharmacyStateSettings = ({ name, pharmacyId }) => {
  const preselect = "AK";
  const { res, reLoadData } = useAxiosFunc(
    `api/PharmacyStateSettings?pharmacyId=${pharmacyId}`,
    []
  );

  const [error, setError] = useState(null);
  const [state, setStateProvince] = useState(null);

  const { states, newStates } = useMemo(() => {
    const copy = JSON.parse(JSON.stringify(res.data));
    const modified = arrayToObject(copy, "StateProvinceId");
    const selectedId = !!state ? state.StateProvinceId : preselect;
    setStateProvince(modified[selectedId]);
    return { states: copy, newStates: modified };
  }, [res]); // Added settings as dep to avoid warnings

  const onStateChange = stateId => {
    setStateProvince(newStates[stateId]);
  };

  const handleSubmit = async (statesetting) => {
    const res = await api.requestmethods.put(
      "api/PharmacyStateSettings",
      statesetting
    );
    if (hasErrorMessage(res)) {
      setError(res.message);
    } else {
      if (error) {
        setError("");
      }
      reLoadData();
      alert("Record has been updated successfully.");
    }
  };

  return (
    <AwCard labelKey={name} label="State Settings">
      <div className="aw-state-page-tabview-section row">
        <div className="col-lg-12">
          {error && (
            <Alert
              align="center"
              message="Sorry, update failed. Try again later."
            />
          )}
        </div>
        <div className="col-lg-12">
          <AwFormFieldWrapper name={"State"} label={"State"}>
            <AwSelectBox
              array={states}
              value="StateProvinceId"
              label2="StateName"
              label="StateProvinceId"
              dValue={preselect}
              onSelectValueChange={onStateChange}
            />
          </AwFormFieldWrapper>
        </div>
        <div className="col-lg-12">
          {!!state && (
            <PharmacyStateSettingsForm
              stateSetting={state}
              onFormSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </AwCard>
  );
};

export default PharmacyStateSettings;
