import React, { useState, useEffect } from "react";
import api from "../../../store/api";
import {
  useModalFunctions,
  useFormRef,
  useAwToastR
} from "../../../components";
import {
  isValidEmail,
  isResponseError,
  resetFormDataToEmptyObj,
  isAllRequiredFieldsAnswered,
  changeStrToLowerCase,
  getObjectKeyLength,
  checkIfObjValuesHasChanged
} from "../../../utils";
import { UserSystemRoles } from "../../../defs";

export const useUserAndRoleAddFuncs = (
  url,
  requiredFieldsArr,
  onHide,
  onGridRowAdded
) => {
  const defaultError = "Please answer all required fields!";
  const [error, setError] = useState("");
  const [formValues, setFormValues] = useFormRef();
  const { modalRef, showModal, hideModal, resetLoading } = useModalFunctions();

  const msgSvc = useAwToastR();

  useEffect(() => {
    showModal();
    return () => {
      resetLoading();
      hideModal();
    };
  }, []);

  const handleChange = e => {
    setFormValues(e);
  };

  const handleSubmitAsync = async pageName => {
    if (isAllRequiredFieldsAnswered(requiredFieldsArr, formValues)) {
      if (pageName === "user") {
        const isValid = isValidEmail(formValues.UserName);
        if (!isValid) {
          resetLoading();
          return setError("Invalid Email!");
        }
      } else {
        const givenName = changeStrToLowerCase(formValues.Name);
        const existAdmin = changeStrToLowerCase(UserSystemRoles.admin);
        const existManager = changeStrToLowerCase(UserSystemRoles.manager);
        if (
          givenName === existAdmin ||
          givenName === existManager ||
          givenName.startsWith(existAdmin) ||
          givenName.startsWith(existManager)
        ) {
          resetLoading();
          return setError("Role already exist!");
        }
      }
      
      const res = await api.requestmethods.post(`/${url}`, formValues);
      finalizeApiRes(res);
    } else {
      handleSetError(true);
    }
    
  };
  
  

  const editUserAsync = async (userData) => {
    const isValid = isAllRequiredFieldsAnswered(requiredFieldsArr, Object.assign({}, userData, formValues));
    if(!isValid) return handleSetError(true);
    const result = checkIfObjValuesHasChanged(userData, formValues);
    if(!result.isModified) return handleSetError(true, 'Please make a change before submitting the form!');
    const res = await api.requestmethods.put(`/${url}`, result.data);
    finalizeApiRes(res);
  }

  const handleAddPhoneAsync = async (data) => {
    const res = await api.requestmethods.post(url, data);
    finalizeApiRes(res, true);
  }
  


  const finalizeApiRes = (res, isPhone = false) => {
    if (!isResponseError(res)) {
      if(onGridRowAdded) onGridRowAdded();
      isPhone ? msgSvc.showSuccess(res, true) : msgSvc.showSuccess("updated");
      setFormValues(resetFormDataToEmptyObj());
      modalClose();
    } else {
      handleSetError(true, `${res.message}`);
    }
  }

  const modalClose = () => {
    handleSetError();
    hideModal();
    onHide();
  };

  // const setCustError = (isError = false, msg = "") => {
  //   if(isError) return setError(msg ? msg : defaultError);
  //   return setError(msg);
  // }

  const handleSetError = (isError = false, msg = "") => {
    resetLoading();
    if(isError) return setError(msg ? msg : defaultError);
    return setError(msg);
  }


  return {
    modalRef,
    error,
    handleChange,
    handleSubmitAsync,
    editUserAsync,
    handleAddPhoneAsync,
    modalClose,
    handleSetError,
    formValues
  };
};
