import React from "react";

import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import { AwInput } from "../form-atoms";

export const AwInputFormField = React.memo(
  ({ init, name, label, onChange, required, type }) => {
    return (
      <AwFormFieldWrapper
        name={name}
        label={label}
        required={required ? required : false}
      >
        <AwInput
          init={init}
          type={type ? type : "text"}
          name={name}
          onChange={onChange}
        />
      </AwFormFieldWrapper>
    );
  }
);
