import React, { useEffect, useState } from "react";
import { searchForReport, groupRxSeachResult } from "../../../utils";
import { useAwToastR } from "../../../components";

export const RxSearchService = ({ rxArray, historyState, ...props }) => {
  const [rxes, setRxes] = useState(historyState ? historyState : []);

  const msgSvc = useAwToastR();

  const search = async () => {
    // const rA = [4454078, 4375595];
    // const arrRes = await searchForReport(rA);
    const arrRes = await searchForReport(rxArray);
    const response = [];
    arrRes.forEach((obj, index) => {
      if(obj.Items.length) {
        response.push(groupRxSeachResult(obj.Items));
      } else {
        msgSvc.showInfo(`No record was found for ${rxArray[index]}.`, true);
      }
    });
    
    
    // console.log('this is the search result', arrRes);
    // console.log('this is the response result', response);
    if (response.length > 0) {
      if (response[0] && response[0].length > 0) {
        setRxes(response);
      }
    }
    //  else {
    //   msgSvc.showInfo("No record was found!", true);
    // }
  };

  useEffect(() => {
    search();
  }, [rxArray]);
  return <>{props.children(rxes)}</>;
};


// const getStrArray = () => {
//   const output = "";
//   const list = [4454078, 4375595];
//   const count = list.length;
//   list.map((item, idx) => {
//     if(count > 1) {
//       output += ` ${item}`
//     } else {

//     }
//   })
//   return output;
// }
