import React, { useEffect, useContext, useRef } from "react";
import {
  AwDModal,
  useModalFunctions,
  useAwToastR
} from "../../../../../components";
import { getSelectionArray, awShowToastr, deepCopy } from "../../../../../utils";
import { ConfigContext } from "../../../../../AwAppContext";

import {DoCModalCheckList} from "./DoCModalCheckList";
import { keywordsActions } from "../store";
import { useDoCApis } from "../useDoCApis";

interface Props {

}
export const DoCModal: React.FC<Props> = () => {
  const { awConfig } = useContext(ConfigContext);


  const {keywords, docDispatch, AddDoCAsync} = useDoCApis();


//   const [error, setError] = useState("");
const { modalRef, showModal, hideModal, resetLoading } = useModalFunctions();

const msgSvc = useAwToastR();



  const submitLabels = {
    states: "StateProvinceIds",
    keywords: "Keywords",
  };

  const idsRef = useRef<any>({
    [submitLabels.states]: [],
    [submitLabels.keywords]: [],
  });

const handleHideModal = (e: any) => {
// setError("");
    hideModal();
// modalClosed();
};



  const handleSubmit = async (e: any) => {
    if (
      idsRef.current[submitLabels.states].length &&
      idsRef.current[submitLabels.keywords].length
    ) {
      await addDoCAsync(deepCopy(idsRef.current));
    } else {
      // setError("You need to at least select an item from each list!");
    }
    
    resetLoading();
  };

  const onListItemSelected = (original: any, current: any, label: string) => {
    const selectedValues = getSelectionArray(
      current,
      original,
      label === labels.states ? "StateProvinceId" : "SRKeyword"
    );

    if (label === labels.states) {
      idsRef.current[submitLabels.states] = selectedValues;
    }
    if (label === labels.keywords) {
      idsRef.current[submitLabels.keywords] = selectedValues;
    }
  };

  const addDoCAsync = async (data: any) => {
    const res = await AddDoCAsync(data);
    // updateDisplay(res, false);
    if(awShowToastr(msgSvc, res, "updated")){
      // const dx = [ ...res.Docs, ...docs.data];
      // const kx = [ ...res.Keywords, ...keywords.data];
      docDispatch({type: keywordsActions.set_search, payload: ''});
      // docDispatch({type: keywordsActions.set_keywords, payload: kx});

      // docDispatch({type: docsActions.set_docs, payload: dx});
    }
  }

  // const updateDisplay = (res: any, isKeyword=true) => {
    // if (awShowToastr(msgSvc, res, "updated")) {
    //   // openKSModal({SRKeywordID: res.SRKeywordID, SRKeyword: ''})
    //   const n = [ ...res.Docs, ...originalGridDataRef.current];
    //   originalGridDataRef.current = n;
    //   updateUI(n);
    //   setKeywordIds([res.Keywords[0].SRKeywordID]);
    // }
  // }
const modalProps = {
    ref: modalRef,
    onModalClose: handleHideModal,
    onSubmit: handleSubmit,
    title: "Drug of Concern"
}

// const checkListProps = {
//     label: "",
//     lists: [],
//     onChecked: onListItemSelected
// }

const labels = {
    keywords: "List of Keywords",
    states: "List of States",
  };
  const categories = [
    {
      label: labels.keywords,
      items: [{ Name: keywords.searchKey, SRKeyword: keywords.searchKey, SRKeywordID: 1 }],
    },
    { label: labels.states, items: awConfig.statesettings },
  ];

  useEffect(() => {
    if (keywords.searchKey) {
      showModal();
    } else {
      handleHideModal("");
    }
  }, [keywords]);

  return (
    <div>
      <AwDModal
        {...modalProps}
      >
        {/* {error && <Alert message={error} align={"center"} />} */}
        {categories.map((category, i) => {
          return (
            <div
              key={i}
              //style={{ marginBottom: !i ? "2em" : null, marginTop: "1em" }}
            >
              <DoCModalCheckList
                category={category}
                onItemSelected={onListItemSelected}
              />
            </div>
          );
        })}
      </AwDModal>
    </div>
  );
};

