import React from "react";
import { AwFormSelectField, useForm } from "../../../../components";
import { getAsapOtions, optionsNames, getAsapFieldOptions } from "./AsapDef";
import AwFormField from "../../../../components/form-molecules/AwFormField";
import { inputTypes } from "../../../../defs";
export const AddAsapFieldNoteForm = React.memo(({ onNoteInputChange }) => {
  const textareaName = "Note";
  const options = getAsapOtions(optionsNames.standard);
  const segmentOptions = getAsapOtions(optionsNames.segment);
  const [fieldOptions, setFieldOptions] = React.useState([]);
  const [isSelected, setIsSelected] = React.useState({
    standard: true,
    segment: false,
    field: false,
    showEditor: true
  });
  const [selections, setSelections] = useForm({});

  const handleFormInputChange = e => {
    const name = e.target.name;
    if (name === optionsNames.standard) {
      setIsSelected(state => {
        return { ...state, segment: true, field: false };
      });
      clearAsapData(name);
    } else if (name === optionsNames.segment) {
      const options = getAsapFieldOptions(e.target.value);
      setFieldOptions(options);
      setIsSelected(state => {
        return { ...state, field: false };
      });

      clearAsapData(name);
    } else if (name === optionsNames.field) {
      setIsSelected(state => {
        return { ...state, field: true };
      });
      clearAsapData(name);
    }

    setValues(e);
  };

  const setValues = e => {
    setSelections(e);
    if (e.target.name === textareaName) {
      onNoteInputChange(selections);
    }
  };

  const clearAsapData = name => {
    switch (name) {
      case optionsNames.standard:
        onNoteInputChange({ [name]: selections[name] });
        break;
      case optionsNames.segment:
        onNoteInputChange({
          ...selections,
          [optionsNames.field]: null,
          Note: null
        });
        break;
      case optionsNames.segment:
        onNoteInputChange({ ...selections, ["Note"]: null });
        break;
    }
  };

  return (
    <form>
      {isSelected.standard && (
        <AwFormSelectField
          options={options}
          name={optionsNames.standard}
          onSelectChange={handleFormInputChange}
        />
      )}

      {isSelected.segment && (
        <AwFormSelectField
          options={segmentOptions}
          name={optionsNames.segment}
          onSelectChange={handleFormInputChange}
        />
      )}

      {isSelected.segment && fieldOptions.length > 0 && (
        <AwFormSelectField
          options={fieldOptions}
          name={optionsNames.field}
          onSelectChange={handleFormInputChange}
          dropdownText="key"
          value="key"
        />
      )}

      {isSelected.segment && isSelected.field && isSelected.showEditor && (
        <AwFormField
          typeOfField={inputTypes.textarea}
          name={textareaName}
          onChange={handleFormInputChange}
        />
      )}
    </form>
  );
});
