import React, { useEffect, useMemo, useRef, useState } from "react";
import { createFormKeysObjArrFromStrArray } from "../../../utils";
import { AwInputFormField, AwButton } from "../../../components";

export const PharmacyStateSettingsForm = ({ stateSetting, onFormSubmit }) => {
  const [settings, setSettings] = useState(stateSetting);
  const objKeys = useRef(createFormKeysObjArrFromStrArray(
      [
        "AutoPostUsername",
        "AutoPostPassword",
        "FilePassword",
        "StateLicenseNumber"
      ]
      // ,
      // {
      //   FilePassword: "password",
      //   AutoPostPassword: 'password'
      // }
    )
    ).current;

    const handleValueChange = e => {
      setSettings(state => ({...state, [e.target.name]: e.target.value}));
    };

     const handleSubmit = e => {
      onFormSubmit(settings);
    };

    useEffect(() => {
      setSettings(stateSetting);
    }, [stateSetting])
  // const stateSettingRef = useRef(deepCopy(stateSetting));
  // const objKeys = useMemo(() => {
  //   stateSettingRef.current = deepCopy(stateSetting);
  //   return createFormKeysObjArrFromStrArray(
  //     [
  //       "AutoPostUsername",
  //       "AutoPostPassword",
  //       "FilePassword",
  //       "StateLicenseNumber"
  //     ],
  //     {
  //       FilePassword: "password"
  //     }
  //   );
  // }, [stateSetting]);

  // const handleValueChange = e => {
  //   stateSettingRef.current[e.target.name] = e.target.value;
  // };

  // const handleSubmit = e => {
  //   onFormSubmit(stateSettingRef.current);
  // };

  // console.log('objectkeys', objKeys);
  return (
    <form className="row">
      {objKeys.length > 0 &&
        objKeys.map((field, idx) => {
          return (
            <div key={idx} className="col-lg-12">
              <AwInputFormField
                init={settings[field.name]}
                name={field.name}
                label={field.label}
                type={field.type}
                onChange={handleValueChange}
                required={false}
              />
            </div>
          );
        })}
      <div className="col-lg-12 text-right">
        <div style={{ width: "70px" }}>
          <AwButton label="Save" loading={false} btnClicked={handleSubmit} />
        </div>
      </div>
    </form>
  );
};

export default PharmacyStateSettingsForm;
