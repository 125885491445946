import React from "react";
import { AwPageContainer } from "../../AwPageContainer";
import { BookmarkGrid } from "./BookmarkGrid";
export const Bookmark = React.memo(({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <BookmarkGrid stateProvinceId={stateProvinceId}></BookmarkGrid>
    </AwPageContainer>
  );
});
