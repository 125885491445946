import React, { lazy, Suspense } from "react";

import { PreApp } from "./PreApp";

export const ConfigContext = React.createContext();

export const CONFIG_VALUE = {
  previousPageName: "signin",
  nextPageName: "dashboard",
  statesettings: [],
  pharmacies: [],
  msgFuncs: {},
  session: { time: 1200, reset: "token" }
};

const AwPNGGrowl = lazy(() =>
  import("./components/message").then(msg => ({ default: msg.AwPNGGrowl }))
);

// This component is basically to set up
export const AwAppContext = () => {
  let msg = React.useRef({});
  CONFIG_VALUE.msgFuncs = msg;
  const [awConfig, setAwConfig] = React.useState(CONFIG_VALUE);
  return (
    <ConfigContext.Provider value={{ awConfig, setAwConfig }}>
      <Suspense fallback={<div />}>
        <AwPNGGrowl ref={msg} />
      </Suspense>
      <PreApp />
    </ConfigContext.Provider>
  );
};
