import { useState } from "react";
import { strToIntArr, awSwalAlert } from "../../../../utils";

export const useSearchFunc = onSearchRx => {
  const [rxValues, setSearchValue] = useState("");
  const [inputMsg, setInputMsgValue] = useState("");

  const handleSubmit = e => {
    if (e) e.preventDefault();
    const strArr = strToIntArr(rxValues);
    const success = strArr.type === "data";
    if (success && strArr.data.length > 0) {
      onSearchRx(strArr.data);
      setSearchValue("");
    }
    if (!success) awSwalAlert(strArr.message, "error");
    setInputMsgValue(strArr.message);
  };

  const handleInputChange = e => {
    if (inputMsg) setInputMsgValue("");
    if (e) e.preventDefault();
    const eValue = e.target.value.replace(/[^0-9,]/g, "");
    setSearchValue(eValue);
  };
  return { rxValues, handleInputChange, handleSubmit };
};
