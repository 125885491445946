import React, { useState } from "react";

import "./ReportStatusSelect.css";
import { deepCopy } from "../../../../utils";
import { reportStatus } from "../../../../defs";

export const ReportStatusSelect = ({ dataItem, statusChanged, columIndex }) => {
  const [state, setState] = useState({
    statuses: deepCopy(reportStatus),
    selectedStatusId: dataItem.StatusId
  });

  const handleChange = event => {
    const value = event.target.value;
    setState({ ...state, selectedStatusId: value });
    statusChanged(Object.assign({}, dataItem, { StatusId: value }));
  };

  return (
    <td>
      <span style={{ width: "100%", display: "flex" }}>
        <select
          key={columIndex}
          value={dataItem.StatusId}
          onChange={handleChange}
          className="dashboard-grid-select form-control"
          style={{
            padding: ".375rem .75rem",
            maxWidth: "135px",
            height: "calc(1.5rem + 2px) !important"
          }}
        >
          {state.statuses.map(status => {
            return (
              <option key={status.StatusId.toString()} value={status.StatusId}>
                {status.Status}
              </option>
            );
          })}
        </select>
      </span>
    </td>
  );
};

// export class ReportStatusSelect extends Component {
//   state = {
//     statuses: JSON.parse(JSON.stringify(reportStatus)),
//     selectedStatusId: this.props.dataItem.StatusId
//   };

//   handleChange = event => {
//     this.setState({ selectedStatusId: event.target.value });
//     this.props.dataItem["StatusId"] = event.target.value;
//     this.props.statusChanged(this.props.dataItem);
//   };

//   render() {
//     const { selectedStatusId, statuses } = this.state;
//     return (
//       <td>
//         <select
//           key={this.props.columIndex}
//           value={selectedStatusId}
//           onChange={this.handleChange}
//           className="dashboard-grid-select"
//         >
//           {statuses.map(status => {
//             return (
//               <option key={status.StatusId.toString()} value={status.StatusId}>
//                 {status.Status}
//               </option>
//             );
//           })}
//         </select>
//         {/* <span className="aw-select-spantag-wrapper">
//           <select
//             key={this.props.columIndex}
//             value={selectedStatusId}
//             onChange={this.handleChange}
//             className="dashboard-grid-select"
//           >
//             {statuses.map(status => {
//               return (
//                 <option
//                   key={status.StatusId.toString()}
//                   value={status.StatusId}
//                 >
//                   {status.Status}
//                 </option>
//               );
//             })}
//           </select>
//         </span> */}
//         {/* <span style={{ width: "100px" }}>
//           <AwSelectBox
//             array={statuses}
//             dValue={selectedStatusId}
//             value={"StatusId"}
//             label={"Status"}
//             onSelectValueChange={e => console.log(e)}
//           />
//         </span> */}
//       </td>
//     );
//   }
// }
