import React, { useRef } from "react";
import { DisplayGrid, useGridRefreshFunc } from "../../../components";
import { AwPageContainer } from "../AwPageContainer";

export const Directory = () => {
  const columnsRef = useRef([
    { field: "FirstName", header: "First Name", width: "128px" },
    { field: "LastName", header: "Last Name" },
    { field: "PhoneNumber", header: "Phone No." },
    { field: "MobileNumber", header: "Mobile" },
    { field: "FaxNumber", header: "Fax No." },
    { field: "Email", header: "Email" },
    { field: "Url", header: "Web Address" },
    { field: "Address1", header: "Address 1" },
    { field: "City", header: "City" },
    { field: "StateProvinceId", header: "State" },
    { field: "PostalCode", header: "Zip Code" }
  ]);

  // const { gridRefresh, handleGridRefresh } = useGridRefreshFunc();
  const { gridRefresh } = useGridRefreshFunc();

  return (
    <AwPageContainer>
      <div className="directory-page">
        <DisplayGrid
          tableName="directory"
          url="api/contacts"
          urlExt={null}
          columns={columnsRef.current}
          showExport={true}
          refresh={gridRefresh}
        ></DisplayGrid>
      </div>
    </AwPageContainer>
  );
};

// export default Directory;
