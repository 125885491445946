import React from "react";
import "./AnimatedTextField.scss";

interface Props {
    label: string;
    name: string;
    type?: string;
    onChange: (v: any) => void;
    required: boolean;
}
export const AnimatedTextField: React.FC<Props> = ({
    label,
    name,
    type = "text",
    onChange,
    required = false
  }) => {
    const [value, setValue] = React.useState("");
    const handleChange = (e: any) => {
      onChange(e);
      setValue(e.target.value);
    };
  
    return (
    <div className="animated-text-field">
        <input
            className={"fancy-input"}
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            required={required}
        />
        <span>
            {label} {required && <em className="text-danger">*</em>}
        </span>
        <div />
    </div>
    )
}