import React from "react";
import { DisplayGrid } from "../../../../components";

export const SubHistoryGrid = ({ stateProvinceId }) => {
  const tableName = "history";
  const base_url = "api/History";

  return (
    <DisplayGrid
      tableName={tableName}
      url={base_url}
      urlExt={stateProvinceId}
      columns={historyColumns}
      showExport={true}
      refresh={true}
    >
      <></>
      <></>
    </DisplayGrid>
  );
};

const historyColumns = [
  { field: "CreatedDate", header: "Submitted Date" },
  { field: "FileName", header: "File Name" },
  { field: "IsLoaded", header: "File Status" },
  { field: "SubmissionType", header: "Submission Type" },
  { field: "RecordCount", header: "Records" },
  { field: "CreatedBy", header: "Created By" }
];
