import React from "react";
import {
  AwGridTopBtnLogic,
  DeleteAndEdit,
  DisplayGrid,
  GridCellWithLink,
  useAxios,
  useStateBasicFunctions
} from "../../../../components";

import { AwButton } from "../../../../components";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { BookmarkForm } from "./BookmarkForm";
import {
  isAllRequiredFieldsAnswered,
  validURL,
  awConfirmDelete
} from "../../../../utils";

import { AwDModal } from "../../../../components/modal/AwDModal";
import { Alert } from "../../../../components/message";
import { formGenericErrorMsg, TABLE_NAME } from "../../../../defs";
import api from "../../../../store/api";
export const BookmarkGrid = ({ stateProvinceId }) => {
  const base_url = "api/Bookmarks";
  const { data } = useAxios(base_url, []);
  const webKey = "WebUrl";

  const {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    modalSubmitBtn,
    formData,
    formError,
    formChanges,
    setFormChanges,
    modalRef,
    createBtnRef,
    handleModalClose,
    handleNewModalOpen,
    handleUpdateClick,
    handleFormError,
    handleHttpResponse
  } = useStateBasicFunctions();

  const handleSubmit = e => {
    const dataToSubmit = Object.assign({}, formData, formChanges);
    const isSubmitable = isAllRequiredFieldsAnswered(
      ["Category", "Title", webKey],
      dataToSubmit
    );

    if (isSubmitable) {
      const isValidUrl = validURL(dataToSubmit[webKey]);
      if (isValidUrl) {
        handleFormError("", false);
        if (modalSubmitBtn === addBtn) {
          dataToSubmit["StateProvinceId"] = stateProvinceId;
          addBookmark(dataToSubmit);
        } else {
          saveBookmark(dataToSubmit);
        }
      } else {
        handleFormError("Please provide a valid 'Web Address'.", true);
      }
    } else {
      handleFormError(formGenericErrorMsg, true);
    }
  };

  const addBookmark = async values => {
    const res = await api.requestmethods.post(base_url, values);
    handleHttpResponse(res);
  };

  const saveBookmark = async values => {
    const res = await api.requestmethods.put(base_url, values);
    handleHttpResponse(res);
  };

  const onDeleteRecord = async e => {
    if (await awConfirmDelete("Are you sure you want to delete this record?")) {
      const res = await api.requestmethods.deleteWithoutParams(
        base_url,
        e.BookmarkId
      );
      msgSvc.showSuccess("deleted");
      handleGridRefresh();
    }
  };

  return (
    <div className="bookmark-grid">
      <AwGridTopBtnLogic table={TABLE_NAME.bookmarks}>
        <AwButton
          icon="plus"
          label={TABLE_NAME.bookmarks}
          btnClicked={handleNewModalOpen}
          ref={createBtnRef}
        />
      </AwGridTopBtnLogic>

      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={modalSubmitBtn}
        title={`${TABLE_NAME.bookmarks} Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <BookmarkForm
          onInputChange={setFormChanges}
          options={data}
          dObj={formData}
        />
      </AwDModal>

      <DisplayGrid
        tableName="bookmark"
        url={base_url}
        urlExt={stateProvinceId}
        columns={getBookmarkColumns}
        showExport={true}
        refresh={gridRefresh}
      >
        <>
          <Column
            field={"Title"}
            title={"Title"}
            cell={props => (
              <GridCellWithLink defaultLink={true} link={"WebUrl"} {...props} />
            )}
          ></Column>
        </>
        <>
          <Column
            width="140px"
            className="added-col-td delete-col"
            title="Action"
            filterable={false}
            cell={props => {
              return (
                <DeleteAndEdit
                  onDelete={onDeleteRecord}
                  onEdit={handleUpdateClick}
                  {...props}
                />
              );
            }}
          />
        </>
      </DisplayGrid>
    </div>
  );
};

const getBookmarkColumns = [
  // { field: "Title", header: "Title" },
  { field: "Category", header: "Category" },
  { field: "WebUrl", header: "Web Address" },
  { field: "CreatedBy", header: "Created By" }
];

// const fieldNames = ["Category", "Title", "WebUrl"];
