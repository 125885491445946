import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AwIconCheckbox = ({checked}) => {
  return (
    <>
      {checked ? (
        <FontAwesomeIcon icon={"check"} />
      ) : (
        <input type="checkbox" style={{cursor: 'pointer'}} />
      )}
    </>
  );
};
