import { APP_URLS } from "../../../../../infrastructure";

export const PrimaryMneuItems = [
  {
    text: "Dashboard",
    icon: "tachometer-alt",
    route: "/dashboard",
    submenu: null
  },
  {
    text: "Reporting",
    icon: "tachometer-alt",
    route: APP_URLS.orders,
    submenu: null
  },
  {
    text: "States",
    icon: "flag-usa",
    route: APP_URLS.states,
    submenu: null
  },
  {
    text: "State DoCs",
    icon: "flag-usa",
    route: APP_URLS.rules,
    submenu: null
  },
  {
    text: "Directory",
    icon: "address-book",
    route: APP_URLS.directory,
    submenu: null
  },
  {
    text: "Pharmacy",
    icon: "capsules",
    route: APP_URLS.pharmacy,
    submenu: null
  },
  {
    text: "Admin",
    icon: "users-cog",
    route: "/admin",
    submenu: null
  }
];

export const AdminMenuItems = [
  {
    text: "Admin",
    icon: "tachometer-alt",
    route: "/admin",
    submenu: null
  },
  {
    text: "Users",
    icon: "users",
    route: "/admin/users",
    submenu: null
  },
  {
    text: "Roles",
    icon: "id-badge",
    route: "/admin/roles",
    submenu: null
  }
];

export const ProfileImageMneuItems = [
  {
    text: "Settings",
    icon: "cogs",
    route: "/settings",
    submenu: null
  },
  {
    text: "Sign out",
    icon: "sign-out-alt",
    route: "/signin",
    submenu: null
  }
];
