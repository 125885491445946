
import NavigationButton from "./NavigationButton";
import FwMenusWrapper from "./FwMenusWrapper";
import { useFwAdminMenuFunc } from "../../../useFwAdminMenuFunc";

export const FwMenus = () => {
  const {
    isHorizontal,
    isAuthenticated,
    onlyHorizontal,
    setOrientation
  } = useFwAdminMenuFunc();

  return (isHorizontal && isAuthenticated) || onlyHorizontal ? (
    <div style={{ textAlign: "right", marginTop: "14px" }} className="menus">
      <FwMenusWrapper isHorizontal={isHorizontal} />
      {!onlyHorizontal && (
        <NavigationButton
          isDefault={false}
          color="secondary"
          icon="chevron-left"
          handleClick={setOrientation}
        />
      )}
    </div>
  ) : null;
};

export default FwMenus;
