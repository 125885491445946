import { call, takeEvery, put } from "redux-saga/effects";

import {
  signIn_type,
  signInSuccess_type,
  signInFailed_type,
  IAction
} from "../actiontypes";

import api from "../api";
import { StateReportUser } from "../../models";

function* signInAsync(action: IAction): Generator<any, void, any> {
  try {
    const user: StateReportUser = yield call(
      api.user.signIn,
      action.payload
    );
    yield put({ type: signInSuccess_type, payload: user });
  } catch (error) {
    const errorObj = JSON.parse(JSON.stringify(error));
    yield put({
      type: signInFailed_type,
      payload: errorObj.message
    });
  }
}

export function* watchSignInAsync() {
  yield takeEvery(signIn_type, signInAsync);
}
