import React from "react";

import { FancyTextField } from "./text-field";
import { AnimatedTextField } from "./text-field/AnimatedTextField";

export const SignInForm = ({ onChange }) => {
  return (
    <>
      <AnimatedTextField
        label="UserName"
        name="UserName"
        onChange={onChange}
        required={true}
      />
      
      <AnimatedTextField
        label="Password"
        name="Password"
        type="password"
        required
        onChange={onChange}
      />
      {/* <FancyTextField
        label="Password"
        name="Password"
        type="password"
        required
        onChange={onChange}
      /> */}
    </>
  );
};
