import React, { useRef } from 'react';
import { IKsAddPatIdListItemProps } from '../IDocInterfaces';


export const KsAddPatIdListItem: React.FC<IKsAddPatIdListItemProps> = ({item, onPatBtnClicked}) => {
    const {add, remove} = useRef({add: "Add Id", remove: "Remove Id"}).current;
    const handleClick = () => {
        let shouldAdd = true;
        if(!!item.Notes) shouldAdd = false;
        onPatBtnClicked(item, shouldAdd);
    }
    const isNote = !!item.Notes;
    return (
        <div className="ks-list-item">
            <div>{item.StateProvinceID} {isNote && <strong>- ({item.Notes})</strong>}</div>
            <div>
                <button 
                    onClick={handleClick} 
                    className="btn btn-sm btn-primary">
                    {isNote ? remove : add}
                </button>
            </div>
        </div>
    )
}