import React from "react";
import { AwPageContainer } from "../../AwPageContainer";
import { DocumentGrid } from "./DocumentGrid";
export const Documents = React.memo(({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <DocumentGrid stateProvinceId={stateProvinceId}></DocumentGrid>
    </AwPageContainer>
  );
});
