import React, { useRef } from "react";
export const useIsComponentActive = () => {
  const componentRef = useRef({ active: true }).current;
  React.useEffect(() => {
    return () => {
      componentRef.active = false;
    };
  }, []);
  return { componentRef };
};
