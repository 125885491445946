import React from "react";

import { AwPageContainer } from "../AwPageContainer";
import { AwTab } from "../../../components";
import { DoCWrapper } from "./DoC";

export const StateRules = () => {
  const onStateChange = (stateId) => {};

  return (
    <AwPageContainer>
      {/* dynamic components */}
      <div className="aw-staterules-page-tabview-section">
        <AwTab>
          <DoCWrapper header="DoC" />
        </AwTab>
      </div>
    </AwPageContainer>
  );
};
