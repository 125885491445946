import React from "react";
import { AwButton } from "../../../../components";
import {useNavigate} from "react-router-dom"
// import { searchForReport, strToIntArr } from "../../../../utils";

const SearchReportByRx = () => {
  const navigate = useNavigate();
  const navigateToReportSearch = e => {
    if (e) e.preventDefault();
    navigate({
      pathname: "/reports"
    });
  };
  // const handleModalClose = e => {
  //   setRxes({ target: { name: "Prescriptions", value: "" } });
  //   modalRef.current.close();
  // };

  // const modalRef = useRef();
  return (
    <div>
      <AwButton
        width="100%"
        icon="edit"
        type="secondary"
        btnClicked={navigateToReportSearch}
      />
    </div>
  );
};

export default SearchReportByRx;
