import React, { useEffect } from "react";

export const useAwListItemCheckFuncs = (listItem, preSelect, handleCheck) => {
  const [icon] = React.useState("check");
  const [checked, setChecked] = React.useState(preSelect ? preSelect : false);
  const [canCheck, setCanCheck] = React.useState(true);
  const handleListSelected = (e) => {
    if (canCheck) {
      const value = !checked;
      setChecked(value);
      handleCheck({ target: { checked: value } });
    }
  };

  useEffect(() => {
    setChecked(preSelect);
    if (listItem.PharmacyId && listItem.PharmacyId === 1) {
      setChecked(true);
      handleCheck({ target: { checked: true } });
      setCanCheck(false);
    }
  }, [preSelect]);

  return { icon, canCheck, checked, handleListSelected };
};
