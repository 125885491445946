import React from "react";
import { checkAndGetAbsoluteLink } from "../../../utils";
import "./GridCellWthLink.css";
export function GridCellWithLink({ link, defaultLink, dataItem, field }) {
  const isLink = link || dataItem.StateSetting;
  let dLink = "";
  if (isLink) {
    if (defaultLink) {
      dLink = checkAndGetAbsoluteLink(dataItem[link]);
    } else {
      dLink = dataItem["StateSetting"]["WebPortalUrl"];
    }
  }

  return (
    <td className="grid-link-td">
      {isLink ? (
        <a
          className="grid-link"
          href={dLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          {dataItem[field]}
        </a>
      ) : (
        <span> {dataItem[field]}</span>
      )}
    </td>
  );
}
