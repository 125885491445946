import React from "react";
import { AwLabel } from "../form-atoms";

export const AwFormFieldWrapper = ({ name, label, required, ...props }) => {
  return (
    <div className="form-group">
      <AwLabel
        htmlFor={!!label ? label : name}
        label={!!label ? label : name}
        required={required}
      />
      {props.children}
    </div>
  );
};
