import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { awIcons } from "../../../utils";
import { AwPresentationalModal } from "../../modal";

export const GridRowDetails = ({ tablename, ...props }) => {
  let data = props.dataItem;
  if (tablename === "dashboard") {
    const dataWithSettings = JSON.parse(JSON.stringify(props.dataItem));
    delete dataWithSettings["StateSetting"];
    delete dataWithSettings["CdiIds"];
    data = dataWithSettings;
  }
  let header = tablename.charAt(0).toUpperCase() + tablename.slice(1);

  return (
    // <td style={{ display: "flex", justifyContent: "center" }}>
    <td style={{textAlign: 'center'}}>
      {/* <AwPresentationalModal
        dataItems={data}
        title={(header += " Row Details")}
        button={
          <span className="ui circular mini button">
            <FontAwesomeIcon icon={awIcons.info} />
          </span>
        }
      /> */}
      
      <AwPresentationalModal
        dataItems={data}
        title={(header += " Row Details")}
        button={
          <span 
            className="ui circular button"
            style={{
              display: 'inline-block', 
              padding: '0',
              margin: '0',
              width: '22px', 
              height: '22px'
            }}
            >
            <FontAwesomeIcon icon={awIcons.info} style={{fontSize: '12px', marginTop: '3px'}} />
          </span>
        }
       />
    </td>
  );
};

GridRowDetails.propTypes = {
  tablename: PropTypes.string.isRequired
};
