import React from 'react';
import {DocProvider} from "./store";
import {DoC} from "./DoC";
export const DoCWrapper = () => {
    return (
        <DocProvider>
            <DoC />
        </DocProvider>
    )
}
