import React from "react";
import { AwGenericListItem } from "./AwGenericListItem";
export default function AwModalListItem({ listItem, preSelect, handleCheck }) {
  return (
    <AwGenericListItem
      listItem={listItem}
      preSelect={preSelect}
      handleCheck={handleCheck}
      tag="li"
    />
  );
}
