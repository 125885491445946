import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthSettingsLogic } from "../../../components";
import { PasswordSettingsPagePresentation } from "./PasswordSettingsPresentation";
import { ConfirmHeader } from "../misc";


export const EmailConfirmation = () => {
  const location = useLocation();
  const [queryString, setQueryString] = React.useState("");
  const {
    loadingSettings,
    settingsMsg,
    confirmEmailAsync
  } = useAuthSettingsLogic("api/auth/ConfirmEmail");

  const passwordLocation = {
    pathname: "/createpassword",
    search: queryString
  };
  const handleConfirmEmailAsync = async () => {
    const queryString = await confirmEmailAsync(location.search);
    setQueryString(queryString);
  };

  React.useEffect(() => {
    handleConfirmEmailAsync();
  }, []);

  return (
    <PasswordSettingsPagePresentation>
      {loadingSettings ? (
        <ConfirmHeader sub="email" />
      ) : (
        <div style={{ textAlign: "center" }}>
          <ConfirmHeader sub="email" status={settingsMsg}  />
          {settingsMsg.success ? (
            <Link to={passwordLocation}>Click here to create a password.</Link>
          ) : (
            <Link to="/signin">
              Click here to sign in or reset your password.
            </Link>
          )}
        </div>
      )}
    </PasswordSettingsPagePresentation>
  );
};
