import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMenuBarOrientationAction, getMenuBarStatus } from "../../store";
import { useWindowResize } from "../custom-hooks";
import { useState, useEffect } from "react";

export const useFwAdminMenuFunc = () => {
  const [onlyHorizontal, setOnlyHorizontal] = useState(false);
  const { isHorizontal, isAuthenticated } = useSelector(state =>
    getMenuBarStatus(state)
  );
  const dispatch = useDispatch();
  const [screen] = useWindowResize();

  const setOrientation = () => dispatch(setMenuBarOrientationAction());

  useEffect(() => {
    if (screen.width <= 900) {
      if (!isHorizontal) {
        setOrientation();
      }
      setOnlyHorizontal(true);
    } else {
      setOnlyHorizontal(false);
    }
  }, [screen.width]);
  return {
    isHorizontal,
    isAuthenticated,
    onlyHorizontal,
    setOrientation
  };
};
