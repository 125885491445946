import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { inputTypes } from "../../defs";
import { getNewEventObj } from "../../utils";

export default function AwInput({
  init,
  type,
  setAutoComplete,
  name,
  onChange
}) {
  const [value, setValue] = useState(init ? init : "");

  const handleChange = e => {
    const newEvent = getNewEventObj(init, e.target.value, e.target.name);
    setValue(e.target.value);
    onChange(newEvent);
  };

  const handleOnInput = e => {
    const eValue = e.target.value.replace(/[^0-9,]/g, "");
    e.target["value"] = eValue;
    handleChange(e);
  };

  useEffect(() => {
    setValue(init ? init : "");
  }, [init]);

  return (
    <>
      {type === inputTypes.onlyNumbersAndComma ? (
        <>
          <div className="ui input" style={{ width: "100%" }}>
            <input
              className="form-control"
              autoComplete={setAutoComplete}
              name={name}
              type={type}
              value={value}
              onChange={handleOnInput}
            />
          </div>
          {/* <input className="form-control" autoComplete={setAutoComplete} name={name} type={type} value={value} onChange={handleOnInput}/> */}
          <small style={{ color: "red" }}>
            *Please separate each prescription number with a comma.
          </small>
        </>
      ) : (
        <div className="ui input cust-semantic input" style={{ width: "100%" }}>
          {type === inputTypes.file ? (
            <input
              className="form-control"
              name={name}
              type={type}
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={onChange}
            />
          ) : (
            <input
              className="form-control"
              autoComplete={setAutoComplete}
              name={name}
              type={type}
              value={value}
              onChange={handleChange}
            />
          )}
        </div>
      )}
    </>
  );
}

{
  /* <input
          className="form-control"
          autoComplete={setAutoComplete}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
        /> */
}
AwInput.propTypes = {
  init: PropTypes.any,
  type: PropTypes.string,
  setAutoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

AwInput.defaultProps = {
  init: "",
  type: inputTypes.text,
  setAutoComplete: "on"
};
