const mobile = "MobileNumber";
const email = "EmailAddress";
const foundItems = "found";
const verify = "verify";
const verified = "verified";

export const checkExistingUIRecords = async (
  contacts: any,
  arrOfNotifications: any[]
) => {
  // add Array of found
  contacts[foundItems] = [];
  for (let i = 0; i < arrOfNotifications.length; i++) {
    if (
      contacts.isMobile &&
      arrOfNotifications[i].MobileNumber === contacts.MobileNumber
    ) {
      contacts[foundItems].push(mobile);
    }
    if (
      contacts.isEmail &&
      arrOfNotifications[i].EmailAddress === contacts.EmailAddress
    ) {
      contacts[foundItems].push(email);
    }

    if (
      (contacts.isBoth && contacts[foundItems].length > 1) ||
      (!contacts.isBoth && contacts[foundItems].length === 1)
    ) {
      return contacts;
    }
  }

  return contacts;
};

export const whatNeedsDBVerification = async (contacts: any) => {
  contacts[verify] = [];
  contacts[verified] = [];
  if (contacts.isBoth && contacts.found.length < 1) {
    // nothing found
    contacts[verify].push(mobile);
    contacts[verify].push(email);
  } else if (contacts.isBoth && contacts.found.length === 1) {
    // one found
    if (contacts.found[0] !== email) {
      contacts[verify].push(email);
      contacts[verified].push(mobile);
    } else {
      contacts[verify].push(mobile);
      contacts[verified].push(email);
    }
  } else if (!contacts.isBoth && contacts.found.length < 1) {
    // nothing found
    if (contacts.isMobile) {
      contacts[verify].push(mobile);
    } else {
      contacts[verify].push(email);
    }
  } else if (contacts.isBoth && contacts.found.length === 2) {
    contacts[verified].push(mobile);
    contacts[verified].push(email);
  } else if (!contacts.isBoth && contacts.found.length === 1) {
    if (contacts.isMobile) {
      contacts[verified].push(mobile);
    } else {
      contacts[verified].push(email);
    }
  }
  return contacts;
};

export const whatNeedsUserVerification = async (contact: any, res: any) => {
  let v: string[] = [];
  contact.verify.forEach((str: string, i: number) => {
    v.push(str);
    if (str === "EmailAddress" && res.Email) {
      v = v.filter(x => x !== str);
      contact[verified].push(str);
    } else if (str === "MobileNumber" && res.Mobile) {
      v = v.filter(x => x !== str);
      contact[verified].push(str);
    }
  });
  contact.verify = v;
  return contact;
};

export const getVerificatinType = (verArr: string[]) => {
  let v = "";
  verArr.forEach((str: string, i: number) => {
    if (str === "EmailAddress") {
      v = v.length ? `${v} and email` : "email ";
    }
    if (str === "MobileNumber") {
      v = v.length ? `${v} and text` : "text ";
    }
  });

  return v;
};
