import React from "react"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { awConfirmDelete } from "../../../../../utils";


interface IKs {
    SRKeywordID: number;
    SRKeywordStateProvinceID: number;
    StateProvinceID: string;
}

interface Props {
    item: IKs;
    onDelete: (item: any) => void;
}

export const KsListItem: React.FC<Props> = ({item, onDelete}) => {
    const handleDeleteAsync = async (item: any) => {
        if(await awConfirmDelete()) {
            onDelete(item);
        }
    }
    return (
        <div className="ks-list-item">
            <div>{item.StateProvinceID}</div>
            <div className="ks-delete" onClick={() => handleDeleteAsync(item)}><FontAwesomeIcon icon="trash-alt" /></div>
        </div>
    )
}