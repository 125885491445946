import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Icon } from "semantic-ui-react";

const AwButton = React.forwardRef<any, any>(function(
  { label, loading, disable, btnClicked, icon, type, width },
  ref
) {
  const handleClick = (e: any) => {
    if (e) e.preventDefault();
    btnClicked();
  };

  const btnId = label ? label.toLowerCase() : '';

  return (
    <button
      className={
        `aw-btn aw-btn-${type} ` + (loading || disable ? "disabled" : "")
      }
      type="button"
      onClick={handleClick}
      style={{ width: `${width}` }}
      ref={ref}
      disabled={loading || disable}
      
    >
    {/* id={btnId} */}

      {/* {loading && <Loader active inline loader />}  
            style={{ position: "absolute", right: "17px", top: "1px" }} */}
      <span style={{ position: "relative" }}>
        {loading && <Icon loading name="spinner" />}
        {!!icon && (
          <span>
            <FontAwesomeIcon icon={icon} />{" "}
          </span>
        )}
        {!!label && <span>{label}</span>}
      </span>
    </button>
  );
});

export default AwButton;

AwButton.propTypes = {
  btnClicked: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string
};

AwButton.defaultProps = {
  loading: false,
  type: "default",
  label: "",
  width: "100%"
};
