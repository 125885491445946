import React from "react";
import { Card, Label } from "semantic-ui-react";
import { initShortDate } from "../../../../utils";
import EditReport from "../edit-report/EditReport";

export const ReportSummary = React.memo<{rx: any; 
  pharmacies: any;}>(({ rx, pharmacies }) => {
  return (
    <>
      {rx.map((patient: any, i: number) => {
        return (
          <Card fluid color="red" key={i}>
            <Card.Content>
              <Card.Header>
                <Label as="a" color="red" ribbon>
                  {patient.RxNumber}
                </Label>
                <span>{patient.PatientName}</span>
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <table className="table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date Filled</th>
                    <th>Created Date</th>
                    <th>Prescriber</th>
                    <th>Status</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {patient.Details.map((detail: any, j: number) => {
                    return (
                      <tr key={j}>
                        <td>
                          <EditReport
                            rxdetails={detail}
                            pharmacies={pharmacies}
                          />
                        </td>
                        <td>{initShortDate(detail.DateFilled)}</td>
                        <td>{initShortDate(detail.CreatedDate)}</td>
                        <td>
                          {detail.PreFirstName} {detail.PreLastName}
                        </td>
                        <td>
                          {detail.StatusId === 2 && <span>Submitted</span>}
                          {detail.StatusId !== 2 && <span>Pending</span>}
                        </td>
                        <td>{detail.CreatedBy}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card.Content>
          </Card>
        );
      })}
    </>
  );
});
