import React from "react";
import { awIcons } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AwDeleteData = ({ onDelete }) => {
  return (
    <span
      onClick={onDelete}
      className="aw-icons"
      style={{ color: "red" }}
      tabIndex="0"
    >
      <FontAwesomeIcon icon={awIcons.delete} />
    </span>
  );
};