import { useState, useRef } from "react";
import { initialGridState } from "../../../defs";
// import { getQueryParams } from "../../../utils";

export const useGridStateChangeFunc = () => {
  const [gridEvent, setGridEvent] = useState(
    JSON.parse(JSON.stringify(initialGridState))
  );

  const gridRef = useRef();

  const handleDataStateChange = changeEvent => {
    const newState =
      changeEvent && changeEvent.dataState ? changeEvent.dataState : initialGridState;
    newState["group"] = !newState.group ? [] : newState.group;
    newState["sort"] = !newState.sort ? [] : newState.sort;
    setGridEvent(newState);
    // let queryStr = getQueryParams(newState, newState.allReports, tableName);
    // console.log(queryStr);
    // console.log(queryStr.split(""));
    // console.log(queryStr.split("").pop());
    // if (!(queryStr.split("").pop() === "=")) {
    //   setGridEvent(newState);
    // }
  };

  return { gridRef, gridEvent, handleDataStateChange };
};
