import { useState } from "react";

export const useGridRefreshFunc = () => {
  const [gridRefresh, setState] = useState({
    reset: false,
    shouldRefresh: false,
    method: "read"
  });
  const handleGridRefresh = () => {
    setState(state => ({
      ...state,
      reset: true,
      shouldRefresh: !state.shouldRefresh
    }));
  };
  return { gridRefresh, handleGridRefresh };
};
