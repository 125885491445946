import { useState, useRef } from "react";
import axios from "axios";
import api from "../../../../../store/api";
import { report_segments_shortName } from "../../../../../defs";

export const useSegmentCardFunc = (
  segment,
  onInputChange,
  rxNumber,
  segmentDetailSearchProp
) => {
  const [report, setReport] = useState({
    segmentName: segment.key,
    current: {},
    submitted: {},
    hide: true
  });
  const currentRef = useRef({});

  const handleHeaderClick = e => {
    // get segment data for both current and submitted report
    if (Object.keys(report.current).length < 1) {
      let url = getSegmentDetailsUrl(segment.key, rxNumber);
      const isCdi = (report_segments_shortName.cdi === segment.key);
      const cdiQueryHelper = {isSubmitted: true, isCdi, rxNumber: segmentDetailSearchProp.rxNumber};
      axios
        .all([
          createApiResource(url, segmentDetailSearchProp.submittedReportId, cdiQueryHelper),
          createApiResource(url, segmentDetailSearchProp.createdReportId, {...cdiQueryHelper, isSubmitted: false})
        ])
        .then(res => res)
        .then(data => {
          // track the initial obj
          // this is will help know whether to make db call when user clickes on save
          currentRef.current = data[1]["Items"][0] || {};
          //   Object.assign(currentRef.current, data[1]["Items"][0] || {});
          setReport(state => ({
            ...state,
            hide: !state.hide,
            submitted: data[0]["Items"][0] || {},
            current: data[1]["Items"][0] || {}
          }));
        });
    } else {
      setReport(state => {
        return { ...state, hide: !state.hide };
      });
    }
  };

  const handleChange = e => {
    setReport(state => {
      return {
        ...state,
        current: { ...state.current, [e.target.name]: e.target.value }
      };
    });
    onInputChange(e, currentRef.current);
  };

  return {
    report,
    handleHeaderClick,
    handleChange
  };
};

function createApiResource(url, id, cdiHelper) {
  let queryStr = `stateReportId=${id}`;

  if(cdiHelper.isCdi) {
    if(!cdiHelper.isSubmitted) return api.requestmethods.get(`${url}`);
    queryStr += `&rxNumber=${cdiHelper.rxNumber}`;
  }

  return api.requestmethods.get(
    url,
    queryStr
  );
}

function getSegmentDetailsUrl(segmentName, rxNumber) {
  let url;
  switch (segmentName) {
    case report_segments_shortName.pat:
      url = `api/StateReportedit/${rxNumber}/GetPatDetails`;
      break;
    case report_segments_shortName.dsp:
      url = `api/StateReportedit/${rxNumber}/GetDspDetails`;
      break;
    case report_segments_shortName.pre:
      url = `api/StateReportedit/${rxNumber}/GetPreDetails`;
      break;
    case report_segments_shortName.cdi:
      url = `api/StateReportedit/${rxNumber}/GetCdiDetails`;
      break;
    case report_segments_shortName.air:
      url = `api/StateReportedit/${rxNumber}/GetAirDetails`;
      break;
  }
  return url;
}
