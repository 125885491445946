import { createSelector } from "reselect";
import { IAppState } from "../AppState";

export const getMenuBarStatus = createSelector(
  (state: IAppState)  => state.auth.IsAuthenticated,
  (state: IAppState) => state.fw.IsHorizontalMenuBar,
  (IsAuthenticated, IsHorizontalMenuBar) => ({
    isAuthenticated: IsAuthenticated,
    isHorizontal: IsHorizontalMenuBar
  })
);
