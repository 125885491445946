import React, { useState, useEffect, useMemo } from "react";

import { Fieldset } from "primereact/fieldset";
import { Tree } from "primereact/tree";
import AwButton from "../../../../components/button/AwButton";

import { getAsapBasicStructure } from "./AsapDef";
import { AddAsapFieldNoteForm } from "./AddAsapFieldNoteForm";
import { Alert } from "../../../../components/message";
import {
  AwDModal,
  AwTextArea,
  useFormRef,
  useAwToastR
} from "../../../../components";
import api from "../../../../store/api";
import { DisplayAsapNote } from "./DisplayAsapNote";
import "./AsapPageStyles.css";
import { isResponseError } from "../../../../utils";
import { httpErrorMsg, formGenericErrorMsg } from "../../../../defs";

export const AsapStandards = React.memo(({ stateProvinceId }) => {
  const base_url = `api/Asap`;
  const btnLabel = "Add";
  let asapBasicStructure = useMemo(() => getAsapBasicStructure(), []);
  const [asapStandards, setAsapStandards] = useState(asapBasicStructure);
  const [edit, setEdit] = useState({ isEdit: false, btn: btnLabel });
  const [noteRef, setNote] = useFormRef();
  const [selectedAsapRule, setSelectedAsapRule] = useState({});
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({
    errorMsg: formGenericErrorMsg,
    isError: false
  });

  const msgSvc = useAwToastR();

  const handleNodeSelection = e => {
    if (e.node.isField) {
      setSelectedAsapRule(e.node.asapRule);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const res = await api.requestmethods.get(
        `${base_url}?stateProvinceId=${stateProvinceId}`
      );
      setAsapStandards(getFolderStructure(asapStandards, res.Items));
    };
    getData();
    return () => {};
  }, []);

  const handleFormSubmit = async e => {
    if (edit.isEdit) {
      const data = Object.assign({}, selectedAsapRule, noteRef);
      const res = await api.requestmethods.put(base_url, data);
      setSelectedAsapRule(res);
      handleHttpResponse(res);
    } else {
      if (!formData.Note) {
        handleFormError("Please complete the form or cancel the modal.", true);
      } else {
        handleFormError("", false);
        formData["StateProvinceId"] = stateProvinceId;
        formData["CreatedDate"] = new Date();
        const res = await api.requestmethods.post(base_url, formData);
        handleHttpResponse(res);
      }
    }
  };
  const handleFormError = (msg, bool = false) => {
    setFormError({ errorMsg: msg, isError: bool });
  };

  const handleHttpResponse = res => {
    const isResError = isResponseError(res);
    if (isResError) {
      // set error
      handleFormError(httpErrorMsg, true);
    } else {
      if (edit.isEdit) {
        setAsapStandards(editOrDeleteFieldInFolder(asapStandards, res));
      } else {
        setAsapStandards(getFolderStructure(asapStandards, [res]));
      }
      handleModalClose();
      msgSvc.showSuccess("updated");
    }
  };

  const handleModalClose = e => {
    modalRef.current.close();
  };

  const handleModalOpen = e => {
    modalRef.current.open();
  };
  const handleCreateNewAsap = e => {
    setEdit({ isEdit: false, btn: btnLabel });
    handleModalOpen();
  };

  const handleNoteInputChange = data => {
    setFormData(data);
  };

  const handleEditNote = data => {
    setEdit({ isEdit: true, btn: "Save" });
    handleModalOpen();
  };

  const handleDeleteNote = async data => {
    await api.requestmethods.delete(
      `${base_url}/${data.AsapRuleId}`,
      `stateProvinceId=${data.StateProvinceId}`
    );
    setAsapStandards(editOrDeleteFieldInFolder(asapStandards, data, true));
    setSelectedAsapRule({});
    handleModalClose();
    msgSvc.showSuccess("deleted");
  };

  const modalRef = React.useRef();
  return (
    <div>
      <Fieldset legend="ASAP Versions" style={{ position: "relative" }}>
        {/* <div
          style={{ position: "absolute", right: "36px", top: "11px" }}
          tooltip="Add Asap Notes"
        > */}
        <div style={{
          position: "absolute",
          right: "10px",
          width: "35px",
          height: "35px",
          zIndex: "1"}}
          tooltip="Add Asap Notes"
          >
            <AwButton icon="plus" btnClicked={handleCreateNewAsap} />
        </div>
          
        {/* </div> */}
        <div className="row">
          <div className="col-lg-5" style={{ marginBottom: "2em" }}>
            <Tree
              selectionMode="single"
              value={asapStandards}
              onSelect={handleNodeSelection}
            />
          </div>
          <div className="col-lg-7">
            {Object.keys(selectedAsapRule).length > 0 && (
              <DisplayAsapNote
                asap={selectedAsapRule}
                editNote={handleEditNote}
                deleteNote={handleDeleteNote}
              />
            )}
          </div>
        </div>
      </Fieldset>
      <AwDModal
        ref={modalRef}
        title={`${edit.btn} Field Note`}
        onSubmit={handleFormSubmit}
        onModalClose={handleModalClose}
        submitBtnLabel={edit.btn}
      >
        {formError.isError && (
          <Alert message={formError.errorMsg} align="center" />
        )}
        {edit.isEdit ? (
          getEditNoteForm(selectedAsapRule.Note, setNote)
        ) : (
          <AddAsapFieldNoteForm onNoteInputChange={handleNoteInputChange} />
        )}
      </AwDModal>
    </div>
  );
});

const getEditNoteForm = (note, saveFunc) => {
  return <AwTextArea init={note} onChange={saveFunc} name={"Note"} />;
};

const getFolderStructure = (folder, dataItems) => {
  //Folder with standard and segments
  return setupFolder(folder, dataItems);
};

const setupFolder = (folder, dataArray) => {
  dataArray.forEach(field => {
    folder = addFieldToFolder(folder, field);
  });
  return folder;
};

const addFieldToFolder = (folder, field) => {
  for (let i = 0; i < folder.length; i++) {
    if (folder[i].label === field.AsapFormat) {
      // standard check
      for (let j = 0; j < folder[i].children.length; j++) {
        if (folder[i].children[j].label === field.AsapSegment) {
          // segment check

          if (folder[i].children[j].children.length) {
            // field check and exist, hence sort
            // push to folder
            const nField = modifyFieldToFolder(field);

            folder[i].children[j].children = sortFolderFields(
              folder[i].children[j].children,
              nField
            );
          } else {
            // no field exist yet so push to folder
            folder[i].children[j].children.push(modifyFieldToFolder(field));
          }
          //return folder
          return folder;
        }
      }
    }
  }
  return folder;
};
const sortFolderFields = (children, field) => {
  children.push(field);
  children.sort((a, b) => {
    if (a.sortValue < b.sortValue) {
      return -1;
    } else if (a.sortValue > b.sortValue) {
      return 1;
    } else {
      return 0;
    }
  });
  return [].concat(children);
};

const getSortValue = fieldName => parseInt(fieldName.slice(3, 5), 10);

const modifyFieldToFolder = item => {
  return {
    label: item.AsapSegmentField,
    key: item.AsapRuleId,
    // parent: `${item.AsapFormat} ${item.AsapSegment}`,
    sortValue: getSortValue(item.AsapSegmentField),
    parent: `${item.AsapSegment}`,
    asapRule: item,
    isField: true
  };
};

const editOrDeleteFieldInFolder = (folder, field, isDelete = false) => {
  for (let i = 0; i < folder.length; i++) {
    if (folder[i].label === field.AsapFormat) {
      // standard check
      for (let j = 0; j < folder[i].children.length; j++) {
        if (folder[i].children[j].label === field.AsapSegment) {
          // segment check

          if (folder[i].children[j].children.length) {
            // field check and exist

            if (isDelete) {
              // delete the field obj with a key = AsapRuleId - delete
              folder[i].children[j].children = folder[i].children[
                j
              ].children.filter(chn => chn.key !== field.AsapRuleId);
            } else {
              // replace the asapRule on the field obj - edit
              folder[i].children[j].children = editStandardFields(
                folder[i].children[j].children,
                field
              );
            }
          }
          //return folder
          return folder;
        }
      }
    }
  }
  return folder;
};

const editStandardFields = (children, field) => {
  for (let i = 0; i < children.length; i++) {
    if (children[i].key === field.AsapRuleId) {
      children[i].asapRule = field;
      return children;
    }
  }
  return children;
};
