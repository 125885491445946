import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isResponseError, getPagedQS } from '../../../../../utils';
import {IDoCResProps} from '../IDocInterfaces';

interface Props {
    navProps: IDoCResProps;
    getMoreDocAsync: (query: string) => Promise<any>,
    showNav: boolean;
    children?: any;
}

export const DoCCardNav: React.FC<Props> = ({navProps, getMoreDocAsync, showNav, ...props}) => {
    const [{PageNumber, PageSize, TotalCount, Start, End, Items}, setPageState] = useState({
        PageNumber: navProps.PageNumber, 
        PageSize: navProps.PageSize, 
        TotalCount: navProps.TotalCount, 
        Start: 1, 
        End: 50,
        Items: navProps.Items
    });

    
    const maxPage = Math.ceil(TotalCount/PageSize);

    
    const handleNavClickedAsync = async (page: number) => {
        const pNumber: number = (PageNumber+page);
        const res: IDoCResProps = await getMoreDocAsync(getPagedQS(pNumber, PageSize));
        
        let success = !isResponseError(res);
        if(success) {
            const start = ((PageSize * (pNumber - 1)) + 1);
            const end = (pNumber === maxPage) ? res.TotalCount : (pNumber * PageSize);
            setPageState(state => ({...state, Start: start, End: end}));
        } 
    }


    useEffect(() => {
        const itemCount = navProps.Items ? navProps.Items.length : 0;
        setPageState(state => ({...state, 
            PageNumber: navProps.PageNumber, 
            PageSize: navProps.PageSize,
            TotalCount: navProps.TotalCount,
            End: itemCount,
            Items: itemCount
        }))
    }, [navProps])

    useEffect(() => {
        const start = ((PageSize * (PageNumber - 1)) + 1);
        const end = (PageNumber === maxPage) ? TotalCount : (PageNumber * PageSize);
        setPageState(state => ({...state, Start: start, End: end}));
    }, [showNav])

    return (
        <div className="doccard-nav">
            {showNav && <div style={{display: "flex",
    justifyContent: "flex-end",
    padding: "0 1rem 1rem"}}> Showing <span style={{fontWeight: 700, marginRight: '0.5rem', marginLeft: '0.5rem'}}> {Start} - {End} </span> Items</div>}
          {props.children}
          {PageNumber && showNav && (
              <div className="doccard-nav-btns">
                {maxPage > 1 && (<div>{<button type="button" className="btn btn-primary" disabled={PageNumber<=1} onClick={() => handleNavClickedAsync(-1)}>
                  <FontAwesomeIcon icon="chevron-left" />
                </button>}
              <span>{PageNumber} of {maxPage}</span>
              {<button type="button" className="btn btn-primary" disabled={PageNumber===maxPage}  onClick={() => handleNavClickedAsync(1)}>
                  <FontAwesomeIcon icon="chevron-right" />
                </button>}</div>)}
                <span style={{marginRight: '0'}}>{Items} Items</span>
              </div>
          )}
        </div>
      )
}


