import React, { useState } from 'react'
import { PhoneNumberFormField } from './PhoneNumberFormField';
import { ReportErrorCheckBoxField } from './ReportErrorCheckBoxField';

interface Props {
    onChange: any;
    user: any;
}

export const SRMsgSetupForm: React.FC<Props> = ({user, onChange}) => {
    const [required, setRequired] = useState(user.CanReceiveReportText);

    const handleChecked = (e: any) => {
        const {checked} = e.target;
        e.target.value = checked;
        onChange(e);
        return checked;
    }
    const handlePhoneChecked = (e: any) => {
      const checked = handleChecked(e);
      setRequired(checked);
  }
    return (
      <form noValidate className="phone-alert-form">
        <ReportErrorCheckBoxField name="CanReceiveReportEmails" type="email" init={user.CanReceiveReportEmails} onCheck={handleChecked}/>
        <ReportErrorCheckBoxField init={user.CanReceiveReportText} onCheck={handlePhoneChecked}/>
        <PhoneNumberFormField init={user.PhoneNumber}  required={required} onChange={onChange}/>
      </form>
    )
}

  
  