import React, { useState, useRef } from "react";
import { AwLabel, AwIcon, AwInput, AwSelectBox } from "../../../../components";
import { AwTransFormStrArrToObjArr } from "../../AwTransFormStrArrToObjArr";
import { getNewEventObj } from "../../../../utils";

export const BookmarkCategoryFormField = ({
  options,
  dValue,
  onCategoryValueChange
}) => {
  const [type, setIsDropdown] = useState({ isDropdown: true, icon: "plus" });
  const [value, setValue] = useState({ input: dValue, dropdown: dValue });
  const optionsRef = useRef(options);

  const handleCatClicked = () => {
    let isSelect = type.icon === "plus";
    if (isSelect) {
      setAndSendValue(value.input);
    } else {
      setAndSendValue(value.dropdown);
    }
    setIsDropdown(state => ({
      isDropdown: !type.isDropdown,
      icon: isSelect ? "undo" : "plus"
    }));
  };

  const handleSelectValue = value => {
    setValue(state => ({ ...state, dropdown: value }));
    setAndSendValue(value);
  };

  const handleInputChange = e => {
    setValue(state => ({ ...state, input: e.target.value }));
    onCategoryValueChange(e);
  };

  const setAndSendValue = value => {
    const e = getNewEventObj("", value, "Category");
    onCategoryValueChange(e);
  };

  return (
    <div className="form-group">
      <div>
        <span style={{ display: "inline-block" }}>
          <AwLabel htmlFor="Category" label="Category" required={true} />
        </span>
        {!dValue && (
          <span style={{ float: "right" }}>
            <AwIcon iconName={type.icon} onIconClick={handleCatClicked} />
          </span>
        )}
      </div>
      {type.isDropdown ? (
        <AwTransFormStrArrToObjArr
          options={optionsRef.current.length > 0 ? optionsRef.current : options}
        >
          {(transformOption, key) => {
            return (
              <AwSelectBox
                array={transformOption}
                value={key}
                label={key}
                dValue={!!dValue ? dValue : value.dropdown}
                onSelectValueChange={handleSelectValue}
              />
            );
          }}
        </AwTransFormStrArrToObjArr>
      ) : (
        <AwInput
          name="Category"
          init={value.input}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
};

{
  /* <select>
          {optionsRef.current.map((opt, i) => (
            <option key={i}>{opt}</option>
          ))}
        </select> */
}
