import React, { useEffect, useContext } from "react";
import { CreateReport } from "./create-report";
import StateRulesBtn from "./StateRulesBtn";
import SearchReportByRx from "./search-by-rx/SearchReportByRx";
import api from "../../../store/api";
import { useSelector } from "react-redux";
import { ConfigContext } from "../../../AwAppContext";

export const TopLevelLogic = React.memo(() => {
  const pharmacyBaseUrl = `api/Pharmacies`;
  const { awConfig, setAwConfig } = useContext(ConfigContext);
  const statesettings = useSelector(state => state.settings.Settings);
  const fetchData = async () => {
    let pharmacy = await api.requestmethods.get(pharmacyBaseUrl);
    if (pharmacy.length === 2 && pharmacy[0]["Name"] === pharmacy[1]["Name"]) {
      pharmacy = pharmacy.filter(list => list.PharmacyId === 1);
    }
    setAwConfig({
      ...awConfig,
      statesettings: statesettings,
      pharmacies: pharmacy
    });
  };

  useEffect(() => {
    fetchData();
  }, []); // added dep to avoid console warnings

  return (
    <div className="dashboard-top-btns-wrapper">
      <div className="top-left-btns">
        <span className="btn-span">
          <CreateReport />
        </span>
        <span className="btn-span">
          <SearchReportByRx />
        </span>
      </div>

      <div className="top-right-btns">
        <span style={{ width: "110px" }}>
          <StateRulesBtn />
        </span>
      </div>
    </div>
  );
});
