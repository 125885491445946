import { combineReducers } from "redux";
import { authReducer, dashboardReducer, layoutReducer, settingsReducer, gridReducer } from "./reducers";

export const rootReducer = combineReducers({
  auth: authReducer,
  fw: layoutReducer,
  dashboard: dashboardReducer,
  settings: settingsReducer,
  grid: gridReducer
});
