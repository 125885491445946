import React, { useEffect } from "react";
import axios from "axios";
import { getRequestHeaders, getStateName, isResponseError } from "../../utils";

export const useAxios = (url, init) => {
  const isCurrent = React.useRef(true);
  const [state, setState] = React.useState({ data: init, loading: true });

  useEffect(() => {
    return () => {
      // called when component is going to unmount

      isCurrent.current = false;
    };
  }, []);

  useEffect(() => {
    setState(state => ({ data: state.data, loading: true }));
    const headers = getRequestHeaders();
    axios
      .get(url, { headers })
      .then(res => res.data)
      .then(data => {
        if (isCurrent.current) {
          if (Array.isArray(data)) {
            if (data.length > 0) {
              if (typeof data[0] === "string") {
                setState({ data: data, loading: false });
              } else {
                const str = "StateProvinceId";
                if (str in data[0] || str.toLowerCase() in data[0]) {
                  data.forEach(item => {
                    item["StateName"] = getStateName(item[str]);
                  });
                  setState({ data: data, loading: false });
                } else {
                  setState({ data: data, loading: false });
                }
              }
            } else {
              setState({ data: data, loading: false });
            }
          } else {
            setState({ data: data, loading: false });
          }
        }
      });
  }, [url, setState]);

  return state;
};


export const useAxiosFunc = (url, init) => {
  const isCurrent = React.useRef(true);
  const [state, setState] = React.useState({ data: init, loading: true });
  const [reload, setReload] = React.useState(1);

  const reLoadData = () => {
    setReload(state => reload + 1);
  }
  useEffect(() => {
    return () => {
      isCurrent.current = false;
    };
  }, []);

  useEffect(() => {
    setState(state => ({ data: state.data, loading: true }));
    const headers = getRequestHeaders();
    axios
      .get(url, { headers })
      .then(res => res.data)
      .then(data => {
        if (isCurrent.current) {
          if (Array.isArray(data)) {
            if (data.length > 0) {
              if (typeof data[0] === "string") {
                setState({ data: data, loading: false });
              } else {
                const str = "StateProvinceId";
                if (str in data[0] || str.toLowerCase() in data[0]) {
                  data.forEach(item => {
                    item["StateName"] = getStateName(item[str]);
                  });
                  setState({ data: data, loading: false });
                } else {
                  setState({ data: data, loading: false });
                }
              }
            } else {
              setState({ data: data, loading: false });
            }
          } else {
            setState({ data: data, loading: false });
          }
        }
      });
  }, [url, setState, reload]);

  return {res: state, reLoadData };
}
