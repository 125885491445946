import React, { useState } from "react";
import { Editor } from "primereact/editor";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import { getNewEventObj } from "../../utils";
import AwCheckBox from "../form-atoms/AwCheckBox";
import { AwLabel } from "../form-atoms";
import { SetStringAsHtml } from "../SetStringAsHtml";

export const AwCommentInputField = ({ dObj, name, onInputChange }) => {
  const [state, setState] = useState();
  const [show, setShow] = useState(false);
  const handleState = e => {
    const evt = getNewEventObj("", e.htmlValue, name);
    onInputChange(evt);
    setState(e.htmlValue);
  };

  const handleCheck = e => {
    if (!e.target.checked) {
      const evt = getNewEventObj("", "", name);
      onInputChange(evt);
    }
    setShow(e.target.checked);
  };

  const field = "Notes";
  return (
    <>
      {dObj[field] && (
        <div className="col-12" style={{ margin: "2em 0" }}>
          <AwLabel htmlFor={""} label={"Current Notes"} />
          <SetStringAsHtml value={dObj[field]} customClass="row" />
        </div>
      )}
      <div className="col-12">
        <div className="text-right">
          <AwCheckBox
            name="show"
            handleCheck={handleCheck}
            label="Do you want to add notes?"
          />
        </div>
        {show && (
          <AwFormFieldWrapper name={field} label={field}>
            <div>
              <Editor
                style={{ height: "320px" }}
                value={state}
                name={field}
                onTextChange={handleState}
              />
            </div>
          </AwFormFieldWrapper>
        )}
      </div>
    </>
  );
};

export default AwCommentInputField;

// { name: "Notes", label: "Notes", isTextArea: true }
