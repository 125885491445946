import React from "react";

import FwTitleBar from "./fwTitleBar/FwTitleBar";
import FwContent from "./fwContent/FwContent";
import FwFooter from "./fwFooter/FwFooter";
import "./FwLayout.css";

import { useCurrentUser } from "../custom-hooks";

const FwLayout = () => {
  const { user } = useCurrentUser();
  return (
    <div className="main-page">
      {user.IsAuthenticated ? <FwTitleBar /> : null}
      <FwContent />
      {user.IsAuthenticated ? <FwFooter /> : null}
    </div>
  );
};

export default FwLayout;

