export class StateReportUser {
    Token = '';
    UserName = '';
    Email = '';
    UserId = '';
    FirstName  = '';
    IsAuthenticated = false;
    Role = '';
    canAccessBasicFeatures = false;
    canAccessAdminPage = false;
    Profile = '';
    expires_in = 0
}