import React from "react";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import AwCheckBox from "../form-atoms/AwCheckBox";

const AwStringArrayCheckBoxFormField = React.memo(
  ({ strArr, name, label, handleCheck, required }) => {
    const handle = e => {
      // console.log(e);
    };
    return (
      <AwFormFieldWrapper
        name={name}
        label={label}
        required={required ? required : false}
      >
        {strArr.map(str => {
          return <AwCheckBox name={name} label={label} handleCheck={handle} />;
        })}
      </AwFormFieldWrapper>
    );
  }
);
export default AwStringArrayCheckBoxFormField;
