import React, { useState } from "react";
import { AwButton } from "../..";

const gridBtnLabel = {
  all: "All Files",
  manual: "Manual Files"
};
export const AwGridTopBtnLogic = ({
  table,
  deleteAll,
  submitAll,
  onReportTypeChanged,
  ...props
}) => {
  const [isCreate] = useState(!(table === "dashboard"));

  const btn = gridBtnLabel.manual;
  const ref = React.useRef(btn);

  const handleReportType = () => {
    let content = ref.current.innerHTML;
    let allreportvalue = true;
    if (content !== gridBtnLabel.all) {
      ref.current.innerHTML = gridBtnLabel.all;
      allreportvalue = false;
    } else {
      ref.current.innerHTML = gridBtnLabel.manual;
      allreportvalue = true;
    }
    onReportTypeChanged(allreportvalue);
  };

  // setValue(!value);
  return (
    <div data-test="awGridTopBtnLogic">
      <div
        data-test="awGridTopBtnLogic-inner"
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {!isCreate && (
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "left"
            }}
          >
            <AwButton
              width="70px"
              ref={ref}
              label={"Submit"}
              btnClicked={submitAll}
            />
            <span style={{ marginLeft: "10px", width: "70px" }}>
              <AwButton
                width="100%"
                ref={ref}
                label={"Delete"}
                type={"danger"}
                btnClicked={deleteAll}
              />
            </span>
          </span>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <span
            style={{
              width: "110px",
              display: "inline-block",
              textAlign: "right"
            }}
            className="btn-span"
          >
            {!isCreate && (
              <AwButton
                width="100%"
                ref={ref}
                label={btn}
                btnClicked={handleReportType}
              />
            )}
            {isCreate && props.children}
          </span>
        </div>
      </div>
    </div>
  );
};
