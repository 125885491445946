import React, { useEffect, useState } from "react";

export default function AwCheckBox({ name, label, handleCheck, init = false }) {
  const [checked, setChecked] = useState(init);

  const inputClicked = e => {
    setChecked(e.target.checked);
    e.target.value = e.target.checked;
    handleCheck(e);
  };
  const checkboxRef = React.useRef();
  
  useEffect(() => {
    if(init) {
      inputClicked({target: {name: name, checked: init }})
    }
  }, [])
  return (
    <div>
      <input
        name={name ? name : ""}
        ref={checkboxRef}
        type="checkbox"
        onChange={inputClicked}
        checked={checked}
      />{" "}
      {label ? label : ""}
    </div>
  );
}
