import React, { useState } from "react";
//import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import { AwModalTitle } from "../../../../../components/modal/AwModalTitle";
import { AwButton } from "../../../../../components";
import "./ReportEditModal.css";
export const ReportEditModal = React.forwardRef<any, any>((props, ref) => {
  const { onModalClose, onSubmit, submitBtnLabel, size, title } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const modal = {
    open: () => handleDisplay(true),
    close: () => handleDisplay(false),
    resetLoading: () => reset()
  };

  const reset = () => {
    setLoading(false);
  };
  const handleDisplay = (bool: boolean) => {
    reset();
    setShow(bool);
  };

  const handleSubmit = (e: any) => {
    setLoading(true);
    onSubmit(e);
  };

  (ref as any).current = modal;
  return (
    <>
      <Modal
        show={show}
        size={size}
        backdrop="static"
        onHide={onModalClose}
        className="report-edit-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <AwModalTitle>{title}</AwModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <AwButton
            width="6em"
            label="Close"
            type="secondary"
            disable={loading}
            btnClicked={onModalClose}
          />
          <AwButton
            loading={loading}
            width="6em"
            label={submitBtnLabel}
            type="default"
            btnClicked={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
});

ReportEditModal.defaultProps = {
  submitBtnLabel: "Save"
};
