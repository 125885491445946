// import moment from 'moment';
import moment from "moment-timezone";
import { initShortDate } from "./FuncUtil";
import api from "../store/api";

const defs = {
  reportingTimeZone: "America/Denver",
  status: [
    { Status: "Pending", StatusId: 1 },
    { Status: "Submitted", StatusId: 2 },
    { Status: "Accepted", StatusId: 3 },
    { Status: "Failed", StatusId: 4 },
    { Status: "sftp Upload", StatusId: 5 },
    { Status: "Web Upload", StatusId: 6 },
    { Status: "Zero Report sftp", StatusId: 7 },
    { Status: "Zero Report Web", StatusId: 8 },
    { Status: "Zero Report Entry", StatusId: 9 },
    { Status: "No Submit", StatusId: 10 },
    { Status: "Rejected", StatusId: 11 },
    { Status: "Web Reporting", StatusId: 12 }
  ]
};

export const strToIntArr = (str: string) => {
  const intArr = [];
  if (str.length) {
    const errArr = [];
    const errMsg = { type: "error", data: [], message: "" };
    let newStr = putCommasInRxSearchString(str);
    const strArr = newStr.split(",");
    for (let i = 0; i < strArr.length; i++) {
      let num = parseInt(strArr[i], 10);
      if (num.toString().length > 6) {
        intArr.push(num);
      } else {
        errArr.push(num);
      }
    }

    if (errArr.length > 0) {
      if (errArr.length > 1) {
        errArr.forEach(rx => {
          errMsg.message += `${rx}, `;
        });
        errMsg.message += ` - these numbers are less than the required 7 digits for prescriptions. `;
      } else {
        errMsg.message += `${errArr[0]} is less than the required 7 digits for prescriptions. `;
      }
      errMsg.message += "Please correct the errors to continue.";

      return errMsg;
    }
  }
  return { type: "data", data: intArr, msg: "" };
};

const putCommasInRxSearchString = (str: string) => {
  let newStr = str;
  let value = newStr.replace(/,/g, "");
  let retValue = value.replace(/\d{7}/g, match => `${match},`);
  if (retValue.endsWith(",")) {
    retValue = retValue.substring(0, retValue.length - 1);
  }
  return retValue;
};

export const endDateExclusive = (date: Date) => {
  return moment(date).add(1, "d");
};

export const reportDateUtc = (date: Date) => {
  const dateStr = moment(date).format("YYYY-MM-DD");
  // return moment.tz(dateStr, defs.reportingTimeZone).format();
  return moment
    .tz(dateStr, defs.reportingTimeZone)
    .utc()
    .format();
};

export const initReport = (report: any) => {
  // report.CreateDate = new Date(report.CreateDate);
  report.CreatedDate = initShortDate(report.CreatedDate);
  report.EndDate = new Date(report.EndDate);
  report.Selected = false;
  report.StartDate = new Date(report.StartDate);
  // report.StateSetting = stateSettings[report.StateProvinceId];
  const endStr = moment(report.EndDate)
    .subtract(1, "s")
    .tz(defs.reportingTimeZone)
    .format("MM/DD/YY");

  const startStr = moment(report.StartDate)
    .tz(defs.reportingTimeZone)
    .format("MM/DD/YY");

  if (startStr === endStr) {
    report.DateRangeString = startStr;
  } else {
    report.DateRangeString = startStr + " - " + endStr;
  }

  if (report.StatusId === defs.status[report.StatusId - 1].StatusId) {
    report.ActionRequired = true;
  }

  return report;
};

// searching for reports for edit
// export const searchForReport = async (rxArray: any[]) => {
//   const base_url = "api/statereportedit";
//   let output: any[] = [];
//   return Promise.all(
//     rxArray.map(rxNumber => api.requestmethods.get(`${base_url}/${rxNumber}`))
//   ).then(res => {
//     res.forEach(obj => {
//       output.push(groupRxSeachResult(obj.Items));
//     });
//     return output;
//   });
// };

export const searchForReport = async (rxArray: any[]) => {
  const base_url = "api/statereportedit";
  return Promise.all(
    rxArray.map(rxNumber => api.requestmethods.get(`${base_url}/${rxNumber}`))
  ).then(res => res);
};

export const groupRxSeachResult = (itemsArr: any[]) => {
  const newArr = [];
  const details: any = {};
  let i, j, currentObject;
  for (i = 0, j = itemsArr.length; i < j; i++) {
    currentObject = itemsArr[i];
    if (!(currentObject.RxNumber in details)) {
      details[currentObject.RxNumber] = {
        RxNumber: currentObject.RxNumber,
        StateReportId: currentObject.StateReportId,
        PatienId: currentObject.PatienId,
        PatientName:
          currentObject.PatientFirstName + " " + currentObject.PatientLastName,
        Details: []
      };
      newArr.push(details[currentObject.RxNumber]);
    }
    details[currentObject.RxNumber].Details.push(currentObject);
  }
  return newArr;
};
