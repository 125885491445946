import React from "react";
import { initShortDate } from "../../../utils";

export const DateCol = props => {
  return (
    <td>
      <div>{initShortDate(props.dataItem[props.fieldName])}</div>
    </td>
  );
};

export default DateCol;
