import React, { useEffect, useState } from 'react';
import { AwDModal, useModalFunctions } from '../../../../../components';
import { getObjectKeyLength } from '../../../../../utils';

export const KeywordStateModal = (props) => {
    const { modalRef, showModal, hideModal, resetLoading } = useModalFunctions();
    const [title, setTitle] = useState("");


    useEffect(() => {
        props.getModalFxns({modalRef, showModal, hideModal, resetLoading});
    }, [])

    useEffect(() => {
        if(getObjectKeyLength(props.keyword.SRKeyword)) {
            setTitle(props.keyword.SRKeyword.toUpperCase());
        }
    }, [props.keyword]);

    return (
        <AwDModal
            ref={modalRef}
            onModalClose={props.handlehideModal}
            onSubmit={props.handleSubmitClicked}
            title={title}
            hideFooter={true}
        >
            {props.children}
        </AwDModal>
    )
}
