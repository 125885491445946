import { useState } from "react";

export const useSetFileValue = () => {
  const [isBigFile, setState] = useState(false);
  const [fileEvt, setFile] = useState(null);
  const msg = "File cannot be more than 20mb!";
  const handleChange = e => {
    const file = e.target.files;
    if (file.item(0).size < 20971520) {
      setState(false);
      setFile({ target: { value: file.item(0), name: e.target.name } });
    } else {
      setState(true);
    }
  };

  return { isBigFile, msg, fileEvt, handleChange };
};
