import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
export const AwPrimeReactDialog = React.forwardRef((props, ref) => {
  const { header } = props;
  const [visible, setVisible] = useState(false);
  const onShow = () => {
    setVisible(true);
  };
  const onHide = () => {
    setVisible(false);
  };
  ref.current = { show: onShow, hide: onHide };
  return (
    <Dialog
      header={header}
      visible={visible}
      style={{ width: "50vw" }}
      modal={true}
      maximizable={true}
      onHide={onHide}
      className={`${props.className ? props.className : ''}`}
    >
      {props.children}
    </Dialog>
  );
});

export default AwPrimeReactDialog;
