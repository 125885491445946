const adminPrefix = "/admin";
export const APP_URLS = {
    registration: {
        base: '/', 
        signin: '/signin', 
        emailverification: '/emailverification', 
        confirmphonenumber: '/confirmphonenumber', 
        createdpassword: '/createpassword'
    },
    dashboard: "/dashboard",
    reports: "/reports",
    orders: "/reporting",
    states: "/states",
    rules: "/rules",
    pharmacy: '/pharmacy',
    directory: '/directory',
    admin: {
        base: adminPrefix,
        users: `${adminPrefix}/users`,
        roles: `${adminPrefix}/roles`
    },
    settings: "/settings",
    notAuthorized: '/notauthorized',
    pageNotfound: '/404',
    wildCard: "/*"
}

export function GetAllAppUrls() {
    const output = []
    var keys = Object.keys(APP_URLS);
    for(let i = 0; i < keys.length; i++) {
      const initKey = keys[i]
      if(typeof APP_URLS[initKey] === 'string') {
        output.push(APP_URLS[initKey]);
        continue;
      };
        const innerKeys = Object.keys(APP_URLS[initKey])
        for(let j = 0; j < innerKeys.length; j++) {
          const innerKey = innerKeys[j];
          output.push(APP_URLS[initKey][innerKey]);
        }
    }
    return output;
}
