import React, { forwardRef } from "react";

import { Toast } from "primereact/toast";
export interface IGrowlFuncs {
  showSuccess: (detail: string, replace?: boolean) => any;
  showInfo: (detail?: string) => any;
  showWarn: (detail?: string) => any;
  showError: (detail: string, replace?: boolean) => any;

}

export const AwPNGGrowl = forwardRef((props, ref: any) => {
  let _growl: Toast | any;
  const seconds = 10000;
  ref.current = {
    showSuccess: (detail: string, replace = false) =>
      _growl.show({
        life: seconds,
        severity: "success",
        summary: null,
        detail: replace ? detail : `The record has been ${detail} successfully.`
      }),
    showInfo: (detail = "submitted") =>
      _growl.show({
        life: seconds,
        severity: "info",
        summary: null,
        detail: detail
      }),
    showWarn: (detail = "submitted") =>
      _growl.show({
        life: seconds,
        severity: "warn",
        summary: null,
        detail: detail
      }),
    showError: (detail: string, replace = false) =>
      _growl.show({
        life: seconds,
        severity: "error",
        summary: null,
        detail: replace
          ? detail
          : `The record couldn't be ${detail}. Please try again later.`
      })
  };
  return (
    <div>
      <Toast ref={el => (_growl = el)} baseZIndex={1000000000000} />
    </div>
  );
});

// export default AwPNGGrowl;
