import React, { useState, createContext } from "react";
export const GridContext = createContext(false);

export const AwPageContainer = React.memo(props => {
  const [value, setValue] = useState(false);
  return (
    <GridContext.Provider value={{ value, setValue }}>
      <div className="aw-page-container" style={{ width: "100%" }}>
        {props.children}
      </div>
    </GridContext.Provider>
  );
});
