import React from "react";
import PropTypes from "prop-types";

import { AwSelectBox } from "../form-atoms";
import { getNewEventObj } from "../../utils";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";

export const AwFormSelectField = React.memo(
  ({
    options,
    optionsLabel,
    name,
    dValue,
    value,
    dropdownText,
    onSelectChange,
    required
  }) => {
    const handleSelectValue = e => {
      const newEvent = getNewEventObj(dValue, e, name);
      onSelectChange(newEvent);
    };

    return (
      <AwFormFieldWrapper
        name={name}
        label={optionsLabel}
        required={required ? required : false}
      >
        <AwSelectBox
          array={options}
          value={value}
          label={dropdownText}
          dValue={dValue}
          onSelectValueChange={handleSelectValue}
        />
      </AwFormFieldWrapper>
    );
  }
);

AwFormSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  dValue: PropTypes.any,
  value: PropTypes.string,
  dropdownText: PropTypes.string
};

AwFormSelectField.defaultProps = {
  value: "value",
  dropdownText: "key",
  dValue: ""
};
