import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "../../../../../third-party-lib";
// import profilepic from "../../../../../assets/images/medquest-building.jpg";
import { connect } from "react-redux";
import DropDownNav from "../../../mainNavigation/DropDownNav";
import { ProfileImageMneuItems } from "../fwMenus/menuList";

import "./FwTopBar.css";
const topBarStyles = {
  height: "30px"
};
const divStyles = {
  position: "relative",
  textAlign: "right",
  paddingTop: "8px"
};
const nameStyles = {
  position: "absolute",
  right: "40px",
  top: "10px"
};
const avatarStyles = {
  display: "inline-block",
  height: "27px",
  width: "27px",
  fontSize: "1rem"
};
export function FwTopBar({ userName, email, image }) {
  const getTnitials = () => {
    if(!email) return 'DU'; // Default User
    const dottedValues = email.split('.');
    if(dottedValues.length < 3) {
      return `${email[0]}${email[1]}`;
    }
    return `${dottedValues[0][0]}${dottedValues[1][0]}`;
  } 
  return (
    <div style={topBarStyles} className="topBar">
      <div style={divStyles}>
        <span style={nameStyles}>{userName}</span>
        <div className="topBar-image-div">
          <DropDownNav style={avatarStyles} menus={ProfileImageMneuItems}>
            <div className="drop-down-icon-div">
              {/* <Avatar alt="image" src={image} style={avatarStyles} className="topbar-avatar-img"/> */}

              {image ? (
                <Avatar alt="image" src={image} style={avatarStyles} className="topbar-avatar-img"/>
                ) : (
                  <div className="initials">
                    {getTnitials()}
                  </div>
                )
              }
            </div>
          </DropDownNav>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userName: state.auth.CurrentUser.UserName,
    email: state.auth.CurrentUser.Email,
    image: state.auth.CurrentUser.Profile
  };
};

FwTopBar.propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default connect(mapStateToProps)(FwTopBar);
