import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextArea } from "semantic-ui-react";
import { getNewEventObj } from "../../utils";

export default function AwTextArea({ init, type, name, onChange }) {
  const [value, setValue] = useState(init);
  const handleChange = e => {
    setValue(e.target.value);
    const newEvent = getNewEventObj(init, e.target.value, name);
    onChange(newEvent);
  };
  
  return (
    <TextArea
      name={name}
      type={type}
      value={value}
      onChange={handleChange}
      style={{ height: "auto", width: "100%" }}
    />
  );
}

{
  /* <textarea name={name} type={type} value={value} onChange={handleChange}></textarea> */
}

AwTextArea.propTypes = {
  init: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

AwTextArea.defaultProps = {
  init: "",
  type: "text"
};
