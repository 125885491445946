import isEmail from "validator/lib/isEmail";
import { getDigits } from "./FuncUtil";
interface IDataToValidate {
  Email?: string;
  Password?: string;
}
interface IValidateError {
  [x: string]: string;
}
function getInvalidEmailMsg(type: string) {
  return `Email/${type.replace(/^\w/, c => c.toUpperCase())} is invalid`;
}

function getPasswordEmptyMsg(type: string) {
  return `${type.replace(/^\w/, c => c.toUpperCase())} cannot be blank`;
}

export const validateAllFields = (data: any): string => {
  // const errors:IValidateError = {};
  let errStr = "";
  let counter = 0;
  let valueCounter = 0;
  const keys = !!data ? Object.keys(data) : [];
  let userNameGivenButNotValid = false;
  keys.forEach(prop => {
    counter++;
    const type = prop.toLowerCase();
    if (type === "username" || type === "email") {
      if (!data[prop]) {
        valueCounter++;
        errStr += getPasswordEmptyMsg(type);
      } else if (!!data[prop] && !isEmail(data[prop])) {
        valueCounter++;
        userNameGivenButNotValid = true;
        if (
          errStr.length > 1 &&
          counter === keys.length &&
          valueCounter > keys.length - 1
        ) {
          errStr += ` and ${getInvalidEmailMsg(type)}`;
        } else {
          errStr += getInvalidEmailMsg(type);
        }
      }
    } else {
      if (!data[prop]) {
        valueCounter++;
        if (
          errStr.length > 1 &&
          counter === keys.length &&
          valueCounter > keys.length - 1
        ) {
          errStr += ` and ${getPasswordEmptyMsg(type)}`;
        } else {
          errStr += getPasswordEmptyMsg(type);
        }
      }
    }

    if (errStr.length > 1 && counter < keys.length - 1) {
      errStr += ", ";
    } else if (errStr.length > 1 && counter === keys.length) {
      errStr += "!";
    }
  });

  return valueCounter === keys.length && userNameGivenButNotValid === false
    ? "All Fields Are Required!"
    : errStr;
  // const type = fieldType.toLowerCase()
  // switch(type){
  //     case 'email':
  //         if(!isEmail(data.Email)) errors[type] = `${type.replace(/^\w/, c => c.toUpperCase())} is invalid!`;
  //         break;
  //     case 'password':
  //         if(!data.Password) errors[type] = `${type.replace(/^\w/, c => c.toUpperCase())} can't be blank!`
  //         break;
  // }
  // return errors;
};

export const checkNewPasswordValidity = (
  password: string
): boolean | string => {
  let message: any = `Password must have at least
  <ul style="margin-left: 15px;">
      <li> 6 characters</li>
      <li>One number (0-9)</li>
      <li>One lowercase letter & One uppercase letter</li>
      <li>One specicial character '!@#$%^&*?_~'</li>
  </ul>
`;
  if (password.length < 6) {
    // message = 'Password must contain at least six characters!';
    return message;
  }

  let re = /[0-9]/;
  if (!re.test(password)) {
    return message;
  }
  re = /[a-z]/;
  if (!re.test(password)) {
    return message;
  }
  re = /[A-Z]/;
  if (!re.test(password)) {
    return message;
  }

  re = /[!@#$%^&*?_~]/g;
  if (!re.test(password)) {
    return message;
  }

  message = true;
  return message;
};

const checkforPassword = (givenValue: any) => {
  const data = ["Password", "ConfirmPassword"];
  for (let i = 0; i < data.length; i++) {
    if (!givenValue[data[i]]) {
      return false;
    }
  }

  return true;
};

export const checkIfStrIsPasswordRules = (str: string) => {
  return str.split("ul").length > 1;
};

export const validatePasswordFormValues = (values: { [x: string]: any }) => {
  let hasValue = checkforPassword(values);
  if (hasValue) {
    if (values.Password === values.ConfirmPassword) {
      const isValid = checkNewPasswordValidity(values.Password);
      if (typeof isValid === "boolean") {
        return null;
      } else {
        return isValid;
      }
    } else {
      return "Passwords don't match.";
    }
  } else {
    return "All fields are required.";
  }
};

export const isAllRequiredFieldsAnswered = (
  arr: string[] = [],
  data: any = {}
) => {
  for (let i = 0; i < arr.length; i++) {
    const value = data[arr[i]];
    if (!value) {
      return false;
    }
  }
  return true;
};

export const validURL = (str: string) => {
  if (str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  return false;
};

export const isValidEmail = (str: string = "") => {
  if (isEmail(str)) {
    return true;
  }
  return false;
};

export const isValidPhoneLength = (obj: any = {}, keys: string[]) => {
  for (let i = 0; i < keys.length; i++) {
    const digits = getDigits(obj[keys[i]]);
    if (digits.length !== 10) {
      return false;
    }
  }
  return true;
};
