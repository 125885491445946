import { states } from "./CoreDefs";

interface IReportStatus {
  Status: string;
  StatusId: number;
}

export interface IDropdown {
  key: string;
  value: number;
}

export interface ISegmentField {
  key: string;
  value: string;
  type?: any;
}

export const reportStatus: IReportStatus[] = [
  { Status: "Pending", StatusId: 1 },
  { Status: "Submitted", StatusId: 2 },
  { Status: "Accepted", StatusId: 3 },
  { Status: "Failed", StatusId: 4 },
  { Status: "sftp Upload", StatusId: 5 },
  { Status: "Web Upload", StatusId: 6 },
  { Status: "Zero Report sftp", StatusId: 7 },
  { Status: "Zero Report Web", StatusId: 8 },
  { Status: "Zero Report Entry", StatusId: 9 },
  { Status: "No Submit", StatusId: 10 },
  { Status: "Rejected", StatusId: 11 },
  { Status: "Web Reporting", StatusId: 12 }
];

export const reportingMethod = [
  { key: "APPRISS", value: 1 },
  { key: "Atlantic Associates", value: 2 },
  { key: "HID", value: 3 },
  { key: "Otech", value: 4 },
  { key: "State sFtp Auto Post", value: 5 },
  { key: "Not Reporting", value: 6 }
];

export const frequency: IDropdown[] = [
  { key: "Daily", value: 1 },
  { key: "Weekly", value: 2 },
  { key: "Monthly", value: 3 },
  { key: "No Submit", value: 4 },
  { key: "RealTime", value: 5 }
];

export const fileFormat: IDropdown[] = [
  { key: "ASAP 5.0", value: 9 },
  { key: "ASAP 4.2B", value: 8 },
  { key: "ASAP 4.2A", value: 7 },
  { key: "ASAP 4.2", value: 6 },
  { key: "ASAP 4.1", value: 5 },
  { key: "ASAP 4.0", value: 4 },
  { key: "ASAP 3.0", value: 3 },
  { key: "ASAP 95", value: 2 },
  { key: "Puerto Rico", value: 1 }
];

export const asapSegments: IDropdown[] = [
  { key: "PAT", value: 1 },
  { key: "DSP", value: 2 },
  { key: "PRE", value: 3 },
  { key: "CDI", value: 4 },
  { key: "AIR", value: 5 }
];

export const zeroProcess: IDropdown[] = [
  { key: "sftp Auto Post", value: 1 },
  { key: "sftp Manual Upload", value: 2 },
  { key: "Web Portal Reporting", value: 3 },
  { key: "Web Portal Upload", value: 4 },
  { key: "No Submit", value: 5 }
];

export const autoPostType: IDropdown[] = [
  { key: "None", value: 1 },
  { key: "sftp Auto Post", value: 2 },
  { key: "IStop", value: 3 },
  { key: "sftp Manual Upload", value: 4 },
  { key: "Web Portal Upload", value: 5 },
  { key: "Web Portal Reporting", value: 6 },
  { key: "Web Service", value: 7 }
];

export const fileCompression: IDropdown[] = [
  { key: "none", value: 1 },
  { key: "zip", value: 2 }
];

export const rxGroup: IDropdown[] = [
  { key: "All", value: 1 },
  { key: "ControlledOnly", value: 2 }
];

export const report_segments_shortName = {
  pat: "PAT",
  dsp: "DSP",
  pre: "PRE",
  cdi: "CDI",
  air: "AIR"
};

interface ISeg {
  PAT: ISegmentField[];
  [x: string]: ISegmentField[];
}

export const REPORT_SEGMENTS: ISeg = {
  PAT: [
    {
      key: "PAT01 - ID Qualifier of Patient Identifier",
      value: "PatientIdQualifierIdentifier"
    },
    { key: "PAT02 - ID Qualifier", value: "PatientIdQualifier" },
    { key: "PAT03 - ID of Patient", value: "PatientId" },
    {
      key: "PAT04 - ID Qualifier of Additional Patient Identifier",
      value: "AdditionalPatientIdQualifierIdentifier"
    },
    {
      key: "PAT05 - Additional Patient ID Qualifier",
      value: "AddtionalPatientIdQualifier"
    },
    { key: "PAT06 - Additional ID", value: "AdditionalPatientId" },
    { key: "PAT07 - Last Name", value: "LastName" },
    { key: "PAT08 - First Name", value: "FirstName" },
    { key: "PAT09 - Middle Name", value: "MiddleName" },
    { key: "PAT10 - Name Prefix", value: "NamePrefix" },
    { key: "PAT11 - Name Suffix", value: "NameSuffix" },
    { key: "PAT12 - Address Information", value: "Address1" },
    { key: "PAT13 - Address Information", value: "Address2" },
    { key: "PAT14 - City Address", value: "City" },
    { key: "PAT15 - State Address", value: "State" },
    { key: "PAT16 - ZIP Code Address", value: "PostalCode" },
    { key: "PAT17 - Phone Number", value: "Phone" },
    { key: "PAT18 - Date of Birth", value: "BirthDate", type: "date" },
    { key: "PAT19 - Gender Code", value: "GenderCode" },
    { key: "PAT20 - Species Code", value: "SpeciesCode" },
    { key: "PAT21 - Patient Location Code", value: "PatientLocationCode" },
    {
      key: "PAT22 - Country of Non-U.S. Resident",
      value: "NonUsResidentCountry"
    },
    { key: "PAT23 - Name of Animal", value: "NameOfAnimal" }
  ],
  DSP: [
    { key: "DSP01 - Reporting Status", value: "ReportingStatus" },
    { key: "DSP02 - Prescription Number", value: "RxNumber" },
    { key: "DSP03 - Date Written", value: "DateWritten", type: "date" },
    { key: "DSP04 - Refills Authorized", value: "RefillsAuthorized" },
    { key: "DSP05 - Date Filled", value: "DateFilled", type: "date" },
    { key: "DSP06 - Refill Number", value: "RefillNumber" },
    { key: "DSP07 - Product ID Qualifier", value: "ProductIdQualifier" },
    { key: "DSP08 - Product ID", value: "ProductId" },
    { key: "DSP09 - Quantity Dispensed", value: "QuantityDispensed" },
    { key: "DSP10 - Days Supply", value: "DaysSupply" },
    { key: "DSP11 - Drug Dosage Units Code", value: "DrugDosageUnitsCode" },
    {
      key: "DSP12 - Transmission Form of Rx Origin Code",
      value: "TransmissionFormOfRxOriginCode"
    },
    { key: "DSP13 - Partial Fill Indicator", value: "PartialFillIndicator" },
    {
      key: "DSP14 - Pharmacist National Provider Identifier (NPI)",
      value: "PharmacistNationalProviderId"
    },
    {
      key: "DSP15 - Pharmacist State License Number",
      value: "PharmacistStateLicenseNumber"
    },
    {
      key: "DSP16 - Classification Code for Payment Type",
      value: "PaymentTypeClassificationCode"
    },
    { key: "DSP17 - Date Sold", value: "DateSold", type: "date" },
    {
      key: "DSP18 - RxNorm Product Qualifier",
      value: "RxNormProductQualifier"
    },
    { key: "DSP19 - RxNorm Code", value: "RxNormCode" },
    {
      key: "DSP20 - Electronic Prescription Reference Number",
      value: "ElectronicRxReferenceNumber"
    },
    {
      key: "DSP21 - Electronic Prescription Order Number",
      value: "ElectronicRxOrderNumber"
    },
    { key: "DSP22 - Quantity Prescribed", value: "QuantityPrescribed" },
    { key: "DSP23 - Rx SIG", value: "Sig" },
    { key: "DSP24 - Treatment Type", value: "TreatmentType" },
    { key: "DSP25 - Diagnosis Code", value: "DiagnosisCode" }
  ],
  PRE: [
    {
      key: "PRE01 - National Provider Identifier",
      value: "NationalProviderId"
    },
    { key: "PRE02 - DEA Number", value: "DeaNumber" },
    {
      key: "PRE03 - DEA Number SuffixPRE03 - DEA Number Suffix",
      value: "DeaNumberSuffix"
    },
    {
      key: "PRE04 - Prescriber State License Number",
      value: "StateLicenseNumber"
    },
    { key: "PRE05 - Last Name", value: "LastName" },
    { key: "PRE06 - First Name", value: "FirstName" },
    { key: "PRE07 - Middle Name", value: "MiddleName" },
    { key: "PRE08 - Phone Number", value: "Phone" },
    { key: "PRE09 - XDEA Number", value: "XDEANumber" },
    {
      key: "PRE10 - Prescriber State License Number Jurisdiction",
      value: "StateLicenseNumberJurisdiction"
    }
  ],
  CDI: [
    {
      key: "CDI01 - Compound Drug Ingredient Sequence Number",
      value: "SequenceNumber"
    },
    { key: "CDI02 - Product ID Qualifier", value: "ProductIdQualifier" },
    { key: "CDI03 - Product ID", value: "ProductId" },
    { key: "CDI04 - Component Ingredient Quantity", value: "Quantity" },
    { key: "CDI05 - Compound Drug Dosage Units Code", value: "DosageUnitsCode" }
  ],
  AIR: [
    {
      key: "AIR01 - State Issuing Rx Serial Number",
      value: "StateIssuingRxSerialNumber"
    },
    {
      key: "AIR02 - State Issued Rx Serial Number",
      value: "StateIssuedRxSerialNumber"
    },
    { key: "AIR03 - ID Issuing Jurisdiction", value: "IssuingJurisdiction" },
    {
      key: "AIR04 - ID Qualifier of Person Dropping Off or Picking Up Rx",
      value: "DropOffPickUpPersonIdQualifier"
    },
    {
      key: "AIR05 - ID of Person Dropping Off or Picking Up Rx",
      value: "DropOffPickUpPersonIdNumber"
    },
    {
      key: "AIR06 - Relationship of Person Dropping Off or Picking Up Rx",
      value: "DropOffPickUpPersonRelationshipCode"
    },
    {
      key: "AIR07 - Last Name of Person Dropping Off or Picking Up Rx",
      value: "DropOffPickUpPersonLastName"
    },
    {
      key: "AIR08 - First Name of Person Dropping Off or Picking Up Rx",
      value: "DropOffPickUpPersonFirstName"
    },
    {
      key: "AIR09 - Last Name or Initials of Pharmacist",
      value: "PharmacistLastNameOrInitials"
    },
    { key: "AIR10 - First Name of Pharmacist", value: "PharmacistFirstName" },
    {
      key: "AIR11 - Dropping Off/Picking Up Identifier Qualifier",
      value: "DropOffPickUpIdQualifier"
    }
  ]
};

export const getStateName = (abbr: string) => {
  return (states as any)[abbr];
};

export const getStateNameWithAbbr = (abbr: string) => {
  return `${abbr} - ${(states as any)[abbr]}`;
};
