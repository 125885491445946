import React from "react";
import { NavLink } from "react-router-dom";

import { Button, FontAwesomeIcon, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, makeStyles } from "../../../third-party-lib";
import "./DropDownNav.css";
import { useAuthResLogic } from "../../custom-hooks";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

export default function DropDownNav({ ...props }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { shouldLogOut } = useAuthResLogic();


  function handleDropdownBtnClicked() {
    const isOpened = !open;
    setDropdownState(isOpened);
  }

  const closeOnMenuItemClicked = () => {
    setDropdownState(false);
  }

  function closeOnOutsideClick(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDropdownState(false);
  }
  
  function setDropdownState(openOrClose) {
    setOpen(prevOpen => openOrClose);
    if(props.onClose) props.onClose(!openOrClose);
  }
  
  function closeAndClearSessionOnSignin(e) {
    setDropdownState(false);
    shouldLogOut();
  }

  
  return (
    // <div className={classes.root + " topbar-nav-dropdown"}>
    <div className={"topbar-nav-dropdown"}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls="menu-list-grow"
          aria-haspopup="true"
          onClick={handleDropdownBtnClicked}
          style={{ ...props.style }}
          className="nav-dropdown-btn"
        >
          {props.children}
        </Button>
        {open && 
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <div id="menu-list-grow" className="aw-dropdown-div">
                <Paper>
                  <ClickAwayListener onClickAway={closeOnOutsideClick}>
                    <MenuList>
                      {props.menus.map(menu => {
                        if (menu.route === "/signin") {
                          return (
                            <NavLink
                              key={menu.text}
                              to={menu.route}
                              className="log-out-btn"
                            >
                              <MenuItem onClick={closeAndClearSessionOnSignin}>
                                {/* onClick={handleClose}  */}
                                <FontAwesomeIcon icon={menu.icon} />
                                {menu.text}
                              </MenuItem>
                            </NavLink>
                          );
                        } else {
                          return (
                            <NavLink key={menu.text} to={menu.route} onClick={closeOnMenuItemClicked}>
                              <MenuItem >
                                {!!menu.icon && menu.icon.length > 2 && (
                                  <FontAwesomeIcon
                                    style={{ marginRight: "5px" }}
                                    icon={menu.icon}
                                  />
                                )}
                                {menu.text}
                              </MenuItem>
                            </NavLink>
                          );
                        }
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </div>
            </Grow>
          )}
        </Popper>}
      </div>
    </div>
  );
}
