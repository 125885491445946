import React from "react";
import { Card, Label } from "semantic-ui-react";
import { SegmentCardContent } from "./SegmentCardContent";
import { useSegmentCardFunc } from "./function-hooks";
export const SegmentCard = ({
  segment,
  onInputChange,
  rxNumber,
  segmentDetailSearchProp
}) => {
  const { report, handleHeaderClick, handleChange } = useSegmentCardFunc(
    segment,
    onInputChange,
    rxNumber,
    segmentDetailSearchProp
  );
  
  return (
    <Card fluid color="red">
      <Card.Content>
        <Card.Header style={{ cursor: "pointer" }} onClick={handleHeaderClick}>
          <Label as="a" color="red" ribbon className="aw-no-user-select">
            {segment.key}
          </Label>
          <span className="aw-no-user-select">{segment.label}</span>
        </Card.Header>
      </Card.Content>
      {!report.hide && (
        <SegmentCardContent report={report} onInputChange={handleChange} />
      )}
    </Card>
  );
};
