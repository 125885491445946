import React, { useState } from "react";

import { TabView, TabPanel } from "primereact/tabview";

export const AwTab = React.memo(({ children, isLicense }) => {
  const [tabState, setTabIndex] = useState({ tabIndex: 0 });
  const handleTabIndexChange = e => {
    setTabIndex({ tabIndex: e.index });
  };
  const chnArray = React.Children.toArray(children);

  // Added this so I can reset the selected tab back got pharmacist
  // just in case the user had selected pharmacy and changes stateprovinceid
  React.useEffect(() => {
    if (isLicense) setTabIndex(state => ({ tabIndex: 0 }));
  }, [children]);

  return (
    <TabView
      activeIndex={tabState.tabIndex}
      onTabChange={handleTabIndexChange}
      className="aw-state-tabview-page"
    >
      {chnArray.map((c, i) => {
        return (
          <TabPanel header={c.props.header} key={i}>
            {c}
          </TabPanel>
        );
      })}
    </TabView>
  );
});
