import React from "react";
import { Card } from "semantic-ui-react";
import { REPORT_SEGMENTS } from "../../../../defs";
import { AwInput, AwPrimeReactCalendar } from "../../../../components";
import { initShortDate, convertDateFromDBFormatWithoutChangingDate } from "../../../../utils";
export const SegmentCardContent = ({ report, onInputChange }) => {
  const handleInputChange = e => {
    onInputChange(e);
  };

  const isDate = value => {
    return report.submitted && !!value;
  };

  return (
    <Card.Content>
      <div className="container">
        <div className="row segment-card-header-row">
          <div className="col-5"></div>
          <div className="col-4">Submitted Values</div>
          <div className="col-3">Current Values</div>
        </div>
        {REPORT_SEGMENTS[report.segmentName].map((seg, i) => {
          return (
            <div key={i} className="row form-group edit-field-row">
              <div className="col-5">{seg.key}</div>
              <div className="col-4" data-test="seg_card_content_submitted">
                {!isDate(seg.type)
                  ? report.submitted[seg.value]
                  : initShortDate(getDateAsIs(report.submitted[seg.value]))}
              </div>
              <div
                className="col-3 seg_card_content_created"
                data-test="seg_card_content_created"
              >
                {isDate(seg.type) ? (
                  <AwPrimeReactCalendar
                    type={seg.value}
                    init={
                      report.current[seg.value]
                        ? getDateAsIs(report.current[seg.value])
                        : new Date()
                    }
                    dateSelected={handleInputChange}
                    showIcon={false}
                  />
                ) : (
                  <AwInput
                    data-test-id={`seg_card_content_created_${i}`}
                    init={
                      report.current[seg.value] === 0
                        ? "0"
                        : report.current[seg.value]
                    }
                    name={seg.value}
                    onChange={handleInputChange}
                  />
                )}
                {report.submitted[seg.value] !== report.current[seg.value] && (
                  <span className="text-danger asterisk-for-diffValue">*</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Card.Content>
  );
};

export default SegmentCardContent;



function getDateAsIs(d) {
  var date = convertDateFromDBFormatWithoutChangingDate(d);
  return new Date(date);
}
