import React from "react";
import { FancyTextField } from "./text-field";
import { AnimatedTextField } from "./text-field/AnimatedTextField";

export const ForgetPasswordForm = ({ onChange }) => {
  return (
    <>
      {/* <FancyTextField label="Email" name="Email" onChange={onChange} required /> */}
      <AnimatedTextField
        label="Email"
        name="Email"
        onChange={onChange}
        required={true}
      />
    </>
  );
};
