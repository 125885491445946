import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AwIcon = React.memo(({ iconName, onIconClick, color, size }) => {
  const [clickable, setClickable] = React.useState(
    onIconClick === null ? false : true
  );
  const iconColor = color || "#186ba0";
  // const iconSize = size ? size : "1.3rem";
  const iconSize =  size || "1.3rem";

  const handleClick = e => {
    if (clickable) {
      onIconClick(e);
    }
  };

  return (
    <FontAwesomeIcon
      onClick={handleClick}
      icon={iconName}
      style={{ fontSize: iconSize, cursor: "pointer", color: iconColor }}
    />
  );
});

AwIcon.propTypes = {
  onIconClick: PropTypes.func,
  iconName: PropTypes.string.isRequired
};

AwIcon.defaultProps = {
  onIconClick: null
};
