import React from "react";

export const AlertErrors = ({ errors = [] }) => {
  return (
    <div
      style={{ padding: "5px", maxHeight: "100px", overflowY: "auto" }}
      className={"alert alert-danger"}
    >
      {errors.length > 1 && (
        <ol>
          {errors.map((error, i) => {
            return <li key={i}>{error}</li>;
          })}
        </ol>
      )}

      {errors.length === 1 && <p>{errors}</p>}
    </div>
  );
};
