import React, { useEffect } from 'react'
import { AwFormFieldWrapper, AwPhoneInput } from '../../../../../components'


interface Props {
    init?: string;
    required: boolean;
    onChange: any;
}

export const PhoneNumberFormField: React.FC<Props> = ({init = "", required, onChange}) => {
    const fieldName = "PhoneNumber";
    useEffect(() => {
      if(init) onChange({target: {value: init, name: fieldName}});
    }, [])

    return (
      <AwFormFieldWrapper name={fieldName} label="Phone Number" required={required}>
        <AwPhoneInput init={init} name={fieldName} onChange={onChange} />
      </AwFormFieldWrapper>
    )
  }