import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button, Typography } from "../../../third-party-lib/mui";

import {
  validateAllFields,
  getGoogleAuthLoginObj,
  isValidEmail,
  getLoggedInUser
} from "../../../utils";
// import { NavLink } from "react-router-dom";
import {
  useIsComponentActive,
  useAuthResLogic,
  useAuthSettingsLogic
} from "../../../components";
import { Alert } from "../../../components/message";

import { ForgetPasswordForm } from "./ForgetPasswordForm";
import { SignInForm } from "./SignInForm";

// import { GoogleLogin } from "react-google-login";

import "./SignIn.scss";
import { formGenericErrorMsg } from "../../../defs";
import { useLocation } from "react-router-dom";
import { APP_URLS } from "../../../infrastructure";

// const GoogleLogin = lazy(() =>
//   import("react-google-login").then(google => ({ default: google.GoogleLogin }))
// );
interface ILoginModel {
  UserName?: string;
  Password?: string;
  Email?: string;
  Refresh_Token?: string;
}

interface IFormProp {
  isDefault: boolean;
  heading: string;
  navLabel: string;
  newPasswordSet: boolean;
}


export default function SignIn() {
  const location = useLocation();
  const redirectUrl = location.state?.redirectUrl || APP_URLS.dashboard;
  const storeError = useSelector((state: any) => state.auth.Error);
  const { componentRef } = useIsComponentActive();

  const { signInAUserAsync } = useAuthResLogic();
  const { forgotPasswordAsync } = useAuthSettingsLogic(
    "api/auth/ForgotPassword"
  );

  const [loading, setLoading] = useState(false);

  const newPasswordSet = !!location.search;

  const defaultFormProps: IFormProp = {
    isDefault: true,
    heading: newPasswordSet
      ? "Please log in with your new password!"
      : "Sign In",
    navLabel: "Forget Password?",
    newPasswordSet: newPasswordSet
  };

  const forgotPasswordProps: Partial<IFormProp> = {
    isDefault: false,
    heading: "Send Email",
    navLabel: "Go Back"
  };

  const [formProps, setFormProps] = useState<IFormProp | Partial<IFormProp>>(defaultFormProps);

  const [loginInfo, setLoginInfo] = useState<ILoginModel>({
    UserName: "",
    Password: "",
    Email: ""
  });
  const [error, setError] = useState(storeError);
  const [successMsg, setSuccessMsg] = useState("");

  const handleInputChange = (e: any) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };

  const handleLoading = (bool: boolean) => {
    setLoading(bool);
  };

  const handleSubmit = async (e: any) => {
    if (e) e.preventDefault();
    let validationError = "";
    if (formProps.isDefault) {
      loginInfo.Email = loginInfo.UserName;

      validationError = validateAllFields({username: loginInfo.UserName, password: loginInfo.Password});
      if (!validationError && validationError.length < 1) {
        const manualAction = { type: "", payload: loginInfo};
        await compSignInAUserAsync(manualAction);
      }
    } else {
      if (loginInfo.Email) {
        const isValid = isValidEmail(loginInfo.Email);
        if (!isValid) validationError = "Please provide a valid email";
        if (isValid) {
          setLoading(true);
          const res = await forgotPasswordAsync({ UserName: loginInfo.Email });
          if (res.success) {
            setSuccessMsg(res.msg);
            setError("");
          } else {
            setLoading(false);
            validationError = res.msg;
            setSuccessMsg("");
          }
        }
      } else {
        validationError = formGenericErrorMsg;
      }
    }

    if (componentRef.active) {
      setError(validationError);
    }
  };

  const handleFormSwitch = () => {
    if (formProps.isDefault) {
      setFormProps(forgotPasswordProps);
    } else {
      setFormProps(defaultFormProps);
    }
    resetSwitchValues();
  };

  const resetSwitchValues = () => {
    setLoginInfo({});
    setError("");
    setSuccessMsg("");
    setLoading(false);
  };

  // const responseGoogle = async (response: any) => {

  //   if (
  //     Object.keys(response).length > 5 &&
  //     Object.keys(response.profileObj).length > 3
  //   ) {
  //     const loginData = getGoogleAuthLoginObj(response.profileObj);
  //     const googleAction = { type: "google", payload: loginData };
  //     await compSignInAUserAsync(googleAction);
  //   } else if (
  //     !!response.details &&
  //     response.details.indexOf("Not a valid origin") > -1
  //   ) {
  //     setError("Google authentication hasn't been configured for this site.");
  //   } else {
  //     setError("Google Authentication Failed!");
  //   }
  // };

  const compSignInAUserAsync = async (action: any) => {
    handleLoading(true);
    await signInAUserAsync(action, true);
    if (componentRef.active) {
      handleLoading(false);
    }
  };

  // const automaticDefaultLoginAsync = async () => {
  //   const dTLogin:ILoginModel = { 
  //     UserName: 'tntow@mqrx.com', 
  //     Password: "Kw@m3M@nu", 
  //     Refresh_Token: "" 
  //   }
  //   const manualAction = { type: "", payload: dTLogin };
  //   await compSignInAUserAsync(manualAction);
  // }
  // useEffect(() => {
  //   const token = getLoggedInUser();
  //   console.log(token);
  //   if(token && Object.keys(token).length < 1) {
  //     automaticDefaultLoginAsync();
  //   }

  //   return () => {}
  // }, [])


  return (
    <div className="full-screen">
      
      <div className="remove">
        <div className="content-c"></div>
      </div>
      <div className={`wrapper ${formProps.isDefault ? '' : 'forgot-psswd-wrapper'}`}>
        {!!successMsg && (
          <div
            style={{ margin: "5% auto" }}
            className="text-success text-center"
          >
            {successMsg.split("\n").map((item, i) => {
              return <p key={i}>{item}</p>;
            })}
          </div>
        )}
        
        {!successMsg && (
          <>
            <Typography component="h1" variant="h5" className="sign-in-header">
              {formProps.heading}
            </Typography>
            {(!!error || storeError) && (
              <div style={{ marginTop: "5%" }}>
                <Alert
                  message={!!storeError ? storeError : error}
                  align={"center"}
                />
              </div>
            )}
            <form id="login-form" className="form login-form" >
              {formProps.isDefault ? (
                <SignInForm onChange={handleInputChange} />
              ) : (
                <ForgetPasswordForm onChange={handleInputChange} />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <span style={{ textTransform: "capitalize" }}>
                    Logging in...
                  </span>
                ) : (
                  <span>{newPasswordSet ? "Sign In" : formProps.heading}</span>
                )}
              </Button>
              {/* <div className="forgot-password-div">
                {!loading && (
                  <div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleFormSwitch}
                    >
                      {formProps.navLabel}
                    </span>
                  </div>
                )}
              </div> */}
              {!loading && (
                  <div className="fg-pwd-container">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleFormSwitch}
                    >
                      {formProps.navLabel}
                    </span>
                  </div>
                )}
              {/* {formProps.isDefault && !formProps.newPasswordSet && (
                <div
                  className="google-auth-btn-div"
                  style={{ marginTop: "20px" }}
                >
                  <Suspense fallback={<div>Loading google auth...</div>}>
                    <GoogleLogin
                      clientId="314618265757-im0cqbjs8d51el05437kncu5kjicv1e0.apps.googleusercontent.com"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      disabled={loading}
                    >
                      <span> Login with Google</span>
                    </GoogleLogin>
                  </Suspense>
                </div>
              )} */}
            </form>
          </>
        )}
      </div>


    </div>
  );
}
