import React, { Suspense, lazy } from "react";
// import PropTypes from 'prop-types';
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";


import { SignIn } from "../containers";
  
import {APP_URLS, GetAllAppUrls} from "../infrastructure";

// import StatePages from "../containers/pages/state/StatePages";
import Reports from "../containers/pages/report/Reports";
import { useCurrentUser } from "./custom-hooks";
import { ConfirmPhoneNumber } from "../containers/pages";

function lazyComponent(component: any) {
  return lazy(() =>
    import("../containers").then((fromContainers: any) => ({
      default: fromContainers[component]
    }))
  );
}

const Directory = lazyComponent("Directory"),
  Pharmacy = lazyComponent("Pharmacy"),
  Orders = lazyComponent("Orders"),
  Admin = lazyComponent("Admin"),
  Users = lazyComponent("Users"),
  Roles = lazyComponent("Roles"),
  StateRules = lazyComponent("StateRules"),
  UserSettings = lazyComponent("UserSettings"),
  Dashboard = lazyComponent("Dashboard"),
  StatePages = lazyComponent("StatePages"),
  CreatePassword = lazyComponent("CreatePassword"),
  EmailConfirmation = lazyComponent("EmailConfirmation"),
  PageNotFound = lazyComponent("PageNotFound");


export default function RouterOutlet() {
  const location = useLocation();
  let { user } = useCurrentUser();
  // const appUrls = GetAllAppUrls();// [string]
  // const notAValidUrl = !appUrls.includes(location.pathname);

  
  if (!location.state) location.state = null;
  return (
      <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <UnAuthRoute
                location={location}
                isLoggedIn={user.IsAuthenticated}
                path={APP_URLS.registration.base}
                exact
                component={SignIn}
              />
              
              <UnAuthRoute
                location={location}
                isLoggedIn={user.IsAuthenticated}
                path={APP_URLS.registration.signin}
                exact
                component={SignIn}
              />
              


              <UnAuthRoute
                  location={location}
                  isLoggedIn={user.IsAuthenticated}
                  path={APP_URLS.registration.emailverification}
                  component={EmailConfirmation}
                />


            <UnAuthRoute
              location={location}
              isLoggedIn={user.IsAuthenticated}
              path={APP_URLS.registration.confirmphonenumber}
              component={ConfirmPhoneNumber}
            />
            <UnAuthRoute
              location={location}
              isLoggedIn={user.IsAuthenticated}
              path={APP_URLS.registration.createdpassword}
              component={CreatePassword}
            />










            <GuardRoute
              exact={true}
              location={location}
              user={user}
              path={APP_URLS.dashboard}
              component={Dashboard}
            />

          {/* admin routes */}
            <AdminRoute
              exact={true}
              location={location}
              user={user}
              path={APP_URLS.admin.base}
              component={Admin}
            />
            <AdminRoute
              location={location}
              user={user}
              path={APP_URLS.admin.users}
              component={Users}
            />
            <AdminRoute
              location={location}
              user={user}
              path={APP_URLS.admin.roles}
              component={Roles}
            />
            


          
          {/* Pages */}
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.reports}
              component={Reports}
            />
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.states}
              component={StatePages}
            />
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.rules}
              component={StateRules}
            />
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.pharmacy}
              component={Pharmacy}
            />
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.directory}
              component={Directory}
            />
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.settings}
              component={UserSettings}
            />
            <GuardRoute
              location={location}
              user={user}
              path={APP_URLS.orders}
              component={Orders}
            />
        
            <GuardRoute 
              exact 
              location={location} 
              user={user} 
              path={APP_URLS.pageNotfound} 
              component={PageNotFound} />

            <WildRoute
              location={location}
              user={user}
            />
        </Routes>
    
         {/* )
         } */}
      </Suspense>
  );
}


const InitAuthRoute = (user: any, Component: any, props: any, isAdminRoute = false) => {
  return user.IsAuthenticated ? (
    <>
      {isAdminRoute ? (<CanViewPage component={Component} hasPermission={user.canAccessAdminPage} {...props} />) : (<Component {...props} />)}
    </>
  ) : (
      <Navigate to={{ pathname: APP_URLS.registration.signin }} />
    );
}


export const UnAuthRoute = ({
  component: Component,
  isLoggedIn,
  location,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      element={isLoggedIn ? ( 
          <Navigate to={{ pathname: APP_URLS.dashboard }} />
        ) : (
          <Component />
        )
      }
    />
  );
};



const GuardRoute = ({ component: Component, user, ...rest }: any) => {
  return (
  <Route
    {...rest}
    exact
    element={(props: any) => InitAuthRoute(user, Component, props)}
  />
)};


const AdminRoute = ({ component: Component, user, ...rest }: any) => {
  return (
    <Route
      {...rest}
      element={(props: any) => InitAuthRoute(user, Component, props, true)}
    />
  )
};

const WildRoute = ({user}: any) => {
  if(!user.IsAuthenticated) return (<Navigate to={{ pathname: APP_URLS.registration.signin  }} />);
  return <Navigate to={{ pathname: APP_URLS.pageNotfound }} />;
};


const NotAValidRoute = ({user, ...props}: any) => {
  return (
    // <Route render={props => user.IsAuthenticated ? 
    //     <PageNotFound {...props} /> 
    //     : (<Navigate to={{ pathname: APP_URLS.registration.signin }} />)} />
    <Route
      element={
        user.isAuthenticated ? (
          <PageNotFound {...props} />
        ) : (
          // <Navigate to="/registration/signin" replace={true} />
          <Navigate to={{ pathname: APP_URLS.registration.signin }} />
        )
      }
    />
  )
}



interface CanViewPageProps {
  component: React.ComponentType<any>;
  hasPermission: boolean;
  // Add any additional props here
}

const CanViewPage: React.FC<CanViewPageProps> = ({
  component: Component,
  hasPermission,
  ...props
}) => {
  if (!hasPermission) {
    return <Navigate to={{pathname: APP_URLS.pageNotfound}} />;
  }

  return <Component {...props} />;
};



