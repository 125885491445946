import React, { useState } from "react";
//import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import { AwModalTitle } from "./AwModalTitle";
import { AwButton } from "..";

import "./Modal.css";

export const AwDModal = React.forwardRef((props, ref) => {
  const { onModalClose, onSubmit, submitBtnLabel, size, title } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setLoading(false);
    setShow(false);
  };

  
  const modal = {
    isShowing: () => show,
    open: () => handleShow(),
    close: () => handleHide(),
    resetLoading: () => reset()
  };

  const reset = () => {
    setLoading(false);
  };
  const handleSubmit = e => {
    setLoading(true);
    onSubmit();
  };

  const onHideEvent = e => {
    onModalClose(e);
  };

  ref.current = modal;
  return (
    <>
      <Modal
        show={show}
        size={size}
        backdrop="static"
        onHide={onHideEvent}
        className={"aw-data-modal " + title}
      >
        <Modal.Header closeButton={!props.hideCloseBtn}>
          <Modal.Title>
            <AwModalTitle>{title}</AwModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        {!props.hideFooter && <Modal.Footer>
          <AwButton
            width="6em"
            label={!props.cancelBtnLabel ? "Close" : props.cancelBtnLabel}
            type="secondary"
            disable={loading}
            btnClicked={onHideEvent}
          />
          <AwButton
            width="7em"
            label={submitBtnLabel}
            type="default"
            loading={loading}
            btnClicked={handleSubmit}
          />
        </Modal.Footer>}
      </Modal>
    </>
  );
});

AwDModal.defaultProps = {
  submitBtnLabel: "Save"
};
