import React, { useEffect, useState } from 'react';
import { AwFormSelectField, AwInputFormField, useCurrentUser } from '../../../../../components';
import { PhoneNumberFormField } from './PhoneNumberFormField';
import { ReportErrorCheckBoxField } from './ReportErrorCheckBoxField';

import { getRoleData, getCanEditUserRole } from "./userFormHelper";



interface Props {
    onInputChange: any;
    onCheck: (e: any) => void;
    onPhoneChecked: (e: any) => void;
    user: any;
}

export const EditUserForm: React.FC<Props> = ({user, onCheck, onPhoneChecked, onInputChange}) => {
    const [phoneRequired, setPhoneRequired] = useState(false);
    const { user: cUser} = useCurrentUser();

    const canEditRole = getCanEditUserRole(user, cUser);

    
    const { role, fields, options } = getRoleData(user, cUser.Role);



    useEffect(() => {
        onInputChange({ target: { value: role, name: "RoleName" } });
    }, []);


    const handleChecked = (e: any) => {
        const { name, checked } = e.target;
        onCheck({ target: {value: checked, name: name}});
    }

    const handlePhoneChecked = (e: any) => {
      const { name, checked } = e.target;
      onPhoneChecked({ target: {value: checked, name: name}});
      setPhoneRequired(checked);
    }

    
    return (
        <form noValidate className="edit-user-form">

            {fields.map((field, i) => {
                return (
                    <AwInputFormField
                    key={i}
                    {...{required: true, init: user[field.name], name: field.name, label: field.label, onChange: onInputChange}}
                    />
                );
            })}
            
            <ReportErrorCheckBoxField name="CanReceiveReportEmails" type="email" init={user.CanReceiveReportEmails} onCheck={handleChecked} />

            <ReportErrorCheckBoxField init={user.CanReceiveReportText} onCheck={handlePhoneChecked} />
                

            <PhoneNumberFormField init={user.PhoneNumber} required={phoneRequired} onChange={onInputChange} />

           {canEditRole &&  <AwFormSelectField
                {...{options: options, 
                    optionsLabel: "Role", 
                    name: "RoleName", 
                    dValue: role,
                    value: "key",
                    dropdownText: "key",
                    onSelectChange: onInputChange,
                    required: true
                }}
            />}
        </form>
    )
}

