import { UserSystemRoles } from "../../../../../defs";

export const getCanEditUserRole = (user: {UserName: string; RoleName: string;}, cUser: {Email: string; Role: string;}) => {
  return (user.UserName !== cUser.Email);
}

export const getCanEditUser = (user: {UserName: string; RoleName: string;}, cUser: {Email: string; Role: string;}) => {
  return (user.RoleName === cUser.Role) || (user.RoleName === UserSystemRoles.manager && cUser.Role === UserSystemRoles.admin) || (cUser.Role === UserSystemRoles.superUser);
}

export const getRoleData = (data: {RoleName: string;}, userRole: string, isNewUser = false) => {
    const isSU = userRole === "SuperUser";
    const fields = isNewUser ? [...userFormFields, 
        { name: "UserName", label: "Email" }] : userFormFields;

    const suOpt = [{ key: "Admin", value: 2 }, { key: "SuperUser", value: 3 }];
    const options = isSU ? [...gOpt, ...suOpt] : gOpt;
    const role = (!isNewUser || isSU) ? data.RoleName : "Manager";

    return { role, fields, options }
}


const gOpt: {key: string; value: number;}[] = [{ key: "Manager", value: 1 }];

const userFormFields: {name: string; label: string;}[] = [
  { name: "FirstName", label: "First Name" },
  { name: "LastName", label: "Last Name" }
];