import { useEffect, useState } from "react";
import { tVoidFunc } from "./ISessionInterface";

// EventListener for for usersession
export const useIdleTimeTracker = (isModalUp: any, showModal: tVoidFunc) => {
    const [isTracking] = useState<boolean>(true);
    const oneMinute_in_milisec = 60 * 1000;
    const max_count = 18;

    const startTracking = () => {
        trackerSettings.modalUp = false;
        trackerSettings.track();
    }

    const trackerSettings = {
        count: 0,
        intervalId: 0 as any,
        modalUp: false,
        track: () => {
            trackerSettings.count = 0;
            if(!isModalUp()) trackerSettings.startInterval();
        },
        startInterval: () => {
            if(trackerSettings.intervalId) trackerSettings.clearInterval();
            
            trackerSettings.intervalId = setInterval(() => {
                trackerSettings.count++;
                if(trackerSettings.count >= max_count) {
                    showModal();
                    trackerSettings.clearInterval();
                };
            }, oneMinute_in_milisec);
        },
        clearInterval: () => {
            clearInterval(trackerSettings.intervalId);
        }
    }

    useEffect(() => {
        const events = [
            "touchstart",
            "pointermove"
        ];

        events.forEach(function(name) {
            document.addEventListener(name, trackerSettings.track, true);
        });

        // trackerSettings.track();
        
        return () => {
            events.forEach(function(name) {
                document.removeEventListener(name, trackerSettings.track, true);
            });
        }
    }, []);

    useEffect(() => {
        trackerSettings.track();
    }, [isTracking]);


    return {
        startTracking
    }
}
