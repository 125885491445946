import React from "react";
import {
  AwFormFieldWrapper,
  AwPhoneInput,
  AwInputFormField,
  AwNumberInput
} from "../../../../components";

export const NotificationForm = ({ onInputChange }) => {
  return (
    <form>
      {notificationFields.map((field, i) => {
        if (field.isPhone) {
          return getFormField("phone", field, onInputChange, i);
        } else if (field.isNumber) {
          return getFormField("number", field, onInputChange, i);
        } else {
          return getFormField("def", field, onInputChange, i);
        }
      })}
    </form>
  );
};

export default NotificationForm;

const notificationFields = [
  {
    name: "DaysPriorToExp",
    label: "Days Prior To Expiration",
    isNumber: true,
    max: 4
  },
  {
    name: "Interval",
    label: "Notification Interval (Days)",
    isNumber: true,
    max: 4
  },
  {
    name: "MobileNumber",
    label: "Mobile Number to receive notifications",
    isPhone: true,
    isRequired: true
  },
  {
    name: "EmailAddress",
    label: "Email Address to receive notifications",
    isRequired: true
  }
];

const getFormField = (type, field, changeFunc, i) => {
  switch (type) {
    case "phone":
      return (
        <div className="col-12" key={i}>
          <AwFormFieldWrapper
            name={field.name}
            label={field.label}
            required={field.isRequired ? false : true}
          >
            <AwPhoneInput name={field.name} onChange={changeFunc} />
          </AwFormFieldWrapper>
        </div>
      );
    case "number":
      return (
        <div className="col-12" key={i}>
          <AwFormFieldWrapper
            name={field.name}
            label={field.label}
            required={field.isRequired ? false : true}
          >
            <AwNumberInput
              max={field.max}
              name={field.name}
              onChange={changeFunc}
            />
          </AwFormFieldWrapper>
        </div>
      );
    default:
      return (
        <div className="col-12" key={i}>
          <AwInputFormField
            required={field.isRequired ? false : true} // if required is set then it means is not required since required is defaulted to true
            name={field.name}
            label={field.label}
            onChange={changeFunc}
          />
        </div>
      );
  }
};
