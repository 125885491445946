import React from "react";
import {
  AwDModal
} from "../../../../../components";
import { Alert } from "../../../../../components/message";
import { useUserAndRoleAddFuncs } from "../../useUserAndRoleAddFuncs";
import { SRMsgSetupForm } from "../forms";

export const AddUserPhone = ({ userRef, onHide, onPhoneAdded }) => {
  const url = "/api/admin/users/addphonenumber";
  const {
    modalRef,
    error,
    handleChange,
    handleAddPhoneAsync,
    modalClose,
    handleSetError,
    formValues
  } = useUserAndRoleAddFuncs(
    url,
    ["PhoneNumber"],
    onHide,
    onPhoneAdded
  );

  const handlePhoneChange = (e) => {
    handleChange(e);
  }
  


  
  const getUpdatedUser = () => Object.assign({}, userRef.current, formValues);

  const onModalSubmitClicked = () => {
    const isEmailAlert = formValues.CanReceiveReportEmails;
    const isTextAlert = !!formValues.PhoneNumber;
    if(!isTextAlert && !isEmailAlert) return handleSetError(true);
    
    const inValidPhone = (formValues.PhoneNumber && formValues.PhoneNumber.length !== 10);
    if(isTextAlert && inValidPhone) return handleSetError(true, "Please enter a valid phone number!");

    const x = getUpdatedUser();
    handleAddPhoneAsync(x);
  }

  
  const isNotConfirmed = (!!userRef.current.PhoneNumber && !userRef.current.PhoneNumberConfirmed && userRef.current.CanReceiveReportText);

  return (
    <div className="add-user-phone">
      <AwDModal
        ref={modalRef}
        onModalClose={modalClose}
        onSubmit={onModalSubmitClicked}
        submitBtnLabel={isNotConfirmed ? "Re-Send" : "Submit" }
        title={"Add User Phone Number"}
      >
        <div>
          {isNotConfirmed && <div style={{textAlign: 'center', color: 'red', marginBottom: '1rem'}}>- {userRef.current.FirstName} needs to confirm his/her phone number -</div>}
          {error && <Alert message={error} align={"center"} />}
          <SRMsgSetupForm user={userRef.current} onChange={handlePhoneChange} />
        </div>
      </AwDModal>
    
    </div>
  );
};



