import React, { useState, useMemo } from "react";

import { useSelector } from "react-redux";

import { AwPageContainer } from "../AwPageContainer";
import { getStateSettingsSelector } from "../../../store";
import { AwSelectBox, AwTab } from "../../../components";

import {
  Bookmark,
  SubmissionHistory,
  Accounts,
  AsapStandards,
  Contacts,
  Documents,
  Licenses,
  SystemSettings
} from ".";
import "./StatePages.css";


export default function StatePages() {
  const [state, setStateProvinceId] = useState(null);
  const settings = useSelector(state => {
    const x = getStateSettingsSelector(state); 
    // console.log(x); 
    return x;
  });
  const states = useMemo(() => JSON.parse(JSON.stringify(settings)), [
    settings
  ]); // Added settings as dep to avoid warnings
  const onStateChange = stateId => {
    setStateProvinceId(stateId);
  };

  // getSettings();

  return (
    <AwPageContainer>
      <div
        style={{ width: "50%", marginBottom: "20px" }}
        className="aw-state-page-dropdown-section"
      >
        <AwSelectBox
          array={states}
          value="StateProvinceId"
          label2="Name"
          label="StateProvinceId"
          onSelectValueChange={onStateChange}
        />
      </div>

      {/* dynamic components */}
      <div className="aw-state-page-tabview-section">
        {!!state && (
          <AwTab>
            <SubmissionHistory
              header="Submission History"
              stateProvinceId={state}
            />
            <Accounts header="Account" stateProvinceId={state} />
            <Licenses header="Licenses" stateProvinceId={state} />
            <SystemSettings header="System Settings" stateProvinceId={state} />
            <Contacts header="Contacts" stateProvinceId={state} />
            <AsapStandards header="ASAP Standards" stateProvinceId={state} />
            <Documents header="Documents" stateProvinceId={state} />
            <Bookmark header="Bookmarks" stateProvinceId={state} />
          </AwTab>
        )}
      </div>
    </AwPageContainer>
  );
}
