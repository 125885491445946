import React from "react";
import { AwDModal, AwInputFormField } from "../../../../components";
import { useUserAndRoleAddFuncs } from "../useUserAndRoleAddFuncs";
import { Alert } from "../../../../components/message";

export const AddRole = ({ onHide, onRoleAdded }) => {
  const {
    modalRef,
    error,
    handleChange,
    handleSubmitAsync,
    modalClose
  } = useUserAndRoleAddFuncs("api/admin/roles", ["Name"], onHide, onRoleAdded);

  return (
    <div>
      <AwDModal
        ref={modalRef}
        onModalClose={modalClose}
        onSubmit={() => handleSubmitAsync("role")}
        submitBtnLabel={"Add"}
        title={"Add Roles"}
      >
        <form
          noValidate
          onSubmit={e => {
            if (e) e.preventDefault();
          }}
        >
          {error && <Alert message={error} align={"center"} />}
          <AwInputFormField
            required={true}
            name={"Name"}
            label={"Name of Role"}
            onChange={handleChange}
          />
        </form>
      </AwDModal>
    </div>
  );
};
