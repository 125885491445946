import React, { useEffect, useState } from "react";
import { SettingsHeader } from "../SettingsHeader";
import {
  AwFileInputField,
  useSetFileValue,
  AwButton
} from "../../../../../components";
import { Alert } from "../../../../../components/message";
import api from "../../../../../store/api";
import { getProfilePicFormData, isResponseError } from "../../../../../utils";
import { useDispatch } from "react-redux";
import { profileImageUploadSuccess } from "../../../../../store";

export const UploadProfile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [refreshInput, setRefresh] = useState(false);
  const base_url = "api/auth/uploadprofilepic";

  const { isBigFile, msg, fileEvt, handleChange } = useSetFileValue();

  const onFormSubmit = async e => {
    const file = fileEvt.target.value;
    if (!file) {
      return setError("Please choose a file!");
    }
    setError("");
    const values = getProfilePicFormData(file);
    setLoading(true);
    const res = await api.requestmethods.post(base_url, values, true);
    // res is an obj with src as property.
    setLoading(false);
    if (!isResponseError(res)) {
      setRefresh(!refreshInput);
      dispatch(profileImageUploadSuccess(res.src));
    } else {
      setError(res.message);
    }
  };

  return (
    <form noValidate>
      <SettingsHeader header={"Upload Profile Picture"} />
      {isBigFile && !error && <Alert message={msg} align="center" />}
      {error && <Alert message={error} align="center" />}
      <AwFileInputField
        refresh={refreshInput}
        name={"File"}
        label={"File"}
        onChange={handleChange}
      />
      <div style={{ width: "90px" }}>
        <AwButton
          label={"UpLoad"}
          loading={loading}
          btnClicked={onFormSubmit}
        />
      </div>
    </form>
  );
};
{
  /* <AwFileInputField
                  name={field.name}
                  label={field.label}
                  onChange={handleChange}
                /> */
}

{
  /* <div class="card-body">
    <div class="card-header settings-chn-header">
    </div>
    <div *ngIf="!!ErrorMessage" class="alert alert-danger aw-btsp-alert" role="alert">
      <div class="text-danger" [innerHTML]="ErrorMessage"></div>
    </div>
    <div class="form-group">
      <input style="height: 45px;" class="form-control" type="file" name="File" ngModel #file="ngModel" (change)="handleFileInput($event.target.files)" accept=".png, .jpg, .jpeg" required #fileInput>
      <div height="200">
        <img [src]="imageUrl" height="200" *ngIf="imageUrl">
      </div>
      <p class="text-danger" *ngIf="file.touched && file.invalid && file.errors.required">File is a required field!</p>
      <p class="text-danger" *ngIf="isBigFile">File cannot be more than 20mb!</p>
    </div>
    <div class="form-group">
      <button [disabled]="!profilePicUploadForm.valid" class="btn aw-primary-btn">Submit</button>
    </div>
  </div> */
}
