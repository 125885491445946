import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//const baseStyle = { padding: "8px", minWidth: "30px", position: "relative" };

const stylesObj = {
  baseStyle: {
    padding: "4px",
    position: "relative",
    top: "-4px",
    cursor: "pointer",
    background: "rgba(10, 10, 10, 0.12)",
    borderRadius: "4px",
    color: "#797877",
    transition: "all 0.5s ease"
  },
  hoverStyles: {
    background: "#636767",
    color: "white"
  }
};

export default function NavigationButton(props) {
  const [hover, setHover] = useState(false);
  // vertical (top to bottom) is default
  const styles = !props.isDefault
    ? { ...stylesObj.baseStyle, top: "-5px" }
    : {
        ...stylesObj.baseStyle,
        left: "30px",
        top: "20px",
        padding: "7px 10px",
        color: "white"
      };
  return (
    <span
      style={{ ...styles, ...(hover ? stylesObj.hoverStyles : null) }}
      onClick={() => props.handleClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FontAwesomeIcon icon={props.icon} />
    </span>
  );
}
