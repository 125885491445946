import { useNavigate } from "react-router-dom";

// import { AwDModal, useModalFuncs } from "../../components";
import {useIdleTimeTracker} from "./useIdleTimeTracker";
import { useSessionTimeout} from "./useSessionTimeout";
import { useAuthResLogic, useModalFunctions } from "../../custom-hooks";
import { APP_URLS } from "../../../infrastructure";
import {SModal } from "./SModal";


const getDoubleDigit = (num: number): string => {
    return ("0" + num).slice(-2)
}

export const SessionModal = () => {
  const navigate = useNavigate();
    
    const {
        modalRef,
        isShowing,
        showModal,
        hideModal,
        } = useModalFunctions();
    
    const {shouldLogOut} = useAuthResLogic()
    
    function navigateAway() {
        navigate(APP_URLS.registration.signin);
    }
    const signUserOutAsync = async () => {
        stopInterval();
        hideModal();

        await shouldLogOut();
        
        navigateAway();
    }
    
    const {minutes, seconds, startInterval, stopInterval} = useSessionTimeout(signUserOutAsync);
    
    const handleShowModal = () => {
        showModal();
        startInterval();
    }
    
     const {startTracking} = useIdleTimeTracker(isShowing, handleShowModal);
    
      const handleLogout = () => {
        stopInterval();
        signUserOutAsync();
      };
    
      const handleStayLoggedIn = () => {
        stopInterval();
        hideModal();
        startTracking();
      };
    
    
    return (
        <SModal
          ref={modalRef}
          onModalClose={handleLogout}
          onSubmit={handleStayLoggedIn}
          submitBtnLabel={"Stay Logged In"}
          cancelBtnLabel={"Log Out"}
          modalTitle={"Need More Time?"}>
            <p>You will be automatically logged out in</p>
            <div>
              <h3>
                {/* <span>{minutes ? minutes : "00"}</span> : 
                <span> {seconds ? seconds : "00"}</span> */}
                <span>{getDoubleDigit(minutes)}</span> : 
                <span> {getDoubleDigit(seconds)}</span>
              </h3>
            </div>
            <p>
              To continue your session, select <em>"Stay Logged In."</em>
            </p>
        </SModal>
    )
    };

