import { useState, useEffect } from "react";
import { screenTypes } from "../../utils";
import { useIsComponentActive } from "./useIsComponentActive";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
export const useWindowResize = () => {
  // const addedColRef = useRef(addedCol);
  const { componentRef } = useIsComponentActive();
  const [state, setState] = useState(setScreenType(getWindowDimensions()));

  useEffect(() => {
    function handleResize() {
      if (componentRef.active) {
        setState(
          setScreenType({
            width: window.innerWidth,
            height: window.innerHeight
          })
        );
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [state];
};

const setScreenType = ({ width, height }) => {
  if (width < 376) {
    return getScreen(screenTypes.phone, width);
  } else if (width > 375 && width < 668) {
    return getScreen(screenTypes.landscapePhone, width);
  } else if (width > 667 && width < 813) {
    return getScreen(screenTypes.portraitTablet, width);
  } else if (width > 812 && width < 1025) {
    return getScreen(screenTypes.landscapeTablet, width);
  } else if (width > 1024 && width < 1301) {
    return getScreen(screenTypes.smallScreen, width);
  } else {
    return getScreen(screenTypes.largeScreen, width);
  }
};

function getScreen(type, width) {
  return { type, width };
}
// Resizing Less Often

// import React from 'react'

// function debounce(fn, ms) {
//   let timer
//   return _ => {
//     clearTimeout(timer)
//     timer = setTimeout(_ => {
//       timer = null
//       fn.apply(this, arguments)
//     }, ms)
//   };
// }

// function MyComponent() {
//   const [dimensions, setDimensions] = React.useState({
//     height: window.innerHeight,
//     width: window.innerWidth
//   })
//   React.useEffect(() => {
//     const debouncedHandleResize = debounce(function handleResize() {
//       setDimensions({
//         height: window.innerHeight,
//         width: window.innerWidth
//       })
//     }, 1000)

//     window.addEventListener('resize', debouncedHandleResize)

//     return _ => {
//       window.removeEventListener('resize', debouncedHandleResize)

//   })
//   return <div>Rendered at {dimensions.width} x {dimensions.height}</div>
