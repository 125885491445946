import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AwFormFieldWrapper } from "./AwFormFieldWrapper";
import { getNewEventObj, awIcons } from "../../utils";

export const AwPasswordInputFormField = React.memo(
  ({ init, name, label, onChange, required, type }) => {
    const defaultP = {
      type: "password",
      icon: awIcons.eyeSlash
    };
    const [show, setShow] = useState(defaultP);
    const handleClick = () => {
      if (show.type === "text") {
        setShow(defaultP);
      } else {
        setShow({
          type: "text",
          icon: awIcons.eye
        });
      }
    };
    const [value, setValue] = useState(init ? init : "");
    useEffect(() => {
      setValue(init ? init : "");
    }, [init]);

    const handleChange = e => {
      const newEvent = getNewEventObj(init, e.target.value, e.target.name);
      setValue(e.target.value);
      onChange(newEvent);
    };

    return (
      <AwFormFieldWrapper
        name={name}
        label={label}
        required={required ? required : false}
      >
        <div
          className="ui input cust-semantic input"
          style={{ width: "100%", position: "relative" }}
        >
          <div
            onClick={handleClick}
            style={{
              position: "absolute",
              right: "15px",
              top: "8px",
              cursor: "pointer"
            }}
          >
            <FontAwesomeIcon icon={show.icon} />
          </div>
          <input
            className="form-control"
            autoComplete={"off"}
            name={name}
            type={show.type}
            value={value}
            onChange={handleChange}
          />
        </div>
      </AwFormFieldWrapper>
    );
  }
);

export default AwPasswordInputFormField;
