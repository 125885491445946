import React from "react";
import PropTypes from "prop-types";
import { getFieldLabelFromFieldName } from "../../utils";

const AwLabel = ({ htmlFor, label, required }) => {
  return (
    <label
      style={{ display: "block", fontWeight: "700" }}
      htmlFor={htmlFor}
      className="aw-no-user-select"
    >
      {getFieldLabelFromFieldName(label)}
      {required && <span style={{ color: "red" }}> *</span>}
    </label>
  );
};

AwLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool
};
export default AwLabel;
