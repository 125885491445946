import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DashboardDownload({ styles, iconName, ...props }) {
  return (
    <td style={{ color: "#186ba0", ...styles }} className="download-td">
      <span className={"aw-icons"}>
        <a
          href={
            "api/StateReports/" + props.dataItem["StateReportId"] + "/Download"
          }
        >
          <FontAwesomeIcon icon={iconName} />
        </a>
      </span>
    </td>
  );
}
