import { useRef, useState, useContext } from "react";
import { useForm, useAwToastR } from "../../../../components";
import {
  strToIntArr,
  reportDateUtc,
  endDateExclusive,
  isResponseError,
  getSelectionArray
} from "../../../../utils";
import api from "../../../../store/api";
import { GridContext } from "../../AwPageContainer";

export const useCreateReportFunc = (resetLoading, hideModal) => {
  const base_url = `api/StateReports/Create`;
  const { value, setValue } = useContext(GridContext);
  const [reportCreateError, setReportCreateError] = useState({
    isError: false,
    isArr: false,
    msg: ""
  });

  const submitLabels = {
    states: "StateProvinceIds",
    pharmacies: "PharmacyIds"
  };

  const idsRef = useRef({
    [submitLabels.states]: [],
    [submitLabels.pharmacies]: []
  });

  const reportData = {
    PharmacyIds: [],
    StateProvinceIds: [],
    StartDateUtc: "",
    EndDateUtc: "",
    Prescriptions: [],
    IgnoreReport: true,
    Submit: false,
    RunBatch: false,
    ShouldSubmitZeroReports: true
  };

  const [prescriptions, setPrescriptions] = useForm({ Prescriptions: "" });

  const [modalDate, setModalDate] = useForm({
    StartDate: new Date(),
    EndDate: new Date()
  });

  const msgSvc = useAwToastR();

  const handlePharmacySelection = (original, current, label) => {
    const arr = getSelectionArray(current, original, "PharmacyId");
    idsRef.current[submitLabels.pharmacies] = arr;
  };

  const handleStateSelection = (original, current, label) => {
    const arr = getSelectionArray(current, original, "StateProvinceId");
    idsRef.current[submitLabels.states] = arr;
  };

  const handleChecks = (e) => {
    let value = false;
    if(typeof e.target.value === 'boolean') {
      value = e.target.value;
    } else if (e.target.value === 'true') {
      value = true;
    } 
    reportData[e.target.name] = value;
  }

  const createReport = async data => {
    data.IgnoreReport = reportData.IgnoreReport;
    data.Submit = reportData.Submit;
    data.ShouldSubmitZeroReports = reportData.ShouldSubmitZeroReports;
    data.RunBatch = reportData.RunBatch;
    // console.log(data);
    // return;
    const res = await api.requestmethods.post(base_url, data);
    if (res) {
      if (isResponseError(res)) {
        handleError(res.message, true, false);
      } else {
        setValue(!value);
        if (res.Messages) {
          handleError(res.Messages, true, true);
        } else {
          msgSvc.showSuccess("created");
          hideModal();
        }
      }
    } else {
      msgSvc.showSuccess("created");
      setValue(!value);
      hideModal();
    }
  };

  const handleFormSubmitAsync = e => {
    if (e) e.preventDefault();
    const checkPresLength = strToIntArr(prescriptions.Prescriptions);
    if (checkPresLength.type === "data") {
      reportData.Prescriptions = checkPresLength.data;
      reportData.PharmacyIds = idsRef.current[submitLabels.pharmacies];
      reportData.StateProvinceIds = idsRef.current[submitLabels.states];
      reportData.StartDateUtc = reportDateUtc(modalDate.StartDate);
      reportData.EndDateUtc = reportDateUtc(
        endDateExclusive(modalDate.EndDate)
      );

      const stateLength = reportData.StateProvinceIds.length;
      const presLength = reportData.Prescriptions.length;

      // if states count is more than one then there shouldn't be any count for rx
      if (stateLength > 1 && presLength) {
        handleError(
          "Sorry, you cannot select more than one state if you have the prescription field filled out!",
          true,
          false
        );
      } else {
        if (presLength > 0 || stateLength > 0 || reportData.RunBatch) {
          createReport(reportData);
          handleError("", false, false);
        } else {
          handleError(checkPresLength.message, true, false, true);
        }
      }
    } else {
      handleError(checkPresLength.message, true, false);
    }
  };

  const handleError = (msg, isError, isArr, stateAndRx) => {
    // sets modal submit button loading to false
    if (isError) {
      resetLoading();
    }

    switch (true) {
      case stateAndRx:
        msg =
          "You need to either select a state or provide a valid rx number to create a report.";
        break;
      default:
        break;
    }
    setReportCreateError({
      isError: isError,
      isArr: isArr,
      msg: msg
    });
  };

  return {
    handleFormSubmitAsync,
    handleChecks,
    setPrescriptions,
    setModalDate,
    reportCreateError,
    handleError,
    handlePharmacySelection,
    handleStateSelection
  };
};
