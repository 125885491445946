import React from "react";
import { Divider, Header } from "semantic-ui-react";
import { ReportSummary } from "./report-summary/ReportSummary";
import { awIcons } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAxios } from "../../../components";

export const ListOfReportSummaries = React.memo<{rxes: any[];}>(({ rxes }) => {
  const { data } = useAxios(`api/Pharmacies`, []);
  return (
    <>
      <Divider horizontal>
        <Header as="h4">
          <FontAwesomeIcon
            icon={(awIcons.capsules as any)}
            style={{ fontSize: "1.3rem", color: "#b21e1e" }}
          />
          Prescription Filled Summary
        </Header>
      </Divider>

      {rxes.map((rx, i) => (
        <ReportSummary key={i} rx={rx} pharmacies={data} />
      ))}
    </>
  );
});