import React, { useEffect, useState } from "react";
import {
  getNewEventObj,
  formatInputAsPhoneNumber,
  getDigits
} from "../../utils";

export const AwPhoneInput = ({ init, name, onChange }) => {
  const [value, setValue] = useState(init ? init : "");

  const handleChange = e => {
    const newEvent = getNewEventObj(
      init,
      getDigits(e.target.value),
      e.target.name
    );
    setValue(e.target.value);
    onChange(newEvent);
  };

  const handleOnInput = e => {
    const eValue = formatInputAsPhoneNumber(getDigits(e.target.value));
    e.target["value"] = eValue;
    valueRef.current = value;
    handleChange(e);
  };

  useEffect(() => {
    setValue(init ? init : "");
  }, [init]);

  const valueRef = React.useRef("");
  const handleKeyup = e => {
    const digits = getDigits(value);
    const refDigits = getDigits(valueRef.current);
    if (
      e.key === "Backspace" &&
      digits.length === 3 &&
      refDigits.length === 3
    ) {
      if (value.match(/(\d{3})/)) {
        handleOnInput({ target: { value: digits.substring(0, 2) } });
      }
    }
    //setValue(eValue);
  };

  return (
    <div className="ui input" style={{ width: "100%" }}>
      <input
        className="form-control"
        autoComplete={"off"}
        name={name}
        type={"text"}
        value={value}
        onChange={handleOnInput}
        onKeyUp={handleKeyup}
      />
    </div>
  );
};

export default AwPhoneInput;
