import React, { useMemo, useState, useRef } from "react";
import { AwCard, AwInputFormField, AwButton } from "../../../components";
import {
  createFormKeysObjArrFromStrArray,
  hasErrorMessage
} from "../../../utils";
import { Alert } from "../../../components/message";
import api from "../../../store/api";

const PharmacySettings = ({ pharmacy }) => {
  const settingRef = useRef(pharmacy);
  const [error, setError] = useState(null);
  const objKeys = useMemo(() => {
    return createFormKeysObjArrFromStrArray([
      "Name",
      "Address1",
      "Address2",
      "City",
      "StateProvinceId",
      "Country",
      "PostalCode",
      "PhoneNumber",
      "ContactName",
      "NationalProviderId",
      "NcpdpOrNabpId",
      "DeaNumber",
      "MontanaUniqueId"
    ]);
  }, [pharmacy]);

  const handleValueChange = e => {
    settingRef.current[e.target.name] = e.target.value;
  };
  const handleSubmit = async e => {
    const res = await api.requestmethods.put(
      "api/Pharmacies",
      settingRef.current
    );
    if (hasErrorMessage(res)) {
      setError(res.message);
    } else {
      if (error) {
        setError("");
      }
      alert("Record has been updated successfully.");
    }
  };

  return (
    <AwCard labelKey={pharmacy.Name} label="Settings">
      <form className="row">
        <div className="col-lg-12">
          {error && (
            <Alert
              align="center"
              message="Sorry, update failed. Try again later."
            />
          )}
        </div>
        {objKeys.length > 0 &&
          objKeys.map((field, idx) => {
            return (
              <div key={idx} className="col-lg-12">
                <AwInputFormField
                  init={pharmacy[field.name]}
                  name={field.name}
                  label={field.label}
                  onChange={handleValueChange}
                  required={false}
                />
              </div>
            );
          })}
        <div className="col-lg-12 text-right">
          <div style={{ width: "70px" }}>
            <AwButton label="Save" loading={false} btnClicked={handleSubmit} />
          </div>
        </div>
      </form>
    </AwCard>
  );
};

export default PharmacySettings;
