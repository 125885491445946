import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AwCard } from "../../../../components";
import { getSegmentNameWithMeaning } from "./AsapDef";
import { getSpecificDate, awIcons, awConfirmDelete } from "../../../../utils";

export const DisplayAsapNote = ({ asap, editNote, deleteNote }) => {
  const handleDeleteClick = async e => {
    const isTrue = await awConfirmDelete();
    if (isTrue) {
      deleteNote(asap);
    }
  };

  const handleEditClick = e => {
    editNote(asap);
  };
  return (
    <div>
      <AwCard
        labelKey={asap.AsapFormat}
        label={getSegmentNameWithMeaning(asap.AsapSegment)}
      >
        <div style={{ display: "block", width: "100%" }}>
          <small className="text-muted">
            <em>Created By: </em> {asap.CreatedBy} <em>On: </em>
            {getSpecificDate(asap.CreatedDate)}
          </small>
          <div className="asap-info">
            <h5 className="card-title">{asap.AsapSegmentField}</h5>
            <ul style={{ marginLeft: "1.3em" }}>
              <li>{asap.Note}</li>
            </ul>
          </div>
        </div>
        <div
          className="asap-actions"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <span className="asap-edit-i" onClick={handleEditClick}>
            <FontAwesomeIcon icon={awIcons.edit} />
          </span>

          <span className="asap-delete-i" onClick={handleDeleteClick}>
            <FontAwesomeIcon icon={awIcons.delete} />
          </span>
        </div>
      </AwCard>
    </div>
  );
};

export default DisplayAsapNote;
