import React from 'react'
import AwCheckBox from '../../../../../components/form-atoms/AwCheckBox'
interface Props {
    onCheck: any;
    name?: string;
    type?: string;
    init?: boolean;
}
export const ReportErrorCheckBoxField: React.FC<Props> = ({name="CanReceiveReportText", type = "text", init = false, onCheck}) => {
    return (
        <div className="form-group" style={{ fontWeight: 700 }}>
            <AwCheckBox init={init} name={name} label={`Send user statereport error ${type}.`} handleCheck={onCheck} />
        </div>
    )
}
