import React, { useEffect, useState } from "react";
import { getNewEventObj, formatInputAsZipCode } from "../../utils";

export const AwZipCodeInput = ({ init, name, onChange }) => {
  const [value, setValue] = useState(init ? init : "");

  const handleChange = e => {
    const newEvent = getNewEventObj(init, e.target.value, e.target.name);
    setValue(e.target.value);
    onChange(newEvent);
  };

  const handleOnInput = e => {
    let eValue = formatInputAsZipCode(e.target.value);
    e.target["value"] = eValue;
    // console.log(eValue);
    handleChange(e);
  };

  useEffect(() => {
    setValue(init ? init : "");
  }, [init]);

  return (
    <div className="ui input" style={{ width: "100%" }}>
      <input
        className="form-control"
        autoComplete={"off"}
        name={name}
        type={"text"}
        value={value}
        onChange={handleOnInput}
      />
    </div>
  );
};

export default AwZipCodeInput;
