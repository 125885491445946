import React, { useState } from "react";
import {
  reportingMethod,
  frequency,
  fileFormat,
  zeroProcess,
  rxGroup,
  autoPostType,
  fileCompression,
  inputTypes
} from "../../../../defs";
import { divideFormGroupsIntoCols, awShowToastr } from "../../../../utils";
import AwFormField from "../../../../components/form-molecules/AwFormField";
import AwButton from "../../../../components/button/AwButton";
import { AwFormSelectField } from "../../../../components/form-molecules/AwFormSelectField";
import api from "../../../../store/api";
import { useFormRef, useAwToastR } from "../../../../components";

export const SystemForm = React.memo(({ selectedStateSetting }) => {
  const base_url = "api/statesettings";
  let statesettings = JSON.parse(JSON.stringify(selectedStateSetting));
  const [loading, setLoading] = useState(false);
  const colRef = React.useRef(2);
  let formColGroup = getFormArray(2);
  const [formObj, setRefValues] = useFormRef(selectedStateSetting);

  const msgSvc = useAwToastR();

  const handleFormSubmit = async () => {
    // for changes to AutoPostUsername &&  AutoPostPassword, set id to 1
    // setting id cuz the server will use that to determine if pharmacyStateSettings needs to change
    let pss = 0;
    if (
      statesettings.AutoPostUsername !== formObj.AutoPostUsername ||
      statesettings.AutoPostPassword !== formObj.AutoPostPassword
    ) {
      pss = 1;
    }
    const new_base_url = `${base_url}/${pss}`;
    setLoading(true);
    const res = await api.requestmethods.put(new_base_url, formObj);
    awShowToastr(msgSvc, res, "saved");
    setLoading(false);
  };

  return (
    <form className="row">
      {colRef.current > 1 && (
        <>
          {formColGroup.map((groupArray, i) => {
            return (
              <div key={i} className="col-md-6">
                {groupArray.map((group, j) => {
                  return (
                    <div key={j}>
                      {getGroup(
                        group,
                        selectedStateSetting[group.name],
                        setRefValues
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}

      {/* <div className="col-md-6 right-col">
        {formGroups.map((group, i) => {
          return (
            <div key={i}>
              {getGroup(group, selectedStateSetting[group.name])}
            </div>
          );
        })}
      </div> */}
      <div className="offset-md-6 col-md-6 text-right">
        <div style={{ width: "120px", float: "right" }}>
          <AwButton
            width="100%"
            label="Save Changes"
            loading={loading}
            btnClicked={handleFormSubmit}
          />
        </div>
      </div>
    </form>
  );
});

const getFormArray = cols => {
  if (cols > 1) {
    return divideFormGroupsIntoCols(formArray);
  }
  return formArray;
};
const formArray = [
  {
    inputType: inputTypes.select,
    name: "ReportingMethodId",
    label: "Reporting Method",
    options: reportingMethod
  },
  {
    inputType: inputTypes.select,
    name: "FrequencyId",
    label: "Reporting Frequency",
    options: frequency
  },
  {
    inputType: inputTypes.select,
    name: "FormatId",
    label: "File Format",
    options: fileFormat
  },
  {
    inputType: inputTypes.text,
    name: "FileNameFormat",
    label: "Filename Format",
    options: []
  },
  {
    inputType: inputTypes.select,
    name: "ZeroProcessId",
    label: "Zero Process",
    options: zeroProcess
  },
  {
    inputType: inputTypes.select,
    name: "RxGroupId",
    label: "Rx Group",
    options: rxGroup
  },
  {
    inputType: inputTypes.select,
    name: "AutoPostTypeId",
    label: "Upload Method",
    options: autoPostType
  },
  {
    inputType: inputTypes.select,
    name: "FileCompressionId",
    label: "File Compression",
    options: fileCompression
  },
  {
    inputType: inputTypes.text,
    name: "AutoPostUrl",
    label: "Auto Post URL",
    options: []
  },
  {
    inputType: inputTypes.text,
    name: "AutoPostUsername",
    label: "Auto Post Username",
    options: []
  },
  {
    inputType: inputTypes.text,
    name: "AutoPostPassword",
    label: "Auto Post Password",
    options: []
  },
  {
    inputType: "webendpoint",
    name: "WebServiceAuthEndpoint",
    label: "Web Service Auth Endpoint",
    options: []
  }
];
const getGroup = (data, dValue, onChangeFunc) => {
  switch (data.inputType) {
    case inputTypes.select:
      return (
        <AwFormSelectField
          options={data.options}
          optionsLabel={data.label}
          name={data.name}
          dValue={dValue}
          onSelectChange={onChangeFunc}
        />
      );
    case inputTypes.webEndPoint:
    case inputTypes.text:
      return (
        <AwFormField
          name={data.name}
          dValue={dValue ? dValue : ""}
          onChange={onChangeFunc}
        />
      );
    default:
      break;
  }
};
