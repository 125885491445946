import {
  signIn_type,
  signOut_type,
  signInSuccess_type,
  signInFailed_type,
  profileImageUploadSuccess_type
} from "../actiontypes";

export const signIn = request => ({ type: signIn_type, payload: request });
export const signInSuccess = payload => ({ type: signInSuccess_type, payload });
export const profileImageUploadSuccess = payload => ({
  type: profileImageUploadSuccess_type,
  payload
});
export const signInFailed = payload => ({ type: signInFailed_type, payload });

export const signOut = () => ({ type: signOut_type, payload: null });
