import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./GridHeaderStyles.css";

const memoizeGridHeader = ({ iconName, ...props }) => {
  return (
    <span className={"grid-header-icons"}>
      <FontAwesomeIcon icon={iconName} />
    </span>
  );
};

memoizeGridHeader.propTypes = {
  iconName: PropTypes.string.isRequired
};

const GridHeader = React.memo(memoizeGridHeader);
export default GridHeader;
