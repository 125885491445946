import { getStateName } from "../../../../utils";

const getPropertyValueIntoLabelDetails = (currentObject, _extraArgs) => {
  let retValue = [];

  if (_extraArgs.length > 0) {
    _extraArgs.forEach(item => {
      retValue.push(currentObject[item]);
    });
  }
  return retValue;
};

const getCurrentObj = (currentObject={}, _extraArgs=[]) => {
  currentObject["LabelDetails"] = getPropertyValueIntoLabelDetails(
    currentObject,
    _extraArgs
  );
  return currentObject;
}

export const grouping = (myArr, groupBy, ..._extraArgs) => {
  const newArr = [];
  const details = {};
  let i, j, currentObject;
  for (i = 0, j = myArr.length; i < j; i++) {
    currentObject = myArr[i];
    if (!(currentObject[groupBy] in details)) {
      details[currentObject[groupBy]] = {
        [groupBy]: currentObject[groupBy],
        Details: []
      };
      newArr.push(details[currentObject[groupBy]]);
    }
    currentObject = getCurrentObj(
      currentObject,
      _extraArgs
    );
    details[currentObject[groupBy]].Details.push(currentObject);
  }

  return newArr;
};



export const spliceArray = (arr, index) => {
  return arr.filter((v, i) => i !== index);
};

export const modifyDocRes = (res) => {
    let data = res.map(item => getCurrentObj(item, ["ProductStrength"]));
    return data;
}
