import * as layoutTypes from "../actiontypes";
import { IAction } from "../actiontypes";

export interface IFwState {
  HideAllNavigationIcons: boolean;
  HideMenuAreaBar: boolean;
  IsHorizontalMenuBar: boolean;
}

const initState: IFwState = {
  HideAllNavigationIcons: true,
  HideMenuAreaBar: true,
  IsHorizontalMenuBar: true
};

export const layoutReducer = (state = initState, action: IAction): IFwState => {
  switch (action.type) {
    case layoutTypes.hideAllNavigationIcons:
      if (
        action.payload &&
        action.payload.HideAllNavigationIcons &&
        action.payload.HideAllNavigationIcons === true
      ) {
        return {
          ...state,
          HideAllNavigationIcons: true,
          HideMenuAreaBar: true,
          IsHorizontalMenuBar: true
        };
      }
      return {
        ...state,
        HideAllNavigationIcons: action.payload
      };
    case layoutTypes.hideMenuAreaBar:
      return {
        ...state,
        HideMenuAreaBar: action.payload
      };
    case layoutTypes.setMenuBarOrientation:
      return {
        ...state,
        IsHorizontalMenuBar: !state.IsHorizontalMenuBar
      };
    default:
      return state;
  }
};
