import React from "react";
import PropTypes from "prop-types";

import { AwDeleteData } from "../../form-molecules";
import { useDetermineCanDelete } from "./useDetermineCanDelete";

export default function DeleteGridRow({
  styles,
  user,
  dataItem,
  isDataNeeded,
  onDelete,
  pageName
}) {
  
  const { canDelete } = useDetermineCanDelete(pageName, dataItem, user);


  const handleConfirm = () => {
    if (isDataNeeded) {
      onDelete(dataItem);
    } else {
      onDelete(dataItem.StateReportId);
    }
  };

  return (
    <td style={styles} className="delete-td">
      {canDelete ? <AwDeleteData onDelete={handleConfirm} /> : null }
    </td>
  );
}

DeleteGridRow.propTypes = {
  onDelete: PropTypes.func.isRequired
};
