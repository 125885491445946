import React, { useRef, useEffect, useState } from 'react';
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { AwCard, DisplayGrid, useGridRefreshFunc, useAwToastR, AwButton, useWindowResize } from '../../../../components';
import { UsersGridBeginingCols, getUserGridCol } from '../AdminUtil';
import api from '../../../../store/api';
import { awShowToastr, awConfirmDelete } from '../../../../utils';


interface Props {
    shouldRefresh: boolean;
    refresh: () => any;// fxn
}

export const DeletedUsersGrid: React.FC<Props> = ({shouldRefresh, refresh}) => {
    const remove_base_url = "api/superuser/users";
    const user_base_url = "/api/superuser/users";



    const {gridRefresh, handleGridRefresh} = useGridRefreshFunc();

    useEffect(() => {
        handleGridRefresh();
    }, [shouldRefresh])
    const msgSvc = useAwToastR();
    
    const col = getUserGridCol();
    const beginningCol = UsersGridBeginingCols();

    const handleRefresh = () => {
        handleGridRefresh();
        refresh();
    }

    const handleReactivateAsync = async (data: any) => {
        const res = await api.requestmethods.get(`${user_base_url}/reactivateUserasync?email=${data.Email}`);
        const success = awShowToastr(msgSvc, res, 'reactivated');
        if(success) handleRefresh();
    }

    const removeUserAsync = async (data: any) => {
        const res = await api.requestmethods.delete(remove_base_url, `id=${data.Id}`);
        const { response } = res;
        let { message } = response;
        if(response.status === 200) message = response.data;
        const success = awShowToastr(msgSvc, response, message, true);
        if(success) handleRefresh();
    }


    const userActionsCol = DeletedUserActionsCol(handleReactivateAsync, removeUserAsync);

    
    return (
        <div className="deleted-users-grid mt-5" style={{}}>
            <AwCard
                labelKey={"Users"}
                label={"Deleted"}
                showPlus={false}
                addRow={null}>
                    <DisplayGrid
                        tableName="users"
                        url={user_base_url}
                        urlExt={null}
                        columns={col}
                        refresh={gridRefresh}
                        showExport={true}>
                            <>{beginningCol.props.children}</>
                            <>{userActionsCol.props.children}</>
                        </DisplayGrid>
                </AwCard>
        </div>
    )
};


const DeletedUserActionsCol = (reactivate: (user: any) => any, removeUser: (user: any) => any) => {
    return (
        <>
            <Column
                title="Actions"
                filterable={false}
                cell={props => {
                    return (
                        <td>
                            <UserAction reactivateFxn={() => reactivate(props.dataItem)} removeFxn={() => removeUser(props.dataItem)}/>
                        </td>
                    )
                }}>
            </Column>
        </>
    )
}

interface IProps {
    reactivateFxn: () => any;
    removeFxn: () => any;
}

const UserAction: React.FC<IProps> = ({reactivateFxn, removeFxn}) => {
    const actionRef = useRef<any>();
    // const [screen] = useWindowResize();
    // const res = "trash-restore-alt";
    // const rem = "trash-alt";
    // const [isLarge, setIsLarge] = useState(false);
    
    // console.log('type', screen)

    const handleRemove = async () => {
        if(await awConfirmDelete("This record will be deleted permanently!")) {
            removeFxn();
        }
    }
    
    // useEffect(() => {
    //     if(screen.type === 'smallscreen'){
    //         if(screen.width < 1258) {
    //             setIsLarge(false);
    //         }
    //     }
    // }, [screen])
    return (
        <div className="su-d-actions" ref={actionRef}>
            <TitleWrapper title="Reactivate User Account">
            {/* isLarge ? '70px' : '40px' */}
                <AwButton ref={null} {...{btnClicked: reactivateFxn, icon: "trash-restore-alt",  width: '30px'}} />
            </TitleWrapper>
            
            <TitleWrapper title="Remove User Account">
                <AwButton ref={null} {...{btnClicked: handleRemove, icon: "trash-alt",  type: 'danger', width: '30px'}} />
            </TitleWrapper>
        </div>
    )
}


interface P {
    title: string;
    children?:any;
}
const TitleWrapper: React.FC<P> = ({title, ...props}) => {
    return (<span title={title}>{props.children}</span>)
}
{/* <div className="deleted-users-grid">
            <AwCard
                labelKey={"Users"}
                label={"Deleted"}
                showPlus={false}
                addRow={null}
                >
                <DisplayGrid
                    tableName="users"
                    url="api/admin/users"
                    urlExt={null}
                    columns={col}
                    refresh={refreshGrid}
                    showExport={true}
                >
                    
                </DisplayGrid>
            </AwCard>
        </div> */}
