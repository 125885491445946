import { IUserLoginResponse } from "../interfaces";
import { StateReportUser } from "../models";

interface IToken {
  Token: string;
}

const separator = "questsec";
const user_key = "user_key";

function setLoggedInUser(token: string) {
  const newUser = { Token: token };
  sessionStorage.setItem(user_key, JSON.stringify(newUser));
}

export const getRequestHeaders = (isFile: boolean = false) => {
  const token = getToken();
  let headers = {
    Authorization: token ? `Bearer ${token}` : "",
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  if (isFile) {
    headers = Object.assign({}, headers, {
      "Content-Type": "multipart/form-data",
      responseType: "blob"
    });
  }

  return headers;
};
export const setTokenAndGetUserData = (
  loggedInResponse: IUserLoginResponse
): StateReportUser => {
  let data: IUserLoginResponse = JSON.parse(JSON.stringify(loggedInResponse));
  let stateReportUser = new StateReportUser();
  const role = data.Role[0];
  const basicAccess = (role === "Manager" || role === "Admin" || role === "SuperUser");
  const adminAccess = (role === "Admin" || role === "SuperUser");
  stateReportUser.Token = adjustToken(data.access_token, data.refresh_token);
  stateReportUser.UserName = data.FirstName;
  stateReportUser.IsAuthenticated = true;
  stateReportUser.Role = role;
  stateReportUser.canAccessBasicFeatures = basicAccess;
  stateReportUser.canAccessAdminPage = adminAccess;
  stateReportUser.UserId = data.UserId;
  stateReportUser.Profile = data.Profile;
  stateReportUser.Email = data.Email;
  stateReportUser.expires_in = data.expires_in;
  setLoggedInUser(stateReportUser.Token);
  return stateReportUser;
};

const adjustToken = (token: string, refreshToken: string) => {
  return `${token}${separator}${refreshToken}`;
};

export const getToken = (): string => {
  const user: IToken = getLoggedInUser();

  if (Object.keys(user).length > 0) {
    const tokenArr = user.Token.split(separator);
    return tokenArr[0];
  }
  return "";
};

export const getLoggedInUser = () => {
  const user = sessionStorage.getItem(user_key) || "{}";
  return JSON.parse(user);
};

export const removeLoggedInUser = () => {
  sessionStorage.removeItem(user_key);
  sessionStorage.clear();
};

export function getRefreshToken(auth: IToken): string {
  const tokenArr = auth.Token.split(separator);
  return tokenArr[1];
}

interface IProfileObj {
  email: string;
  familyName: string;
  givenName: string;
  googleId: string;
  imageUrl: string;
  name: string;
}

interface IExternalLoginInfo {
  LoginProvider: string;
  ProviderKey: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Image: string;
}

export const getGoogleAuthLoginObj = (profileObj: IProfileObj) => {
  const userLoginInfo: IExternalLoginInfo = {
    LoginProvider: "google",
    ProviderKey: profileObj.googleId,
    Email: profileObj.email,
    FirstName: profileObj.givenName,
    LastName: profileObj.familyName,
    Image: profileObj.imageUrl
  };

  return userLoginInfo;
};

export const isAuthError = (data: any) => {
  return data.message.indexOf("401") > -1;
};

// export const removeUserCredentials = (): void => {
//     removeLoggedInUser();
//     stateReportUser.UserName = '';
//     stateReportUser.IsAuthenticated = false;
//     stateReportUser.Role = '';
// }
