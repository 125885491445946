// import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { all } from "redux-saga/effects";
import { watchSignInAsync } from "../store/sagas/authSagas";
import { watchGetDashboardReportAsync } from "../store/sagas/dashboardSagas";

import { rootReducer } from "./rootReducer";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: "MedQuest",
        actionsBlacklist: ["REDUX_STORAGE_SAVE"]
      })
    : compose;

function configureStore(saga) {
  // export default function configureStore(routeHistory, initialState) {
  const enhancer = composeEnhancers(
    applyMiddleware(saga)
    // other store enhancers if any
  );
  // return createStore(rootReducer, composeWithDevTools(applyMiddleware(saga)));
  return createStore(rootReducer, enhancer);
}

const sagaMiddlware = createSagaMiddleware();
export const store = configureStore(sagaMiddlware);

function* rootSaga() {
  yield all([watchSignInAsync(), watchGetDashboardReportAsync()]);
}

export const runSagaMiddlware = () => {
  sagaMiddlware.run(rootSaga);
};
