import React from "react"
import { AwPrimeMinMaxCalender, AwPrimeReactCalendar } from "../../form-atoms"


interface Props {
    onDateSelected: (date: any) => void,
    label: string,
    type: string,
    isVertical?: boolean
    init?: Date
}

export const AwDateField: React.FC<Props> = ({onDateSelected, label, type, init = new Date(), isVertical = true}) => {
    return (
        <div className={`${isVertical ? "col-6" : "horizontal"}`}>
            <label>{label}</label>
            <AwPrimeReactCalendar
              type={type}
              init={init}
              dateSelected={onDateSelected}
            />
        </div>
    )
}


interface PropsExtra extends Props {
    [x: string]: any
}


export const AwMinMaxDateField: React.FC<PropsExtra>   = ({label, type, onDateSelected, init = new Date(), isVertical = true, ...props}) => {
    return (
        <div className={`${isVertical ? "col-6" : "horizontal"}`}>
            <label>{label}</label>
            <AwPrimeMinMaxCalender
            type={type}
            init={init}
            dateSelected={onDateSelected}
            {...props}
            />
        </div>
    )
}