import React, { useState } from "react";
import RoleGrid from "./RoleGrid";
import { GridActionCol } from "../AdminUtil";
import api from "../../../../store/api";
import { awConfirmDelete } from "../../../../utils";
import { useCurrentUser } from "../../../../components";
import { UserSystemRoles } from "../../../../defs";

export const Roles = () => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { user } = useCurrentUser();

  const handleDelete = async data => {
    const isTrue = await awConfirmDelete();
    if (isTrue) {
      await api.requestmethods.delete(`api/admin/roles/${data.Id}`);
      setRefreshGrid(!refreshGrid);
    }
  };

  const pageName = "role";
  const col = (user.Role === UserSystemRoles.superUser) ? GridActionCol({ handleDelete, user, pageName }) : null;
  return (
    <div className="roles" style={{ marginTop: "1.3em" }}>
      <RoleGrid refresh={refreshGrid} extraCol={col} />
    </div>
  );
};

export default Roles;
