import React from 'react';

export const PageNotFound = () => {
    return (
        <div className="page-not-found" style={{width: "100%",
            display: "flex",
            justifyContent: "center",
            color: "red"}}>
            <h3>404 Page Not Found!</h3>
        </div>
    )
}
