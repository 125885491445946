import React from "react";
import { AwPageContainer } from "../../../AwPageContainer";
import { PharmacyGrid } from "./PharmacyGrid";

export const Pharmacy = ({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <PharmacyGrid stateProvinceId={stateProvinceId}></PharmacyGrid>
    </AwPageContainer>
  );
};

export default Pharmacy;
