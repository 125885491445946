import React from "react";
import AccountFormTitle from "./AccountFormTitle";
import {
  AwInputFormField,
  AwPasswordInputFormField
} from "../../../../components";

export const AccountForm = ({ options, dObj, onInputChange }) => {
  const typeOptionObjArr = options;
  const strOptions = typeOptionObjArr.map((obj, i) => obj.Description);
  return (
    <form className="state-account-modal-form" autoComplete="off">
      <AccountFormTitle
        options={strOptions}
        dValue={Object.keys(dObj).length > 0 ? dObj["Type"] : null}
        onTypeSelected={onInputChange}
      />
      {accountColumns.map((field, i) => {
        return field.isPassword ? (
          <AwPasswordInputFormField
            key={i}
            required={field.required ? false : true}
            init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
            name={field.name}
            label={field.label}
            onChange={onInputChange}
          />
        ) : (
          <AwInputFormField
            key={i}
            required={field.required ? false : true}
            init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
            name={field.name}
            label={field.label}
            onChange={onInputChange}
          />
        );
      })}
    </form>
  );
};

export default AccountForm;

const accountColumns = [
  { name: "Title", label: "Title", required: true },
  // required is set to true eventhough is not required cuz required is set to true by default and most of the fields are required anyways
  // { field: "Type", header: "Type" },
  { name: "Url", label: "Web Address" },
  { name: "UserName", label: "Username" },
  {
    name: "Password",
    label: "Password",
    filterable: true,
    isPassword: true
  } // filterable will set this col to false
];
