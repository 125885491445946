import React, { useState, useEffect, useContext } from "react";
import {
  AwGridTopBtnLogic,
  DisplayGrid,
  useAwToastR,
  useWindowResize,
  useGridRefreshFunc
} from "../../../components";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { GridCellWithLink } from "../../../components";
import { useSelector } from "react-redux";
import GridHeader from "../../../components/grid/GridHeader";
import { awIcons, awConfirmDelete, awConfirmWithUser } from "../../../utils";
import DeleteGridRow from "../../../components/grid/delete/DeleteGridRow";
import DashboardSubmit from "./DashboardSubmit";
import DashboardDownload from "./DashboardDownload";
import api from "../../../store/api";
import AwCheckBox from "../../../components/form-atoms/AwCheckBox";
import { GridContext } from "../AwPageContainer";
import { ReportStatusSelect } from "./select";
import { useLocation } from "react-router-dom";
import { API_URLS } from "../../../infrastructure/api-urls";



const styles = { textAlign: "center", fontSize: "16px" };

export const DashboardGrid = () => {
  const location = useLocation();
  const base_url = API_URLS.statereports;
  const [allReports, setAllReports] = useState(true);

  const dbStateSettings = useSelector(
    state => state.settings.DashboardStateSettings
  );

  const selectedReportRef = React.useRef([]);
  const [columns, setColumns] = useState(getColumns());

  // custom-hooks
  const msgSvc = useAwToastR();
  const [screen] = useWindowResize();
  const { gridRefresh, handleGridRefresh } = useGridRefreshFunc();
  // refresh dashboard after create modal has been submitted
  const { value } = useContext(GridContext);

  const [prevRefreshValue, setRefreshValue] = useState(value);
  useEffect(() => {
    // prevents resetting of previous cache response on initial
    if (
      value !== prevRefreshValue ||
      (location.state && location.state.reset)
    ) {
      handleGridRefresh();
      setRefreshValue(value);
    }
    return () => {};
  }, [value]);

  useEffect(() => {
    setColumns(state => setScreenColumns(screen.width));
  }, [screen]);

  const setScreenColumns = width => {
    const newCol = getColumns();
    if (width < 1001) {
      return newCol.filter((item, idx) => idx < 3);
    } else if (width > 1000 && width < 1400) {
      return newCol.filter((item, idx) => idx < 5);
    } else {
      return newCol;
    }
  };

  const handleStatusChange = async report => {
    // report is the report with the new status change
    const res = await api.requestmethods.put(
      `${base_url}/UpdateStatus`,
      report
    );

    if (Object.keys(res || {}).length && res["StateReportId"]) {
      // res is suppose to be the return report obj
      // if it does have statereport then update was successful
      msgSvc.showSuccess("updated");
      handleGridRefresh();
    } else {
      // there was an error
      msgSvc.showError("updated");
    }
  };

  const handleCheck = (e, data) => {
    if (e.target.checked) {
      addSelectedId(data.StateReportId);
    } else {
      removeSelectedId(data.StateReportId);
    }
  };

  const addSelectedId = id => {
    selectedReportRef.current.push(id);
  };

  const removeSelectedId = id => {
    selectedReportRef.current = selectedReportRef.current.filter(
      item => item !== id
    );
  };

  const handleDeleteAll = async () => {
    if (selectedReportRef.current.length > 0) {
      if (await awConfirmDelete()) {
        const res = await api.requestmethods.deleteAll(
          base_url,
          selectedReportRef.current
        );
        setLoadingErrorOrRefreshGrid(res);
      }
    }
  };

  const handleSubmitAll = async () => {
    if (selectedReportRef.current.length > 0) {
      if (
        await awConfirmWithUser("The selected report(s) will be submitted!")
      ) {
        submitAsync(selectedReportRef.current);
      }
    }
  };

  const onSubmitReport = async reportId => {
    if (
      reportId &&
      (await awConfirmWithUser("The selected report will be submitted!"))
    ) {
      submitAsync([reportId]);
    }
  };

  const submitAsync = async array => {
    const res = await api.requestmethods.submitAll(`${base_url}/submit`, array);
    setLoadingErrorOrRefreshGrid(res, true);
  };

  const onDeleteReport = async reportId => {
    if (await awConfirmDelete()) {
      const res = await api.requestmethods.delete(`${base_url}/${reportId}`);
      setLoadingErrorOrRefreshGrid(res);
    }
  };

  const setLoadingErrorOrRefreshGrid = (res, isSubmit = false) => {
    const msg = isSubmit ? "submitted" : "updated";
    if (res && res.isError) {
      msgSvc.showError(msg);
    } else {
      if (selectedReportRef.current.length > 0) {
        selectedReportRef.current.forEach(id => {
          removeSelectedId(id);
        });
      }
      handleGridRefresh();
      // handleGridRefresh();
      msgSvc.showSuccess(msg);
    }
  };

  const handleReportTypeChanged = bool => {
    setAllReports(bool);
  };

  return (
    <div className="dashboard-grid">
      <AwGridTopBtnLogic
        table="dashboard"
        deleteAll={handleDeleteAll}
        submitAll={handleSubmitAll}
        onReportTypeChanged={handleReportTypeChanged}
      />
      
      <DisplayGrid
        tableName="dashboard"
        url={API_URLS.getreports}
        urlExt={null}
        columns={[]}
        showExport={true}
        reportsettings={dbStateSettings}
        refresh={gridRefresh}
        allReports={allReports}
      >
        <>
          <Column
            className="checkbox-col-td"
            title="Select"
            width="60px"
            filterable={false}
            headerCell={props => (
              <GridHeader {...props} iconName={awIcons.checkmark} />
            )}
            cell={props => {
              return (
                <td>
                  <AwCheckBox
                    name="Id"
                    init={false}
                    handleCheck={e => handleCheck(e, props.dataItem)}
                  />
                </td>
              );
            }}
          ></Column>

          <Column
            className="added-col-td"
            title="Download"
            width="60px"
            filterable={false}
            headerCell={props => (
              <GridHeader {...props} iconName={awIcons.download} />
            )}
            cell={props => {
              return (
                <DashboardDownload
                  styles={styles}
                  iconName={awIcons.download}
                  {...props}
                />
              );
            }}
          ></Column>

          <Column
            className="added-col-td submit-col"
            title="Submit"
            width="60px"
            filterable={false}
            headerCell={() => <GridHeader iconName={awIcons.submit} />}
            cell={props => {
              return (
                <DashboardSubmit
                  styles={styles}
                  submit={onSubmitReport}
                  {...props}
                />
              );
            }}
          ></Column>

          {columns.map((column, index) =>
            column.field === "StatusId" ? (
              <Column
                key={column.field}
                field={column.field}
                title={column.header}
                cell={props => {
                  return (
                    <ReportStatusSelect
                      key={column.field}
                      columIndex={index}
                      styles={styles}
                      statusChanged={handleStatusChange}
                      {...props}
                    />
                  );
                }}
              />
            ) : (
              <Column
                key={column.field}
                field={column.field}
                title={column.header}
                width={
                  column.field === "StateProvinceId" ||
                  column.field === "RecordCount"
                    ? "120px"
                    : ""
                }
                cell={props =>
                  column.field === "StateProvinceId" ? (
                    <GridCellWithLink {...props} />
                  ) : (
                    <td>{props.dataItem[column.field]}</td>
                  )
                }
              ></Column>
            )
          )}
        </>
        <>
          <Column
            className="added-col-td delete-col"
            title="Delete"
            width="60px"
            filterable={false}
            headerCell={() => <GridHeader iconName={awIcons.delete} />}
            cell={props => {
              return (
                <DeleteGridRow
                  styles={styles}
                  onDelete={onDeleteReport}
                  {...props}
                />
              );
            }}
          ></Column>
        </>
      </DisplayGrid>
    </div>
  );
};

const getColumns = () => {
  return [
    { field: "StateProvinceId", header: "State" },
    { field: "RecordCount", header: "Records" },
    { field: "StatusId", header: "Status" },
    { field: "DateRangeString", header: "Report Date(s)" },
    { field: "CreatedDate", header: "Submitted Date" },
    { field: "ReportingMethod", header: "Reporting Method" },
    { field: "CreatedBy", header: "Created By" }
  ];
};
