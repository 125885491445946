import React from "react";
import "./FancyFieldStyle.css";

export const FancyTextField = ({
  label,
  name,
  type = "text",
  onChange,
  isWhiteBg = false,
  required = false
}) => {
  const [value, setValue] = React.useState("");
  const handleChange = e => {
    onChange(e);
    setValue(e.target.value);
  };

  return (
    <div className={"fancy-text " + (isWhiteBg && "white-bg")}>
      <p>
        {label} {required && <em className="text-danger">*</em>}
      </p>
      <input
        className={"fancy-input"}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        required
      />
    </div>
  );
};
