export const nodeDataType = {
  standard: "ASAP Folder",
  segment: "Segment Folder"
};

export const nodeKeys = {
  grandParent: "grandparent",
  parent: "parent",
  child: "child"
};

export const standardLabels = {
  "50": "ASAP 5.0",
  "42B": "ASAP 4.2B",
  "42A": "ASAP 4.2A",
  "42": "ASAP 4.2",
  "41": "ASAP 4.1",
  "40": "ASAP 4.0",
  "30": "ASAP 3.0",
  "95": "ASAP 95",
  PR: "Puerto Rico"
};
const standardKeys = ["50", "42B", "42A", "42", "41", "40", "30", "95", "PR"];

export const segments = {
  pat: "PAT",
  dsp: "DSP",
  pre: "PRE",
  cdi: "CDI",
  air: "AIR"
};

const segmentLabels = {
  pat: "Patient Information",
  dsp: "Dispensing Record",
  pre: "Prescriber Information",
  cdi: "Compound Drug Ingredient Detail",
  air: "Additional Information Reporting"
};

export const getSegmentNameWithMeaning = segment => {
  if (segment) {
    return `${segment} - ${segmentLabels[segment.toLowerCase()]}`;
  }
  return segment;
};

const segmentKeys = ["pat", "dsp", "pre", "cdi", "air"];
export const optionsNames = {
  standard: "AsapFormat",
  segment: "AsapSegment",
  field: "AsapSegmentField"
};

export const getAsapFieldOptions = fieldName => {
  return getAsapSegmentFieldNames(fieldName);
};
export const getAsapOtions = asapType => {
  let standards = {};
  let keys = [];
  let optionName = null;
  switch (asapType) {
    case optionsNames.standard:
      standards = Object.assign({}, standardLabels);
      keys = standardKeys.slice();
      break;
    case optionsNames.segment:
      standards = Object.assign({}, segments);
      keys = segmentKeys.slice();
      optionName = asapType;
      break;
  }
  return getOptions(standards, keys, optionName);
};

function getOptions(standards, keys, option) {
  const options = keys.map((key, i) => {
    let value = standards[key];
    return {
      value: value,
      key: !!option ? getUniqueOptionKey(value, key, option) : value
    };
  });
  return options;
}

function getUniqueOptionKey(value, key, option) {
  if (option === optionsNames.field) {
    return `${value} - ${segmentLabels[key]}`;
  } else {
    return `${value} - ${segmentLabels[key]}`;
  }
}

export function getAsapBasicStructure() {
  const standards = getAsapStandardStructure() || [];
  return standards.map((standard, i) => {
    let label = nodeKeys.grandParent;
    let key = (label += ` - ${i}`);
    standard["key"] = key;
    standard["parent"] = "";
    const children = getAsapStandardSegments();
    standard.children = children.map((child, j) => {
      let parentKey = nodeKeys.parent;
      parentKey = parentKey += ` - ${j}`;
      child["key"] = parentKey;
      child["parent"] = standard.label;
      return child;
    });
    return standard;
  });
}

export function getAsapStandardStructure() {
  return [
    {
      key: 0,
      label: standardLabels["50"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 1,
      label: standardLabels["42B"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 2,
      label: standardLabels["42A"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key:3,
      label: standardLabels["42"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",

      children: []
    },
    {
      key: 4,
      label: standardLabels["41"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",

      children: []
    },
    {
      key: 5,
      label: standardLabels["40"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",

      children: []
    },
    {
      key: 6,
      label: standardLabels["30"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 7,
      label: standardLabels["95"],
      data: "ASAP Folder",
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 8,
      label: standardLabels["PR"],
      data: "Puerto Folder",
      icon: "pi pi-fw pi-inbox",
      children: []
    }
  ];
}

export function getAsapStandardSegments() {
  return [
    {
      key: 0,
      label: "PAT",
      data: nodeDataType.segment,
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 1,
      label: "DSP",
      data: nodeDataType.segment,
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 2,
      label: "PRE",
      data: nodeDataType.segment,
      icon: "pi pi-fw pi-inbox",
      children: []
    },
    {
      key: 3,
      label: "CDI",
      data: nodeDataType.segment,
      icon: "pi pi-fw pi-inbox",

      children: []
    },
    {
      key: 4,
      label: "AIR",
      data: nodeDataType.segment,
      icon: "pi pi-fw pi-inbox",

      children: []
    }
  ];
}

export function getAsapSegmentFieldNames(segment) {
  let fieldNameArray = [];
  switch (segment) {
    case segments.pat:
      fieldNameArray = [
        { key: "PAT01 - ID Qualifier of Patient Identifier", value: 1 },
        { key: "PAT02 - ID Qualifier", value: 2 },
        { key: "PAT03 - ID of Patient", value: 3 },
        {
          key: "PAT04 - ID Qualifier of Additional Patient Identifier",
          value: 4
        },
        { key: "PAT05 - Additional Patient ID Qualifier", value: 5 },
        { key: "PAT06 - Additional ID", value: 6 },
        { key: "PAT07 - Last Name", value: 7 },
        { key: "PAT08 - First Name", value: 8 },
        { key: "PAT09 - Middle Name", value: 9 },
        { key: "PAT10 - Name Prefix", value: 10 },
        { key: "PAT11 - Name Suffix", value: 11 },
        { key: "PAT12 - Address Information", value: 12 },
        { key: "PAT13 - Address Information", value: 13 },
        { key: "PAT14 - City Address", value: 14 },
        { key: "PAT15 - State Address", value: 15 },
        { key: "PAT16 - ZIP Code Address", value: 16 },
        { key: "PAT17 - Phone Number", value: 17 },
        { key: "PAT18 - Date of Birth", value: 18 },
        { key: "PAT19 - Gender Code", value: 19 },
        { key: "PAT20 - Species Code", value: 20 },
        { key: "PAT21 - Patient Location Code", value: 21 },
        { key: "PAT22 - Country of Non-U.S. Resident", value: 22 },
        { key: "PAT23 - Name of Animal", value: 23 }
      ];
      break;
    case segments.dsp:
      fieldNameArray = [
        { key: "DSP01 - Reporting Status", value: "DSP01" },
        { key: "DSP02 - Prescription Number", value: "DSP02" },
        { key: "DSP03 - Date Written", value: "DSP03" },
        { key: "DSP04 - Refills Authorized", value: "DSP04" },
        { key: "DSP05 - Date Filled", value: "DSP05" },
        { key: "DSP06 - Refill Number", value: "DSP06" },
        { key: "DSP07 - Product ID Qualifier", value: "DSP07" },
        { key: "DSP08 - Product ID", value: "DSP08" },
        { key: "DSP09 - Quantity Dispensed", value: "DSP09" },
        { key: "DSP10 - Days Supply", value: "DSP10" },
        { key: "DSP11 - Drug Dosage Units Code", value: "DSP11" },
        {
          key: "DSP12 - Transmission Form of Rx Origin Code",
          value: "DSP12"
        },
        { key: "DSP13 - Partial Fill Indicator", value: "DSP13" },
        {
          key: "DSP14 - Pharmacist National Provider Identifier (NPI)",
          value: "DSP14"
        },
        { key: "DSP15 - Pharmacist State License Number", value: "DSP15" },
        {
          key: "DSP16 - Classification Code for Payment Type",
          value: "DSP16"
        },
        { key: "DSP17 - Date Sold", value: "DSP17" },
        { key: "DSP18 - RxNorm Product Qualifier", value: "DSP18" },
        { key: "DSP19 - RxNorm Code", value: "DSP19" },
        {
          key: "DSP20 - Electronic Prescription Reference Number",
          value: "DSP20"
        },
        {
          key: "DSP21 - Electronic Prescription Order Number",
          value: "DSP21"
        },
        { key: "DSP22 - Quantity Prescribed", value: "DSP22" },
        { key: "DSP23 - Rx SIG", value: "DSP23" },
        { key: "DSP24 - Treatment Type", value: "DSP24" },
        { key: "DSP25 - Diagnosis Code", value: "DSP25" }
      ];
      break;
    case segments.pre:
      fieldNameArray = [
        { key: "PRE01 - National Provider Identifier", value: "PRE01" },
        { key: "PRE02 - DEA Number", value: "PRE02" },
        {
          key: "PRE03 - DEA Number SuffixPRE03 - DEA Number Suffix",
          value: "PRE03"
        },
        { key: "PRE04 - Prescriber State License Number", value: "PRE04" },
        { key: "PRE05 - Last Name", value: "PRE05" },
        { key: "PRE06 - First Name", value: "PRE06" },
        { key: "PRE07 - Middle Name", value: "PRE07" },
        { key: "PRE08 - Phone Number", value: "PRE08" },
        { key: "PRE09 - XDEA Number", value: "PRE09" },
        {
          key: "PRE10 - Prescriber State License Number Jurisdiction",
          value: "PRE10"
        }
      ];
      break;
    case segments.cdi:
      fieldNameArray = [
        {
          key: "CDI01 - Compound Drug Ingredient Sequence Number",
          value: "CDI01"
        },
        { key: "CDI02 - Product ID Qualifier", value: "CDI02" },
        { key: "CDI03 - Product ID", value: "CDI03" },
        { key: "CDI04 - Component Ingredient Quantity", value: "CDI04" },
        { key: "CDI05 - Compound Drug Dosage Units Code", value: "CDI05" }
      ];
      break;
    case segments.air:
      fieldNameArray = [
        { key: "AIR01 - State Issuing Rx Serial Number", value: "AIR01" },
        { key: "AIR02 - State Issued Rx Serial Number", value: "AIR02" },
        { key: "AIR03 - ID Issuing Jurisdiction", value: "AIR03" },
        {
          key: "AIR04 - ID Qualifier of Person Dropping Off or Picking Up Rx",
          value: "AIR04"
        },
        {
          key: "AIR05 - ID of Person Dropping Off or Picking Up Rx",
          value: "AIR05"
        },
        {
          key: "AIR06 - Relationship of Person Dropping Off or Picking Up Rx",
          value: "AIR06"
        },
        {
          key: "AIR07 - Last Name of Person Dropping Off or Picking Up Rx",
          value: "AIR07"
        },
        {
          key: "AIR08 - First Name of Person Dropping Off or Picking Up Rx",
          value: "AIR08"
        },
        {
          key: "AIR09 - Last Name or Initials of Pharmacist",
          value: "AIR09"
        },
        { key: "AIR10 - First Name of Pharmacist", value: "AIR10" },
        {
          key: "AIR11 - Dropping Off/Picking Up Identifier Qualifier",
          value: "AIR011"
        }
      ];
      break;
  }

  return fieldNameArray;
}
