import React from "react";
import { AwPageContainer } from "../../../AwPageContainer";
import { PharmacistGrid } from "./PharmacistGrid";
export const Pharmacist = ({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <PharmacistGrid stateProvinceId={stateProvinceId}></PharmacistGrid>
    </AwPageContainer>
  );
};

export default Pharmacist;
