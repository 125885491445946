import React from "react";
import { AwPageContainer } from "../../AwPageContainer";
import { AccountGrid } from "./AccountGrid";
export const Accounts = React.memo(({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <AccountGrid stateProvinceId={stateProvinceId}></AccountGrid>
    </AwPageContainer>
  );
});
