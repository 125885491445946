import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { AwModalTitle } from "./AwModalTitle";
import { getLabel } from "../../utils";


interface Props {
    title: string;
    queryAsync?: any;
    children?: any;
}

export const AwGenericPModal: React.FC<Props> = ({ title, queryAsync, ...props }) => {
  const [show, setShow] = useState(false);

  const modal = {
    open: () => setShow(true),
    close: () => setShow(false)
  };

  
  const openModalAsync = async (e: any) => {
    if (e) e.preventDefault();
    if(queryAsync) await queryAsync();
    modal.open();
  };

  const closeModal = () => {
    modal.close();
  };

  const chn: any[] = React.Children.toArray(props.children);

  return (
    <>
      <span className="btn-span">
        {chn.length > 0 && React.cloneElement(chn[0].props.children, { onClick: openModalAsync, tabIndex: "0" }) }
      </span>

      <Modal show={show} backdrop="static" onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <AwModalTitle>{getLabel(title)}</AwModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {chn.length > 1 && chn[1].props.children }
        </Modal.Body>
      </Modal>
    </>
  );
};