import React from "react";

import "./Dashboard.css";
import { TopLevelLogic } from "./TopLevelLogic";
import { DashboardGrid } from "./DashboardGrid";
import { AwPageContainer } from "../AwPageContainer";

const Dashboard = () => {
  return (
    <AwPageContainer>
      <TopLevelLogic />
      <DashboardGrid />
    </AwPageContainer>
  );
};

export default Dashboard;
