import React, { useEffect } from "react";
import { useInputSelectionFuncs } from "../custom-hooks";
import AwModalListItem from "./AwModalListItem";

export const AwModalCheckList = React.memo(
  ({ lists = [], label, onChecked }) => {
    const {
      value,
      checked,
      onSelected,
      handleAllChecked,
    } = useInputSelectionFuncs(lists, onChecked, label);

    return (
      <div data-testid="aw-modal-check-list">
        <div
          id="labelWrapper"
          style={{
            fontWeight: "700",
            display: "flex",
            justifyContent: "space-between",
            marginRight: "10px",
          }}
        >
          <label className="aw-no-user-select">{label}</label>
          {lists.length > 1 && (
            <span>
              <input
                id="selectAll"
                type="checkbox"
                checked={checked}
                onChange={handleAllChecked}
              />
              Select All
            </span>
          )}
        </div>

        <div style={{ maxHeight: "200px", overflow: "auto" }}>
          <ul style={{ overflowX: "hidden" }}>
            {lists.map((list, i) => {
              return (
                <AwModalListItem
                  key={i}
                  listItem={list}
                  preSelect={lists.length === 0 ? true : value[i]}
                  handleCheck={(e) => onSelected(e, i)}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
);
