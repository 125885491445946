import React from "react";

export const SetStringAsHtml = ({ value, customClass = "" }) => {
  return (
    <div
      className={customClass}
      dangerouslySetInnerHTML={{ __html: value }}
    ></div>
  );
};
