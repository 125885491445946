import moment from "moment-timezone";

import Swal from "sweetalert2";

import { states, reportStatus } from "../defs";
import axios from "axios";

export const checkIfObjValuesHasChanged = (oldObj: any = {}, newObj: any = {}) => {
  const keys = Object.keys(newObj);
  for (let i = 0; i < keys.length; i++) {
    const item = keys[i];
    if(oldObj[item] !== newObj[item]){
      // console.log(oldObj[item], newObj[item])
      return {isModified: true, data: Object.assign({}, oldObj, newObj)}
    }
  }
  return {isModified: false, data: oldObj};
}

export const getDigits = (str: string) => {
  return str.replace(/\D/g, "");
};
// number
export const formatInputAsNumber = (str: string, max: number) => {
  str = str.replace(/[^0-9]/g, "");
  const rxStr = `^${"\\"}d{1,${max}}`;
  const re = new RegExp(rxStr, "g");
  let ret = str.match(re);
  return ret ? ret[0] : "";
};
// zip functions
export const formatInputAsZipCode = (str: string) => {
  str = str.replace(/[^0-9]/g, "");
  let ret = str.match(/^\d{1,5}/);
  return ret ? ret[0] : "";
  // return str.replace(/(\d{5})(\d{4})?/, function(_, p1, p2) {
  //   let output = "";
  //   if (p1) output = `${p1}`;
  //   if (p2) output += `-${p2}`;
  //   return output;
  // });
};

// phone functions
// export const formatInputAsPhoneNumber = (str: string) => {
//   str = str.replace(/[^0-9]/g, "");
//   let ret = str.match(/^\d{1,10}/);
//   str = ret ? ret[0] : "";
//   return str.replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function(
//     // return str.replace(/(\d{1})(\d{1,2})?(\d{1,3})?(\d{1,4})?/, function(
//     _,
//     p1,
//     p2,
//     p3,
//     p4
//   ) {
//     let output = "";
//     if (p1) output = `(${p1}`;
//     if (p2) output += `${p2})`;
//     if (p3) output += ` ${p3}`;
//     if (p4) output += `-${p4}`;
//     return output;
//   });
// };

export const formatInputAsPhoneNumber = (str: any) => {
  if (str) {
    // check the current format before modifying it
    const xterLength = str.replace(/[^()-]/g, "").length;
    // Take all digits
    let allDigits: any = getDigits(str);
    if (xterLength === 3 && allDigits.length === 10 && str.length === 14) {
      return str;
    }
    // get just ten digits as limit
    allDigits = allDigits.match(/^\d{1,10}/);
    allDigits = allDigits ? allDigits[0] : "";
    // count to make sure is either 10 or 11
    const dLength = allDigits.length;
    // code for 11 and 10 separately
    let maskPhoneNumber = "";
    if (dLength) {
      if (dLength === 10) {
        maskPhoneNumber = allDigits.match(/(\d{3})(\d{3})(\d{4})/);
        maskPhoneNumber =
          "(" +
          maskPhoneNumber[1] +
          ") " +
          maskPhoneNumber[2] +
          "-" +
          maskPhoneNumber[3];
      } else if (dLength === 11) {
        maskPhoneNumber = allDigits.match(/(\d{1})(\d{3})(\d{3})(\d{4})/);
        maskPhoneNumber =
          maskPhoneNumber[1] +
          "(" +
          maskPhoneNumber[2] +
          ") " +
          maskPhoneNumber[3] +
          "-" +
          maskPhoneNumber[4];
      } else {
        maskPhoneNumber = allDigits.match(/(\d{3})?(\d{3})?(\d{4})?/);
        if (maskPhoneNumber && maskPhoneNumber[1]) {
          if (maskPhoneNumber[1] && !maskPhoneNumber[2]) {
            maskPhoneNumber = `(${maskPhoneNumber[1]}) ${allDigits.substring(
              3
            )}`;
          } else if (maskPhoneNumber[2] && !maskPhoneNumber[3]) {
            maskPhoneNumber = `(${maskPhoneNumber[1]}) ${maskPhoneNumber[2]}`;
            if (dLength > 6) {
              maskPhoneNumber += `-${allDigits.substring(6)}`;
            }
          } else if (maskPhoneNumber[3]) {
            maskPhoneNumber = `(${maskPhoneNumber[1]}) ${maskPhoneNumber[2]}-${maskPhoneNumber[3]}`;
          }
        } else {
          return allDigits;
        }
      }
    }
    return maskPhoneNumber;
  }
  return str;
};

export const maskPhoneNumberDisplay = (str: string) => {
  if (str) {
    // check the current format before modifying it
    const xterLength = str.replace(/[^()-]/g, "").length;
    if (xterLength === 3) {
      return str;
    }
    // Take all digits
    const allDigits = str.replace(/[^0-9]/g, "");
    // count to make sure is either 10 or 11
    const dLength = allDigits.length;
    // code for 11 and 10 separately
    let maskPhoneNumber: any = "";
    if (dLength === 10) {
      maskPhoneNumber = allDigits.match(/(\d{3})(\d{3})(\d{4})/);
      maskPhoneNumber =
        "(" +
        maskPhoneNumber[1] +
        ") " +
        maskPhoneNumber[2] +
        "-" +
        maskPhoneNumber[3];
    } else {
      maskPhoneNumber = allDigits.match(/(\d{1})(\d{3})(\d{3})(\d{4})/);
      maskPhoneNumber =
        maskPhoneNumber[1] +
        "(" +
        maskPhoneNumber[2] +
        ") " +
        maskPhoneNumber[3] +
        "-" +
        maskPhoneNumber[4];
    }
    return maskPhoneNumber;
  }
  return str;
};

// form data
export function getFormData(formValues: any): FormData {
  const formData: FormData = new FormData();
  formData.append(formValues["File"].name, formValues["File"]);
  formData.append("FileName", formValues["File"].name);
  formData.append("StateProvinceId", formValues["StateProvinceId"]);
  formData.append("DateUploaded", getSpecificDate());
  formData.append("FileSize", formValues["File"].size);
  formData.append("FileType", formValues["File"].type);
  formData.append("Description", formValues["Description"]);
  formData.append("DocumentName", formValues["DocumentName"]);
  // console.log(formData.get("FileName"));
  // var ent: any = formData.entries();
  // for (const entry of ent) {
  //   console.log(entry);
  // }
  return formData;
}

export function getProfilePicFormData(fileToUpload: any): FormData {
  const formData: FormData = new FormData();
  formData.append(fileToUpload.name, fileToUpload);
  formData.append("FileName", fileToUpload.name);
  return formData;
}

export const makeDeepCopy = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const arrayToObject = (array: any, keyField: string) => {
  return array.reduce((accumulator: any, currentValue: any) => {
    accumulator[currentValue[keyField]] = currentValue;
    return accumulator;
  }, {});
};

export function getSpecificDate(date?: any, format?: string) {
  let newDate;
  if (!!date && !!format) {
    newDate = moment(`${date}`).format(`${format}`);
  } else if (!!date) {
    newDate = moment(`${date}`).format("L");
  } else {
    newDate = moment().format("MMMM Do YYYY, h:mm:ss a");
  }

  return newDate;
}

export function convertDateFromDBFormatWithoutChangingDate(date: any) {
  if(typeof date === 'string'){
 // eg. "1961-07-29T00:00:00+00:00"
     const d = date
     .toLowerCase()// make sure every character is lowercase
     .split('t')[0]// get the first part which is the actual date
     .split("-")//remove '-' from the date so javascript doesn't convert it to previous date
     .join("/");// join it with '/' - '1961/07/29'
     return d;
  }
    
  return new Date(date);
}

export function convertStringToDate(dateStr: string) {
  return moment(moment(dateStr).format("MM/DD/YY"), "MM/DD/YY").toDate();
}
export function initShortDate(date: Date) {
  return moment(date).format("MM/DD/YY");
}

export function semanticDropDownArraySetUp(
  items: any[],
  value: string,
  text: string,
  text2?: string
) {
  const newArray = items.reduce((sum, cv, ci) => {
    sum[ci] = {
      key: cv[value],
      value: cv[value],
      text: !!text2 ? `${cv[text]} - ${cv[text2]}` : cv[text]
    };
    return sum;
  }, []);
  return newArray;
}

export function getFormKeys(formObj: any, chunkSize: number = 1) {
  let result: any[] = [];
  let arrayOfkeys = Object.keys(formObj);
  if (chunkSize > 1) {
    const spliceSize = Math.ceil(arrayOfkeys.length / chunkSize);
    while (arrayOfkeys.length) {
      result.push(arrayOfkeys.splice(0, spliceSize));
    }
  } else {
    result = arrayOfkeys;
  }

  return result;
}

export const divideFormGroupsIntoCols = (formGroups: any[]) => {
  const results = [];
  let array = JSON.parse(JSON.stringify(formGroups));
  const spliceSize = Math.ceil(array.length / 2);
  while (array.length) {
    results.push(array.splice(0, spliceSize));
  }
  return results;
};

export const getKeysWithoutIds = (items: { [x: string]: any }): string[] => {
  const keys = Object.keys(items);
  return keys.filter(str =>
    str.length > 2 ? str.substring(str.length - 2) !== "Id" : false
  );
};
export const getFieldLabelFromFieldName = (name: any) => {
  const str = name;
  const len = str.match(/\S+/g).length;
  if (!!str && len < 2) {
    if (len === 1) {
      return str;
    }
    return str.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  }
  return str;
};

interface IEvent {
  target: any;
  [x: string]: any;
}

export const getNewEventObj = (
  oldValue: string,
  newValue: string,
  fieldName: string
) => {
  let e: IEvent = { target: {} };
  e.target["oldValue"] = oldValue;
  e.target["newValue"] = newValue;
  e.target["value"] = newValue;
  e.target["name"] = fieldName;
  return e;
};

export const isResponseError = (res: any) => {
  if (!!res) {
    var arr: any[] = Object.keys(res || {});
    if (arr.indexOf("message") > -1) {
      // throw new Error(res);
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const awShowErrorToastr = (msgSvc: any, res: any, msg: string, replace = false): boolean => {
  let success = !isResponseError(res);
  if(!success) {
    if(replace) msg = res.message;
    msgSvc.showError(msg, replace);
  }
  return success;
}
export const awShowToastr = (msgSvc: any, res: any, msg: string, replace = false): boolean => {
  let success = !isResponseError(res);
  if(success) {
    replace ? msgSvc.showSuccess(msg, replace) : msgSvc.showSuccess(msg);
  } else {
    if(replace) msg = res.message;
    msgSvc.showError(msg, replace);
  }
  return success;
}



export const screenTypes = {
  phone: "phone",
  landscapePhone: "landscapePhone",
  portraitTablet: "portraitTablet",
  landscapeTablet: "landscapeTablet",
  smallScreen: "smallScreen",
  largeScreen: "largeScreen"
};

// getting State name
export function getStateName(abbr: any): string {
  return (states as any)[abbr];
}

export const getLabel = (str: string | any): string => {
  return str.match(/[A-Z][a-z]+|[0-9]+/g).join(" ") || "";
} 
// form functions
function getFormLabel(fieldName: any): string {
  if (fieldName === "StateProvinceId") {
    return "State";
  } else if (fieldName === "NcpdpOrNabpId") {
    return "NCPDP/NABP Id";
  } else if (fieldName.length > 4) {
    return fieldName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ") || "";
    // return fieldName.match(/[A-Z][a-z]+|[0-9]+|[AA-ZZ]+|[a-z]+/g).join(" ");
  }
  return fieldName;
}

function testStrWithIdsAtTheEnd(str: string) {
  const re = /\w*Id\b/g;
  return re.test(str);
}

export function createFormKeysObjArr(
  obj: any,
  specialInputObjs: any,
  excludeKeysArr: any[] = [],
  mustIncludedKeys = []
) {
  let retValue = []; // initialize return value
  // get obj keys
  let objKeys = Object.keys(obj);
  // filter Id values out
  objKeys = objKeys.filter(
    str =>
      !testStrWithIdsAtTheEnd(str) &&
      !(
        excludeKeysArr.includes(str) ||
        excludeKeysArr.includes(str.toLowerCase())
      )
  );
  // include exception keys
  objKeys = objKeys.concat(mustIncludedKeys);
  // create new obj keys array
  retValue = createFormKeysObjArrFromStrArray(objKeys, specialInputObjs);
  return retValue;
}

export function createFormKeysObjArrFromStrArray(
  objKeys: string[],
  specialInputObjs: any = {}
) {
  return objKeys.map((str, i) => {
    // set the new keys for the forms
    let sKeys = { name: str, label: getFormLabel(str), type: "text" };
    // change the input type for special input fields
    if (str in specialInputObjs || str.toLowerCase() in specialInputObjs) {
      // sets the new type for this particular key
      // this helps to make sure the lowercase version of the desc can be captured str.replace(/^\w/, c => c.toLowerCase())
      sKeys["type"] = specialInputObjs[str]
        ? specialInputObjs[str]
        : specialInputObjs[str.replace(/^\w/, c => c.toLowerCase())];
    }
    return sKeys;
  });
}

// Error checking
export function hasErrorMessage(res: any) {
  return !Array.isArray(res) && res && "message" in res;
}

// queryparams for grid
function trimParams(str: string) {
  if (!!str && typeof str !== "number") {
    str = str.trim();
    return str;
  } else {
    return str;
  }
}

export const getQueryParams = (
  state: any,
  allReports: boolean,
  tableName: string
) => {
  let params = "";
  if (tableName === "dashboard") {
    params = `pNumber=${trimParams(state.skip)}&&pSize=${trimParams(
      state.take
    )}&&allReports=${allReports}`;
    // } else if (tableName === "documents") {
    //   params = `stateProvinceId=${trimParams(urlStateProvinceId)}
    //                 &&pageNumber=${trimParams(state.skip)}
    //                  &&pageSize=${trimParams(state.take)}`;
  } else {
    params = `pageNumber=${trimParams(state.skip)}&&pageSize=${trimParams(
      state.take
    )}`;
  }

  if (!!state.sort && state.sort.length > 0) {
    params += `&&sortBy=${trimParams(
      state.sort[0].field
    )}&&sortOrder=${trimParams(state.sort[0].dir)}`;
  }

  if (state.filter && state.filter.filters.length > 0) {
    const filterLength = state.filter.filters.length;
    const filter = state.filter.filters[filterLength - 1];

    params += `&&filterBy=${trimParams(
      filter.field
    )}&&filterOperator=${trimParams(filter.operator)}&&filterValue=${trimParams(
      getQueryParamsFilterValue(filter.field, filter.value, tableName)
    )}`;
  }

  return params;
};

const getQueryParamsFilterValue = (
  field: string,
  value: string,
  tableName: string
) => {
  return tableName === "dashboard" && field === "StatusId"
    ? getDashboardStatusId(value)
    : value.trim();
};
const getDashboardStatusId = (value: string) => {
  let valuesString: string = "";
  reportStatus.forEach(item => {
    if (item.Status.toLowerCase().startsWith(value.toLowerCase())) {
      if (valuesString) {
        valuesString += `,${item.StatusId}`;
      } else {
        valuesString += `${item.StatusId}`;
      }
    }
  });
  return valuesString.trim();
};

export const checkAndGetAbsoluteLink = (link: string) => {
  let output = link;
  const securedLink = "https://";
  const isAbsolute = link.startsWith(securedLink);
  if (!isAbsolute) {
    output = `${securedLink}${output}`;
  }
  return output;
};

// Sweet alert confirmation
export const awSwalAlert = (
  msg: string = "Something went wrong",
  type: any = "warning",
  html: boolean = false
) => {
  // Swal.fire("Deleted!", "Your file has been deleted.", "success");
  Swal.fire({

  })
  Swal.fire({
    // text: msg,
    icon: type,
    html: msg,
    // customClass: html ? "aw-html" : ""
    customClass: {
      container: html ? "aw-html" : ""
    }
  });
  // Swal.fire({
  //   icon: "error",
  //   title: 'Oops...',
  //   text: 'Something went wrong!'
  // })
};
export const awConfirmDelete = (
  custText: string = "You won't be able to revert this change!"
) => {
  return Swal.fire({
    title: "Are you sure?",
    text: custText,
    icon: "warning",
    // backdrop: "static",
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: "#db2828",
    cancelButtonColor: "#6c757d",
    confirmButtonText: "Yes, delete it!"
  }).then(result => {
    return result.value;
  });
};
export const awConfirmWithUser = (custText: string) => {
  return Swal.fire({
    title: "Are you sure?",
    text: custText,
    icon: "warning",
    // backdrop: "static",
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: "#186ba0",
    cancelButtonColor: "#6c757d",
    confirmButtonText: "Yes!"
  }).then(result => {
    return result.value;
  });
};
export const deleteSuccess = () => {
  Swal.fire("Deleted!", "Your file has been deleted.", "success");
};

export const confirmVerification = (verify: string) => {
  const html = `Send me verification code to activate
  <span style="text-transform: uppercase"><b>${verify}</b>
  </span>
notification.`;
  return Swal.fire({
    html: html,
    showCancelButton: true,
    confirmButtonColor: "#186ba0",
    cancelButtonColor: "#6c757d",
    confirmButtonText: "Yes",
    cancelButtonText: "No"
  }).then(result => {
    return result.value;
  });
};

export const verifyCode = async (code: any, custTitle?: string) => {
  return Swal.fire({
    title: "Contact Verification!",
    html: custTitle,
    input: "text",
    inputAttributes: {
      autocapitalize: "off"
    },
    showCancelButton: true,
    confirmButtonText: "Verify",
    confirmButtonColor: "#186ba0",
    cancelButtonColor: "#6c757d",
    showLoaderOnConfirm: true,
    preConfirm: async value => {
      if (value === code) {
        return true;
      } else {
        return "failed";
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then(result => {
    return result;
  });
};

export const resetFormDataToEmptyObj = (newObj: any) => {
  let obj = {};
  if (newObj) {
    obj = newObj;
  }
  return { value: obj, isNewState: true };
};

// Axios configuration
const startProgress = () => {
  const progress = (window as any).ProgressBar || {};
  if (Object.keys(progress).length) {
    progress.start();
  }
};

export const endProgress = () => {
  const progress = (window as any).ProgressBar || {};
  if (Object.keys(progress).length) {
    progress.end();
  }
};
// export const getAxios = () => {
//   const instance = axios.create();
//   instance.interceptors.request.use(
//     function(config) {
//       // Do something before request is sent
//       startProgress();
//       return config;
//     },
//     function(error) {
//       // Do something with request error
//       endProgress();
//       return Promise.reject(error);
//     }
//   );

//   instance.interceptors.response.use(
//     function(response) {
//       // Any status code that lie within the range of 2xx cause this function to trigger
//       // Do something with response data
//       endProgress();
//       return response;
//     },
//     function(error) {
//       // Any status codes that falls outside the range of 2xx cause this function to trigger
//       // Do something with response error
//       endProgress();
//       return Promise.reject(error);
//     }
//   );
//   return instance;
// };

export const getAxios = () => {
  const instance = axios.create();
  instance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      startProgress();
      return config;
    },
    function(error) {
      // Do something with request error
      // endProgress();
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function(response) {
      // Do something with 2XX response
      return response;
    },
    function(error) {
      // Do something with outside 2XX response
      return Promise.reject(error);
    }
  );
  return instance;
};


export const deepCopy = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const getSelectionArray = (
  selection: any[],
  initialCollection: any[],
  neededValue: string
) => {
  return selection
    .map((v, i) =>
      typeof v === "boolean" && v ? initialCollection[i][neededValue] : null
    )
    .filter(v => v !== null);
};

export const getObjectKeyLength = (obj: { [x: string]: any }) => {
  return Object.keys(obj || {}).length;
};

export const getPagedQS = (pNumber: number, pSize: number, stateProvinceId?: string) => {
  if(stateProvinceId) return `stateProvinceId=${stateProvinceId}&pageNumber=${pNumber}&pageSize=${pSize}`;
  return `pageNumber=${pNumber}&pageSize=${pSize}`;
}

// change string to lowercase
export const changeStrToLowerCase = (str: string) => {
  return str ? str.toLowerCase() : str;
};
