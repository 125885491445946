import { Container, Typography, Box, Link} from "../../../third-party-lib";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sr.emedplus.com">
        Medquest StateReport
      </Link>{" "}
      {new Date().getFullYear()}
      {/* {". Built By MedQuest - "} */}
    </Typography>
  );
}
export default function FwFooter() {
  return (
    <div style={{ borderTop: "1px solid #ccc" }}>
      <Container component="main" maxWidth="xs">
        <Box>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
