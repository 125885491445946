import React, { useState, useEffect } from "react";
import {
  AwPrimeReactDialog,
  AwButton,
  AwDModal,
  useFormRef,
  useForm,
  useAwToastR
} from "../../../../components";
import {
  awConfirmDelete,
  isValidEmail,
  isAllRequiredFieldsAnswered,
  confirmVerification,
  verifyCode,
  resetFormDataToEmptyObj,
  awConfirmWithUser
} from "../../../../utils";

import stop from "../../../../assets/images/stop-sign-icon.png";

import { NotificationForm } from "./NotificationForm";
import {
  checkExistingUIRecords,
  whatNeedsDBVerification,
  whatNeedsUserVerification,
  getVerificatinType
} from "./LicenseUtils";
import { Alert } from "../../../../components/message";
import { formGenericErrorMsg } from "../../../../defs";
import api from "../../../../store/api";
export const LicenseAlertDialog = React.forwardRef((props, ref) => {
  const isPharmacy = props.isPharmacy ? true : false;
  const [notifications, setNotifications] = useState(props.alerts || []);
  const [formValues, setFormValueChange] = useForm({});
  const [formError, setFormError] = useState({
    errorMsg: formGenericErrorMsg,
    isError: false
  });
  const { license } = props;

  const msgSvc = useAwToastR();

  useEffect(() => {
    setNotifications(props.alerts);
  }, [props.alerts]);

  const handleDeleteClicked = async (id, idx) => {
    const isTrue = await awConfirmDelete();
    if (isTrue) {
      const queryParams = `notificationId=${id}&isPharmacist=${!isPharmacy}`;
      const isDelete = await api.requestmethods.delete(
        `api/Notifications`,
        queryParams
      );
      const alerts = notifications.filter(
        item => item.NotificationId !== item.NotificationId
      );
      setNotifications(alerts);
    }
  };

  const handleAddClicked = () => {
    modalRef.current.open();
  };
  const handleModalClose = () => {
    setFormValueChange(resetFormDataToEmptyObj());
    modalRef.current.close();
  };

  const handleSubmit = () => {
    const data = Object.assign({}, formValues);
    const x = ["DaysPriorToExp", "Interval"];
    const mobile = "MobileNumber";
    const email = "EmailAddress";
    const isBoth = "isBoth";

    let isSubmitable = isAllRequiredFieldsAnswered(x, data);
    if (isSubmitable && (!!data[mobile] || !!data[email])) {
      setFormError({
        isError: false,
        errorMsg: ""
      });
      let msg = "";

      // set noContact
      data[isBoth] = false;
      // if mobile exist add to data
      if (!!data[mobile] && data[mobile].length === 10) {
        data["isMobile"] = true;
      } else if (data[mobile] && data[mobile].length < 10) {
        msg += "Phone number should be 10 digits; ";
      }
      // if email exist add to data
      if (!!data[email] && isValidEmail(data[email])) {
        if (data["isMobile"] === true) {
          data[isBoth] = true;
        }
        data["isEmail"] = true;
      } else if (data[email] && !isValidEmail(data[email])) {
        msg += "Email should be in a format - john@example.com; ";
      }

      if (msg.length) {
        const m = "Please correct these errors; ";
        setFormError({
          isError: true,
          errorMsg: `${m}${msg}`
        });
      } else {
        // no errors
        // check alerts for contact in any of the notifications
        verifications(data);

        // check database if alerts is empty or doent have the said contact
      }
    } else {
      // define errors  for daysprior and interval
      setFormError({
        isError: true,
        errorMsg:
          "Please answer all required fields and at least one contact info!"
      });
    }
  };

  let dbAlertRef = React.useRef();

  const verifications = async contacts => {
    let whatToVerify = Object.assign({}, contacts);
    dbAlertRef.current = {
      IsPharmacist: !isPharmacy,
      PhaId: !isPharmacy ? license.PharmacistId : license.PharmacyId,
      DaysPriorToExp: contacts["DaysPriorToExp"],
      Interval: contacts["Interval"]
    };
    const nContacts = await checkExistingUIRecords(contacts, notifications);
    // if isboth and none found then verify both with db
    whatToVerify = await whatNeedsDBVerification(nContacts);
    if (whatToVerify.verify.length) {
      const contacts = Object.assign({}, dbAlertRef.current);

      whatToVerify.verify.forEach(element => {
        contacts[element] = whatToVerify[element];
      });
      const matchedDBContacts = await api.requestmethods.post(
        "api/Notifications/1/RecordCheck",
        contacts
      );

      whatToVerify = await whatNeedsUserVerification(
        whatToVerify,
        matchedDBContacts
      );
    }

    // add all verified contact to be saved on notification.
    if (whatToVerify.verified.length) {
      whatToVerify.verified.forEach(str => {
        if (contacts[str]) {
          dbAlertRef.current[str] = contacts[str];
        }
      });
    }

    // verify any contact that needs verification
    if (whatToVerify.verify.length) {
      const verStr = getVerificatinType(whatToVerify.verify);
      if (await confirmVerification(verStr)) {
        // close notifi form modal
        await userVerification(whatToVerify);
      }
    } else if (whatToVerify.verified.length) {
      // If there are contacts but none needs verification then add to notification.
      await preAddNotification();
    }
  };

  const userVerification = async contacts => {
    const contactsToVerify = contacts.verify;
    for (let i = 0; i < contactsToVerify.length; i++) {
      const code = await sendAndSetCode({
        [contactsToVerify[i]]: contacts[contactsToVerify[i]]
      });
      // close modal
      handleModalClose();
      const contact = contacts[contactsToVerify[i]];
      const va = await reVerifyCode(code, contact);
      if (va.dismiss === "cancel") {
        // canceled by user
        //await awConfirmWithUser('If confirmed you will NOT receive any notification on this contact!');
        msgSvc.showInfo("Contact wasn't verified.");
      } else if (!va.value || va.value === "failed") {
        const sec = await reVerifyCode(code, contact, 2);
        if (sec.dismiss === "cancel") {
          // canceled by user
          msgSvc.showInfo("Contact wasn't verified.");
        } else if (!sec.value || sec.value === "failed") {
          const last = await reVerifyCode(code, contact, 1);
          if (last.dismiss === "cancel") {
            // canceled by user
            msgSvc.showInfo("Contact wasn't verified.");
          } else if (!last.value || last.value === "failed") {
            // console.log("couldn't activate contact");
          } else {
            // add contact
            dbAlertRef.current[contactsToVerify[i]] = contact;
          }
        } else {
          // add contact
          dbAlertRef.current[contactsToVerify[i]] = contact;
        }
      } else {
        // add contact
        dbAlertRef.current[contactsToVerify[i]] = contact;
      }
    }

    // everything went well so i have to make sure to save it right away.
    await preAddNotification();
  };

  const preAddNotification = async () => {
    if (
      dbAlertRef.current["MobileNumber"] ||
      dbAlertRef.current["EmailAddress"]
    ) {
      const res = await addNotification(dbAlertRef.current);
      const alerts = [res].concat(notifications);
      setNotifications(alerts);
      handleModalClose();
      msgSvc.showSuccess("added");
    }
  };

  const reVerifyCode = async (code, contact, attemptLeft = 0) => {
    if (attemptLeft) {
      const text = `You have <b>${attemptLeft}</b> attempt(s) left!`;
      return await verifyCode(code, text);
    } else {
      // const text = `A verification code has been sent to your <b>${contact}</b> Please enter the code to recieve notifications.`;
      const text = `A verification code has been sent to <b>${contact}</b>. Please enter the code to recieve notifications.`;
      return await verifyCode(code, text);
    }
  };

  const codeRef = React.useRef();
  const sendAndSetCode = async data => {
    const code = await api.requestmethods.post(
      `api/Notifications/1/Verification`,
      data
    );
    codeRef.current = { ["code"]: code };
    return code;
  };

  const addNotification = async contacts => {
    dbAlertRef.current["DaysPriorToExp"] = contacts.DaysPriorToExp;
    dbAlertRef.current["Interval"] = contacts.Interval;
    dbAlertRef.current["SMS"] = !!contacts.MobileNumber ? true : false;
    dbAlertRef.current["Email"] = !!contacts.EmailAddress ? true : false;

    dbAlertRef.current["MobileNumber"] =
      dbAlertRef.current.SMS === true ? contacts.MobileNumber : "";
    dbAlertRef.current["EmailAddress"] =
      dbAlertRef.current.Email === true ? contacts.EmailAddress : "";
    dbAlertRef.current['LicenseId'] = license.LicenseId;
    
    return await api.requestmethods.post(
      `api/Notifications`,
      dbAlertRef.current
    );
  };

  const modalRef = React.useRef();

  return (
    <div>
      <AwDModal
        ref={modalRef}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
        submitBtnLabel={"Add"}
        title={`Notification Form`}
      >
        {formError.isError && (
          <Alert align="center" message={formError.errorMsg} />
        )}
        <NotificationForm onInputChange={setFormValueChange} />
      </AwDModal>
      
      <AwPrimeReactDialog header="License" ref={ref} >
        <div style={{ textAlign: "right", marginBottom: "1.5em " }}>
          <AwButton
            label={"Notification"}
            btnClicked={handleAddClicked}
            icon={"plus"}
            width={"100px"}
          />
        </div>
        {/* {isPharmacy && <NotificationForm />} */}
        {getNotificationsDisplayTable(notifications, handleDeleteClicked)}
      </AwPrimeReactDialog>
    </div>
  );
});

export default LicenseAlertDialog;

const getNotificationsDisplayTable = (notifications, deleteFunc) => {
  const styles = { width: "65px" };
  return (
    <table
      className="table table-bordered license-notification"
      id="notificationModalTable"
    >
      <thead>
        <tr>
          <th style={{ ...styles }}>Days</th>
          <th style={{ ...styles, width: "75px" }}>Interval</th>
          <th>Type</th>
          <th>Contact</th>
          <th style={{ ...styles }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {notifications.map((notification, i) => {
          return (
            <tr key={i}>
              {getNotificationsDisplayTableRowTds(notification, deleteFunc, i)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const getNotificationsDisplayTableRowTds = (notification, deleteFunc, key) => {
  return (
    <>
      <td>{notification.DaysPriorToExp}</td>
      <td>{notification.Interval}</td>
      <td>
        {notification.SMS && notification.Email && <span>Text / Email</span>}
        {notification.SMS && !notification.Email && <span>Text</span>}
        {notification.Email && !notification.SMS && <span>Email</span>}
      </td>
      <td>
        {notification.SMS && notification.Email && (
          <span>
            {notification.MobileNumber} / {notification.EmailAddress}
          </span>
        )}
        {notification.SMS && !notification.Email && (
          <span>{notification.MobileNumber}</span>
        )}
        {notification.Email && !notification.SMS && (
          <span>{notification.EmailAddress}</span>
        )}
      </td>
      <td className="text-center">
        <span className="aw-icons" tabIndex="0">
          <img
            src={stop}
            alt=""
            style={{ height: "30px", cursor: "pointer" }}
            onClick={() => deleteFunc(notification.NotificationId, key)}
          />
        </span>
      </td>
    </>
  );
};
