import { AwButton } from "../../../components";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../infrastructure";

const StateRulesBtn = () => {
  const navigate = useNavigate();
  return (
    <AwButton
      width="100%"
      type="danger"
      label="State DoCs"
      btnClicked={() => navigate(APP_URLS.rules)}
    />
  );
};

export default StateRulesBtn;
