import React from "react";
import api from "../../store/api";
import { isResponseError } from "../../utils";
// import { useAwToastR } from "./useAwToastR";
import querystring from "query-string";

export const useAuthSettingsLogic = url => {
  const baseUrl = "/api/auth";
  const [loading, setLoading] = React.useState(false);
  const [settingsMsg, setMsg] = React.useState({ success: true, msg: "" });
  
  const forgotPasswordAsync = async data => {
    // setLoading(true);
    const res = await api.requestmethods.post(url, data);
    // setLoading(false);
    if (!res)
      return {
        success: true,
        msg:
          "Email Sent Successfully!\nPlease check your email for password reset details."
      };
    return { success: false, msg: "Please provide a valid email!" };
  };

  const getQueryObj = qString => {
    const query = querystring.parse(qString);
    if (query.token) {
      query.token = query.token.split(" ").join("+");
    } else if (query.Token) {
      query.Token = query.Token.split(" ").join("+");
    }
    return Object.assign({}, query);
  };

  const runAuthApiAsync = async (urlSeg, qString) => {
    setLoading(true);
    const query = getQueryObj(qString);
    return await api.requestmethods.post(`${baseUrl}/${urlSeg}`, query);
  }

  const setResponseMsg = (res, sub, isPhone = false) => {
    if (!isResponseError(res)) {
      setMsg(state => ({
        success: true,
        msg: isPhone ? res : getVerificationMsg(true, sub)
      }));
    } else {
      setMsg(state => ({
        success: false,
        msg: isPhone ? res.message : getVerificationMsg(false, sub)
      }));
    }
    
    setLoading(false);
  }


  const confirmPhoneAsync = async (qString) => {
    const res = await runAuthApiAsync('confirmphone', qString);
    setResponseMsg(res, 'phone', true);
    return res;
  }

  const confirmEmailAsync = async qString => {
    const res = await runAuthApiAsync('confirmemail', qString);
    setResponseMsg(res, 'email');
    // response is an obj with Token and UserId property
    return `?${querystring.stringify(res)}`;
  };

  const setPasswordAsync = async (values, qString) => {
    setLoading(true);
    let query = getQueryObj(qString);
    query = Object.assign(query, values);

    const res = await api.requestmethods.post(
      url,
      query
    );

    setLoading(false);
    let msg = { success: true, msg: ""};
    if (isResponseError(res)) {
      msg = {
        success: false,
        msg: res.data
      };
    }


    return msg;
  };

  const resetPasswordAsync = async (values, qString) => {
    setLoading(true);
    let query = getQueryObj(qString);
    query = Object.assign(query, values);

    const res = await api.requestmethods.post(
      `api/auth/PasswordReset`,
      query
    );

    setLoading(false);
    let msg = "";
    if (isResponseError(res)) {
      msg = {
        success: false,
        msg: res.data
      };
    }

    return msg;
  };

  return {
    loadingSettings: loading,
    settingsMsg,
    confirmEmailAsync,
    resetPasswordAsync,
    setPasswordAsync,
    // changePasswordAsync,
    confirmPhoneAsync,
    forgotPasswordAsync
  };
};



const getVerificationMsg = (success, sub) => success ? 
`Your ${sub} has been confirmed successfully!` 
: `We couldn't verify your ${sub}. Please see your administrator for assistance.`;