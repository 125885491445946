import { useEffect } from "react";
import { useUpdateArrayBooleans } from "./useUpdateArrayBooleans";

export const useInputSelectionFuncs = (lists, onChecked, label) => {
  const { value, checked, singleValue, selectAll, resetBoolArray } = useUpdateArrayBooleans(
    lists
  );

  useEffect(() => {
    onChecked(lists, value, label);
  }, [value]);

  useEffect(() => {
    if (lists.length === 1) {
      selectAll(value, true);
    }
  }, []);

  const onSelected = (e, index) => {
    singleValue(index, e.target.checked);
  };

  const handleAllChecked = (e) => {
    selectAll(value, e.target.checked);
  };

  return {
    value,
    checked,
    onSelected,
    handleAllChecked,
    resetBoolArray
  };
};
