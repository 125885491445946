import React, { useState } from "react";
import {
  AwPasswordInputFormField,
  useForm,
  AwButton,
  SetStringAsHtml,
  useAwToastR
} from "../../../../../components";
import { Alert } from "../../../../../components/message";
import {
  checkNewPasswordValidity,
  isResponseError,
  resetFormDataToEmptyObj,
  validatePasswordFormValues,
  checkIfStrIsPasswordRules
} from "../../../../../utils";
import { SettingsHeader } from "../SettingsHeader";
import api from "../../../../../store/api";
import { useSelector } from "react-redux";

export const ChangePassword = () => {
  const [value, setValue] = useForm({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");
  const userId = useSelector(state => state.auth.CurrentUser.UserId);
  const msgSvc = useAwToastR();

  const changeUserPassword = async value => {
    const data = {
      UserId: userId,
      OldPassword: value.OldPassword,
      NewPassword: value.Password
    };

    setLoading(true);
    const res = await api.requestmethods.post(
      "api/auth/ChangePassword",
      data
    );

    if (res) {
      if (!isResponseError(res)) {
        passwordChangeSuccess();
      } else {
        setError(
          "Unable to update password at the moment. Please try again later!"
        );
      }
    } else {
      passwordChangeSuccess();
    }
    setLoading(false);
  };

  const passwordChangeSuccess = () => {
    msgSvc.showSuccess("Password has been updated successfully!", true);
    setValue(resetFormDataToEmptyObj({}));
  };

  const handleValueChange = e => {
    setValue(e);
  };

  const onFormSubmit = () => {
    setError("");
    setInfo("");
    const formMsg = validatePasswordFormValues(value);
    if (formMsg && checkIfStrIsPasswordRules(formMsg)) {
      setInfo(formMsg);
    } else if (formMsg) {
      setError(formMsg);
    } else if (!formMsg) {
      changeUserPassword(value);
    }
  };

  return (
    <form noValidate>
      <SettingsHeader header={"Change Password"} />
      {error && <Alert message={error} align={"center"} />}
      {info && (
        <div className="alert alert-danger">
          <SetStringAsHtml value={info} />
        </div>
      )}
      {formFields.map((field, i) => {
        return (
          <AwPasswordInputFormField
            key={i}
            name={field.name}
            label={field.label}
            onChange={handleValueChange}
            required={true}
          />
        );
      })}

      <div style={{ width: "90px" }}>
        <AwButton
          label={"Update"}
          loading={loading}
          btnClicked={onFormSubmit}
        />
      </div>
    </form>
  );
};

// disable={disable}
const formFields = [
  { name: "OldPassword", label: "Current password" },
  { name: "Password", label: "New password" },
  { name: "ConfirmPassword", label: "Confirm password" }
];

// disabled="!passwordReset.valid"
// <form #passwordReset="ngForm" novalidate>
//       <div className="card-body">
//         <div className="card-header settings-chn-header">
//           Change Password
//         </div>
//         <div *ngIf="!!ErrorMessage" className="alert alert-danger aw-btsp-alert" role="alert">
//           <div className="text-danger" [innerHTML]="ErrorMessage"></div>
//         </div>
//         <div className="form-group">
//           <p>Current password <em className="text-danger">*</em></p>
//           <password-wrapper>
//             <input #inputType className="form-control" type="password" ngModel name="OldPassword" required />
//           </password-wrapper>
//         </div>
//         <div className="form-group">
//           <p>New password <em className="text-danger">*</em></p>
//           <password-wrapper>
//             <input className="form-control" #inputType type="password" ngModel name="Password" required />
//           </password-wrapper>
//         </div>
//         <div className="form-group">
//           <p>Confirm password <em className="text-danger">*</em></p>
//           <password-wrapper>
//             <input className="form-control" #inputType type="password" ngModel name="ConfirmPassword" required />
//           </password-wrapper>
//         </div>
//         <div className="form-group">
//           <button [disabled]="!passwordReset.valid" className="btn aw-primary-btn" (click)="onFormSubmit(passwordReset)">Update</button>
//         </div>
//       </div>
//     </form>
