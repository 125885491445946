import React from "react";

import "./FwContent.css";
import { FwMenusWrapper, NavigationButton } from "../fwTitleBar";
import RouterOutlet from "../../RouterOutlet";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useFwAdminMenuFunc } from "../useFwAdminMenuFunc";
import { SignIn } from "../../../containers";

export const FwContent = () => {
  const location = useLocation();

  const {
    isHorizontal,
    isAuthenticated,
    onlyHorizontal,
    setOrientation
  } = useFwAdminMenuFunc();

  return (
    <div className={isAuthenticated ? "fw-content" : null}>
      {!isHorizontal && isAuthenticated && !onlyHorizontal && (
        <div style={{ backgroundColor: "#555", color: "white" }}>
          <FwMenusWrapper />
          <NavigationButton
            isDefault={true}
            color="primary"
            icon="chevron-up"
            handleClick={setOrientation}
          />
        </div>
      )}

      <div className={isAuthenticated ? "content" : ""}>
        {/* <RouterOutlet location={location} /> */}
        <Outlet />
      </div>
    </div>
  );
};


export default FwContent;
