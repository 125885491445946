import React from "react";
import { Calendar } from "primereact/calendar";

export default function AwPrimeReactCalendar({
  type,
  dateSelected,
  init = new Date(),
  showIcon = true
}) {
  const [date, setDate] = React.useState(init);
  const handleSelectedDate = e => {
    const event = { target: { name: type, value: e.value } };
    dateSelected(event);
    setDate(e.value);
  };
  return (
    <Calendar 
      value={date} 
      onChange={handleSelectedDate} 
      showIcon={showIcon} 
      className={`${showIcon ? 'with-icon' : ''}`} />
    // <div className="card flex justify-content-center">
    //     <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon />
    // </div>
  );
}


export const AwPrimeMinMaxCalender = ({dateSelected, ...props }) => {
  const [date, setDate] = React.useState(props.init);

  const handleSelectedDate = e => {
    const event = { target: { name: props.type, value: e.value } };
    if(dateSelected) dateSelected(event);
    setDate(e.value);
  };

  return (
    <Calendar value={date} onChange={handleSelectedDate} showIcon={props.showIcon} {...props} />
  )
}