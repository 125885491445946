import React from "react";
import {
  AwInputFormField,
  AwFormFieldWrapper,
  AwPhoneInput,
  AwAddressInputFields,
  AwCommentInputField
} from "../../../../components";

export const ContactForm = ({ onInputChange, dObj }) => {
  return (
    <div className="state-bookmark-modal-form">
      <form noValidate className="container">
        <div className="row">
          {contactFields.map((field, i) => {
            if (field.isPhone) {
              return getFormField("phone", dObj, field, onInputChange, i);
            } else {
              return getFormField("def", dObj, field, onInputChange, i);
            }
          })}
          <AwAddressInputFields dObj={dObj} onInputChange={onInputChange} />
          <AwCommentInputField
            name="Notes"
            dObj={dObj}
            onInputChange={onInputChange}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;

const contactFields = [
  { name: "FirstName", label: "First Name", width: "128px" },
  { name: "LastName", label: "Last Name" },
  { name: "Title", label: "Title" },
  { name: "Department", label: "Department" },
  { name: "PhoneNumber", label: "Phone 1", isPhone: true, isRequired: true },
  { name: "PhoneNumber2", label: "Phone 2", isPhone: true },
  { name: "MobileNumber", label: "Mobile", isPhone: true, isRequired: true },
  { name: "FaxNumber", label: "Fax", isPhone: true },
  { name: "Email", label: "Email 1" },
  { name: "Email2", label: "Email 2", isRequired: true },
  { name: "Url", label: "Url 1" },
  { name: "Url2", label: "Url 2", isRequired: true }
];

// { name: "Address1", label: "Address 1" },
// { name: "Address2", label: "Address 2" }
// { name: "City", label: "City" },
// { name: "StateProvinceId", label: "State", displayOnly: true },
// { name: "PostalCode", label: "Zip Code", isZipCode: true }
const getFormField = (type, dObj, field, changeFunc, i) => {
  switch (type) {
    case "phone":
      return (
        <div className="col-6" key={i}>
          <AwFormFieldWrapper
            name={field.name}
            label={field.label}
            required={field.isRequired}
          >
            <AwPhoneInput
              name={field.name}
              onChange={changeFunc}
              init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
            />
          </AwFormFieldWrapper>
        </div>
      );
    default:
      return (
        <div className="col-6" key={i}>
          <AwInputFormField
            required={field.isRequired ? false : true} // if required is set then it means is not required since required is defaulted to true
            init={Object.keys(dObj).length > 0 ? dObj[field.name] : ""}
            name={field.name}
            label={field.label}
            onChange={changeFunc}
          />
        </div>
      );
  }
};
