import React, { useState, ReactNode } from 'react';
import {Modal} from "react-bootstrap";


import { IBtnMouseEvent, IButton } from './ISessionInterface';
import { IModalProps } from '../../modal/modal-interfaces';


interface Props {
    onModalClose: any;
    onSubmit: any;
    submitBtnLabel: string;
    cancelBtnLabel?: string;
    modalTitle: string;
    children: ReactNode | any;
    showHeaderClsBtn?: boolean;

    // []
}
type RefNodeFunc = React.MutableRefObject<IModalProps>;
type Ref = RefNodeFunc | any;

export const SModal = React.forwardRef<Ref, Props>((props, ref) => {
  const { onModalClose, onSubmit, submitBtnLabel, cancelBtnLabel = "Close", modalTitle, showHeaderClsBtn = true } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setLoading(false);
    setShow(false);
  };

  const modal = {
    isShowing: () => show,
    open: () => handleShow(),
    close: () => handleHide(),
    resetLoading: () => reset()
  };

  const reset = () => {
    setLoading(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  const onHideEvent = (e?: any) => {
    onModalClose(e);
  };

  (ref as RefNodeFunc).current = modal;
  return (
    <>
      <Modal show={show} backdrop="static" onHide={onHideEvent} dialogClassName="s-modal">
        <Modal.Header closeButton={showHeaderClsBtn}>
          <Modal.Title>
            <ModalTitle title={modalTitle}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
            <ModalFooter>
                <SButton label={cancelBtnLabel} clicked={onHideEvent}/>
                <SButton label={submitBtnLabel} clicked={handleSubmit} cls="btn btn-success" />
                {/* <AwButton
                width="6em"
                label="Close"
                type="secondary"
                btnClicked={onModalClose}
                />
                <AwButton
                    width="6em"
                    label={submitBtnLabel}
                    type="default"
                    btnClicked={onSubmit}
                /> */}
            </ModalFooter>
          
        </Modal.Footer>
      </Modal>
    </>
  );
})

interface ISButton extends IButton {
  cls?: string;
}
const SButton: React.FC<ISButton> = ({label, clicked, cls = `aw-btn aw-btn-secondary`}) => {
    const handleClick = (e:IBtnMouseEvent) => {
        if (e) e.preventDefault();
        clicked(e);
      };
    return (
        <button className={cls} onClick={handleClick} >{label}</button>
    );
}


interface FooterProps {
  children?: any;
}
const ModalFooter: React.FC<FooterProps> = ({...props}) => {
    return (
        <div>
            {props.children}
        </div>
    )
}

interface TitleProps {
    title: string;
}
const ModalTitle: React.FC<TitleProps> = ({title}) => {
    return (
        <div>
            {title}
        </div>
    )
}
