import React, { useState } from "react";
import { useForm } from "./useForm";
import { useFormRef } from "./useFormRef";
import { formGenericErrorMsg, httpErrorMsg } from "../../defs";
import { resetFormDataToEmptyObj, isResponseError } from "../../utils";
import { useAwToastR } from "./useAwToastR";
import { useModalFunctions } from "./useModalFunctions";
import { useGridRefreshFunc } from "./useGridRefreshFunc";
export const useStateBasicFunctions = (stateProvinceObj, licenseInitObj) => {
  const addBtn = "Add";
  const saveBtn = "Save";
  const [modalSubmitBtn, setModalSubmitBtn] = useState(addBtn);
  const [formData, setFormData] = useForm(
    Object.keys(stateProvinceObj || {}).length ? stateProvinceObj : {}
  );
  const [formError, setFormError] = useState({
    errorMsg: formGenericErrorMsg,
    isError: false
  });

  const [formChanges, setFormChanges] = useFormRef(
    Object.keys(licenseInitObj || {}).length ? licenseInitObj : {}
  );

  const msgSvc = useAwToastR();
  const { gridRefresh, handleGridRefresh } = useGridRefreshFunc();

  const createBtnRef = React.useRef();

  // custom hook
  const { modalRef, showModal, hideModal, resetLoading } = useModalFunctions();

  const handleFormError = (msg, bool = false) => {
    if (bool) {
      resetLoading();
    }
    setFormError({ errorMsg: msg, isError: bool });
  };

  const handleUpdateClick = e => {
    setModalSubmitBtn(saveBtn);
    setFormChanges(resetFormDataToEmptyObj(e));
    setFormData(resetFormDataToEmptyObj(e));
    showModal();
  };

  const handleModalClose = () => {
    handleFormError({
      isError: false,
      errorMsg: formGenericErrorMsg
    });
    hideModal();
  };
  const handleNewModalOpen = e => {
    setModalSubmitBtn(addBtn);
    setFormData(resetFormDataToEmptyObj(e));
    showModal();
  };
  const handleHttpResponse = res => {
    const isResError = isResponseError(res);
    if (isResError) {
      // set error
      handleFormError(httpErrorMsg, true);
    } else {
      handleGridRefresh();
      handleModalClose();
      msgSvc.showSuccess("saved");
      setFormChanges(resetFormDataToEmptyObj());
    }
  };
  return {
    msgSvc,
    gridRefresh,
    handleGridRefresh,
    addBtn,
    modalSubmitBtn,
    formData,
    setFormData,
    formError,
    setFormError,
    formChanges,
    setFormChanges,

    modalRef,
    createBtnRef,
    handleModalClose,
    handleNewModalOpen,
    handleUpdateClick,
    handleFormError,

    handleHttpResponse
  };
};
