import { useRef } from "react";
import { getObjectKeyLength } from "../../utils";
export const useFormRef = (init = {}) => {
  let state = useRef(init).current;
  const handleUpdate = e => {
    if (e.isNewState) {
      // for (var member in state) delete state[member];
      // state = e.value;
      if (getObjectKeyLength(e.value)) {
        Object.assign(state, e.value);
      } else {
        // state = e.value; // didn't replace the previous object
        for (var member in state) delete state[member];
      }
    } else {
      state[e.target.name] = e.target.value;
    }
    return state;
  };

  return [state, handleUpdate];
};
