import React from "react";
import { AwFormFieldWrapper, AwSelectBox } from "../../../components";

export const PharmacySelect = ({ array }) => {
  const onStateChange = e => {};
  return (
    <div className="row">
      <div className="col-lg-12">
        <AwFormFieldWrapper name={array[0].Name} label={"Selected Pharmacy"}>
          <AwSelectBox
            array={array}
            value="Name"
            label2="StateName"
            label="Name"
            dValue={"MedQuest Pharmacy Inc"}
            onSelectValueChange={onStateChange}
          />
        </AwFormFieldWrapper>
      </div>
    </div>
  );
};

export default PharmacySelect;
