import React from "react";
import { AwLabel } from "../form-atoms";
import { initShortDate } from "../../utils";
export const AwModalPItem = ({ label, value, isDate }) => {
  return (
    <>
      {value && typeof value === "object" ? null : (
        <div style={{ display: "flex" }}>
          <AwLabel
            htmlFor={label}
            label={label === "WebUrl" ? "Web Address" : label}
          />{" "}
          : {isDate && value ? initShortDate(value) : getPropValue(value)}
        </div>
      )}
    </>
  );
};

function getPropValue(value) {
  let bolVal = 'No';
  const isBool = typeof value === 'boolean';
  if(isBool && value === true) bolVal = 'Yes';
  return isBool ? bolVal : value;
}

