import { NavigateFunction, useNavigate } from "react-router-dom";

interface iHistory {
  push: any;
  [x: string]: any;
}

export const navigateAway = (navigate: NavigateFunction, path: any) => {
  // export const navigateAway = (history: iHistory, path: any) => {
  navigate(path);
};
