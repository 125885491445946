import React, { useState, useRef } from "react";
import { getNewEventObj } from "../../../../utils";
import { AwLabel, AwSelectBox } from "../../../../components";
import { AwTransFormStrArrToObjArr } from "../../AwTransFormStrArrToObjArr";

export const AccountFormTitle = ({ options, dValue, onTypeSelected }) => {
  const type = "Type";
  const [value, setValue] = useState({ input: dValue, dropdown: dValue });
  const optionsRef = useRef(options);

  const handleSelectValue = value => {
    setValue(state => ({ ...state, dropdown: value }));
    setAndSendValue(value);
  };

  const setAndSendValue = value => {
    const e = getNewEventObj("", value, type);
    onTypeSelected(e);
  };

  return (
    <div className="form-group">
      <div>
        <span style={{ display: "inline-block" }}>
          <AwLabel htmlFor={type} label={type} required={true} />
        </span>
      </div>
      <AwTransFormStrArrToObjArr
        options={optionsRef.current.length > 0 ? optionsRef.current : options}
      >
        {(transformOption, key) => {
          return (
            <AwSelectBox
              array={transformOption}
              value={key}
              label={key}
              dValue={!!dValue ? dValue : value.dropdown}
              onSelectValueChange={handleSelectValue}
            />
          );
        }}
      </AwTransFormStrArrToObjArr>
    </div>
  );
};

export default AccountFormTitle;
