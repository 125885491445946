import React from "react";
import { AwButton, useModalFunctions, AwDModal } from "../../../../components";
import { CreateReportForm } from "./CreateReportForm";
import { useCreateReportFunc } from "./useCreateReportFunc";
import { AlertErrors } from "../../../../components/message";

export const CreateReport = () => {
  const { modalRef, showModal, hideModal, resetLoading } = useModalFunctions();

  const {
    handleFormSubmitAsync,
    handleChecks,
    setPrescriptions,
    setModalDate,
    reportCreateError,
    handleError,
    handlePharmacySelection,
    handleStateSelection
  } = useCreateReportFunc(resetLoading, hideModal);

  const handleModalClose = () => {
    handleError("", false, false);
    hideModal();
  };

  return (
    <div id="createReport" className="create-report">
      <AwButton
        width="100%"
        icon="pencil-alt"
        type="danger"
        data-test-id="createButton"
        btnClicked={showModal}
      />
      <AwDModal
        ref={modalRef}
        hideCloseBtn={true} //it hides the header close button
        onModalClose={handleModalClose}
        onSubmit={handleFormSubmitAsync}
        submitBtnLabel="Create"
        title="Create State Report"
      >
        {reportCreateError.isError && (
          <AlertErrors
            errors={
              reportCreateError.isArr
                ? reportCreateError.msg
                : [reportCreateError.msg]
            }
          />
        )}

        <CreateReportForm
          onPharmacySelected={handlePharmacySelection}
          onStateSelected={handleStateSelection}
          onCheckChanged={handleChecks}
          onDateSelected={setModalDate}
          onPrescriptionsChange={setPrescriptions}
        />
      </AwDModal>
    </div>
  );
};
