import React from "react";
import { AwPageContainer } from "../../AwPageContainer";
import { SubHistoryGrid } from "./SubHistoryGrid";
export const SubmissionHistory = React.memo(({ stateProvinceId }) => {
  return (
    <AwPageContainer>
      <SubHistoryGrid stateProvinceId={stateProvinceId}></SubHistoryGrid>
    </AwPageContainer>
  );
});
