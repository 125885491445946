import React, { useEffect, useState } from "react";
import { getNewEventObj, formatInputAsNumber } from "../../utils";

export const AwNumberInput = ({ init, name, onChange, max = 5 }) => {
  const [value, setValue] = useState(init ? init : "");

  const handleChange = e => {
    const newEvent = getNewEventObj(init, e.target.value, e.target.name);
    setValue(e.target.value);
    onChange(newEvent);
  };

  const handleOnInput = e => {
    // let eValue = formatInputAsZipCode(e.target.value);
    let eValue = formatInputAsNumber(e.target.value, max);
    e.target["value"] = eValue;
    handleChange(e);
  };

  useEffect(() => {
    setValue(init ? init : "");
  }, [init]);

  return (
    <div className="ui input" style={{ width: "100%" }}>
      <input
        className="form-control"
        autoComplete={"off"}
        name={name}
        type={"text"}
        value={value}
        onChange={handleOnInput}
      />
    </div>
  );
};

export default AwNumberInput;
