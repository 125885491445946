
export const docsActions = {
    set_cache: 'SET_DOC_CACHE',
    remove_cache: 'REMOVE_DOC_CACHE',
    set_docs: 'SET_DoCs',
    set_doc_fetch: 'SET_DoC_FETCH'
}

export const keywordsActions = {
    set_cache: 'SET_KW_CACHE',
    remove_cache: 'REMOVE_KW_CACHE',
    set_search: 'SET_SEARCH',
    set_keyword: 'SET_KEYWORD',
    delete_keyword: 'DELETE_KEYWORD',
    set_keywords: 'SET_KEYWORDS',
    set_kw_fetch:  'SET_KW_FETCH'
}