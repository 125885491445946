import React, { useEffect } from "react";

interface Props {
  getMoreDoc: (str: string) => void;
  showNavProps: (bol: boolean) => void;
  reset: boolean;
  show: boolean;
  children?: any;
}
export const DoCCardContent: React.FC<Props> = ({show, getMoreDoc, showNavProps, reset, ...props}) => {
  const d = {position: 'absolute'}
  const defaultState = {label: 'more', pos: 'absolute', height: '120px'};

  const pageNumber = 1;
  const pageSize = 50;
  const [state, setState] = React.useState(defaultState);
  

  const setDefaults = () => {
    showNavProps(false);
    setState(defaultState);
  }

  const handleHeight = async () => {
    if(state.label === defaultState.label) {
      await getMoreDoc(`pageNumber=${pageNumber}&pageSize=${pageSize}`);
      showNavProps(true);
      setState({label: 'less', pos: 'relative', height: '100%'});
    } else {
      setDefaults();
    }
  }

  useEffect(() => {  
    setDefaults();
  }, [reset])

  
    return (
      <div style={{display: 'flex',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
            minHeight: '120px',
            height: state.height,
            overflowY: 'hidden' }}>
              {props.children}
              {show && <div className={`m-l-btn-div ${state.label}`} >
                  <a style={{color: '#007ad9'}} onClick={handleHeight}>Show {state.label}...</a>
                </div>}
      </div>
    )
}
