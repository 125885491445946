import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./FwTitleBar.css";
import { FwMenuArea } from "./fwMenuArea";
import DropDownNav from "../mainNavigation/DropDownNav";
import { PrimaryMneuItems } from "./fwMenuArea/fwMenus/menuList";
import { AwTitleBar } from "./AwTitleBar";
import { useCurrentUser } from "../../custom-hooks";

export default function FwTitleBar() {
  const [isClosed, setIsClosed] = useState(true);
  const { user } = useCurrentUser();

  const menus = user.canAccessAdminPage
    ? PrimaryMneuItems
    : getBasicMenus(PrimaryMneuItems);

  const handleClose = (closed) => setIsClosed(x => closed);

  return (
    <AwTitleBar title="true">
      <div className="drop-down-div">
        <DropDownNav menus={menus} onClose={handleClose}>
          <div className="drop-down-icon-div">
            <FontAwesomeIcon icon="bars" />
            <FontAwesomeIcon icon="sort-down" className={isClosed ? '' : 'open'} />
          </div>
        </DropDownNav>
      </div>
      <FwMenuArea show={user.canAccessAdminPage} />
    </AwTitleBar>
  );
}

const getBasicMenus = menus => {
  return menus.filter(menu => menu.text.toLowerCase() != "admin");
};
