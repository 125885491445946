import { getSettingsSuccess_type, IAction, getSettingsFailed_type } from "../actiontypes";
import { arrayToObject } from "../../utils";

export interface ISettingsState {
    Settings: any[];
    DashboardStateSettings: any;
    Error: string;
}

const initState: ISettingsState = {
    Settings: [],
    DashboardStateSettings: {},
    Error: ""
  };
  
  export const settingsReducer = (state = initState, action: IAction): ISettingsState => {
    switch (action.type) {
      case getSettingsSuccess_type:
        let settingsCopy = JSON.parse(JSON.stringify(action.payload));
        settingsCopy = arrayToObject(settingsCopy, "StateProvinceId");
        return {
          ...state,
          Settings: action.payload,
          DashboardStateSettings: settingsCopy,
          Error: ""
        };
        case getSettingsFailed_type:
            return {
              ...state,
              Settings: [],
              Error: action.payload
            };
      default:
        return state;
    }
  };
  